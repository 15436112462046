import React, { useState } from "react"
import { t } from "i18next"
import { Dialog } from "primereact/dialog"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import RatingShipperView from "./rating-shipper-view"
import { RatingShipperModalProps } from "../rating-product-modal/props"
import { ratingService } from "src/services"
import { CreateRatingProps } from "src/services/rating-service/props"
import { useToast } from "src/hooks/use-toast"
import ProductEvalateRatingView from "./product-evaluate-rating-view"

export default function RatingShipperModal(props: RatingShipperModalProps) {
  const {
    ratingProductData,
    handleCloseShip,
    isShowModal,
    orderId,
    shopCode,
    handleConfirm,
  } = props
  const [dataFeedback, setDataFeedback] = useState<string>("")
  const [dataRating, setDataRating] = useState<number>(5)
  const showToast = useToast()

  const feedbackData = (value: string) => {
    setDataFeedback(value)
  }
  const ratingData = (value: number) => {
    setDataRating(value)
  }

  const handleSubmit = () => {
    const resBody: CreateRatingProps = {
      object_id: orderId,
      content: dataFeedback,
      rating: dataRating,
      medias: [],
      object_type: "SHIPPING",
      factors: [],
      notes: [],
      object_code: shopCode,
    }

    ratingService
      .createRating(resBody)
      .then(() => {
        handleConfirm()
        showToast({
          detail: `${capitalizedFirst(t("modal.rating-order-successfully"))}`,
          severity: "success",
        })
      })
      .catch(() => {
        showToast({
          detail: `${capitalizedFirst(t("modal.rating-order-failed"))}`,
          severity: "error",
        })
      })
  }
  const FooterContent = (
    <div className="flex justify-end pt-4 text-16 font-semibold leading-24">
      <div className="flex items-center">
        <button
          className="rounded-3 border border-gray-300  py-10px px-18px text-gray-700 hover:bg-gray-100"
          onClick={() => handleCloseShip()}
        >
          {capitalizedFirst(t("global.cancel"))}
        </button>
        <button
          className="ml-2 flex items-center gap-1 rounded-3 border border-blue-500 bg-blue-500 py-10px px-18px text-white hover:bg-blue-600"
          onClick={handleSubmit}
        >
          {capitalizedFirst(t("global.submit"))}
        </button>
      </div>
    </div>
  )
  return (
    <>
      <Dialog
        id="dialog_rating_ship"
        draggable={false}
        visible={isShowModal}
        footer={FooterContent}
        onHide={() => handleCloseShip()}
      >
        <div
          id="body_dialog"
          className="flex flex-col items-center border-b border-gray-200 px-4 pb-4 "
        >
          <div className="flex flex-col">
            {ratingProductData && (
              <ProductEvalateRatingView ratingProductData={ratingProductData} />
            )}
          </div>
          <div className="w-full">
            <RatingShipperView
              feedbackData={feedbackData}
              ratingData={ratingData}
            />
          </div>
        </div>
      </Dialog>
    </>
  )
}
