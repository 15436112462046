import http from "src/config/interceptors"

export const detectProduct = (productId: string) => {
  return http.get(`/stock/products/${productId}/info`, {
    withCredentials: true,
  })
}

export const detectProductByParam = (productId: string, afincd: string) => {
  return http.get(`/stock/products/${productId}/info?afincd=${afincd}`)
}
export const detectPurchase = (orderId: string) => {
  return http.get(`/order/orders/${orderId}/status`, { withCredentials: true })
}
export const detectPurchaseByParam = (orderId: string, afincd: string) => {
  return http.get(
    `/order/orders/${orderId}/status?afincd=${afincd}&token=${orderId}`
  )
}
