import React from "react"
import { StockViewItemType } from "./type"
import ImageCustom from "../../img-custom"

export const StockItemImageView = (props: StockViewItemType) => {
  const { data, selectedId, updateSelected } = props
  const changeSelected = (newSelected: string) => {
    if (updateSelected && selectedId !== newSelected)
      updateSelected(newSelected)
  }
  return (
    <>
      {data?.map((i) => (
        <div
          key={i?.id}
          onClick={() => changeSelected(i?.id)}
          className={`flex w-[276px] cursor-pointer rounded-2 border ${
            i?.id === selectedId && "border-blue-600 bg-blue-50"
          }`}
        >
          {
            i?.image?.id ? (
              <div className="flex h-[44px] w-[44px]">
                <ImageCustom
                  className="h-full w-full rounded-l-2"
                  url={i?.image?.id}
                  alt="productImg"
                />
              </div>
            ) : null
          }

          <div className="flex flex-1 items-center rounded-r-2 px-1 h-[44px]">
            <span className="text-12 font-normal leading-18 text-gray-700">
              {i?.name}
            </span>
          </div>
        </div>
      ))}
    </>
  )
}
