import React from "react"
import ImgCustom from "src/components/img-custom"
import { OrderProductProperties } from "src/pages/purchase-history/props"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { useNavigate } from "react-router-dom"
import { TYPELINK } from "src/constants/common"
import { convertCurrency } from "src/utils/convert-currency"
import { convertViToEn } from "src/utils/common"

export default function ProductOrders(product: OrderProductProperties) {
  const navigate = useNavigate()
  const handleGoToDetails = (id: string, name: string) => {
    const productCode = id?.split("-")?.length ? id?.split("-")[0] : ""
    navigate(`/shopping/${convertViToEn(name)}/${id}/${productCode}`)
  }

  return (
    <div className="mb-2 flex w-full items-center gap-3 py-3 sm:flex-row md:px-3">
      {/* image */}
      <div className="flex h-[76px] w-[80px] items-center">
        {product.image?.includes(TYPELINK.DOMAIN_URL_YOUTUBE) ? (
          <img
            src={`https://img.youtube.com/vi/${
              product.image?.split("/").slice(-1)[0]
            }/maxresdefault.jpg`}
            alt="product image"
            className="h-[76px] w-[80px] rounded-2 border-gray-200 object-cover sm:border"
          />
        ) : (
          <ImgCustom
            className="h-[76px] w-[80px] rounded-2 border-gray-200 object-cover sm:border"
            alt="product image"
            url={product.image as string}
          />
        )}
      </div>

      <div className="flex w-full flex-col">
        {/* title */}
        <div
          onClick={() => handleGoToDetails(product.product_id, product.title)}
          className="w-full cursor-pointer items-center break-words text-14 font-semibold text-gray-700 line-clamp-2 hover:text-blue-700 md:line-clamp-3"
        >
          {product.title}
        </div>
        {/* info */}
        <div className="flex w-full items-center">
          <div className="flex w-3/4 flex-col">
            {product?.variation1 && product?.variation2 && (
              <div className="flex items-center gap-1">
                <p className="text-12 font-medium text-gray-500">
                  {translateCapitalize("shoppingCartPage.variation")}
                </p>
                <p className="text-12 font-semibold text-gray-700">
                  {`${product?.variation1}/${product?.variation2}`}
                </p>
              </div>
            )}
            <div className="flex items-center gap-1">
              <p className="text-12 font-medium leading-3 text-gray-500">
                {translateCapitalize("global.quantity")}:
              </p>
              <p className="text-12 font-semibold text-gray-700">{product.quantity}</p>
            </div>
          </div>
          {/* price */}
          <div className="flex h-full w-1/4 items-end justify-end gap-1 pt-5">
            {
              product.oldPrice && product.oldPrice !== product.currentPrice ? (
                <div className="flex items-center text-12 font-normal text-gray-500 line-through">
                  {convertCurrency(product.oldPrice + (product.oldPrice * product.tax) / 100)}
                </div>
              ) : null
            }
            <div className="flex items-center text-14 font-semibold text-gray-700">
              {convertCurrency(product.currentPrice + (product.currentPrice * product.tax) / 100)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
