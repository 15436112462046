import React from "react"
import ImgCustom from "src/components/img-custom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { DealProductType } from "../types"
import { RootState } from "src/app/store"
import { useDispatch, useSelector } from "react-redux"
import { useToast } from "src/hooks/use-toast"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { setBuyNowId } from "src/features/shopping-cart"
import { convertCurrency } from "src/utils/convert-currency"
import { mathRoundNumber } from "src/utils/common"
const DealProduct = (props: DealProductType) => {
  const {
    productImg,
    productName,
    discount,
    price,
    originPrice,
    shopId,
    liveToken,
    stockId,
    productId,
    isShopOwner,
    sold,
    quantity,
  } = props
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const showToast = useToast()
  const dispatch = useDispatch()
  const handleBuyNow = () => {
    if (authStore?.value) {
      const reqData = {
        object_type: "PRODUCT",
        entity_id: shopId || "",
        object_id: productId || "",
        stock_id: stockId || "",
        quantity: 1,
        token: liveToken || "",
      }
      new CartControllerApi(configHeader(SERVICE.CART))
        .cartsPost(reqData)
        .then((res) => {
          if (res?.data?.success) {
            const url = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`
            // const url = "http://localhost:4200/shopping/order-cart"
            dispatch(setBuyNowId(stockId || ""))
            window.open(url, "_blank")
          }
        })
        .catch(() =>
          showToast({
            detail: "Add product to cart fail",
            severity: "error",
          })
        )
    } else {
      showToast({ detail: "Please login before continue", severity: "warn" })
    }
  }
  return (
    <div className="flex items-center gap-1 rounded-3 border border-gray-200 bg-white p-4px">
      <div className="h-[40px] w-[40px] rounded-2">
        <ImgCustom
          alt="dealProduct"
          url={productImg}
          className="h-full w-full rounded-2"
        />
      </div>
      <div className="flex flex-col">
        <span className="w-[160px] text-[10px] font-medium leading-[14px] text-gray-700 line-clamp-1">
          {productName}
        </span>
        <div className="flex w-fit items-center justify-center rounded-[2px] bg-orange-dark-50 px-[6px] py-[1px] text-[10px] font-medium leading-[14px] text-orange-700 line-clamp-1">
          {capitalizedFirst(t("live-stream.discount", { value: discount }))}
        </div>
        <div className="flex items-center justify-between gap-1">
          <div className="flex flex-col items-start">
            <span className="text-[12px] font-semibold leading-[14px] text-orange-dark-600">
              {convertCurrency(price)}
            </span>
            {mathRoundNumber(price) !== mathRoundNumber(originPrice) ? (
              <span className="text-[12px] font-medium leading-[14px] text-gray-400 line-through">
                {convertCurrency(originPrice)}
              </span>
            ) : null}
          </div>
          {quantity - sold > 0 ? (
            <>
              {!isShopOwner ? (
                <button
                  onClick={handleBuyNow}
                  className="rounded-3 bg-blue-600 p-4px text-[10px] font-medium leading-[14px] text-white hover:bg-blue-500"
                >
                  {capitalizedFirst(t("live-stream.buy-now"))}
                </button>
              ) : null}
            </>
          ) : (
            <>
              <span className="text-12 font-semibold leading-[14px] text-red-500">
                {capitalizedFirst(t("global.out-stock"))}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default DealProduct
