/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from "react"
import { CustomCardProps } from "./custom-card-props"
import { CardCustomType } from "src/constants/constants/card-custom-type"

import { ButtonSeverity } from "src/constants/constants/button-severity"
import "./styles.scss"
import ButtonCustom from "src/components/button-custom/button-custom/button-custom"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { Avatar } from "primereact/avatar"
import "primeicons/primeicons.css"
import followers from "../../../assets/images/user-icon.svg"
import star from "../../../assets/images/star-icon.svg"
import box from "../../../assets/images/box-icon.svg"
import ImgCustom from "src/components/img-custom"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { bookmarkProduct, deleteBookmark, getBloomFilter } from "src/services/bookmark-service"
import { useToast } from "src/hooks/use-toast"
import { convertViToEn, handleCheckBloomFilter } from "src/utils/common"
import { BOOKMARK_CATEGORY, TYPELINK } from "src/constants/common"
import { handleCountViewBoth } from "src/services/count-view-service"
import { convertCurrency } from "src/utils/convert-currency"

export default function CustomCard(props: CustomCardProps) {
  const {
    typeCard,
    name,
    follower,
    productNumber,
    description,
    price_after_tax,
    customClassTitle,
    customClassContent,
    customClassSubContent,
    original_price_after_tax,
    voucherName,
    voucherCondition,
    voucherExpiredDate,
    id,
    imgUrl,
    rating,
    shopId,
    // point,
    sub_label,
    handleReload,
    bloom_filter,
    sold,
    avatar,
    flash_deal_status,
    flash_deals,
    flash_deals_time,
    shop_id,
    shop_name,
    vouchers,
    isShowVoucherShip,
    structView,
  } = props

  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const systemStore = useSelector((state: RootState) => state.system)
  const { t } = useTranslation()
  const [bloomData, setBloomData] = useState<string>("")
  const [followerNew, setFollowerNew] = useState<number>(0)
  const [play, setPlay] = useState<number>(0)
  const [autoPlay, setAutoPlay] = useState<boolean>(false)
  const [isClickBookMark, setIsClickBookMark] = useState<boolean>(false)

  // handle bookmark
  const handleBookmark = (id: string | undefined) => {
    if(isClickBookMark) return
    setIsClickBookMark(true)
    if (authStore?.value && id) {
      if (bloom_filter) {
        if (!handleCheckBloomFilter(bloom_filter as string, id as string)) {
          bookmarkProduct(id, BOOKMARK_CATEGORY.PRODUCT)
            .then(() => {
              if (handleReload) {
                handleReload()
              }
            })
            .catch((error) => {
              error
            }).finally(() => setIsClickBookMark(false))
        } else {
          deleteBookmark(id)
            .then(() => {
              if (handleReload) {
                if (handleReload) {
                  handleReload()
                }
              }
            })
            .catch((error) => {
              error
            }).finally(() => setIsClickBookMark(false))
        }
      } else {
        bookmarkProduct(id, BOOKMARK_CATEGORY.PRODUCT)
          .then(() => {
            if (handleReload) {
              handleReload()
            }
          })
          .catch((error) => {
            error
          }).finally(() => setIsClickBookMark(false))
      }
    } else {
      showToast({ detail: t("product-details.login-before-bookmark"), severity: "warn" })
      setIsClickBookMark(false)
    }
  }

  const handleSelectItem = (id: string | undefined) => {
    if (authStore?.value) {
      handleCountViewBoth(shop_id as string, id as string)
    }
  }

  const handleGetNewNameToNavigate = (id: string | undefined, name?: string) => {
    const productCode = id?.split("-")?.length ? id?.split("-")[0] : ""
    const link = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/${convertViToEn(name as string)}/${id}/${productCode}`
    return link
  }

  const handleSelectShop = (id: string | undefined) => {
    if (authStore?.value) {
      handleCountViewBoth(id as string)
    }
  }

  const handleGetLinkOfShop = (id: string) => {
    const shopCode = id?.split("-")?.length ? id?.split("-")[0] : ""
    return shop_name ? `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/merchant/${convertViToEn(shop_name as string)}/${id}/${shopCode}` : `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/merchant/${id}`
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  const handleBookmarkShop = async () => {
    if(isClickBookMark) return
    setIsClickBookMark(true)
    if (authStore?.value && shopId) {
      if (bloomData) {
        if (!handleCheckBloomFilter(bloomData as string, shopId as string)) {
          bookmarkProduct(shopId, BOOKMARK_CATEGORY.SHOP)
            .then(() => {
              setFollowerNew(followerNew + 1)
              handleGetBloomData()
            })
            .catch((error) => {
              throw error
            }).finally(() => setIsClickBookMark(false))
        } else {
          deleteBookmark(shopId)
            .then(() => {
              handleGetBloomData()
              setFollowerNew(followerNew - 1)
            })
            .catch((error) => {
              error
            }).finally(() => setIsClickBookMark(false))
        }
      } else {
        bookmarkProduct(shopId, BOOKMARK_CATEGORY.SHOP)
          .then(() => {
            handleGetBloomData()
            setFollowerNew(followerNew + 1)
          })
          .catch((error) => {
            throw error
          }).finally(() => setIsClickBookMark(false))
      }
    } else {
      showToast({ detail: t("product-details.login-before-bookmark"), severity: "warn" })
      setIsClickBookMark(false)
    }
  }

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    if (bloom_filter) {
      setBloomData(bloom_filter)
    }
  }, [bloom_filter])

  useEffect(() => {
    setFollowerNew(follower as number)
  }, [follower])

  const handleGetDiscount = (originalPrice: number, price: number) => {
    return `-${Math.floor(100 - (price * 100) / originalPrice + 0.5)}%`
  }
  const isShowDisCount = (originalPrice: number, price: number) => {
    return Math.floor(100 - (price * 100) / originalPrice) > 1
  }

  const handleMouseEnter = () => {
    setPlay(1)
    setAutoPlay(true)
  }
  const handleMouseLeave = () => {
    setPlay(0)
    setAutoPlay(false)
  }

  const isCurrentDate = () => {
    if (flash_deals_time?.start_at) {
      const startDate = new Date(flash_deals_time?.start_at)
      const curDate = new Date()

      startDate.setHours(0, 0, 0, 0)
      curDate.setHours(0, 0, 0, 0)

      return startDate.getTime() === curDate.getTime()
    } else {
      return false
    }
  }

  const getTime = () => {
    if (!flash_deals_time?.start_at) return ""
    const date = new Date(flash_deals_time?.start_at)
    const hours = date.getHours().toString().padStart(2, "0")
    const minutes = date.getMinutes().toString().padStart(2, "0")
    const seconds = date.getSeconds().toString().padStart(2, "0")
    return `${hours}:${minutes}:${seconds}`
  }

  const typeDataImage = () => {
    if (avatar?.type === TYPELINK.LINK) {
      if (avatar?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)) {
        return (
          <div className="h-[175px] w-full rounded-t-3 md:h-[209px]">
            <iframe
              src={`${avatar.id}?autoplay=${play}&mute=1`}
              frameBorder="8px"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
              className="h-full w-full rounded-t-3"
            />
          </div>
        )
      } else
        return (
          <video
            src={avatar?.id}
            muted
            className="h-full w-full object-cover"
            autoPlay={autoPlay}
          ></video>
        )
    } else
      return (
        <ImgCustom
          className="max-h-[384px] w-full transform rounded-2 object-cover 
          transition duration-[1500ms]
          hover:scale-110"
          url={imgUrl as string}
          alt="images"
          type="WEB"
        />
      )
  }

  const currentVoucher = useMemo(() => {
    const result = {
      type: "",
      discountAmount: 0,
      discountPercent: 0,
      isValid: false,
    }
    const currentTime = new Date().getTime()

    if (vouchers?.length) {
      for (let i = 0; i < vouchers.length; i++) {
        const compareVoucher = vouchers[i]
        const discountValue = compareVoucher.discount_amount || 0 // PERCENT || AMOUNT
        if (currentTime >= new Date(compareVoucher.start_date).getTime() && currentTime <= new Date(compareVoucher.end_date).getTime() && (price_after_tax || 0) >= compareVoucher.min_order_price) {
          result.isValid = true
          if(compareVoucher.discount_type == "PRICE" && discountValue > result.discountAmount) {
            result.discountAmount = discountValue
          }
          if(compareVoucher.discount_type == "PERCENTAGE" && discountValue > result.discountPercent) {
            result.discountPercent = discountValue
          }
        }
      }
    }
    if(result.isValid && Number(price_after_tax || 0) * (result.discountPercent / 100) > result.discountAmount) {
      result.type = "PERCENTAGE"
    } else {
      result.type = "PRICE"
    }
    return result
  }, [vouchers])

  return (
    <div>
      {typeCard?.toLocaleUpperCase() === CardCustomType.product && (
        <div
          className="h-full w-full cursor-pointer rounded-3 border border-gray-200 bg-white flex flex-col"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="relative">
            <div
              className="h-[175px] w-full rounded-t-3 md:h-[209px]"
              onClick={() => handleSelectItem(id)}
            >
              <a href={handleGetNewNameToNavigate(id, name)}>{typeDataImage()}</a>
            </div>
            <div
              onClick={() => handleBookmark(id)}
              className={`absolute right-6px top-6px flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full bg-gray-100 fill-none stroke-gray-500 ${id && bloom_filter && handleCheckBloomFilter(bloom_filter as string, id as string)
                ? "fill-yellow-500 stroke-yellow-500"
                : "fill-none stroke-gray-500"
                }`}
            >
              <svg width="16" height="16" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
              </svg>
            </div>
            {original_price_after_tax &&
              original_price_after_tax !== 0 &&
              price_after_tax !== original_price_after_tax &&
              isShowDisCount(original_price_after_tax as number, price_after_tax as number) ? (
              <div className="custom-button-shadow absolute left-6px top-6px cursor-pointer rounded-1 bg-gray-200 px-6px py-[1px] text-12 font-medium leading-18 text-red-500">
                <p className="text-white">
                  {handleGetDiscount(original_price_after_tax as number, price_after_tax as number)}
                </p>
              </div>
            ) : null}

            {/* <div className="absolute bottom-[27px] left-1 flex h-4 w-4 items-center justify-center rounded-full bg-white">
              <img src={giftIcon} alt="gift" className="h-3 w-3" />
            </div> */}

            {/* sub label */}
            {sub_label && (
              <div className="absolute bottom-0 left-0 rounded-tr-2 bg-gradient-to-l from-orange-dark-400 to-orange-dark-500 px-6px py-2px text-10 font-medium leading-16 text-white">
                {sub_label}
              </div>
            )}

            {/* attributes */}
            <div className="absolute bottom-4px right-4px flex h-[132px] w-40px flex-col-reverse gap-4px">
              {flash_deals?.map((flash_deal) => (
                <div
                  key={flash_deal?.id}
                  className="flex h-30px w-full flex-col items-center justify-center bg-indigo-600"
                >
                  <ImgCustom
                    url={flash_deal?.filter_thumbnail}
                    alt={flash_deal?.title}
                    className="h-full w-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>

          <a
            className="flex-1 justify-between flex flex-col px-2 py-2px md:py-6px"
            onClick={() => handleSelectItem(id)}
            href={handleGetNewNameToNavigate(id, name)}
          >
            <div className="flex flex-col gap-6px">
              <div
                id="product_title"
                className="flex h-[40px] max-w-[250px] cursor-pointer pt-4px text-[13px] font-medium leading-18 hover:text-blue-light-500  hover:underline sm:text-14"
              >
                {name}
              </div>
              {/* FLASH DEAL TASH */}
              {
                structView?.isFlashView ? (
                  <>
                    <div className="flex h-[20px] w-full justify-start">
                      {["ONGOGING", "UPCOMING"].includes(flash_deal_status ?? "") && (
                        <div
                          className={`item flex items-center justify-center rounded-[2px] py-2px px-4px text-[11px] font-medium text-orange-600 sm:text-12 ${flash_deal_status === "ONGOGING" ||
                            (flash_deal_status === "UPCOMING" && isCurrentDate())
                            ? "bg-orange-50"
                            : ""
                            }`}
                        >
                          <p>
                            {flash_deal_status === "ONGOGING" ? "Flashdeal" : ""}
                            {flash_deal_status === "UPCOMING" && isCurrentDate()
                              ? `Flashdeal at ${getTime()}`
                              : ""}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                ) : null
              }
              {/* SOLD */}
              {
                structView?.isSoldView ? (
                  <>
                    <div className="flex items-center justify-start gap-[2px]">
                      {sold && sold > 0 ? (
                        <div className="flex items-center gap-[2px] text-[11px] font-normal leading-18 text-gray-600 sm:text-12">
                          {`${sold} ${t("global.sold")}`}
                        </div>
                      ) : (
                        <div className="flex h-[18px] w-full"></div>
                      )}
                    </div>
                  </>
                ) : null
              }
              {/* SYSTEM VOUCHER */}
              {
                isShowVoucherShip ? (
                  <>
                    {
                      systemStore.shipVoucherMin?.voucher_discount_range?.length && systemStore.shipVoucherMin?.voucher_discount_range[0].discount_percent ? (
                        <div className="flex items-center gap-1">
                          <div className="rounded-[4px] px-4px py-2px bg-blue-500 text-white text-[12px] leading-[16px] font-medium items-center justify-center">
                            <span>{capitalizedFirst(t("system.system-voucher", { value: systemStore.shipVoucherMin?.voucher_discount_range[0].discount_percent }))}</span>
                          </div>
                        </div>
                      ) : <div className="flex h-[20px] w-full"></div>
                    }
                  </>
                ) : null
                

              }
              {/* SYSTEM VOUCHER */}
              {
                !isShowVoucherShip ? (
                  <>
                    {
                      systemStore.systemVoucher?.discount_percent ? (
                        <div className="flex items-center gap-1">
                          <div className="rounded-[4px] px-4px py-2px bg-blue-500 text-white text-[12px] leading-[16px] font-medium items-center justify-center">
                            <span>{capitalizedFirst(t("system.system-voucher", { value: systemStore.systemVoucher.discount_percent }))}</span>
                          </div>
                        </div>
                      ) : null
                    }
                  </>
                ) : null               
              }
              {/* SHOP VOUCHER */}
              {
                currentVoucher.isValid && (currentVoucher?.discountAmount || currentVoucher?.discountPercent) ? (
                  <div className="flex items-center gap-1">
                    <div className="rounded-[4px] px-4px py-2px bg-orange-dark-50 text-orange-700 text-[12px] leading-[16px] font-medium items-center justify-center">
                      <span>{capitalizedFirst(t("system.vouchers", { value: currentVoucher.type === "PRICE" ? convertCurrency(currentVoucher.discountAmount) : `${currentVoucher.discountPercent}%` }))}</span>
                    </div>
                  </div>
                ) : <div className="flex h-[20px] w-full"></div>
              }
            </div>
            {/*  */}
            <div className="flex flex-col w-full justify-start gap-[4px]">
              <div className="flex items-center truncate">
                <p className="truncate text-18 font-semibold leading-30 text-orange-dark-600 md:text-20">
                  {convertCurrency(price_after_tax as number)}
                </p>
              </div>
              <div className="flex h-[24px] items-center">
                <p className="truncate text-14 font-medium leading-6 text-gray-400 line-through md:text-16">
                  {original_price_after_tax && original_price_after_tax !== price_after_tax ? convertCurrency(original_price_after_tax) : ""}
                </p>
              </div>
            </div>
          </a>
        </div>
      )}
      {typeCard?.toLocaleUpperCase() === CardCustomType.productReComment && (
        <div className="grid h-fit w-full cursor-pointer grid-cols-8 rounded-3 border-[1px] border-gray-200 bg-white">
          <div className="relative col-span-3">
            <div className="h-[120px] w-full" onClick={() => handleSelectItem(id)}>
              <a href={handleGetNewNameToNavigate(id, name)} className="h-full w-full">
                <ImgCustom
                  url={imgUrl as string}
                  alt={name as string}
                  className="h-full w-full transform rounded-l-3 object-cover transition duration-[1500ms]
                hover:scale-110"
                />
              </a>
            </div>
            <div
              onClick={() => handleBookmark(id)}
              className={`absolute right-4px top-4px flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full bg-gray-100 fill-none stroke-gray-500 ${bloom_filter && id && handleCheckBloomFilter(bloom_filter as string, id as string)
                ? "fill-yellow-400 stroke-yellow-400"
                : "fill-none stroke-gray-500"
                }`}
            >
              <svg width="16" height="16" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
              </svg>
            </div>
            {sub_label && (
              <p className="absolute bottom-0 left-0 truncate rounded-bl-2 rounded-tr-2 bg-gradient-to-l from-orange-dark-400 to-orange-dark-500 px-1 py-2px text-10 font-normal tracking-wide text-white">
                {sub_label}
              </p>
            )}
          </div>
          <a
            className="col-span-5 flex  flex-col justify-start p-2"
            onClick={() => handleSelectItem(id)}
            href={handleGetNewNameToNavigate(id, name)}
          >
            <div id="product_title" className="md:h-[40px] w-full cursor-pointer text-14 font-medium leading-5">
              {name}
            </div>
              {
                systemStore.systemVoucher?.discount_percent ? (
                  <div className="md:h-[20px] flex items-center gap-1">              
                    <div className="rounded-[4px] px-4px py-2px bg-blue-500 text-white text-[12px] leading-[16px] font-medium items-center justify-center">
                      <span>{capitalizedFirst(t("system.system-voucher", { value: systemStore.systemVoucher.discount_percent }))}</span>
                    </div>
                  </div>
                ) : null
              }
            {/* SHOP VOUCHER */}
            <div className="md:h-[20px] flex items-center gap-1 mt-6px">
              {
                currentVoucher.isValid && (currentVoucher.discountAmount || currentVoucher.discountPercent) ? (
                  <div className="rounded-[4px] px-4px py-2px bg-orange-dark-50 text-orange-700 text-[12px] leading-[16px] font-medium items-center justify-center">
                    <span>{capitalizedFirst(t("system.vouchers", { value: currentVoucher.type === "PRICE" ? convertCurrency(currentVoucher.discountAmount) : `${currentVoucher.discountPercent}%` }))}</span>
                  </div>
                ) : null
              }
            </div>

            {price_after_tax && (price_after_tax < (original_price_after_tax || 0)) ? (
              <div className="mt-1 flex-col flex justify-start gap-[4px]">
                <div>
                  <p className="text-16 font-medium text-orange-dark-500">
                    {convertCurrency(price_after_tax)}
                  </p>
                </div>
                <div>
                  <p className="text-14 font-medium leading-6 text-gray-400 line-through">
                    {convertCurrency(original_price_after_tax)}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mt-1 flex-col flex justify-start gap-[4px]">
                <div className="flex gap-1">
                  <p className="text-16 font-medium text-orange-dark-500">
                    {convertCurrency(original_price_after_tax)}
                  </p>
                </div>
                <div className="h-[24px] flex items-center"></div>
              </div>
            )}
          </a>
        </div>
      )}
      {typeCard?.toLocaleUpperCase() === CardCustomType.profile && (
        <div className="flex w-full items-center justify-between rounded-4 border-[1px] border-gray-200 bg-white lg:w-fit">
          <div className="px-1 py-1">
            <Avatar
              image={`${process.env.REACT_APP_API_URL}/storage/files/web/${imgUrl}`}
              shape="circle"
              size="xlarge"
              className="border border-amber-400"
            />
          </div>
          <div className=" flex w-full flex-col gap-2 py-1">
            <div className="flex justify-between">
              <div
                onClick={() => handleSelectShop(shopId)}
                className="flex cursor-pointer justify-start gap-1 text-16 font-semibold leading-6 text-gray-600 hover:text-blue-light-500 hover:underline"
              >
                <a href={handleGetLinkOfShop(shopId || "")}>{name}</a>
              </div>
              <div className="flex items-center justify-between gap-2 px-1">
                {bloomData &&
                  shopId &&
                  handleCheckBloomFilter(bloomData as string, shopId as string) ? (
                  <ButtonCustom
                    classCustom="rounded-2 border py-[1px] px-[6px] border-blue-700 text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                    severity={ButtonSeverity.custom}
                  >
                    <div
                      onClick={handleBookmarkShop}
                      className={`flex items-center justify-between gap-2  ${bloomData &&
                        shopId &&
                        handleCheckBloomFilter(bloomData as string, shopId as string)
                        ? "text-blue-700"
                        : "text-gray-700"
                        }`}
                    >
                      <p className="text-14 font-semibold ">
                        {capitalizedFirst(handleTranslate("global.followed"))}
                      </p>
                    </div>
                  </ButtonCustom>
                ) : (
                  <ButtonCustom
                    classCustom="rounded-2 border py-[1px] px-[6px] border-gray-300 text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                    severity={ButtonSeverity.custom}
                  >
                    <div
                      onClick={handleBookmarkShop}
                      className={`flex items-center justify-between gap-2  ${bloomData &&
                        shopId &&
                        handleCheckBloomFilter(bloomData as string, shopId as string)
                        ? "text-blue-700"
                        : "text-gray-700"
                        }`}
                    >
                      <i
                        className="pi pi-plus "
                        style={{ fontSize: "0.75rem", fontWeight: 600 }}
                      ></i>
                      <p className="text-14 font-semibold ">
                        {capitalizedFirst(handleTranslate("global.follow"))}
                      </p>
                    </div>
                  </ButtonCustom>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-start lg:flex-row">
              <div className="flex items-center justify-start border-r border-gray-300 pr-4 pl-2 text-14 font-medium text-gray-600 lg:pl-0">
                <img className="mr-2 h-[20px] w-[20px]" src={star} alt="star" />
                <p>{`${rating?.toFixed(1)}/5`}</p>
              </div>
              <div className="flex items-center justify-start border-r border-gray-300 pl-2 text-14 font-medium text-gray-600 lg:px-4">
                <img className="mr-2 h-[20px] w-[20px]" src={followers} alt="follower" />
                <p>{capitalizedFirst(handleTranslate("global.follower"))}:</p>
                {followerNew}
              </div>
              <div className="flex items-center justify-start px-4 pl-2 text-14 font-medium text-gray-600 lg:px-4">
                <img className="mr-2 h-[20px] w-[20px]" src={box} alt="box" />
                <p>{capitalizedFirst(handleTranslate("global.products"))}: </p>
                {productNumber}
              </div>
            </div>
            <div className="text-12 font-normal text-gray-700">
              {capitalizedFirst(handleTranslate(description))}
            </div>
          </div>
        </div>
      )}
      {typeCard?.toLocaleUpperCase() === CardCustomType.voucher && (
        <div>
          <div className="rounded-4 border-x border-solid border-x-orange-dark-500">
            <div className="borderT"></div>
            <div className="grid grid-cols-12">
              <div className="col-span-8  mr-[20px] flex flex-col gap-1 border-r border-dashed border-orange-dark-400 px-4">
                <div
                  className={` ${customClassTitle ? customClassTitle : "text-12 font-bold text-orange-dark-500 "
                    }`}
                >
                  {capitalizedFirst(handleTranslate(voucherName))}
                </div>
                <div
                  className={`${customClassContent
                    ? customClassContent
                    : "text-[10px] font-medium text-gray-700"
                    }`}
                >
                  {capitalizedFirst(handleTranslate(voucherCondition))}
                </div>
                <div
                  className={`${customClassSubContent
                    ? customClassSubContent
                    : "text-[10px] font-normal text-gray-700"
                    }`}
                >
                  {capitalizedFirst(handleTranslate(voucherExpiredDate))}
                </div>
              </div>
              <div className="col-span-4 flex items-center justify-start pr-2">
                <div className="flex items-center">
                  <ButtonCustom
                    type="button"
                    severity={ButtonSeverity.custom}
                    classCustom="px-1 text-14 font-semibold rounded-2 py-4px  text-white bg-gradient-to-l from-orange-dark-400 to-orange-dark-600"
                  >
                    {capitalizedFirst(handleTranslate("global.claim"))}
                  </ButtonCustom>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
