import { AddressDirectoryControllerApi } from "@soctrip/shipment-service"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { configHeader } from "src/config/interceptors"
import { SERVICE, TYPE_PAGE } from "src/constants/common"
import { getCurrencies } from "src/services/billing-service"
import { AddressType, CurrencyType, RestaurantType, ShopInformationFormType } from "../types"
import ShopAddressView from "./shop-address-view"

const ShopInformationForm = (props: ShopInformationFormType) => {
  const { dataForm, typePage, restaurantType } = props
  const [countryData, setCountryData] = useState<AddressType[]>([])
  const [currencyData, setCurrencyData] = useState<CurrencyType[]>([])
  const { t } = useTranslation()

  const isInValidField = (name: string) => {
    return !!(dataForm?.touched[`${name}`] && dataForm?.errors[`${name}`])
  }

  const itemTemplate = (option: RestaurantType) => {
    return (
      <div className="align-items-center flex">
        <div className="text-14 leading-20 text-gray-700">{option.name}</div>
      </div>
    )
  }
  const headerTemplate = () => <div className="hidden" />

  const getCountryList = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressCountriesGet()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.country_id,
              name: i?.country_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setCountryData(result)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }


  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const res = await getCurrencies()
        setCurrencyData(res?.data?.data ?? [])
      } catch (error) {
        console.log(error)
      }
    }
    fetchCurrencies()
  }, [])

  useEffect(() => {
    function initView() {
      getCountryList()
    }
    initView()
  }, [])

  const selectedCurrencyTemplate = (option: CurrencyType) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>
            {option.code} - {option.name}
          </div>
        </div>
      )
    }
    return <span>{t("request-open-page.select-a-currency") ?? ""}</span>
  }

  const countryOptionTemplate = (option: CurrencyType) => {
    return (
      <div className="align-items-center flex">
        <div>
          {option.code} - {option.name}
        </div>
      </div>
    )
  }
  // Add mew Address
  const addNewAddress = () => {
    const getId = dataForm.values.address.length ? dataForm.values.address[dataForm.values.address.length -1]?.index : 0
    const newAddressData = {
      isDefault: false,
      default_label: `Address ${getId + 1}`,
      index: getId + 1,
      country: null,
      city: null,
      district: null,
      ward: null,
      address: "",
      subAddress: "",
    }
    dataForm.setFieldValue("address", [...dataForm.values.address, newAddressData])
  }
  // delete address
  const deleteAddress = (idAddress: number) =>{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = [] as any []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataForm?.values?.address.forEach((i: any) => {
      if(i?.index !== idAddress) result.push(i)
    })
    dataForm.setFieldValue("address", [...result])
  }
  console.log(dataForm.values)
  return (
    <div className="rounded mt-3 w-full rounded-3 border-gray-200 bg-white p-3">
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {t("request-open-page.shop-information")}
            </span>
          </div>
        </div>
        <div className="ml-4 flex w-full flex-col lg:w-[760px]">
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.merchant-name")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <InputText
              className={`mt-[6px] w-full ${isInValidField("shop_name") ? "p-invalid" : ""} `}
              placeholder={t("request-open-page.enter-merchant-name") || ""}
              name="shop_name"
              value={dataForm?.values?.shop_name}
              onChange={(e) => {
                if (!dataForm?.touched?.shop_name)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ shop_name: true },
                  })
                dataForm.setFieldValue("shop_name", e.target.value)
              }}
            />
          </div>
          {dataForm.errors.shop_name && dataForm.touched.shop_name && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm.errors.shop_name}
            </span>
          )}
          {/* NEW ADRESSS VIEW */}
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataForm?.values?.address?.map((i: any) => <ShopAddressView deleteAddress={deleteAddress} countryData={countryData} index={i.index as number || 0} key={i.index} dataForm={dataForm}/>)
          }
          {/* Add new address view */}
          <div onClick={addNewAddress} className="mt-[12px] rounded-3 px-[16px] py-[8px] cursor-pointer w-fit border border-[#1570EF] text-[#1570EF] text-[14px] leading-[20px] font-semibold">
            <button className="">{"Add new"}</button>
          </div>
          {/* CURRENCY */}
          <div className="mt-1 flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.currency")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              value={dataForm?.values?.currency}
              name="currency"
              onChange={(e) => {
                if (!dataForm?.touched?.currency)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ currency: true },
                  })
                dataForm.setFieldValue("currency", e.value)
              }}
              options={currencyData}
              optionLabel="name"
              optionValue="code"
              valueTemplate={selectedCurrencyTemplate}
              itemTemplate={countryOptionTemplate}
              placeholder={t("request-open-page.select-a-currency") ?? ""}
              className={`mt-[6px] w-full ${isInValidField("currency") ? "p-invalid" : ""} `}
            />
            {dataForm.errors.currency && dataForm.touched.currency && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.currency}
              </span>
            )}
          </div>

          {typePage === TYPE_PAGE.FOOD ? (
            <div className="mt-3 flex flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {"Category type "}
                <span className="text-red-600">{" *"}</span>
              </span>
              <MultiSelect
                name="shop_category"
                value={dataForm?.values?.shop_category}
                options={restaurantType}
                onChange={(e) => {
                  if (!dataForm?.touched?.shop_category)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ shop_category: true },
                    })
                  dataForm.setFieldValue("shop_category", e.value)
                }}
                optionLabel="name"
                placeholder="Select category"
                panelHeaderTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                className={`mt-[6px] w-full ${isInValidField("shop_category") ? "p-invalid" : ""}`}
                display="chip"
              />
              {dataForm.errors.shop_category && dataForm.touched.shop_category && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.shop_category}
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ShopInformationForm
