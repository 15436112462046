import React from "react"
import "./product-detail-custom.scss"
import { Color } from "./props"
import { getImageUrl } from "src/services/file-service"

const SelectedVariantView = (props:Color) => {
  const { id, value, isActiveColor, index, isDisabled, chooseFirstList, onClickVariant, imageData } = props
  const handleCLick = () => {
    onClickVariant(imageData)
    if (!isActiveColor && chooseFirstList) chooseFirstList({ id, index, value })
  }

  return (
    <button
      disabled={isDisabled}
      onClick={() => handleCLick()}
      className={`  flex h-[44px] items-center justify-center rounded-3 border px-1 py-4px text-14 font-medium
      ${isDisabled ? "text-gray-300 !bg-gray-100 !border-gray-100" : "text-gray-700 cursor-pointer hover:bg-gray-100 "}
      ${isActiveColor ? " border-blue-600 text-blue-600" : "bg-gray-200  "}
      ${isDisabled && isActiveColor ? "!border !border-blue-200":""}
      `}
    >
      {imageData &&
      <img
        src={getImageUrl(imageData)}
        alt="img"
        className="w-[28px] h-[28px] rounded-1 object-cover mr-4px border border-gray-200 shadow-sm"
      />
      } {value}
    </button>
  )
}
export default SelectedVariantView