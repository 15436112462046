/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import { BreadCrumb } from "primereact/breadcrumb"
import { MenuItem } from "primereact/menuitem"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "src/app/store"
import BookmarkList from "src/components/bookmark-list"
import AddressList from "src/components/purchase-history/address-list"
import MyOrders from "src/components/purchase-history/my-orders"
import { PURCHASE_HISTORY_NAVIGATE_CODE } from "src/constants/common"
import { getOrderSetting } from "src/services/purchase-history-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import useWindowDimensions from "src/utils/screen-size"
import EmptyPage from "../empty-page"
import { NavigateItemProperties } from "./props"

export default function PurchaseHistory() {
  const navigate = useNavigate()
  const authStore = useSelector((state: RootState) => state.auth)
  const controller = new AbortController()
  const signal = controller.signal
  const { width } = useWindowDimensions()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [navigateItems, setNavigateItems] = useState<NavigateItemProperties[]>()
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false)
  const [currentLabel, setCurrentLabel] = useState<string>(
    "purchase-history.my-order"
  )
  const [selected, setSelected] = useState<string>(
    PURCHASE_HISTORY_NAVIGATE_CODE.PURCHASE
  )
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  const handleSelectedBar = (code: string, label: string) => {
    setSelected(code)
    setCurrentLabel(label)
  }

  const items: MenuItem[] = [
    {
      label: capitalizedFirst(handleTranslate("purchase-history.shopping")),
      command: () => {
        navigate("/shopping")
      },
    },
    {
      label: capitalizedFirst(handleTranslate(currentLabel)),
      className: "font-semibold",
      command: () => {
        // navigate("/purchase-history")
      },
    },
  ]
  const home: MenuItem = {
    icon: "pi pi-home",
    command: () => {
      navigate("/")
    },
  }

  const getSettingOrders = async (isLoading = false) => {
    setIsLoading(isLoading)
    const getOrderTab = setTimeout(async () => {
      await getOrderSetting(signal)
        .then((response) => {
          setNavigateItems(response?.data?.data)
          setIsLoading(false)
        })
        .catch(() => {
          setIsError(true)
        })
    }, 100)
    return () => {
      clearTimeout(getOrderTab)
      controller.abort()
    }
  }

  useEffect(() => {
    getSettingOrders(true)
  }, [])

  useEffect(() => {
    if (!authStore?.value) navigate("/")
  }, [authStore])

  if (isError) {
    return (
      <div className="h-full w-full">
        <EmptyPage message="Network Error. Please refresh page try again." />
      </div>
    )
  }

  return (
    <div className="relative mx-auto max-w-[1200px] px-2 pb-3 xl:px-3">
      {isLoading ? (
        <div className="my-3 flex h-screen w-full items-center justify-center gap-2 rounded-3 bg-white">
          <p className="animate-pulse duration-75">Loading...</p>
          <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col">
          <div className="flex w-full md:flex-col">
            {/* breadcrumb */}
            <div className="item-center flex w-full">
              <BreadCrumb
                model={items}
                home={home}
                className="flex h-6 items-center border-none bg-gray-50 text-16"
              />
            </div>
          </div>
          {/* content */}
          {width < 768 ? (
            <div
              onClick={() => setIsShowMenu(!isShowMenu)}
              className="flex w-full flex-1 items-center justify-between rounded-3 bg-white py-1 px-1"
            >
              <div className="flex items-center font-semibold text-gray-800">
                {capitalizedFirst(
                  t("purchase-history.my-management-portfolio")
                )}
              </div>
              {!isShowMenu ? (
                <i
                  className="pi pi-angle-up text-gray-500"
                  style={{ fontSize: "1rem" }}
                ></i>
              ) : (
                <i
                  className="pi pi-angle-down text-gray-500"
                  style={{ fontSize: "1rem" }}
                ></i>
              )}
            </div>
          ) : null}

          <div className="flex h-full w-full flex-col gap-3 md:flex-row">
            {!isShowMenu && width < 768 ? (
              <div className="w-full"></div>
            ) : (
              <div
                className={`sticky top-[72px] z-[2] mb-4 flex h-fit flex-col  rounded-3 bg-white md:w-1/4 md:gap-3 ${
                  navigateItems?.length ? "py-1" : ""
                }`}
              >
                {navigateItems
                  ?.slice(0, 2)
                  ?.map((item: NavigateItemProperties, index: number) => (
                    <div
                      onClick={() => handleSelectedBar(item?.code, item.label)}
                      key={index}
                      className="flex cursor-pointer items-center justify-between p-1 md:p-2"
                    >
                      <div
                        className={`flex items-center gap-3 hover:text-blue-light-400 ${
                          selected === item?.code
                            ? "text-blue-600"
                            : "text-gray-700"
                        }`}
                      >
                        <span className={`pi ${item.icon} text-20`}></span>
                        <div className="truncate text-14 font-semibold lg:text-16">
                          {capitalizedFirst(handleTranslate(item.label))}
                        </div>
                      </div>
                      {item?.code !== PURCHASE_HISTORY_NAVIGATE_CODE.SETTING &&
                        item?.code !==
                          PURCHASE_HISTORY_NAVIGATE_CODE.SECURITY &&
                        item?.code !== PURCHASE_HISTORY_NAVIGATE_CODE.HELP && (
                          <div
                            className={
                              "flex items-center justify-end rounded-[100px] bg-gray-200 px-1 py-[3px]"
                            }
                          >
                            <p className="text-12 font-medium text-gray-700">
                              {item?.total_item}
                            </p>
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            )}

            <div className="flex flex-col rounded-3 md:w-3/4">
              {/* my order */}
              {selected === PURCHASE_HISTORY_NAVIGATE_CODE.PURCHASE && (
                <MyOrders />
              )}
              {selected === PURCHASE_HISTORY_NAVIGATE_CODE.ADDRESS && (
                <AddressList onDataChange={getSettingOrders}/>
              )}
              {selected === PURCHASE_HISTORY_NAVIGATE_CODE.BOOKMARK && (
                <BookmarkList />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
