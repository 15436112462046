import React, { useEffect } from "react"
import ShoppingHomePageContent from "src/components/shopping-homepage-view/shopping-homepage-content"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"

export default function ShoppingHomePage() {
  useEffect(() => {
    gaPostAction(TAG_TYPE.PAGE_VIEW, {
      contentId: "HOME_PAGE",
      contentType: "HOME_PAGE",
    })
  }, [])
  return (
    <div className="mx-auto max-w-[1200px] px-3">
      <div className="mx-auto">
        <div>
          <ShoppingHomePageContent />
        </div>
      </div>
    </div>
  )
}
