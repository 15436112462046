import React, { useState } from "react"
import HeaderView from "src/components/header-view"
import { InputForm, CompleteForm } from "src/components/request-open-view"
import { DataCompleteType } from "src/components/request-open-view/types"
import { TYPE_PAGE } from "src/constants/common"

const STATE_FORM = {
  INPUT: "INPUT",
  COMPLETE: "COMPLETE",
}

export default function RequestOpen() {
  const [stateForm, setStateForm] = useState<string>(STATE_FORM.INPUT)
  const [dataComplete, setDataComplete] = useState<DataCompleteType | undefined>(undefined)
  const handleUpdateDataComplete = (data: DataCompleteType) => {
    setDataComplete(data)
    setStateForm(STATE_FORM.COMPLETE)
  }
  return (
    <div id="requestOpenPage" className="flex flex-col w-full min-h-full">
      <HeaderView />
      <div className="mt-[64px] h-[200px] bg-[linear-gradient(98.52deg,#FEAD34_4.54%,#FF7041_87.05%)]"></div>
      <div className="bg-gray-100 flex-1 flex justify-center">
        <div className="w-full max-w-[1200px] mt-[-50px] sm:mt-[-80px] lg:mt-[-120px] flex justify-around h-fit">
          {stateForm === STATE_FORM.INPUT ?
            <InputForm
              updateDateComplete={(data: DataCompleteType) => handleUpdateDataComplete(data)}
              typePage={TYPE_PAGE.PRODUCT}
            /> : <CompleteForm dataComplete={dataComplete} typePage={TYPE_PAGE.PRODUCT} />}
        </div>
      </div>
    </div>
  )
}
