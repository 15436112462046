import React, { useMemo } from "react"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import "./index.scss"
import { getLogoApp } from "src/utils/app-logo"

const ConfirmForm = () => {
  const { t } = useTranslation()
  const handleContinue = () => {
    const redirectUrl = `${process.env.REACT_APP_HOME_DOMAIN_URL}/login?r=${window.location.href}`
    window.open(redirectUrl, "_self")
  }
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])
  return (
    <div className="flex w-full flex-col p-4">
      <div className="flex items-center justify-center gap-1">
        <img className="h-[30px] w-[30px]" alt="appLogo" src={getLogoApp()} />
        <span className="items-center text-24 font-bold text-blue-600 md:flex">
          {capitalizedFirst(t("global.app-name", { getAppTitle: getAppTitle }))}
        </span>
      </div>
      <div className="mt-3 flex flex-col gap-1 text-center text-[16px] leading-[20px] text-gray-600">
        <span>{capitalizedFirst(t("modal.sign-up-complete"))}</span>
        <span>{capitalizedFirst(t("modal.continue-sign-in"))}</span>
      </div>
      <div className="mt-3 flex w-full items-center justify-center">
        <button
          onClick={handleContinue}
          className="w-full rounded-3 bg-blue-600 px-3 py-1 text-[16px] font-semibold leading-[24px] text-white hover:bg-blue-500"
        >
          {capitalizedFirst(t("modal.continue"))}
        </button>
      </div>
    </div>
  )
}

export default ConfirmForm
