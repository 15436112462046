import React, { memo, useEffect, useState } from "react"
import PhoneInput, { Value } from "react-phone-number-input"
import "react-phone-number-input/style.css"
import "./index.scss"
import { PhoneInputNumberProps } from "./props"

function PhoneInputNumber(props: PhoneInputNumberProps) {
  const {phoneNumber, updatePhoneNumber } = props
  const [value, setValue] = useState<string>("")

  
  const handleValueChange = (value: Value) => {
    if(value) updatePhoneNumber(value)
  }

  useEffect(() => {
    if(phoneNumber) setValue(phoneNumber)
  }, [phoneNumber])

  return (
    <>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="VN"
        value={value}
        onChange={handleValueChange}/>
    </>
  )
}

export default memo(PhoneInputNumber)