import React, { useEffect, useState } from "react"
import storeIcon from "src/assets/images/store-icon.svg"
import ModalData from "../modal"
import { WebsocketInstance } from "src/utils/cart-socket-instance"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
export interface DealVoucherType {
  id: string
  isShopOwner: boolean
  liveId: string
}
const StoreModal = (props: DealVoucherType) => {
  const { id, isShopOwner, liveId } = props
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const authStore = useSelector((state: RootState) => state.auth)
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )

  useEffect(() => {
    if (authStore.value && !WebsocketInstance.stompClient) {      
      WebsocketInstance?._connect()
    } else if (!authStore.value && WebsocketInstance.stompClient) {
      WebsocketInstance?._disconnect()
    }
  }, [authStore.value, shoppingCartStore])
  
  useEffect(() => {
    return (() => {
      if(WebsocketInstance.stompClient) WebsocketInstance?._disconnect()
    })
  }, [])

  return (
    <div className="flex h-[44px] w-[44px] items-center justify-center">
      <img
        onClick={() => setIsShowModal(true)}
        src={storeIcon}
        alt="storeIcon"
        className="h-[20px] w-[20px] cursor-pointer"
      />
      {isShowModal ? (
        <ModalData
          isShowModal={isShowModal}
          handleClose={() => setIsShowModal(false)}
          handleConfirm={() => {
            setIsShowModal(false)
          }}
          id={id}
          isShopOwner={isShopOwner}
          liveId={liveId}
        />
      ) : null}
    </div>
  )
}

export default StoreModal
