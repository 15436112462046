import React, { useEffect, useMemo, useState } from "react"
import EmptyPage from "../empty-page"
import Loading from "src/components/loading"
import { useNavigate, useParams } from "react-router-dom"
import {
  DealProduct,
  DealVoucher,
  StoreModal,
} from "src/components/live-streams"
import { LivestreamCampaignControllerApi } from "@soctrip/axios-livestream-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import {
  DealProductType,
  DealVoucherType,
} from "src/components/live-streams/types"
import { handlePromiseAll } from "src/utils/common"
import { ClaimVoucherControllerApi } from "@soctrip/axios-promotion-service"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"
import { useToast } from "src/hooks/use-toast"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { ROUTERS } from "src/constants/router"
import NotLoginView from "src/components/live-streams/not-login"
import { ShopControllerApi } from "@soctrip/axios-shop-service"
export interface LiveStreamViewType {
  hostImg: string
  hostName: string
  liveName: string
  bgImg: string
  id: string
  views: number
  actionCount: number
}
const STATE_VIEW = {
  INIT: "INIT",
  LOADING: "LOADING",
  NOT_FOUND: "NOT_FOUND",
  RENDER: "RENDER",
  NOT_LOGIN: "NOT_LOGIN",
}
export const MESSAGE_LIVE_STREAM = {
  LIVESTREAM_COMMENT: "LIVESTREAM_COMMENT",
  LIVESTREAM_REACTION: "LIVESTREAM_REACTION",
  LIVESTREAM_INFO: "LIVESTREAM_INFO",
  PRODUCT: "PRODUCT",
  VOUCHER: "VOUCHER",
  LIVESTREAM_END: "LIVESTREAM_END",
}
export interface LiveDataType {
  liveStreamId: string
  liveUrl: string
  liveId: string
  campaignId: string
}

export default function LiveStreamRoom() {
  const [stateView, setStateView] = useState<string>(STATE_VIEW.INIT)
  const [liveData, setLiveData] = useState<LiveDataType | undefined>(undefined)
  const [isLivePublic, setIsLivePublic] = useState<boolean>(false)
  const [isRender, setIsRender] = useState<boolean>(false)
  const [isShopOwner, setIsShopOwner] = useState<boolean>(false)
  const [shopId, setShopId] = useState<string>("")
  const [currentDealProduct, setCurrentDealProduct] = useState<
    DealProductType | undefined
  >(undefined)
  const [currentDealVoucher, setCurrentDealVoucher] = useState<
    DealVoucherType | undefined
  >(undefined)
  const [isCompleteIframe, setIsCompleteIframe] = useState<boolean>(false)
  const [clampVoucher, setClampVoucher] = useState<string[]>([])
  const params = useParams()
  const showToast = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)

  const handleOnLoad = () => {
    setIsCompleteIframe(true)
  }

  useEffect(() => {
    if (authStore.value && shopId) {
      new ShopControllerApi(configHeader(SERVICE.SHOP))
        .shopsMyShopGet()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data?.id) {
            const result = res.data.data.id === shopId
            setIsShopOwner(result)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [shopId, authStore])

  useEffect(() => {
    if ( isRender && liveData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apis = [] as any[]
      apis.push(getProduct())
      apis.push(getVoucherData())
      handlePromiseAll(apis)
    }
  }, [params, isRender, liveData])

  useEffect(() => {
    if (params?.id && stateView === STATE_VIEW.INIT && isRender) {
      getLiveDetail()
    }
  }, [params, isRender])

  const getLiveDetail = () => {
    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLivesLiveIdGet(params?.id as string)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res:any) => {
        if (res?.data?.data) {
          localStorage.setItem("liveIdCurrent", res.data.data?.live_info?.id || "")
          setLiveData({
            liveStreamId: params.id as string,
            liveUrl: res.data.data?.live_detail?.guest_link || "",
            liveId: res.data.data?.live_info?.id || "",
            campaignId: res?.data?.data?.id,
          })
          setShopId(res.data.data?.shop?.id || "")
          const isPublic = !!(
            res.data.data?.live_detail.is_shop_live &&
            res.data.data?.live_detail.status === "ONGOING"
          )
          setIsLivePublic(isPublic)
          setStateView(STATE_VIEW.RENDER)
        } else {
          setStateView(STATE_VIEW.NOT_FOUND)
        }
      })
      .catch(() => setStateView(STATE_VIEW.NOT_FOUND))
  }

  // detect first load
  const getProduct = () => {
    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLiveProductsGet(liveData?.campaignId as string)
      .then((res) => {
        if (res?.data?.data?.length) {
          const result: DealProductType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.id || "",
              productImg: i?.avatar?.id || "",
              productName: i?.name || "",
              discount: i?.livestream_price || 0,
              price: i?.price_after_tax || 0,
              originPrice: i?.original_price_after_tax || 0,
              isDisplay: i?.is_display,
              liveToken: i?.lives_token || "",
              stockId: i?.stock_id || "",
              productId: i?.id || "",
              shopId: i?.object_id || "",
              sold: i?.sold || 0,
              quantity: i?.livestream_quantity || 0,
            })
          })
          if (result.length) {
            const valueDisplay = result.find((i) => i.isDisplay)
            if (valueDisplay) setCurrentDealProduct(valueDisplay)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  // detect first load
  const getVoucherData = () => {
    new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLiveVouchersGet(liveData?.campaignId as string)
      .then((res) => {
        if (res?.data?.data.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const valueDisplay = res.data.data.find((i: any) => i?.is_display)
          if (valueDisplay) {
            const voucherDataMessage =
              valueDisplay?.voucher_discount_range[0] || undefined
            const voucherResult: DealVoucherType = {
              id: valueDisplay?.id || "",
              voucherName: valueDisplay?.voucher_code || "",
              voucherImg: valueDisplay?.voucherType?.icon || "",
              reward:
                (voucherDataMessage?.discount_type === "PERCENTAGE"
                  ? voucherDataMessage?.discount_percent
                  : voucherDataMessage?.discount_amount) || 0,
              minSpend: voucherDataMessage?.min_order_price || 0,
              isDisplay: valueDisplay?.is_display,
              discountType: voucherDataMessage?.discount_type || "",
              endTime: valueDisplay?.end_at || undefined,
              campaign_id: liveData?.liveId || "",
              title: valueDisplay?.name || "",
            }
            setCurrentDealVoucher(voucherResult)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  const getIframeUrl = useMemo(() => {
    const message = capitalizedFirst(t("live-stream.is-coming-soon"))
    let result = ""
    if (liveData) {
      const userId = authStore?.value?.user?.id
        ? authStore?.value?.user?.id
        : ""
      const token = authStore?.value?.access_token
        ? authStore?.value?.access_token
        : ""
      result = `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/live/${liveData?.liveId}?userId=${userId}&token=${token}&mcs=${message}`
    }
    return result
  }, [liveData])
  useEffect(() => {
    if (currentDealProduct) {
      // const filters = campaignId ? `object_id==${idsShop[0]},live_campaign_id==${live}` : `object_id==${idsShop[0]}`
      new ClaimVoucherControllerApi(configHeader(SERVICE.PROMOTION))
        .findByObjectId1("LIVESTREAM", "ONGOING", 0, 50)
        .then((res) => {
          if (res?.data?.data?.data?.length) {
            const result: string[] = []
            res.data.data.data.forEach((i) => {
              result.push(i?.voucher?.id || "")
            })
            if (result.length) setClampVoucher(result)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [currentDealProduct])

  const updateClaimVoucher = (newVoucherId: string) => {
    const result: string[] = [...clampVoucher, newVoucherId]
    setClampVoucher(result)
  }
  const isVoucherClaim = useMemo(() => {
    let result = false
    if (clampVoucher.length && currentDealVoucher?.id) {
      result = clampVoucher.includes(currentDealVoucher.id)
    }
    return result
  }, [clampVoucher, currentDealVoucher])
  const isJsonString = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  const handleEndLiveStream = () => {
    showToast({
      detail: capitalizedFirst(t("live-stream.room-end")),
      severity: "info",
    })
    setTimeout(() => {
      navigate("/shopping/"+ROUTERS.LIVESTREAM)
    }, 2000)
  }
  const toggleProduct = function (value: DealProductType | undefined) {
    if (value?.isDisplay) setCurrentDealProduct(value)
    else setCurrentDealProduct(undefined)
  }
  const toggleVoucher = function (value: DealVoucherType | undefined) {
    setCurrentDealVoucher(undefined)
    setTimeout(() => {
      if (value?.isDisplay) setCurrentDealVoucher(value)
      else setCurrentDealVoucher(undefined)
    }, 200)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e: any) => {
    if (e?.data && isJsonString(e?.data) && JSON.parse(e?.data)) {
      const messageContent = JSON.parse(e.data)
      switch (messageContent?.type) {
      case MESSAGE_LIVE_STREAM.PRODUCT:
        {
          const productResult: DealProductType = {
            id: messageContent?.id || "",
            productImg: messageContent?.avatar?.id || "",
            productName: messageContent?.name || "",
            discount: messageContent?.livestream_price || 0,
            price: messageContent?.price || 0,
            originPrice: messageContent?.original_price || 0,
            isDisplay: messageContent?.is_display,
            liveToken: messageContent?.lives_token || "",
            stockId: messageContent?.stock_id || "",
            productId: messageContent?.id || "",
            shopId: messageContent?.object_id || "",
            sold: messageContent?.sold || 0,
            quantity: messageContent?.livestream_quantity || 0,
          }
          toggleProduct(productResult)
        }
        break
      case MESSAGE_LIVE_STREAM.VOUCHER:
        {
          const voucherDataMessage =
            messageContent?.voucher_discount_range[0] || undefined
          const voucherResult: DealVoucherType = {
            id: messageContent?.id || "",
            voucherName: messageContent?.voucher_code || "",
            voucherImg: messageContent?.voucherType?.icon || "",
            reward:
              (voucherDataMessage?.discount_type === "PERCENTAGE"
                ? voucherDataMessage?.discount_percent
                : voucherDataMessage?.discount_amount) || 0,
            minSpend: voucherDataMessage?.min_order_price || 0,
            isDisplay: messageContent?.is_display,
            discountType: voucherDataMessage?.discount_type || "",
            endTime: messageContent?.end_at || "",
            campaign_id: liveData?.liveId || "",
            title: messageContent?.name || "",
          }
          toggleVoucher(voucherResult)
        }
        break
      case MESSAGE_LIVE_STREAM.LIVESTREAM_END:
        {
          const liveIdCurrent = localStorage.getItem("liveIdCurrent")
          if (
            messageContent?.receiver_id === liveIdCurrent &&
            messageContent?.content === "ENDED"
          )
            handleEndLiveStream()
        }
        break
      default:
        break
      }
    }
  }
  useEffect(() => {
    if(authStore?.value?.user?.id) {
      setIsRender(true)
    } else {
      setStateView(STATE_VIEW.NOT_LOGIN)
    }

    return () =>{
      if(authStore?.value?.user?.id) window.removeEventListener("message", handleReceiveMessage)
    }    
  }, [])

  useEffect(() => {
    if(liveData) {
      window.addEventListener("message", handleReceiveMessage)
    }

    return () =>{
      window.removeEventListener("message", handleReceiveMessage)
    }    
  }, [liveData])
  
  if(stateView === STATE_VIEW.NOT_LOGIN) return <NotLoginView/>

  if (
    stateView === STATE_VIEW.INIT ||
    stateView === STATE_VIEW.LOADING ||
    !liveData ||
    !isRender
  )
    return (
      <div className="h-[600px]">
        <Loading />
      </div>
    ) 
  
  if (stateView === STATE_VIEW.NOT_FOUND)
    return (
      <div className="h-[600px]">
        <EmptyPage />
      </div>
    )

  return (
    <div className="relative flex h-screen w-screen flex-col">
      <iframe
        id={liveData.liveId}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
        title={`Room ${liveData.liveStreamId}`}
        src={getIframeUrl}
        onLoad={handleOnLoad}
      />
      {currentDealProduct && isCompleteIframe && isLivePublic ? (
        <div className="absolute left-[30px] top-[100px] z-[2]">
          <DealProduct {...currentDealProduct} isShopOwner={isShopOwner}/>
        </div>
      ) : null}
      {currentDealVoucher && isCompleteIframe && isLivePublic ? (
        <div className="absolute bottom-[160px] left-[30px] z-[2] max-w-[357px]">
          <DealVoucher
            isClamp={isVoucherClaim}
            isRoom={true}
            clearCurrentVoucher={() => setCurrentDealVoucher(undefined)}
            updateClaimData={(voucherId: string) =>
              updateClaimVoucher(voucherId)
            }
            {...currentDealVoucher}
            isShopOwner={isShopOwner}
            pingProduct={{
              token: currentDealProduct?.liveToken || "",
              shopId: currentDealProduct?.shopId || "",
              stockId: currentDealProduct?.stockId || "",
              productId: currentDealProduct?.productId || "",
            }}
          />
        </div>
      ) : null}
      {isLivePublic ? (
        <div className="absolute bottom-[55px] left-[calc((100vw-400px)/2)] z-[2]">
          <StoreModal id={liveData.campaignId} isShopOwner={isShopOwner} liveId={liveData.liveId} />
        </div>
      ) : null}
    </div>
  )
}
