import React, { useMemo } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import { useSelector, useDispatch } from "react-redux"
import type { RootState } from "src/app/store"
import ImgCustom from "src/components/img-custom"
import EmptyPage from "src/pages/empty-page"
import { ContentViewType, ObjectDtoType, ItemContentViewType, CartType } from "../../type"
import { FLASH_DEAL_TYPE, ORDER_TYPE_MODE, TYPELINK } from "src/constants/common"
import { CheckoutViewType } from "./type"
import { updateCart } from "src/features/shopping-cart"
import ShopConfigView from "../../shop-config-view"
import { getFlashDealTime } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"

const ShopCartItemView = (props: ItemContentViewType) => {
  const { objectDto, cartName } = props
  const { t } = useTranslation()
  const systemStore = useSelector((state: RootState) => state.system)
  const ProductTemplate = (rowData: ObjectDtoType) => {
    const { title, objectImgUrl, stockDetail, isFlashDeal, quantity } = rowData

    const getVariation = useMemo(() => {
      return stockDetail.variation_second_name
        ? `${stockDetail.variation_first_name}/${stockDetail.variation_second_name}`
        : stockDetail.variation_first_name
    }, [])

    const getStockQuantity = useMemo(() => {
      let result = 0
      const timeFlashDeal = getFlashDealTime(systemStore.value)
      if (
        rowData.liveToken &&
        rowData.isLiveStream &&
        rowData.liveStreamDeal &&
        quantity <= rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      ) {
        result = rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      } else if (
        isFlashDeal &&
        rowData.flashDeal &&
        timeFlashDeal &&
        quantity <= rowData.flashDeal.max_sold - rowData.flashDeal.sold
      ) {
        result = rowData.flashDeal.max_sold - rowData.flashDeal.sold
      } else {
        result = stockDetail?.quantity
      }
      return result
    }, [stockDetail, quantity])

    return (
      <div className="flex items-center">
        <div className="h-[66px] w-[66px] rounded-2 border border-gray-200">
          {objectImgUrl?.includes(TYPELINK.DOMAIN_URL_YOUTUBE) ? (
            <img
              src={`https://img.youtube.com/vi/${
                objectImgUrl?.split("/").slice(-1)[0]
              }/maxresdefault.jpg`}
              alt="img-video"
              className="h-full w-full object-cover"
            />
          ) : (
            <ImgCustom
              className="h-full w-full object-cover"
              alt="objImg"
              url={objectImgUrl as string}
            />
          )}
        </div>
        <div className="ml-1 flex flex-col">
          <span className="text-12 font-semibold leading-18 text-gray-700 line-clamp-2">
            {title}
          </span>
          {stockDetail?.quantity ? (
            <span className="mt-2px truncate text-10 font-medium leading-16 text-gray-500">
              {capitalizedFirst(
                t("shoppingPage.stocks_remaining", {
                  count: getStockQuantity || 0,
                })
              )}
            </span>
          ) : null}
          <div className="mt-2px text-12 font-normal leading-18 text-gray-600">
            <span>{capitalizedFirst(t("shoppingCartPage.variation"))}</span>
            <span className="ml-4px font-semibold">{getVariation}</span>
          </div>
        </div>
      </div>
    )
  }
  const PriceTemplate = (rowData: ObjectDtoType) => {
    const { stockDetail, quantity } = rowData
    const getPrice = useMemo(() => {
      let result = 0
      const timeFlashDeal = getFlashDealTime(systemStore.value)

      if (
        rowData.liveToken &&
        rowData.isLiveStream &&
        rowData.liveStreamDeal &&
        quantity <= rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      ) {
        result = (100 - rowData.liveStreamDeal.discount_percent) * stockDetail.original_price_after_tax / 100
      } else if (
        rowData.isFlashDeal &&
        rowData.flashDeal &&
        timeFlashDeal &&
        quantity <= rowData.flashDeal.max_sold - rowData.flashDeal.sold
      ) {
        if (rowData.flashDeal.type === FLASH_DEAL_TYPE.FIXED_PRICE) {
          result = rowData.flashDeal.marketing_price
        } else {
          result = (100 - rowData.flashDeal.marketing_price) * stockDetail.original_price_after_tax / 100
        }
      } else {
        result = stockDetail?.price_after_tax
      }

      return result
    }, [stockDetail, quantity])

    return (
      <div className="flex flex-col font-medium">
        <span className="text-14 leading-20 text-gray-900">{convertCurrency(getPrice)}</span>
        {
          getPrice !== stockDetail.original_price_after_tax ? (
            <>
              <span className="text-12 leading-18 line-through">
                {convertCurrency(stockDetail.original_price_after_tax)}
              </span>
            </>
          ) : null
        }
      </div>
    )
  }

  const TotalPriceTemplate = (rowData: ObjectDtoType) => {
    const { stockDetail, quantity } = rowData
    const getPrice = useMemo(() => {
      let result = 0
      const timeFlashDeal = getFlashDealTime(systemStore.value)
      if (
        rowData.liveToken &&
        rowData.isLiveStream &&
        rowData.liveStreamDeal &&
        quantity <= rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      ) {
        result = (100 - rowData.liveStreamDeal.discount_percent) * stockDetail.original_price_after_tax / 100
      } else if (
        rowData.isFlashDeal &&
        rowData.flashDeal &&
        timeFlashDeal &&
        quantity <= rowData.flashDeal.max_sold - rowData.flashDeal.sold
      ) {
        if (rowData.flashDeal.type === FLASH_DEAL_TYPE.FIXED_PRICE) {
          result = rowData.flashDeal.marketing_price
        } else {
          result = (100 - rowData.flashDeal.marketing_price) * stockDetail.original_price_after_tax / 100
        }
      } else {
        result = stockDetail?.price_after_tax
      }

      return result
    }, [])
    const totalPrice = useMemo(() => {
      return getPrice * quantity
    }, [quantity, getPrice])
    return (
      <div className="flex flex-col gap-1">
        <span className="text-[14px] font-medium leading-20 text-gray-700">
          {convertCurrency(totalPrice)}
        </span>
      </div>
    )
  }

  const QuantityTemplate = (rowData: ObjectDtoType) => {
    return (
      <>
        <span className="block md:hidden font-14 font-medium leading-20 text-gray-700">{`X${rowData?.quantity}`}</span>
        <span className="hidden md:block font-14 font-medium leading-20 text-gray-700">{`${rowData?.quantity}`}</span>
      </>
    )
  }

  const tableFields = [
    {
      field: "product",
      label: cartName,
      customStyle: {},
      isCustom: true,
      template: ProductTemplate,
    },
    {
      field: "price",
      label: capitalizedFirst(t("global.unitPrice")),
      customStyle: {
        width: "110px",
      },
      template: PriceTemplate,
    },
    {
      field: "quantity",
      label: capitalizedFirst(t("global.quantity")),
      customStyle: {
        width: "110px",
      },
      template: QuantityTemplate,
    },
    {
      field: "totalPrice",
      label: capitalizedFirst(t("global.totalPrice")),
      customStyle: {
        width: "110px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      template: TotalPriceTemplate,
    },
  ]

  return (
    <div className="w-[100%]" id="customTable">
      <div className="hidden md:block">
        <DataTable
          className="w-full rounded-3 border border-gray-200 bg-white p-2"
          value={objectDto}
          tableStyle={{ minWidth: "30rem" }}
        >
          {tableFields.map((item, index) => (
            <Column
              headerClassName={`bg-white cart-product-header ${!index ? "shop-name" : ""}`}
              bodyClassName={index === tableFields.length - 1 ? "border-b-none" : ""}
              key={index}
              field={item.field}
              header={item.label}
              bodyStyle={item.customStyle}
              headerStyle={item.customStyle}
              body={item.template}
            />
          ))}
        </DataTable>
      </div>
      <div className="block md:hidden">
        {objectDto.map(i => (
          <>
            <div className="flex flex-col py-3 gap-1">
              <ProductTemplate {...i}/>
              <div className="flex items-center justify-between">
                <QuantityTemplate {...i}/>
                <TotalPriceTemplate {...i}/>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

const ContentView = (props: ContentViewType) => {
  const {
    cartName,
    cartImg,
    index,
    objectDto,
    typeView,
    shopNote,
    isInvoice,
    cartId,
    shipAddress,
    tab,
  } = props
  const shoppingCartStore = useSelector((state: RootState) => state.shoppingCart)
  const isShopTicket = useMemo(() => {
    let result = false
    if(cartId) {
      const shopData = shoppingCartStore.cartData?.find((shop) => shop?.cartId === cartId)
      if(shopData) {
        shopData.objectDto.forEach(prod => {
          if(prod.isSelected && prod.catalogs?.length && prod.catalogs[0].type === "E_TICKET") result = true
        })
      }
    }
    return result
  }, [shoppingCartStore])
  return (
    <div className={`flex flex-col ${index ? "mt-3" : "mt-1"}`}>
      <ShopCartItemView
        typeView={typeView}
        objectDto={objectDto}
        cartName={cartName}
        cartImg={cartImg}
      />
      <div className="flex w-full items-center justify-between gap-3">
        <ShopConfigView
          shopId={cartId}
          isShopInvoice={isInvoice}
          isReadOnly={true}
          shopNote={shopNote}
          shipAddress={shipAddress}
          isHiddenShip={tab === ORDER_TYPE_MODE.OFFLINE}
          isShopTicket={isShopTicket}
        />
      </div>
    </div>
  )
}

export default function CheckoutView(props: CheckoutViewType) {
  const { typeView, shipAddress, tab } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const shoppingCartStore = useSelector((state: RootState) => state.shoppingCart)
  const getDataShopCheckout = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = [] as any
    shoppingCartStore.cartData.forEach((cart) => {
      let isHaveProductCheckout = false
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pCheckout = [] as ObjectDtoType[]
      cart?.objectDto?.forEach((obj) => {
        if (obj?.isSelected) {
          isHaveProductCheckout = true
          pCheckout.push({ ...obj })
        }
      })
      if (isHaveProductCheckout) {
        result.push({
          objectDto: pCheckout,
          cartImd: cart?.cartImg,
          cartName: cart?.cartName,
          cartId: cart?.cartId,
          shopNote: cart?.shopNote,
          feeShip: cart?.feeShip,
          isInvoice: cart?.isInvoice,
        })
      }
    })
    return result
  }, [shoppingCartStore])

  const updateShopNote = (newNote: string, cartId: string) => {
    const newCarts = [] as CartType[]

    shoppingCartStore.cartData?.forEach((cart) => {
      if (cart.cartId === cartId) {
        newCarts.push({ ...cart, shopNote: newNote })
      } else {
        newCarts.push({ ...cart })
      }
    })

    dispatch(updateCart(newCarts))
  }
  return (
    <>
      <div className="flex w-full flex-col rounded-3 bg-white p-3">
        <span className="text-18 font-semibold leading-28 text-gray-800">
          {capitalizedFirst(t("checkoutPage.productsSelected"))}
        </span>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getDataShopCheckout?.map((item: any, index: any) => (
            <ContentView
              typeView={typeView}
              key={index}
              index={index}
              shipAddress={shipAddress}
              updateShopNote={updateShopNote}
              tab={tab}
              {...item}
            />
          ))
        }
        {!getDataShopCheckout.length && (
          <EmptyPage message="Not found data. Please try again later." />
        )}
      </div>
    </>
  )
}
