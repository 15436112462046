export const ButtonSeverity = {
  primary: "PRIMARY",
  secondary: "SECONDARY",
  success: "SUCCESS",
  info: "INFO",
  warning: "WARNING",
  help: "HELP",
  danger: "DANGER",
  custom: "CUSTOM",
}
