import React, { useMemo } from "react"
import { AddressType } from "../type"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

const AddressInformationView = (props: AddressType) => {
  const { t } = useTranslation()
  const {
    address,
    ward,
    district,
    city,
    fullName,
    phoneNumber,
    addressType,
    isDefault,
  } = props

  const getFullAddress = useMemo(() => {
    return [address, ward, district, city].filter((i) => i).join(", ")
  }, [props])

  return (
    <div className="flex w-[100%] flex-col rounded-3 border border-gray-200 p-3">
      <div className="flex">
        <span className="truncate text-16 font-semibold leading-24 text-gray-700 flex-1">
          {fullName}
        </span>
        {addressType ?
          <div className="ml-4px cursor-pointer rounded-5 bg-orange-50 py-2px px-1 text-12 font-semibold leading-18 text-orange-700">
            {capitalizedFirst(t(`global.${addressType}`))}
          </div> 
          : null
        }
        
        {isDefault && (
          <div className="ml-4px cursor-pointer rounded-5 bg-blue-50 py-2px px-1 text-12 font-semibold leading-18 text-blue-700">
            {capitalizedFirst(t("global.default"))}
          </div>
        )}
      </div>
      <span className="mt-1 text-14 font-normal leading-20 text-gray-500">
        {phoneNumber}
      </span>
      <span className="mt-1 text-14 font-normal leading-20 text-gray-500">
        {getFullAddress}
      </span>
    </div>
  )
}
export default AddressInformationView
