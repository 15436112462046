import React from "react"
import { ButtonProperties } from "./button-custom-props"
import { ButtonSeverity } from "src/constants/constants/button-severity"

export default function ButtonCustom({
  children,
  classCustom,
  severity,
  ...attributes
}: ButtonProperties) {
  const buttonSeverity = ButtonSeverity

  return (
    <button
      {...attributes}
      className={`flex items-center ${
        severity?.toLocaleUpperCase() === buttonSeverity.custom
          ? `${classCustom}`
          : "rounded-md px-3 py-2 text-base font-semibold text-white"
      }
	   ${
    severity?.toLocaleUpperCase() === buttonSeverity.primary
      ? "bg-purple-600"
      : ""
    } ${
      severity?.toLocaleUpperCase() === buttonSeverity.secondary
        ? "bg-gray-500"
        : ""
    } ${
      severity?.toLocaleUpperCase() === buttonSeverity.success
        ? "bg-green-light-500"
        : ""
    } ${
      severity?.toLocaleUpperCase() === buttonSeverity.info
        ? "bg-blue-500"
        : ""
    } ${
      severity?.toLocaleUpperCase() === buttonSeverity.warning
        ? "bg-yellow-500"
        : ""
    } ${
      severity?.toLocaleUpperCase() === buttonSeverity.help
        ? "bg-purple-500"
        : ""
    } ${
      severity?.toLocaleUpperCase() === buttonSeverity.danger
        ? "bg-red-500"
        : ""
    } `}
    >
      {children}
    </button>
  )
}
