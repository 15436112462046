import { DataArrayViewProduct } from "src/components/card-custom/card-custom/custom-card-props"
import { handleClickSponsors } from "../sponsor-service"

export const handleCountViewBoth = (shopId: string, productId?: string) => {
  // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
  let originalData: any[] = JSON.parse(
    localStorage.getItem("dataArray") as string
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newData: any[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dataArray: any[] = []

  if (productId) {
    dataArray = [
      {
        m: "Shop",
        c: "SH",
        a: "C",
        d: shopId,
        co: 1,
        l: "Successful",
      },
      {
        m: "Shop",
        c: "P",
        a: "C",
        d: productId,
        co: 1,
        l: "Successful",
      },
    ]
  } else {
    dataArray = [
      {
        m: "Shop",
        c: "SH",
        a: "C",
        d: shopId,
        co: 1,
        l: "Successful",
      },
    ]
  }

  if (originalData?.length) {
    newData = [...originalData, ...dataArray]
  } else {
    newData = dataArray
  }
  localStorage.setItem("dataArray", JSON.stringify(newData))
  const timeout = setTimeout(() => {
    const data: DataArrayViewProduct[] = JSON.parse(
      localStorage.getItem("dataArray") as string
    )
    if (data?.length >= 6) {
      const dto = {
        platform: "Web",
        screenSize: "1920x1080",
        createdAt: Date.now(),
        sessionId: "",
        dataArray: JSON.parse(localStorage.getItem("dataArray") as string),
      }

      handleClickSponsors(dto)
        .then(() => {
          localStorage.removeItem("dataArray")
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, 500)
  return () => clearTimeout(timeout)
}
