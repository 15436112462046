import React, { useEffect, useState } from "react"
import ImgCustom from "src/components/img-custom"
import { getStickyBanner } from "src/services/banners-service"
import closeIcon from "src/assets/images/close-icon.svg"

interface StickType {
  url: string
  image: string
}
const StickyBanner = () => {
  const [isShow, setIsShow] = useState<boolean>(true)
  const [sticky, setSticky] = useState<StickType | undefined>(undefined)
  useEffect(() => {
    function fetchData() {
      getStickyBanner()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data?.length) {
            const data = res.data.data[0]
            setSticky({
              url: data?.url || "",
              image:
                data?.medias?.length && data.medias[0]?.id
                  ? data.medias[0]?.id
                  : "",
            })
          }
        })
        .catch((err) => console.log(err))
    }
    fetchData()
  }, [])

  const clickSticker = () => {
    if (sticky?.url) window.open(sticky.url, "_self")
  }
  if (!isShow || !sticky) return null

  return (
    <div className="fixed right-[8px] bottom-[70px] z-[98] cursor-pointer md:right-[16px] md:bottom-[130px]">
      <div className="relative">
        <div className="h-[80px] w-[80px] rounded-[8px]" onClick={clickSticker}>
          <ImgCustom
            url={sticky.image}
            alt="stickyImg"
            className="rounded-[8px]"
          />
        </div>
        <div
          onClick={() => setIsShow(false)}
          className="z-1 absolute top-[-8px] right-[-8px] flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white"
        >
          <img src={closeIcon} className="h-[16px] w-[16px]" />
        </div>
      </div>
    </div>
  )
}
export default StickyBanner
