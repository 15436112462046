import http from "src/config/interceptors"

export const bookmarkProduct = (productId?: string, dag_object?: string) => {
  const dto = {
    object_id: productId,
    dag_object: dag_object,
  }
  return http.post("/bookmarks/bookmarks", dto)
}

export const getBookmarkList = (
  filterString: string,
  page?: number,
  limit?: number
) => {
  return http.get(
    `/bookmark/bookmarks?pageNum=${page}&pageSize=${limit}&filters=${filterString}`
  )
}

export const deleteBookmark = (id?: string) => {
  return http.delete(`/bookmarks/bookmarks/${id}`)
}

export const getBloomFilter = () => {
  return http.get("/bookmarks/bookmarks/bloom-filter")
}
