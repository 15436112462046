import React, { useEffect, useState } from "react"
import { Dropdown } from "primereact/dropdown"
import { RegionDataType } from "../modal/language-modal"
import { getRegions } from "src/services/user-service"
import { useNavigate } from "react-router-dom"
import arrowDownIcon from "src/assets/images/arrow-down-white.svg"

const RegionView = () => {
  const [regions, setRegions] = useState<RegionDataType[]>([])
  const [region, setRegion] = useState<string>(
    localStorage.getItem("localRegion") || "US"
  )
  const navigate = useNavigate()
  useEffect(() => {
    getRegions()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result = [] as RegionDataType[]
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              value: i?.country_code || "",
              label: i?.country || "",
            })
          })
          setRegions(result)
          setRegion(localStorage.getItem("localRegion") || "")
        }
      })
      .catch((err) => console.log(err))
  }, [])

  const privacyOptionTemplate = (option: RegionDataType) => {
    return (
      <div>
        <span className="hidden md:block text-white font-medium  leading-[16px] text-[12px] md:leading-[20px] md:text-[14px]">{option.label}</span>
        <span className="block md:hidden text-white font-medium  leading-[16px] text-[12px] md:leading-[20px] md:text-[14px]">{option.value}</span>
      </div>
    )
  }

  const selectTemplate = (option: RegionDataType) => {
    return (
      <div className="flex items-center gap-[4px] bg-[#0000004D] py-4px px-[8px]">
        <>
          <span className="hidden md:block text-white font-medium leading-[16px] text-[12px] md:leading-[20px] md:text-[14px]">{option.label}</span>
          <span className="block md:hidden  text-white font-medium leading-[16px] text-[12px] md:leading-[20px] md:text-[14px]">{option.value}</span>
        </>
        <img src={arrowDownIcon} alt="arrowDownIcon" className="w-[12px] h-[12px]"/>
      </div>
    )
  }
  const handleChangeRegion = (newRegion: string) => {
    if (newRegion !== region) localStorage.setItem("localRegion", newRegion)
    setTimeout(() => {
      navigate(0)
    }, 200)
  }

  if (!regions.length) return null
  return (
    <div id="regionView">
      <Dropdown
        optionLabel="label"
        value={region}
        options={regions}
        onChange={(e) => handleChangeRegion(e?.target?.value)}
        valueTemplate={selectTemplate}
        itemTemplate={privacyOptionTemplate}
        className="flex items-center justify-center bg-[transparent]"
        panelClassName="bg-[#0000004D] py-[0px] custom-region-dropdown"
      />
    </div>
  )
}

export default RegionView
