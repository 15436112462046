import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { AuthType, AuthSliceType } from "./props"

export const initialState: AuthSliceType = {
  value: undefined,
  isShowLoginModal: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<AuthType | undefined>) => {
      state.value = action.payload
    },
    signOut: (state) => {
      localStorage.removeItem("accessToken")
      state.value = undefined
      state.isShowLoginModal = false
    },
    toggleLoginModal: (state, action: PayloadAction<boolean>) => {
      state.isShowLoginModal = action.payload
    },
  },
})

export const { signIn, signOut, toggleLoginModal } = authSlice.actions

export default authSlice.reducer
