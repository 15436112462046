import React, { useEffect, useMemo, useState } from "react"
import { Dialog } from "primereact/dialog"
import { MyyAddressModalProps, AddressType, TypeAddress } from "./props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { RadioButton } from "primereact/radiobutton"
import editAddressIcon from "../../../assets/images/edit-address-icon.svg"
import { addressService } from "../../../services/index"

const AddressItemView = (props: AddressType) => {
  const {
    fullName,
    phoneNumber,
    isSelected,
    address,
    isDefault,
    district,
    city,
    ward,
    addressType,
    index,
    id,
    handleDeleteMyAddress,
    handleEditAddress,
    updateIsSelected,
  } = props
  const { t } = useTranslation()
  const getFullAddress = useMemo(() => {
    return `${address}, ${ward}, ${district}, ${city}`
  }, [address, ward, city, district])

  const clickEditAddress = () => {
    if (handleEditAddress) handleEditAddress(id as string)
  }
  const handleDeleteAddress = () => {
    if (handleDeleteMyAddress) handleDeleteMyAddress(id as string)
  }
  const handleUpdateAddress = (value: boolean) => {
    if (value && updateIsSelected) updateIsSelected(id as string)
  }
  const getGlobalType = useMemo(() => {
    if(addressType) return capitalizedFirst(t(`global.${addressType}`))
    return
  }, [addressType])

  return (
    <div
      className={`flex justify-between rounded-3 border p-3 ${
        isSelected ? "border-blue-500" : "border-gray-300"
      } ${index && "mt-3"}`}
    >
      <div className="flex w-[28px] justify-center">
        <RadioButton
          className=""
          checked={isSelected}
          onChange={(e) => handleUpdateAddress(e.checked as boolean)}
          readOnly
        />
      </div>
      <div className="ml-1 flex flex-1 flex-col">
        <div className="flex">
          <span className="truncate text-16 font-semibold leading-24 text-gray-700 max-w-[250px]">
            {fullName}
          </span>
          {
            addressType ? (
              <div className={`ml-4px cursor-pointer rounded-5 py-2px px-1 text-12 font-semibold leading-18 ${addressType === TypeAddress.HOME ? "bg-orange-50 text-orange-700" : "text-emerald-600 bg-green-50"}`}>
                {getGlobalType}
              </div>
            ) : null
          }
          {isDefault && (
            <div className="ml-4px cursor-pointer rounded-5 bg-blue-50 py-2px px-1 text-12  font-semibold leading-18 text-blue-700">
              {capitalizedFirst(t("global.default"))}
            </div>
          )}
        </div>
        <span className="mt-1 text-14 font-normal leading-20 text-gray-500">
          {phoneNumber}
        </span>
        <span className="mt-1 text-14 font-normal leading-20 text-gray-500">
          {getFullAddress}
        </span>
      </div>
      <div className="ml-1 flex w-[28px] flex-col">
        <div
          onClick={clickEditAddress}
          className="flex h-[28px] cursor-pointer items-center justify-center"
        >
          <img src={editAddressIcon} alt="editAddressIcon" />
        </div>
        <div
          onClick={handleDeleteAddress}
          className="mt-1 flex h-[28px] cursor-pointer items-center justify-center"
        >
          <i className="pi pi-trash text-16 text-red-600" />
        </div>
      </div>
    </div>
  )
}

export default function MyAddressModal(props: MyyAddressModalProps) {
  const { t } = useTranslation()
  const {
    isShow,
    handleClose,
    handleConfirm,
    handleEditMyAddressSelect,
    openNewAddress,
    addressSelect,
    openConfirmDeleteModal,
  } = props
  const [myAddress, setMyAddress] = useState<AddressType[]>([])
  const [addressSelected, setAddressSelected] = useState<AddressType>()

  const handleAddNewAddress = () => {
    openNewAddress()
  }
  const handleDeleteMyAddress = (addressId: string) => {
    if (myAddress.length > 1) {
      openConfirmDeleteModal(addressId)

    }
  }
  const handleEditMyAddress = (addressId: string) => {
    handleEditMyAddressSelect(addressId)
  }

  const mapperAddressList = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listAddressData: any[],
    isDeleteSelected: boolean
  ) => {
    const addressData: AddressType[] = []
    listAddressData.forEach((itemAddress) => {
      const addressItem = {
        id: itemAddress.id,
        fullName: itemAddress.user_full_name,
        phoneNumber: itemAddress.user_phone_number,
        address: itemAddress.address,
        country: itemAddress.country,
        city: itemAddress.city,
        district: itemAddress.district,
        ward: itemAddress.ward,
        addressType: itemAddress.address_type,
        isDefault: itemAddress.is_default,
        city_id: itemAddress.city_id,
        ward_id: itemAddress.ward_id,
        district_id: itemAddress.district_id,
      }
      addressData.push(addressItem)
      if (!addressSelect && addressItem.isDefault) {
        setAddressSelected(addressItem)
      }
      if (isDeleteSelected && addressItem.isDefault) {
        setAddressSelected(addressItem)
      }
    })
    setMyAddress(addressData)
  }
  async function fetchDataView(isDeleteSelected: boolean) {
    addressService.getListAddress().then((res) => {
      if (!isDeleteSelected) setAddressSelected(addressSelect)
      if (res.data.data) mapperAddressList(res.data.data, isDeleteSelected)
    })
  }

  useEffect(() => {
    if (isShow) fetchDataView(false)
  }, [isShow])

  const updateAddressSelected = (newAddress: string) => {
    const newAddressDefault = myAddress.find((i) => i.id === newAddress)
    setAddressSelected(newAddressDefault)
  }
  const handleSave = () => {
    if (addressSelected) handleConfirm(addressSelected)
  }
  const footerContent = (
    <div className="mt-5 flex justify-end gap-1">
      <button
        className="flex items-center justify-center rounded-3 border border-gray-400 py-1 px-3 font-medium text-gray-500 transition hover:border-gray-600"
        onClick={() => handleClose()}
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        className="flex items-center justify-center rounded-3 bg-blue-600 py-1 px-3 font-medium text-white transition hover:bg-blue-700"
        onClick={handleSave}
      >
        {capitalizedFirst(t("global.confirm"))}
      </button>
    </div>
  )
  return (
    <Dialog
      header={capitalizedFirst(t("modal.myAddress"))}
      visible={isShow}
      onHide={() => handleClose()}
      footer={footerContent}
      draggable={false}
    >
      <div className="flex w-full flex-col p-4 sm:w-[500px] md:w-[600px]">
        <div>
          {myAddress.map((item, index) => (
            <AddressItemView
              handleEditAddress={handleEditMyAddress}
              handleDeleteMyAddress={handleDeleteMyAddress}
              key={index}
              index={index}
              isSelected={addressSelected?.id === item.id}
              updateIsSelected={updateAddressSelected}
              {...item}
            />
          ))}
        </div>
        <div className="mt-3">
          <button
            className="flex items-center justify-center rounded-3 bg-blue-600 py-2 px-3 font-medium text-white transition hover:bg-blue-700"
            onClick={handleAddNewAddress}
          >
            <i className="pi pi-plus" />
            <span className="ml-1">
              {capitalizedFirst(t("checkoutPage.addNewAddress"))}
            </span>
          </button>
        </div>
      </div>
    </Dialog>
  )
}
