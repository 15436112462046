import React from "react"
import starIcon from "src/assets/images/star-icon.svg"
import starUncheckIcon from "src/assets/images/star-un-check.svg"
import {StarType} from "./props"

const StarView = (props: StarType) => {
  const {isCheck, index, updateRate} = props
  const handleClick = () => {
    if(updateRate) updateRate(index + 1)
  }
  return (
    <div onClick={handleClick} className={`cursor-pointer w-6 h-6 md:w-[56px] md:h-[56px] ${index && "ml-4"}`}>
      <img className="w-full h-full" src={isCheck ? starIcon : starUncheckIcon} alt="starIcon"/>
    </div>
  )
}
export default StarView
