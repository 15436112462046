import React, { useEffect, useRef, useState } from "react"
import SkeletonLoading from "src/components/skeleton-loading"
import FlashSaleCardView from "src/components/shopping-homepage-view/shopping-flash-sale/product-flash-sale-card-view"
import {
  FlashSaleProductProps,
  FlashSaleRangeType,
} from "src/components/shopping-homepage-view/shopping-flash-sale/type"
import EmptyPage from "src/pages/empty-page"
import { getBloomFilter } from "src/services/bookmark-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import useOnScreen from "src/hooks/use-on-screen"
import { FlashDealSettingControllerApi } from "@soctrip/axios-advertising-service"
import { useTranslation } from "react-i18next"
import "./index.scss"
import { useLocation } from "react-router-dom"

export default function FlashSalePage() {
  const location = useLocation()
  const { t } = useTranslation()
  const loadingRef = useRef(null)
  const isOnScreen = useOnScreen(loadingRef)
  const authStore = useSelector((state: RootState) => state.auth)
  const [productList, setProductList] = useState<FlashSaleProductProps[]>([])
  const [loadingYourMarket, setLoadingYourMarket] = useState<boolean>(true)
  const [pageYourMarket, setPageYourMarket] = useState<number>(0)
  const [loadMoreYourMarket, setLoadMoreYourMarket] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>("")
  const [flashSaleRange, setFlashSaleRange] = useState<FlashSaleRangeType[]>([])
  const [flashSaleRangeCurrent, setFlashSaleRangeCurrent] = useState<
    FlashSaleRangeType | undefined
  >(undefined)
  const [isRenderView, setIsRenderView] = useState<boolean>(false)

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  const handleMoreProduct = async () => {
    if (!flashSaleRangeCurrent) return
    if (productList?.length < totalProducts) {
      const currentTime = new Date()
      const data = {
        start: currentTime.getTime() + flashSaleRangeCurrent?.timeStampStart,
        end: currentTime.getTime() + flashSaleRangeCurrent?.timeStampEnd,
        pageNum: 1,
        pageSize: 5,
        sort: "",
      }
      const curPage = pageYourMarket + 1
      setPageYourMarket(curPage)
      setLoadMoreYourMarket(true)
      let encodeSearch = ""
      if (searchValue) {
        encodeSearch = `name@=${searchValue}`
      } else {
        encodeSearch = ""
      }
      const localRegion = localStorage.getItem("localRegion") || ""
      await new StockProductControllerApi(configHeader(SERVICE.STOCK))
        .productsSearchFlashDealsGet(
          data.start,
          undefined,
          encodeSearch,
          curPage,
          20,
          undefined,
          {
            headers: {
              region: localRegion,
            },
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          setProductList([...productList, ...res?.data?.data?.data])
          setLoadingYourMarket(false)
          setLoadMoreYourMarket(false)
        })
        .catch(() => {
          setLoadingYourMarket(false)
        })
    }
  }

  useEffect(() => {
    if (isOnScreen && !isFirstLoad) {
      handleMoreProduct()
    }
  }, [isOnScreen])

  const fetchProductList = async () => {
    if (!flashSaleRangeCurrent) return
    const currentTime = new Date()
    const data = {
      start: currentTime.getTime() + flashSaleRangeCurrent?.timeStampStart,
      end: currentTime.getTime() + flashSaleRangeCurrent?.timeStampEnd,
      pageNum: 1,
      pageSize: 5,
      sort: "",
    }
    let encodeSearch = ""
    if (searchValue) {
      encodeSearch = `name@=${searchValue}`
    } else {
      encodeSearch = ""
    }
    const currentPage = 0
    setPageYourMarket(currentPage)
    const localRegion = localStorage.getItem("localRegion") || ""
    await new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchFlashDealsGet(
        data.start,
        undefined,
        encodeSearch,
        currentPage,
        20,
        undefined,
        {
          headers: {
            region: localRegion,
          },
        }
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          setTotalProducts(res?.data?.data?.totalElement)
          setProductList(res?.data?.data?.data)
        } else {
          setProductList([])
        }
        setLoadingYourMarket(false)
        setIsFirstLoad(false)
      })
      .catch(() => setLoadingYourMarket(false))
  }

  useEffect(() => {
    if (isRenderView && flashSaleRangeCurrent && productList.length) {
      const currentTime = new Date()
      currentTime.setHours(0)
      currentTime.setMinutes(0)
      currentTime.setSeconds(0)
    }
  }, [isRenderView, flashSaleRangeCurrent, productList])
  //call api get product list
  useEffect(() => {
    if (isRenderView && flashSaleRangeCurrent) handleGetBloomData()
    setPageYourMarket(0)
    setLoadingYourMarket(true)
    fetchProductList()
  }, [isRenderView, flashSaleRangeCurrent, searchValue])

  useEffect(() => {
    if (flashSaleRange.length) {
      const clientTimeData = new Date()
      const currenTimestamp =
        clientTimeData.getHours() * 1000 * 60 * 60 +
        clientTimeData.getMinutes() * 1000 * 60 +
        clientTimeData.getSeconds() * 1000

      flashSaleRange.forEach((i) => {
        if (
          i?.timeStampEnd >= 24 * 60 * 60 * 1000 &&
          (currenTimestamp >= i?.timeStampStart ||
            currenTimestamp < i?.timeStampEnd - 24 * 60 * 60 * 1000)
        ) {
          setFlashSaleRangeCurrent(i)
          setIsRenderView(true)
        } else {
          if (
            currenTimestamp >= i?.timeStampStart &&
            currenTimestamp < i?.timeStampEnd
          ) {
            setFlashSaleRangeCurrent(i)
            setIsRenderView(true)
          }
        }
      })
      setIsRenderView(true)
    }
  }, [flashSaleRange])

  useEffect(() => {
    new FlashDealSettingControllerApi(configHeader(SERVICE.ADVERTISING))
      .getFlashDealsSettingPage(undefined, undefined, undefined, undefined, {
        headers: {
          region: localStorage.getItem("localRegion") || "",
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          const result: FlashSaleRangeType[] = []
          if (res.data?.data?.data[0]?.time_slot_units?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data[0].time_slot_units?.forEach((i: any) => {
              const dateData = new Date(i?.start_at)
              const timeStampStart =
                dateData.getHours() * 1000 * 60 * 60 +
                dateData.getMinutes() * 1000 * 60 +
                dateData.getSeconds() * 1000

              const timeStampEnd =
                dateData.getHours() * 1000 * 60 * 60 +
                dateData.getMinutes() * 1000 * 60 +
                dateData.getSeconds() * 1000 +
                i?.duration * 60 * 1000
              result.push({
                timeStampStart: timeStampStart,
                timeStampEnd: timeStampEnd,
                notify_before: i?.notify_before,
                notify_end: i?.notify_end,
                duration: i?.duration,
              })
            })
          }
          setFlashSaleRange(result)
        }
      })
  }, [])

  useEffect(() => {
    const searchValue = location?.search?.split("=")?.[1]
    if (searchValue) {
      setSearchValue(searchValue)
    } else {
      setSearchValue("")
    }
  }, [location])

  return (
    <div className="relative flex w-full max-w-[1200px] flex-col p-3">
      <div className="flex h-full w-full flex-col gap-2">
        {/* header */}
        <div className="flex h-fit w-fit items-center text-24 font-semibold text-gray-900">
          {capitalizedFirst(t("flash-deals.on-going"))}
        </div>
        {/* content */}
        <div className="flex h-full w-full flex-col">
          {loadingYourMarket ? (
            <div className="h-fit w-full">
              <SkeletonLoading limit={5} />
            </div>
          ) : (
            <>
              {productList?.length ? (
                <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
                  {productList?.map((pro) => (
                    <FlashSaleCardView
                      key={pro.id}
                      id={pro.id}
                      avatar={pro.avatar}
                      name={pro.name as string}
                      point={pro.point}
                      sum_review={pro.sum_review}
                      price={pro.price as number}
                      price_after_tax={pro.price_after_tax as number}
                      original_price={pro.original_price}
                      original_price_after_tax={pro.original_price_after_tax}
                      quantity={pro.quantity}
                      sub_label={pro.sub_label}
                      handleReload={handleGetBloomData}
                      voucherOfProducts={[pro?.voucher as string]}
                      bloom_filter={bloomData as string}
                      sold={pro?.sold}
                      flash_deals={pro?.flash_deals}
                      isShowName={true}
                      max_sold={pro?.max_sold}
                    />
                  ))}
                </div>
              ) : (
                <div className="h-[677px] w-full">
                  <EmptyPage message="Not found data. Please try again later." />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {pageYourMarket < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!loadMoreYourMarket && (
            <div className="flex items-center justify-center">
              {productList?.length < totalProducts ? (
                <div
                  onClick={handleMoreProduct}
                  className="cursor-pointer py-1 text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("shoppingPage.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}

      {loadMoreYourMarket && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
