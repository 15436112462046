import React from "react"
import "./index.scss"
import { LOGO } from "src/constants/common"
import { getLogoApp } from "src/utils/app-logo"

const HeaderLeftView = () => {
  return (
    <div className="flex items-center gap-2">
      <div
        onClick={() => window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}`, "_self")}
        className="flex cursor-pointer items-center gap-1"
      >
        <img className="h-[30px] w-[30px] " src={getLogoApp()} alt="logoHeader" />
        <div className="hidden items-center text-20 font-bold leading-[24px] text-blue-600 sm:block md:flex">
          {LOGO.NAME}
        </div>
      </div>
    </div>
  )
}
export default HeaderLeftView
