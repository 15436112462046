import React from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import emptyVoucherBanner from "src/assets/images/empty-voucher-background.svg"
import { EmptyVoucherViewType } from "./types"
import { VOUCHER_TYPE } from "src/constants/common"

const EmptyVoucherView = (props: EmptyVoucherViewType) => {
  const { t } = useTranslation()
  const { voucherType } = props

  return (
    <div className="flex flex-col items-center text-center">
      <div>
        <img src={emptyVoucherBanner} alt="noVoucherBackground" />
      </div>
      <span className="mt-4 text-[18px] font-semibold leading-[28px] text-gray-900">
        {voucherType === VOUCHER_TYPE.VOUCHER
          ? capitalizedFirst(
            t("shoppingCartPage.no-voucher", { value: "shop" })
          )
          : capitalizedFirst(t("shoppingCartPage.no-ship-voucher"))}
      </span>
    </div>
  )
}

export default EmptyVoucherView
