import { Carousel } from "primereact/carousel"
import React from "react"
import ImgCustom from "../img-custom"
import { CarouselProps, ImageProps } from "./props"
import { IMAGE_TYPE } from "src/constants/common"

export default function CarouselCustom(props: CarouselProps) {
  const productTemplate = (image: ImageProps) => {
    return (
      <div className="flex h-[370px] w-full items-center justify-center gap-4 px-4px">
        <ImgCustom
          className="h-full w-full rounded-3 object-cover object-center shadow-xl"
          url={image?.id}
          alt="image"
          type={IMAGE_TYPE.WEB}
        />
      </div>
    )
  }

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: "991px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "620px",
      numVisible: 1,
      numScroll: 1,
    },
  ]

  return (
    <div className="h-full w-full">
      <Carousel
        value={props?.images}
        numVisible={4}
        numScroll={4}
        itemTemplate={productTemplate}
        autoplayInterval={3000}
        circular
        showIndicators={false}
        showNavigators={false}
        responsiveOptions={responsiveOptions}
      />
    </div>
  )
}
