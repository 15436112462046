import { GenericAbortSignal } from "axios"
import http from "src/config/interceptors"
import { CreateProductCartProps } from "src/pages/purchase-history/props"

export const getOrderList = (
  tabCode: string,
  text?: string,
  pageNumber?: number,
  pageSize?: number,
  signal?: GenericAbortSignal,
  language?: string
) => {
  return http.get(
    `/order/orders?tab=${tabCode}&keyword=${text}&pageNum=${pageNumber}&pageSize=${pageSize}&language=${language}`,
    {
      signal: signal,
    }
  )
}

export const getOrderSetting = (signal?: GenericAbortSignal) => {
  return http.get("/order/orders/setting", { signal: signal })
}

export const getOrderDetails = (id: string, signal?: GenericAbortSignal) => {
  return http.get(`/order/orders/${id}`, { signal: signal })
}

export const createCartItems = (
  data: CreateProductCartProps,
  signal?: GenericAbortSignal
) => {
  return http.post("/cart/shopping-cart", data, { signal: signal })
}

export const getTabs = () => {
  return http.get("/order/tabs")
}

export const handleRePayOrder = () => {
  return http.get("/order/tabs/detail")
}
