import React, { useEffect, useMemo, useState } from "react"
import { Dialog } from "primereact/dialog"
import { PreviewVoucherModalProps } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { TYPE_VOUCHER_VIEW } from "../../../constants/common"
import "./index.scss"
import ItemView from "./item-view"
import EmptyPage from "./empty-voucher"
import { VoucherType } from "../select-voucher-modal/types"

export default function PreviewVoucherModal(props: PreviewVoucherModalProps) {
  const { isShow, handleClose, vouchers, voucherType, bill } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [voucherRender, setVoucherRender] = useState<VoucherType[]>([])

  const isVoucherApply = (data: VoucherType) => {
    return bill > data.minimum_order_price
  }
  useEffect(() => {
    const result = [] as VoucherType[]
    vouchers.forEach(i => {
      result.push(i)
    })
    // sort list data
    result.forEach((i) => (i.isApply = isVoucherApply(i)))
    result.sort((i1, i2) => {
      const a1 = i1.isApply ? 1 : 0
      const a2 = i2.isApply ? 1 : 0
      return a2 - a1
    })
    setVoucherRender(result)
    setIsLoading(false)
  }, [])

  const isLoadMore = useMemo(() => {
    if (!voucherRender.length) return false
    return voucherRender.length - pageIndex * 3 >= 1
  }, [pageIndex, voucherRender])

  return (
    <Dialog
      id="voucherPreviewModal"
      draggable={false}
      header={capitalizedFirst(t("shoppingCartPage.shopVouchers"))}
      visible={isShow}
      onHide={() => handleClose()}
    >
      <div className="no-scrollbar flex w-full min-w-[unset] flex-col px-4 pb-4 md:min-w-[500px]">
        <div className="flex flex-col">
          {isLoading ? (
            <>
              <div className="my-3 flex h-[300px] w-full items-center justify-center gap-2 rounded-3 bg-white">
                <p className="animate-pulse duration-75">
                  {capitalizedFirst(t("global.loading"))}
                </p>
                <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
              </div>
            </>
          ) : (
            <div className="flex flex-col ">
              <div className="flex flex-col">
                <div>
                  {voucherRender.length ? (
                    <>
                      {voucherRender.slice(0, pageIndex * 3).map((item, index) => (
                        <ItemView
                          key={item?.id}
                          index={index}
                          shopBilling={bill}
                          itemType={voucherType}
                          {...item}
                        />
                      ))}
                    </>
                  ) : (
                    <EmptyPage
                      voucherType={voucherType}
                      typeView={TYPE_VOUCHER_VIEW.SHOP}
                    />
                  )}
                  {isLoadMore ? (
                    <div
                      onClick={() => setPageIndex(pageIndex + 1)}
                      className="mt-1 flex cursor-pointer items-center justify-center py-4px text-[14px] font-semibold leading-[20px] text-blue-700"
                    >
                      {capitalizedFirst(t("shoppingPage.view-more"))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}
