import React, { useEffect, useState } from "react"
import { CustomCardProps } from "../card-custom/card-custom/custom-card-props"
import CustomCard from "../card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { handleGetMost } from "src/services/shopping-homepage-service/shopping-homePage-service"
import { TrendingControllerApi } from "@soctrip/axios-trending-service"
import SkeletonLoading from "../skeleton-loading"
import { HOME_MOST, SERVICE } from "src/constants/common"
import { MostPopularProps } from "./type"
import EmptyPage from "src/pages/empty-page"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import HomePageSponsor from "../home-page-sponsor"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { configHeader } from "src/config/interceptors"
import useWindowDimensions from "src/utils/screen-size"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { handlePromiseAll } from "src/utils/common"

interface Header {
  name: string
  code: string
  index?: number
  value?: string
}
const headerData: Header[] = [
  {
    name: "global.best-seller",
    code: HOME_MOST.BEST_SELLER,
    index: 1,
    value: "-sold|int",
  },
  {
    name: "global.most-popular",
    code: HOME_MOST.MOST_POPULAR,
    index: 0,
    value: "",
  },
  {
    name: "global.best-reviewed",
    code: HOME_MOST.BEST_REVIEW,
    index: 2,
    value: "-sum_review|int",
  },
]

export default function HomePageMostPopular({
  bloomFilter,
  handleReload,
}: MostPopularProps) {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const [limit, setLimit] = useState<number>(5)
  const [showViewAll, setShowViewAll] = useState<boolean>(false)
  const ref = React.useRef<HTMLInputElement>(null)
  const [selected, setSelected] = useState<Header>(headerData[0])
  const [productList, setProductList] = useState<CustomCardProps[]>([])
  const [loadingYourMarket, setLoadingYourMarket] = useState<boolean>(true)
  const [sponsorData, setSponsorData] = useState([])
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false)
  const [isRender, setIsRender] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleGetProductList = async (
    sortOption?: string,
    stringFilter?: string
  ) => {
    setLoadingYourMarket(true)
    if (stringFilter) {
      try {
        const res = await handleGetMost(
          0,
          6,
          encodeURIComponent(sortOption as string),
          encodeURIComponent(stringFilter as string)
        )
        setProductList(res?.data?.data?.data)
        checkShouldShowViewAll(res?.data?.data?.data)
        setLoadingYourMarket(false)
      } catch (error) {
        setLoadingYourMarket(false)
      }
    } else {
      try {
        const res = await handleGetMost(
          0,
          6,
          encodeURIComponent(sortOption as string)
        )
        setProductList(res?.data?.data?.data)
        checkShouldShowViewAll(res?.data?.data?.data)
        setLoadingYourMarket(false)
      } catch (error) {
        setLoadingYourMarket(false)
      }
    }
  }

  useEffect(() => {
    if (width >= 1024) {
      setLimit(5)
    } else {
      setLimit(4)
    }
  }, [width])

  const getPopularData = async () => {
    setLoadingYourMarket(true)
    await new TrendingControllerApi(configHeader(SERVICE.TRENDING))
      .getProductPopular(0, 5)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setProductList(response?.data?.data?.data)
        setLoadingYourMarket(false)
      })
      .catch(() => setLoadingYourMarket(false))
  }

  useEffect(() => {
    const apis = []
    if (!isFirstLoad) apis.push(handleGetSponsorData())
    if (selected?.code === HOME_MOST.MOST_POPULAR) {
      apis.push(getPopularData())
    } else if (selected?.code === HOME_MOST.BEST_REVIEW) {
      apis.push(handleGetProductList(selected?.value, "point>=4"))
    } else {
      apis.push(handleGetProductList(selected?.value))
    }
    handlePromiseAll(apis).then(() => {
      if (!isFirstLoad) setIsFirstLoad(true)
      setIsRender(true)
    })
  }, [selected])

  const handleGoToViewAll = () => {
    navigate(`/shopping/most-page?code=${selected?.code}`)
  }

  useEffect(() => {
    function handleResize() {
      checkShouldShowViewAll(productList)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [productList.length])

  const handleGetSponsorData = async () => {
    const localRegion = localStorage.getItem("localRegion") || ""
    await new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchSponsorsGet("", 0, 5, undefined, {
        headers: {
          region: localRegion,
        },
      })
      .then((response) => {
        setSponsorData(response?.data?.data?.data)
      })
      .catch((err) => console.log(err))
  }

  const checkShouldShowViewAll = (productList: CustomCardProps[]) => {
    const width = window.innerWidth
    const thresholds = { sm: 2, md: 3, xl: 5 }
    let minCount = thresholds.xl
    if (width < 1024) {
      minCount = thresholds.md
    }
    if (width < 768) {
      minCount = thresholds.sm
    }
    if (productList.length > minCount) {
      setShowViewAll(true)
    } else {
      setShowViewAll(false)
    }
  }

  const detectStructView = (index: number) => {
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = productList.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach(i => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === "ONGOGING" || i?.flash_deal_status === "UPCOMING" ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }
  if (!isRender) return null
  return (
    <div className="flex h-full w-full flex-col gap-1 rounded-3 bg-white p-3">
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex flex-1 items-center gap-1 py-1 md:gap-3">
          {headerData?.map((header, index) => (
            <div
              onClick={() => setSelected(header)}
              key={index}
              className={`flex cursor-pointer items-center rounded-3 px-1 py-1 text-10 font-semibold sm:px-2 sm:text-12 md:text-16 ${
                header?.code === selected?.code
                  ? "bg-blue-light-50 text-blue-600"
                  : "text-gray-500"
              }`}
            >
              <div className="w-full line-clamp-1">
                {capitalizedFirst(t(header?.name))}
              </div>
            </div>
          ))}
        </div>
        <div className="ml-4px flex items-center">
          {showViewAll && (
            <div
              onClick={handleGoToViewAll}
              className="flex cursor-pointer truncate text-10 font-medium text-blue-600 sm:text-12 md:text-16"
            >
              {capitalizedFirst(t("global.view-all"))}
            </div>
          )}
        </div>
      </div>
      {/* Empty view */}
      {
        !productList.length && !sponsorData.length ? (
          <div className="h-[300px] md:h-[500px] w-full">
            <EmptyPage message={capitalizedFirst(t("global.data-not-found"))}/>
          </div>
        ) : null
      }

      {/* sponsor */}
      {!loadingYourMarket ? (
        <div className="flex w-full items-center">
          <HomePageSponsor
            bloom_filter={bloomFilter as string}
            handleReload={
              handleReload
                ? handleReload
                : () => { console.log("reload")}
            }
            space={12}
            sponsorData={sponsorData}
          />
        </div>
      ) : null}
      {/* Data View */}
      <div className="flex h-full w-full flex-col gap-3 lg:flex-row">
        <div className="flex h-full w-full flex-col">
          {loadingYourMarket ? (
            <div className="h-fit w-full">
              <SkeletonLoading limit={5} rows={1} />
            </div>
          ) : (
            <>
              {productList?.length ? (
                <div
                  ref={ref}
                  className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5"
                >
                  {productList?.slice(0, limit)?.map((pro, index) => (
                    <CustomCard
                      key={`${pro.id}-${index}`}
                      id={pro.id} 
                      typeCard={CardCustomType.product}
                      imgUrl={pro.avatar?.id}
                      avatar={pro.avatar}
                      name={pro.name}
                      point={pro.point}
                      sum_review={pro.sum_review}
                      price={pro.price}
                      price_after_tax={pro.price_after_tax}
                      original_price={pro.original_price}
                      original_price_after_tax={pro.original_price_after_tax}
                      quantity={pro.quantity}
                      productAttr={pro.product_attributes}
                      productId={pro.id}
                      shop_id={pro?.shop_id}
                      shop_name={pro.shop?.name}
                      sub_label={pro.sub_label}
                      shop={pro.shop}
                      handleReload={handleReload}
                      voucherOfProducts={[pro?.voucher as string]}
                      bloom_filter={bloomFilter as string}
                      sold={pro?.sold}
                      flash_deal_status={pro?.flash_deal_status}
                      flash_deals={pro?.flash_deals}
                      flash_deals_time={pro?.flash_deals_time}
                      is_flash_deal={pro?.is_flash_deal}
                      vouchers={pro?.vouchers}
                      structView={detectStructView(index)}
                    />
                  ))}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
