import http from "src/config/interceptors"
import { CreateRatingProps } from "./props"
import { AxiosRequestConfig } from "axios"
import { ReportDto } from "src/components/ratings-and-reviews-custom/ratings-and-reviews-custom-props"

export const getNote = (language:string) => {
  const url = `/rating/notes/PRODUCT/first-page?pageSize=100&language=${language}`
  return http.get(url)
}
export const getRating = (ratingIds: string[]) => {
  let url = "rating/ratings"
  ratingIds.forEach((i, index) => {
    url = index
      ? (url = url + `&object_ids=${i}`)
      : (url = url + `?object_ids=${i}`)
  })

  return http.get(url, { isToken: true } as AxiosRequestConfig)
}

export const createRating = (resBody: CreateRatingProps) => {
  const url = "rating/ratings"
  return http.post(url, resBody, { isToken: true } as AxiosRequestConfig)
}

export const updateRating = (id: string, resBody: CreateRatingProps) => {
  const url = "rating/ratings/" + id
  return http.put(url, resBody, { isToken: true } as AxiosRequestConfig)
}

export const reportRating = (dto: ReportDto) => {
  return http.post("/report/report", dto, {
    isToken: true,
  } as AxiosRequestConfig)
}

export const getCategoryReportRating = () => {
  return http.get("/report/report-category/all")
}
