export interface LiveStreamViewType {
  hostImg: string
  hostName: string
  liveName: string
  bgImg: string
  id: string
  views: number
  actionCount: number
  isLive: boolean
  liveId: string
}
export const STATE_VIEW = {
  INIT: "INIT",
  LOADING: "LOADING",
  NOT_FOUND: "NOT_FOUND",
  RENDER: "RENDER",
}
export const PAGE_SIZE_LIVE_STREAM = 12

export interface DealProductModalType {
  id: string
  productImg: string
  productName: string
  price: number
  originPrice: number
  point: number
  sold: number
  stockId?: string
  liveToken?: string
  shopId?: string
  productId?: string
  isShopOwner?: boolean
  liveQuantity: number
}
export interface DealProductType {
  id: string
  productImg: string
  productName: string
  discount: number
  price: number
  originPrice: number
  isDisplay?: boolean
  stockId?: string
  liveToken?: string
  shopId?: string
  productId?: string
  isShopOwner?: boolean
  sold: number
  quantity: number
}

export interface DealVoucherType {
  id: string
  voucherImg: string
  reward: number
  voucherName: string
  minSpend: number
  discountType?: string
  isDisplay?: boolean
  isClamp?: boolean
  isRoom?: boolean
  isShopOwner?: boolean
  endTime?: undefined | string
  updateClaimData?: (voucherId: string) => void
  clearCurrentVoucher?: () => void
  pingProduct?: PingProductType | undefined
  campaign_id: string
  title?: string
}

export interface PingProductType {
  shopId: string
  productId: string
  stockId: string
  token: string
  time?: number
}
