import axios from "axios"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Image } from "primereact/image"
        
interface ChangeLog {
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to: any
  isChange: boolean
  type: string
}

export default function ApproveShopChangeLog() {
  const { id } = useParams()
  const [isNew, setIsNew] = useState<boolean>(true)
  const [changeLogs, setChangeLogs] = useState<{[key: string]: ChangeLog}>({
    // SHOP INFORMATION
    name: {
      label: "Merchant name",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "string",
    },
    phone: {
      label: "Phone number",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "string",
    },
    street: {
      label: "Pickup address 1",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "string",
    },
    sub_street: {
      label: "Pickup address 2",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "string",
    },
    country: {
      label: "Country",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "address",
    },
    province: {
      label: "City/Province",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "address",
    },
    district: {
      label: "District",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "address",
    },
    ward: {
      label: "Ward",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "address",
    },

    // VERIFY INFORMATION
    certificate_type: {
      label: "Certificate type",
      from: [],
      to: [],
      isChange: false,
      type: "string",
    },
    certificate: {
      label: "Certificate",
      from: [],
      to: [],
      isChange: false,
      type: "image",
    },
    other_document: {
      label: "Other document",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "document",
    },
    note: {
      label: "Note",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "string",
    },
    currency: {
      label: "Currency",
      from: undefined,
      to: undefined,
      isChange: false,
      type: "string",
    },
  })
  const changeLogsRender = Object.values(changeLogs)

  useEffect(() => {
    (async function () {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/shop/shops/change-log/${id}`
        )
        const data = [
          ...(res.data.data?.shop || []),
          ...(res.data.data?.address || []),
        ]
        const newLogs = { ...changeLogs }
        Object.keys(newLogs).forEach((key) => {
          const field = data?.find((item) => item.field === key)
          if (field) {
            newLogs[key] = {
              ...newLogs[key],
              from: field?.value1,
              to: field?.value2,
              isChange: true,
            }
            setIsNew(false)
          }
        })
        setChangeLogs(newLogs)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  if(isNew) return null
  return (
    <div>
      <h2 className="mb-3 text-18 font-semibold">Change log</h2>
      <div>
        {changeLogsRender.map(
          (log) =>
            log.isChange && (
              <div key={log.label} className="flex items-start gap-3">
                <div className="flex w-20px flex-col items-center justify-between gap-6px py-6px">
                  <div className=" h-1 w-1 rounded-full bg-blue-600"></div>
                  <div className="h-3 w-[1px] bg-gray-500"></div>
                </div>

                <div className="flex items-start gap-1 text-14 font-medium text-gray-700">
                  {log.label}:
                  <div className="flex items-baseline gap-1">
                    {/* STRING */}
                    {log.type == "string" ? (
                      <span className="flex items-center gap-1">
                        <span className="ml-1 font-normal text-gray-500 line-through">
                          {log.from}
                        </span>
                        {"->"}
                        <span className="font-normal">{log.to}</span>
                      </span>
                    ) : (
                      ""
                    )}

                    {/* ADDRESS */}
                    {log.type == "address" ? (
                      <span className="flex items-center gap-1">
                        <span className="ml-1 font-normal text-gray-500 line-through">
                          {log.from?.name}
                        </span>
                        {"->"}
                        <span className="font-normal">{log.to?.name}</span>
                      </span>
                    ) : (
                      ""
                    )}

                    {/* IMAGE */}
                    {log.type == "image" ? (
                      <div className="flex items-center gap-1">
                        <div className="mb-2 flex items-center gap-1">
                          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                          {log.from?.map((item: any) => (
                            <Image 
                              key={item?.id} 
                              src={`${process.env.REACT_APP_API_URL}/storage/files/thumbnail/${item?.id}.webp`} 
                              alt="Certificate" 
                              width="100" 
                              className="h-[60px]"
                              preview   
                            />
                          ))}
                        </div>
                        {"->"}
                        <div className="mb-2 flex items-center gap-1">
                          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                          {log.to?.map((item: any) => (
                            <Image 
                              key={item?.id} 
                              src={`${process.env.REACT_APP_API_URL}/storage/files/thumbnail/${item?.id}.webp`} 
                              alt="Certificate" 
                              width="100" 
                              className="h-[60px]"
                              preview   
                            />
                          ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* DOCUMENTS */}
                    {log.type == "document" ? (
                      <div className="flex items-center gap-1">
                        <div className="flex items-center gap-1">
                          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                          {log.from?.map((item: any) => (
                            <a
                              key={item?.id}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-600 underline"
                              href={`${process.env.REACT_APP_API_URL}/storage/files/download/${item.id}${item.extension}`}
                            >
                              {item.original_name}
                            </a>
                          ))}
                        </div>
                        {"->"}
                        <div className="flex items-center gap-1">
                          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                          {log.to?.map((item: any) => (
                            <a
                              key={item?.id}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-600 underline"
                              href={`${process.env.REACT_APP_API_URL}/storage/files/download/${item.id}${item.extension}`}
                            >
                              {item.original_name}
                            </a>
                          ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  )
}
