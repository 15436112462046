import React, { useMemo } from "react"
import {TextLikeProps} from "./props"
const TextLineView = (props:TextLikeProps) => {
  const {label, isChecked, updateLikeText, id} = props

  const handleClick = () => {
    updateLikeText({
      id: id,
      name: label,
    })
  }

  const getStyleView = useMemo(() => {
    const stateStyle = isChecked ? "text-blue-700 border-blue-600" : "text-gray-600 bg-gray-200 border-gray-200"
    const commonStyle = "flex items-center justify-center text-center cursor-pointer border px-2 py-10px text-14 leading-20 rounded-3"
    return `${commonStyle} ${stateStyle}`
  }, [isChecked])
  return(
    <div onClick={handleClick} className={getStyleView}>{label}</div>
  )
}

export default TextLineView
