import React, { useRef, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Menu } from "primereact/menu"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import homeNavIcon from "../../assets/images/home-nav-icon.svg"
import bookingNavIcon from "../../assets/images/booking-nav-icon.svg"
import foodNavIcon from "../../assets/images/food-nav-icon.svg"
import shopNavIcon from "../../assets/images/shop-nav-icon.svg"
import arrowDownIcon from "../../assets/images/arrow-down-icon.svg"
import { useNavigate } from "react-router-dom"
import { MenuItem } from "primereact/menuitem"
import bookingCarIcon from "src/assets/images/booking-car-icon.svg"
import bookingTourIcon from "src/assets/images/booking-tour-icon.svg"
import bookingHotelIcon from "src/assets/images/booking-hotel-icon.svg"
import bookingFlightIcon from "src/assets/images/booking-flight-icon.svg"
import "./index.scss"
import HeaderRightView from "./header-right-view"
import HeaderLeftView from "./header-left-view"
import { getUserInfo } from "src/services/user-service"
import i18n from "src/locales"
import { useDispatch } from "react-redux"
import { signIn } from "src/features/auth"
import { updateCurrency } from "src/utils/convert-currency"
import { userType } from "src/features/auth/props"

const HeaderView = () => {
  const { t } = useTranslation()
  const bookingMenuRef = useRef<Menu>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const bookingItems: MenuItem[] = [
    {
      command: () => {
        window.open(`${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}/booking/flight`, "_blank")
      },
      template: (item, options) => {
        return (
          <a
            href={`${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}/booking/flight`}
            className="flex w-full cursor-pointer items-center border-b border-gray-200 px-[20px] py-[12px] hover:bg-gray-100"
            onClick={(e) => options.onClick(e)}
          >
            <img src={bookingFlightIcon} className="h-[20px] w-[20px]" />
            <span className="ml-1 text-[16px] font-semibold leading-[24px] text-gray-500">
              {capitalizedFirst(t("menu-header.booking-menu.flight"))}
            </span>
          </a>
        )
      },
    },
    {
      command: () => {
        window.open(`${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}`, "_blank")
      },
      template: (item, options) => {
        return (
          <a
            href={`${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}`}
            className="border-gray-2 flex w-full cursor-pointer items-center border-b px-[20px] py-[12px] hover:bg-gray-100"
            onClick={(e) => options.onClick(e)}
          >
            <img src={bookingHotelIcon} className="h-[20px] w-[20px]" />
            <span className="ml-1 text-[16px] font-semibold leading-[24px] text-gray-500">
              {capitalizedFirst(t("menu-header.booking-menu.hotel"))}
            </span>
          </a>
        )
      },
    },
    {
      command: () => {
        window.open(`${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`, "_blank")
      },
      template: (item, options) => {
        return (
          <a
            href={`${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`}
            className="border-gray-2 flex w-full cursor-pointer items-center border-b px-[20px] py-[12px] hover:bg-gray-100"
            onClick={(e) => options.onClick(e)}
          >
            <img src={bookingCarIcon} className="h-[20px] w-[20px]" />
            <span className="ml-1 text-[16px] font-semibold leading-[24px] text-gray-500">
              {capitalizedFirst(t("menu-header.booking-menu.car"))}
            </span>
          </a>
        )
      },
    },
    {
      command: () => {
        window.open(`${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}`, "_blank")
      },
      template: (item, options) => {
        return (
          <a
            href={`${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}`}
            className="flex w-full cursor-pointer items-center px-[20px] py-[12px] hover:bg-gray-100"
            onClick={(e) => options.onClick(e)}
          >
            <img src={bookingTourIcon} className="h-[20px] w-[20px]" />
            <span className="ml-1 text-[16px] font-semibold leading-[24px] text-gray-500">
              {capitalizedFirst(t("menu-header.booking-menu.tours"))}
            </span>
          </a>
        )
      },
    },
  ]

  const navItem = useMemo(() => {
    return [
      {
        icon: homeNavIcon,
        label: "global.home-page",
        action: function () {
          window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}`, "_self")
        },
        type: "home",
        hrefLink: process.env.REACT_APP_HOME_DOMAIN_URL,
      },
      {
        icon: bookingNavIcon,
        label: "global.booking-page",

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: function (e: any) {
          bookingMenuRef?.current?.toggle(e)
        },
        subMenu: [],
        type: "booking",
      },
      {
        icon: foodNavIcon,
        label: "global.food",
        action: function () {
          window.open(`${process.env.REACT_APP_FOOD_DOMAIN_URL}`, "_blank")
        },
        type: "food",
        hrefLink: process.env.REACT_APP_FOOD_DOMAIN_URL,
      },
      {
        icon: shopNavIcon,
        label: "global.shopping-page",
        action: function () {
          navigate("/shopping")
        },
        isActive: true,
        type: "shop",
        hrefLink: process.env.REACT_APP_ECOMMERCE_DOMAIN_URL,
      },
    ]
  }, [])

  
  const getUser = () => {
    const userId = localStorage.getItem("userId")
    if(!userId) return
    getUserInfo(userId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {        
        if (res?.data?.data) {
          const langResult = res.data.data?.language
            ? res.data.data.language
            : "vn"
          const currentCurrency = res.data.data?.currency
            ? res.data.data?.currency
            : "USD"
          i18n.changeLanguage(langResult.toLowerCase())
          const authData = {
            user: res?.data?.data as userType,
            access_token: localStorage.getItem("accessToken") || "",
          }
          dispatch(signIn(authData))
          updateCurrency(currentCurrency)
        }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getUser()
  },[])
  return (
    <header className="fixed top-0 z-[21] flex w-full items-center justify-center border border-gray-200 bg-white py-2">
      <div className="flex w-[1200px] items-center justify-between gap-1 px-[12px]">
        <HeaderLeftView />
        <div className="flex flex-1 items-center justify-center">
          <div className="flex items-center gap-2">
            {navItem.map((i, index) => (
              <div key={index}>
                {i?.type === "food" || i?.type === "shop" || i?.type === "home" ? (
                  <a
                    key={index}
                    href={i?.hrefLink || undefined}
                    target={i?.type === "food" ? "_blank" : "_self"}
                    className={`flex flex-1 cursor-pointer items-center justify-center rounded-3 py-1 md:px-3 px-1 hover:bg-blue-50 hover:text-blue-600 ${
                      i?.isActive ? "bg-blue-50" : ""
                    }`}
                    rel="noreferrer"
                  >
                    <img src={i.icon} alt="navIcon" className="h-[20px] w-[20px]" />
                    <span
                      className={`ml-1 hidden text-[16px] leading-[24px] md:block  ${
                        i?.isActive ? "font-semibold text-blue-600" : "font-medium text-gray-500"
                      }`}
                    >
                      {capitalizedFirst(t(i.label))}
                    </span>
                  </a>
                ) : (
                  <div
                    onClick={(e) => i?.action(e)}
                    key={index}
                    className={`flex flex-1 cursor-pointer items-center justify-center rounded-3 py-1 md:px-3 px-1 hover:bg-blue-50 hover:text-blue-600 ${
                      i?.isActive ? "bg-blue-50" : ""
                    }`}
                  >
                    <img src={i.icon} alt="navIcon" className="h-[20px] w-[20px]" />
                    <span
                      className={`ml-1 hidden text-[16px] leading-[24px] md:block  ${
                        i?.isActive ? "font-semibold text-blue-600" : "font-medium text-gray-500"
                      }`}
                    >
                      {capitalizedFirst(t(i.label))}
                    </span>

                    {i?.subMenu && (
                      <img
                        src={arrowDownIcon}
                        alt="arrowDownIcon"
                        className="ml-4px h-[20px] w-[20px]"
                      />
                    )}
                    {i.type === "booking" ? (
                      <Menu
                        className="mt-[16px] rounded-3"
                        model={bookingItems}
                        popup
                        ref={bookingMenuRef}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <HeaderRightView />
      </div>
    </header>
  )
}
export default HeaderView
