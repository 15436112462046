export interface MyyAddressModalProps {
  isShow: boolean
  handleClose: () => void
  handleConfirm: (address: AddressType) => void
  handleEditMyAddressSelect: (addressDelete: string) => void
  openNewAddress: () => void
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeDefaultAddress?: (data?: any) => void
  addressSelect?: AddressType
  openConfirmDeleteModal: (addressId: string) => void
}
export interface ProductProps {
  productImg: string
  color: string
  isActive: boolean
  index?: number
  updateColor: (color: string) => void
}

export interface AddressType {
  index?: number
  id?: string
  userId?: string
  fullName: string
  phoneNumber: string
  address: string
  country: string
  city: string
  district: string
  ward: string
  addressType: string
  isDefault: boolean
  isSelected?: boolean
  city_id: string
  ward_id: string
  district_id: string
  handleDeleteMyAddress?: (addressId: string) => void
  handleEditAddress?: (addressId: string) => void
  updateIsSelected?: (addressId: string) => void
}

export enum TypeAddress{
  HOME = "HOME",
  OFFICE="OFFICE"
}
