import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { getPaymentGateway } from "src/services/payment-service"
import CheckoutHeader from "../checkout-view/checkout-header"
import { RadioButton } from "primereact/radiobutton"
import Loading from "src/components/loading"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"

export interface PaymentMethodViewType {
  stepView: string
  updateStepView: (value: string) => void
  getPaymentType: (value: string) => void
  updateIsCallClick: () => void
}

export const PAYMENT_TYPE_SELECT = {
  ONLINE: "ONLINE",
  COD: "COD",
}
export default function PaymentMethodView(props: PaymentMethodViewType) {
  const { stepView, updateStepView, getPaymentType, updateIsCallClick } = props
  const { t } = useTranslation()
  const [isRender, setIsRender] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [isPaymentOnline, setIsPaymentOnline] = useState<boolean>(false)
  const [isPaymentCod, setIsPaymentCod] = useState<boolean>(false)
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(true)
  const [paymentTypeSelect, setPaymentTypeSelect] = useState<string>(
    PAYMENT_TYPE_SELECT.ONLINE
  )
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const detectPaymentType = () => {
    getPaymentGateway()
      .then((res) => {
        let isCod = false
        let isOnline = false
        if (res?.data?.data?.length) {
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            if (i?.name === "COD") isCod = true
            if (!isOnline && (i?.name === "STRIPE" || i?.name === "APP_OTA"))
              isOnline = true
          })
        }
        setIsPaymentOnline(isOnline)
        setIsPaymentCod(isCod)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsReady(true)
      })
  }

  useEffect(() => {
    detectPaymentType()
  }, [])

  useEffect(() => {
    // generate View
    if (isReady) {
      if (isPaymentCod && isPaymentOnline) {
        setPaymentTypeSelect("ONLINE")
        getPaymentType("ONLINE")
        setIsRender(true)
      } else if(!isPaymentCod && !isPaymentOnline) setIsRender(true)
      else {
        const result = isPaymentOnline ? "ONLINE" : "COD"
        getPaymentType(result)
        updateIsCallClick()
      }
    }
  }, [isReady])

  const changeOnlineType = () => {
    if (paymentTypeSelect !== PAYMENT_TYPE_SELECT.ONLINE && isPaymentOnline) {
      setPaymentTypeSelect(PAYMENT_TYPE_SELECT.ONLINE)
      getPaymentType(PAYMENT_TYPE_SELECT.ONLINE)
    }
  }
  const changeCodType = () => {
    if (paymentTypeSelect !== PAYMENT_TYPE_SELECT.COD && isPaymentCod) {
      setPaymentTypeSelect(PAYMENT_TYPE_SELECT.COD)
      getPaymentType(PAYMENT_TYPE_SELECT.COD)
    }
  }

  const isProductIsTicket = useMemo(() => {
    let result = false
    shoppingCartStore?.cartData.forEach(cart => {
      cart.objectDto.forEach(prod => {
        if(prod.isSelected && prod.catalogs?.length && prod.catalogs[0].type === "E_TICKET") result = true
      })
    })
    return result
  }, [shoppingCartStore])

  return (
    <div className="flex flex-col items-center justify-between rounded-3 border border-gray-200 bg-white p-3">
      <CheckoutHeader modeView={stepView} updateStepView={updateStepView} />
      {isRender ? (
        <>
          {!isPaymentOnline && !isPaymentCod ? (
            <>
              <div className="flex h-[200px] items-center">
                <span className="mt-[24px] w-full text-[18px] font-semibold leading-[28px] text-gray-900">
                  {capitalizedFirst(t("payment-gateway.not-available"))}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="mt-[24px] flex w-full items-center justify-between">
                <span className="text-[14px] font-semibold leading-[20px] text-gray-900 md:text-[18px] md:leading-[28px]">
                  {capitalizedFirst(t("contentHeader.paymentMethod"))}
                </span>
                <div
                  onClick={() => setIsOpenSelect(true)}
                  className="flex items-center gap-[4px] md:gap-[8px] cursor-pointer"
                >
                  {/* <span className="text-[10px] font-medium leading-[14px] text-gray-900 md:text-[14px] md:leading-[20px]">
                    {capitalizedFirst(
                      t(
                        paymentTypeSelect === PAYMENT_TYPE_SELECT.ONLINE
                          ? "payment-gateway.online-type"
                          : "payment-gateway.cod-type"
                      )
                    )}
                  </span>
                  <div className="flex items-center gap-1 py-4px px-[0px] md:px-[12px]">
                    <span className="hidden text-[14px] font-semibold leading-[20px] text-blue-600 md:block">
                      {capitalizedFirst(t("global.change"))}
                    </span>
                    <i
                      className={`${
                        isOpenSelect
                          ? "sctr-icon-arrow-narrow-up"
                          : "sctr-icon-arrow-narrow-down"
                      } text-blue-600`}
                    />
                  </div> */}
                </div>
              </div>
              {isOpenSelect ? (
                <>
                  <div className="mt-[16px] flex w-full flex-col items-center gap-[12px] md:flex-row">
                    {isPaymentOnline ? (
                      <>
                        <div
                          onClick={changeOnlineType}
                          className={`item-center flex w-full  cursor-pointer gap-[12px] rounded-[8px] border border-blue-600 p-[16px] md:flex-1 ${
                            !isPaymentOnline ? "opacity-50" : ""
                          }`}
                        >
                          <RadioButton
                            disabled={!isPaymentOnline}
                            checked={
                              paymentTypeSelect === PAYMENT_TYPE_SELECT.ONLINE
                            }
                            readOnly
                          />
                          <span className="text-[14px] font-medium leading-[20px] text-gray-900">
                            {capitalizedFirst(t("payment-gateway.online-type"))}
                          </span>
                        </div>
                      </>
                    ) : null}

                    {isPaymentCod && !isProductIsTicket ? (
                      <>
                        <div
                          onClick={changeCodType}
                          className={`item-center flex w-full cursor-pointer gap-[12px] rounded-[8px] border border-blue-600 p-[16px] md:flex-1 ${
                            !isPaymentCod ? "opacity-50" : ""
                          }`}
                        >
                          <RadioButton
                            disabled={!isPaymentCod}
                            checked={
                              paymentTypeSelect === PAYMENT_TYPE_SELECT.COD
                            }
                            readOnly
                          />
                          <span className="text-[14px] font-medium leading-[20px] text-gray-900">
                            {capitalizedFirst(t("payment-gateway.cod-type"))}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          )}
        </>
      ) : (
        <div className="flex min-h-[300px] items-center justify-center">
          <Loading />
        </div>
      )}
    </div>
  )
}
