import { isIFrame } from "src/components/shareToken/types"
import { store } from "../app/store"

export interface SocChatOpenProductType {
  agencyId: string
  metaData: MetaDataType | undefined
}
export interface MetaDataType {
  object_id: string
  name: string
  avt: string
  type: string
  user_link: string
  agency_link: string
  admin_link: string
  price: number
}

export const SOCCHAT_STATUS = {
  INIT: "INIT",
  READY: "READY",
  FAIL: "FAIL",
}

export const SocChatInstance = {
  isLoad: false,
  isOpen: false,
  status: SOCCHAT_STATUS.INIT,
  openAction: undefined || Function,
  closeAction: undefined || Function,
  // create prod conversation
  startNewConversation: function (data: SocChatOpenProductType) {
    const frame = document.getElementById("socchatViewReact")
    const storeData = store.getState()
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "initial",
        data: {
          token: storeData.auth.value?.access_token,
          userId: storeData.auth.value?.user?.id,
          module: "SHOP",
          isEnableChatBot: false,
          agencyId: data.agencyId,
          meta_data: data.metaData,
        },
      }
      console.log(messageData, "SOCCHAT_DATA")
      frame.contentWindow.postMessage(messageData, "*")
    }
    if (!this.isOpen && this.openAction) this.openAction()
  },
  // create shop conversation
  startShopConversation: function (shopId: string) {
    const frame = document.getElementById("socchatViewReact")
    const storeData = store.getState()
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "initial",
        data: {
          token: storeData.auth.value?.access_token,
          userId: storeData.auth.value?.user?.id,
          module: "SHOP",
          isEnableChatBot: false,
          agencyId: shopId,
          meta_data: undefined,
        },
      }
      console.log(messageData, "SOCCHAT_DATA")
      frame.contentWindow.postMessage(messageData, "*")
      if (!this.isOpen && this.openAction) this.openAction()
    }
  },
  // TOGGLE OPEN/CLOSE SOCCHAT
  toggleSocChat: function () {
    if (this.isLoad) {
      if (this.isOpen) {
        if (this.closeAction) this.closeAction()
      } else {
        if (this.openAction) this.openAction()
      }
      this.isOpen = !this.isOpen
    }
  },
  // INIT SOCCHAT
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initSocChat: function (openSocChat: any, closeSocChat: any) {
    const frame = document.getElementById("socchatViewReact")
    const storeData = store.getState()
    if (isIFrame(frame) && frame.contentWindow) {
      const data = {
        type: "initial",
        data: {
          token: storeData.auth.value?.access_token,
          userId: storeData.auth.value?.user?.id,
          module: "SHOP",
          isEnableChatBot: false,
        },
      }
      this.isLoad = true
      this.closeAction = closeSocChat
      this.openAction = openSocChat
      frame.contentWindow.postMessage(data, "*")
    }
  },
}
