import React, { useState, useMemo, useEffect } from "react"
import shopVoucherIcon from "../../../assets/images/shop-voucher-icon.svg"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { Checkbox } from "primereact/checkbox"
import "./index.scss"
import ImgCustom from "../../img-custom"
import { useNavigate } from "react-router-dom"
import { ObjectDtoType, ShoppingCartViewType } from "../type"
import { FLASH_DEAL_TYPE, ORDER_TYPE_MODE } from "src/constants/common"
import ProductCartView from "./product-cart-view"
import { SelectVoucherModal } from "src/components/modal"
import {
  VoucherApplyType,
  VoucherDataType,
  VoucherType,
} from "src/components/modal/select-voucher-modal/types"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { convertViToEn, getFlashDealTime, mathRoundNumber } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"

export default function ShopView(props: ShoppingCartViewType) {
  const { t } = useTranslation()
  const {
    refetch,
    cartName,
    cartImg,
    index,
    objectDto,
    updateShopProduct,
    cartId,
    updateSelectAll,
    deleteProduct,
    updateShopVoucher,
    updateShopNote,
    shopVoucher,
    shipVoucher,
    shopNote,
    tab,
    is_suspension_mode,
    isShopActive,
  } = props
  const [isAllProductCheck, setIsAllProductCheck] = useState<boolean>(false)
  const navigator = useNavigate()
  const [voucherData, setVoucherData] = useState<VoucherDataType | undefined>(
    undefined
  )

  const [isOpenVoucherModal, setIsOpenVoucherModal] = useState<boolean>(false)
  const handleUpdateProductSelected = (isAllSelected: boolean) => {
    if (updateSelectAll) updateSelectAll(isAllSelected, cartId)
    if (isAllSelected) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const itemData = [] as any[]
      shoppingCartStore.cartData.forEach((shop) => {
        if(shop.cartId === cartId) {
          shop.objectDto.forEach((p) => {
            itemData.push({
              item_id: p.id,
              item_name: p.title,
              price: mathRoundNumber(p.stockDetail.price_after_tax),
              quantity: p.quantity,
            })
          })
        }
      })
      const gaData = {
        item_list_id: "MY_CART",
        item_list_name: `SELECT_ALL_ITEM_SHOP_${cartId}`,
        items: itemData,
      }
      gaPostAction(TAG_TYPE.VIEW_ITEM_LIST, gaData)
    }
  }
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const systemStore = useSelector((state: RootState) => state.system)
  const handleUpdateProductShop = (productData: ObjectDtoType) => {
    const newProducts = [] as ObjectDtoType[]
    objectDto?.forEach((p) => {
      if (p.id === productData.id) {
        newProducts.push(productData)
      } else {
        newProducts.push(p)
      }
    })
    if (updateShopProduct) {
      updateShopProduct(newProducts, cartId as string)
    }
  }

  const getPriceProduct = (data: ObjectDtoType) => {
    const rangeTime = getFlashDealTime(systemStore.value)
    let result = 0
    if (
      data?.liveToken &&
      data?.isLiveStream &&
      data?.liveStreamDeal &&
      data?.quantity <=
        data?.liveStreamDeal.quantity - data?.liveStreamDeal.sold
    ) {
      result = (100 - data?.liveStreamDeal.discount_percent) * data?.stockDetail.original_price_after_tax / 100
    } else if (
      data?.isFlashDeal &&
      data?.flashDeal &&
      rangeTime &&
      data?.quantity <= data?.flashDeal.max_sold - data?.flashDeal.sold
    ) {
      if (data.flashDeal.type === FLASH_DEAL_TYPE.FIXED_PRICE) {
        result = data.flashDeal.marketing_price
      } else {
        result = (100 - data.flashDeal.marketing_price) * data.stockDetail.original_price_after_tax / 100
      }
    } else {
      result = data?.stockDetail?.price_after_tax
    }
    return result
  }

  const shopBilling = useMemo(() => {
    let billing = 0
    objectDto?.forEach((p) => {
      if (p.isSelected) {

        billing += getPriceProduct(p) * p?.quantity
      } 
    })
    return billing
  }, [objectDto])

  const liveBilling = useMemo(() => {
    let billing = 0
    objectDto?.forEach((p) => {
      if (p.isSelected && p?.isLiveStream) {
        billing += getPriceProduct(p) * p?.quantity
      }
    })
    return billing
  }, [objectDto])

  const liveProductData = useMemo(() => {
    const result = [] as {value: number; id: string}[]
    objectDto?.forEach((p) => {
      if (p.isSelected && p?.isLiveStream) {
        result.push({
          value: getPriceProduct(p) * p?.quantity,
          id: p?.objectId,
        })
      }
    })
    return result
  }, [objectDto])

  const campaignId = useMemo(() => {
    let result = ""
    objectDto?.forEach((p) => {
      if (p.isSelected && p?.isLiveStream) {
        if(!result && p.liveStreamDeal?.campaign_id) result = p.liveStreamDeal.live_id
      }
    })
    return result
  }, [objectDto])

  useEffect(() => {
    let countProductSelected = 0
    objectDto.forEach((p) => {
      if (p.isSelected) countProductSelected += 1
    })
    setIsAllProductCheck(countProductSelected === objectDto.length)
  })
  
  const handleVisitShop = () => {
    const shopCode = cartId?.split("-")?.length ? cartId?.split("-")[0]: ""
    navigator(`/shopping/merchant/${convertViToEn(cartName)}/${cartId}/${shopCode}`)
  }

  const openVoucherShop = () => {
    const objId: string[] = []
    const cartIdData: string[] = []
    cartIdData.push(cartId)
    objectDto?.forEach((obj) => {
      if (obj.isSelected) objId.push(obj.objectId)
    })
    if (objId.length) {
      const voucherData = {
        cartId: cartIdData,
        objId: objId,
      }
      setVoucherData(voucherData)
      setIsOpenVoucherModal(true)
    }
  }
  const updateDiscountShop = (
    voucherApply: VoucherApplyType | undefined,
    shipVoucherApply: VoucherApplyType | undefined,
    systemVoucherApply: VoucherType | undefined
  ) => {
    updateShopVoucher(voucherApply, shipVoucherApply, systemVoucherApply, cartId)
    setIsOpenVoucherModal(false)
  }
  const handleUpdateNoteShop = (newNote: string) => {
    updateShopNote(newNote, cartId)
  }
  const isHaveSelect = useMemo(() => {
    let result = false
    objectDto?.forEach((obj) => {
      if (obj.isSelected) {
        result = true
        return
      }
    })
    return result
  }, [objectDto])
  useEffect(() => {
    if (!isHaveSelect && shopNote) handleUpdateNoteShop("")
  }, [isHaveSelect, shopNote])

  const getIdShopSelected = useMemo(() => {
    const result: string[] = []
    shoppingCartStore.cartData.forEach((cart) => {
      if (cart.cartId === cartId) {
        cart.objectDto.forEach((product) => {
          if (product.isSelected) {
            result.push(cart.cartId)
            return
          }
        })
      }
    })
    return result
  }, [shoppingCartStore.cartData])

  const isHaveData = useMemo(() => {
    let countItem = 0
    objectDto?.forEach((pro) => {
      if (pro.isDisplay) countItem += 1
    })
    return countItem > 0
  }, [objectDto])

  if (!isHaveData) return <></>

  return (
    <div
      className={`flex flex-col rounded-[8px] border border-gray-200 bg-white pb-3 ${
        index && "mt-3"
      }`}
    >
      <div className="flex w-[100%] items-center justify-between border-b-[1px] p-3">
        <div className="flex items-center">
          <Checkbox
            className="h-[22px] w-[22px]"
            onChange={(e) => handleUpdateProductSelected(e.checked as boolean)}
            checked={isAllProductCheck}
            disabled={is_suspension_mode || !isShopActive}
          />
          <div className="ml-1 h-[32px] w-[32px] rounded-circle">
            <ImgCustom
              url={cartImg}
              alt="cartImg"
              className="h-[32px] w-[32px] rounded-circle object-cover"
            />
          </div>
          <span className="font ml-1 text-16 font-semibold leading-24 text-gray-700">
            {cartName}
          </span>
        </div>
        {!isShopActive ? ( 
          <div className="text-12 text-red-500 font-medium animate-pulse">{capitalizedFirst(t("shoppingCartPage.shop-not-active"))}</div>
        ) : null}
        {is_suspension_mode && isShopActive ? ( 
          <div className="text-12 text-red-500 font-medium animate-pulse">{capitalizedFirst(t("shoppingCartPage.suspend-message"))}</div>
        ) : null}
        <div
          onClick={handleVisitShop}
          className="flex cursor-pointer items-center justify-center"
        >
          <span className="text-14 font-semibold leading-20 text-blue-700">
            {capitalizedFirst(t("shoppingCartPage.visitShop"))}
          </span>
          <i className="pi pi-angle-right ml-1 text-blue-700"></i>
        </div>
      </div>
      <div className="flex border-b-[1px] py-2">
        <ProductCartView
          refetch={refetch}
          cartId={cartId}
          objectDto={objectDto}
          deleteProduct={deleteProduct}
          updateProductShop={handleUpdateProductShop}
          is_suspension_mode={is_suspension_mode}
        />
      </div>
      <div className="flex w-full flex-col items-start justify-between gap-2 p-2 md:flex-row md:items-center">
        <div
          onClick={openVoucherShop}
          className={`flex min-w-max items-center justify-center rounded-3 border border-orange-dark-50 bg-orange-dark-50 px-3 py-10px ${
            isHaveSelect ? "cursor-pointer" : "opacity-50 cursor-default"
          }`}
        >
          <img src={shopVoucherIcon} alt="shopVoucherIcon" />
          <span className="ml-1 truncate text-14 font-semibold leading-20 text-orange-dark-400">
            {capitalizedFirst(t("shoppingCartPage.selectVouchers"))}
          </span>
        </div>
        <div className="flex w-full justify-start md:justify-end md:items-center">
          <span className="font-12 truncate px-4px font-semibold leading-18 text-gray-700">
            {capitalizedFirst(t("shoppingCartPage.shopCartTotal"))}
          </span>
          <span className="font-14 ml-1 font-medium leading-20 text-orange-dark-500">
            {convertCurrency(shopBilling)}
          </span>
        </div>
      </div>
      {isOpenVoucherModal ? (
        <SelectVoucherModal
          isShow={isOpenVoucherModal}
          handleClose={() => setIsOpenVoucherModal(false)}
          handleConfirm={(
            voucherApply: VoucherApplyType | undefined,
            shipVoucherApply: VoucherApplyType | undefined,
            systemVoucherApply: VoucherType | undefined
          ) => updateDiscountShop(voucherApply, shipVoucherApply, systemVoucherApply)}
          voucherData={voucherData}
          shipVoucherApply={shipVoucher}
          shopBilling={shopBilling}
          voucherApply={shopVoucher}
          idsShop={getIdShopSelected}
          liveBilling={liveBilling}
          isHiddenShip={tab === ORDER_TYPE_MODE.OFFLINE}
          liveProductData={liveProductData}
          campaignId={campaignId}
        />
      ) : null}
    </div>
  )
}
