import { InputText } from "primereact/inputtext"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import MerchantFilter from "src/components/merchant-filter"
import { FilterTextProps } from "./props"
import {
  GetProductList,
  handleGetMerchantData,
} from "src/services/merchant-service"
import "./index.scss"
import EmptyPage from "../empty-page"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import MerchantProfile from "src/components/merchant-profile"
import { ShopProperties } from "../merchant-page/props"
import ImgCustom from "src/components/img-custom"
import SkeletonLoading from "src/components/skeleton-loading"
import { getBloomFilter } from "src/services/bookmark-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import { Menu } from "primereact/menu"
import CustomCard from "src/components/card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { CustomCardProps } from "src/components/card-custom/card-custom/custom-card-props"
import useOnScreen from "src/hooks/use-on-screen"
import HeaderLoading from "./header-loading"
import MerchantHeaderCustom from "src/components/shopping-homepage-view/merchant-header-custom"
import { ImgCustomType } from "src/constants/constants/image-custom-type"
import { convertViToEn } from "src/utils/common"

export default function MerchantSearchPage() {
  const menuRight = useRef<Menu>(null)
  const params = useParams()
  const loadingRef = useRef(null)
  const navigate = useNavigate()
  const isOnScreen = useOnScreen(loadingRef)
  const [searchValues, setSearchValues] = useState<string>("")
  const [loadMoreYourMarket, setLoadMoreYourMarket] = useState<boolean>(false)
  const authStore = useSelector((state: RootState) => state.auth)
  const [shopData, setShopData] = useState<ShopProperties>()
  const [textSearchFilter, setTextSearchFilter] = useState<string>("")
  const [propDataFilter, setPropDataFilter] = useState<FilterTextProps>()
  const [productLists, setProductLists] = useState<CustomCardProps[]>([])
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [shopId, setShopId] = useState<string>("")
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [sort, setSort] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)
  const [background, setBackground] = useState<string>("")
  const [avatar, setAvatar] = useState<string>("")
  const [isFilter, setIsFilter] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [limitSkeleton, setLimitSkeleton] = useState<number>(5)
  const [emptyMessage, setEmptyMessage] = useState<string>(
    "Not found data. Please try again later."
  )

  const itemsSearchContent: MenuItem[] = [
    {
      data: "-created_at|LocalDateTime",
      label: translateCapitalize("global.newest-arrival"),
      icon: "pi pi-caret-up",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "s_name",
      label: "A->Z",
      icon: "pi pi-sort-alpha-down",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "-s_name",
      label: "Z -> A",
      icon: "pi pi-sort-alpha-down-alt",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "-price|double",
      label: translateCapitalize("global.high-to-low"),
      icon: "pi pi-sort-amount-down",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
    {
      data: "price|double",
      label: translateCapitalize("global.low-to-high"),
      icon: "pi pi-sort-amount-up",
      command: (e: MenuItemCommandEvent) => {
        setSort(e.item.data)
      },
    },
  ]

  const getData = async (shopId: string) => {
    await handleGetMerchantData(shopId)
      .then((response) => {
        setShopData(response?.data?.data)
      })
      .catch((err) => {
        err
      })
  }

  useEffect(() => {
    setIsEmpty(false)
    const param = new URL(window.location.href).searchParams.get("keyword")
    const id = params?.id
    setShopId(id as string)
    getData(id as string)
    setSearchValues(param as string)
  }, [])

  // get filter from child
  const handleFilterData = (data: FilterTextProps) => {
    setPropDataFilter(data)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValues) {
        const textSearchFilter = `name@=${searchValues}`
        setTextSearchFilter(textSearchFilter)
        navigate("?keyword=" + searchValues)
      } else {
        setTextSearchFilter("")
        navigate("?")
      }
    }, 800)
    return () => clearTimeout(timeout)
  }, [searchValues])

  useEffect(() => {
    if (
      propDataFilter?.price &&
      propDataFilter?.rating &&
      (!isFirstLoad || textSearchFilter.length !== 0)
    ) {
      handleGetProductList()
    }
  }, [textSearchFilter, propDataFilter, sort])

  useEffect(() => {
    if (isFirstLoad && textSearchFilter.length === 0) {
      handleGetProductList()
      setIsFirstLoad(false)
    }
  }, [textSearchFilter])

  // handle get product list
  const handleGetProductList = async () => {
    const arrayString = []
    const current = 0
    setCurrentPage(current)
    if (textSearchFilter) {
      arrayString.push(textSearchFilter)
    }
    if (propDataFilter?.category) {
      arrayString.push(propDataFilter?.category)
    }
    if (propDataFilter?.price) {
      arrayString.push(propDataFilter?.price)
    }
    if (propDataFilter?.rating) {
      arrayString.push(propDataFilter?.rating)
    }

    const textFilter = arrayString.join()
    const encodeTextFilter = encodeURIComponent(textFilter)
    const encodeSort = encodeURIComponent(sort)

    if (textFilter) {
      setLoading(true)
      setTotalProducts(0)
      await GetProductList(shopId, current, 20, encodeTextFilter, encodeSort)
        .then((response) => {
          if (response?.data?.data) {
            setTotalProducts(response?.data?.data?.totalElement)
            setProductLists(response?.data?.data?.data)
            setLoading(false)
            setIsEmpty(false)
          }
          if (response?.data?.data?.totalElement === 0) {
            setEmptyMessage("No data found. Please try with other filters.")
            setIsEmpty(true)
          }
        })
        .catch(() => {
          setLoading(false)
          setIsEmpty(true)
          setEmptyMessage("Network Error. Please refresh page try again.")
        })
      setIsFirstLoad(false)
    }
  }

  const handleLoadMore = async () => {
    const arrayString = []
    if (textSearchFilter) {
      arrayString.push(textSearchFilter)
    }
    if (propDataFilter?.category) {
      arrayString.push(propDataFilter?.category)
    }
    if (propDataFilter?.price) {
      arrayString.push(propDataFilter?.price)
    }
    if (propDataFilter?.rating) {
      arrayString.push(propDataFilter?.rating)
    }
    const textFilter = arrayString.join()
    const encodeTextFilter = encodeURIComponent(textFilter)
    const encodeSort = encodeURIComponent(sort)
    const current = currentPage + 1
    setCurrentPage(current)
    if (textFilter) {
      await GetProductList(shopId, current, 20, encodeTextFilter, encodeSort)
        .then((response) => {
          if (response?.data?.data) {
            const results: CustomCardProps[] = response?.data?.data?.data
            setProductLists([...productLists, ...results])
          }
          setLoadMoreYourMarket(false)
        })
        .catch(() => {
          setLoadMoreYourMarket(false)
        })
    }
  }

  const handleReloadData = async () => {
    handleGetBloomData()
  }

  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  useEffect(() => {
    if (shopData) {
      setBackground(shopData?.background?.id)
      setAvatar(shopData?.avatar?.id)
    }    
  }, [shopData])

  const handleFilter = () => {
    setIsFilter(!isFilter)
  }

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  useEffect(() => {
    if (isFilter) {
      setLimitSkeleton(4)
    } else {
      setLimitSkeleton(5)
    }
  }, [isFilter])

  useEffect(() => {
    handleGetBloomData()
  }, [])

  useEffect(() => {
    if (
      isOnScreen &&
      !isFirstLoad &&
      currentPage < 2 &&
      totalProducts > 20 &&
      productLists?.length < totalProducts
    ) {
      handleLoadMore()
    }
  }, [isOnScreen])

  return (
    <div id="top" className="mx-auto px-3">
      <div className="flex h-full w-full flex-col py-4">
        {/* header */}
        {shopId ? (
          <div className="relative flex">
            {/* background image */}
            <div className="flex w-full items-center rounded-t-3 bg-blue-light-100 object-cover object-center sm:h-full md:h-full">
              {background ? (
                <ImgCustom
                  url={background}
                  alt="header"
                  className="max-h-[400px] min-h-[350px] w-full rounded-3 object-cover object-center sm:h-[150px] md:min-h-[300px]"
                />
              ):<MerchantHeaderCustom
                typeImg={ImgCustomType.image}
                imgUrl={background}
              />}
            </div>
            <div className="absolute top-[54%] z-[1] w-full rounded-4 px-1 sm:left-4 sm:max-w-[450px] md:top-[55%] md:max-w-[650px] md:px-2px">
              {/* shop info */}
              <MerchantProfile
                id={shopData?.id as string}
                avatar={avatar}
                description={shopData?.slogan}
                followers={shopData?.followers}
                rating={shopData?.rating}
                name={shopData?.name}
                productNumber={shopData?.total_products}
                background={shopData?.background?.id as string}
              />
            </div>
            {/* Search input */}
            <div className="absolute bottom-[55%] left-1 right-[5%] h-fit w-[90%] sm:left-4 sm:w-[70%] md:left-4 md:top-[30%] md:right-4 md:w-2/4 lg:top-[55%] lg:left-[72.6%] lg:w-1/4 xl:top-[75%] xl:right-5 xl:w-1/4">
              <span className="p-input-icon-left w-full">
                <i className="pi pi-search" />
                <InputText
                  className="w-full"
                  placeholder={capitalizedFirst(
                    handleTranslate("global.search")
                  )}
                  value={searchValues || ""}
                  onChange={(event) => setSearchValues(event.target.value)}
                />
              </span>
            </div>
          </div>
        ) : (
          <div className="h-[350px] w-full">
            <HeaderLoading/>
          </div>
        )}

        {/* content */}
        <div className="flex flex-col pt-3">
          <div className="mb-2 flex w-full flex-col items-center md:flex-row">
            <div className="flex w-full items-center gap-4px text-16 md:w-[350px] md:text-18 lg:w-[750px] lg:text-20">
              <div className="flex items-center font-semibold text-gray-700">
                <span>{totalProducts}</span>
                &nbsp;
                <span>{handleTranslate("merchant-page.results")}</span>
                &nbsp;
                {searchValues && (
                  <span>{handleTranslate("merchant-page.for")} </span>
                )}
              </div>
              <div className="truncate font-semibold text-blue-600">
                {searchValues}
              </div>
            </div>
            {/* sort */}
            <div className="mt-1 flex w-full items-center justify-end gap-1">
              {/* back to previous page */}
              <div
                onClick={() => {
                  const shopCode = shopData?.id?.split("-")?.length ? shopData?.id?.split("-")[0]: ""
                  navigate(`/shopping/merchant/${convertViToEn(shopData?.name as string)}/${shopData?.id}/${shopCode}`)
                }}
                className="cursor-pointer truncate rounded-3 px-2 py-4px text-14 text-blue-500 hover:text-blue-light-400 hover:underline"
              >
                {capitalizedFirst(
                  handleTranslate("merchant-page.back-to-shop")
                )}
              </div>

              {/* sort button */}
              <div
                onClick={(event) => menuRight?.current?.toggle(event)}
                className="relative flex cursor-pointer items-center gap-4px rounded-3 bg-white px-3 py-1 hover:stroke-blue-500"
              >
                <div className="text-14 font-semibold text-gray-600">
                  {translateCapitalize("global.sort")}
                </div>
                <div className="fill-gray-600 stroke-gray-600">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <Menu
                  className="mt-1 px-4px"
                  model={itemsSearchContent}
                  popup
                  ref={menuRight}
                  id="popup_menu_right"
                />
              </div>
              {/* show filter */}
              <div
                onClick={handleFilter}
                className="flex cursor-pointer items-center gap-4px rounded-3 bg-white px-3 py-1 text-gray-600 "
              >
                <p className="text-14 font-semibold ">
                  {capitalizedFirst(handleTranslate("global.filter"))}
                </p>
                <span className="pi pi-filter text-14 font-semibold "></span>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col gap-1">
            {/* content */}
            <div className="flex flex-col gap-2 md:flex-row">
              <div
                className={`h-fit bg-white ${
                  !isFilter ? "hidden w-full" : "w-full md:w-1/4"
                }`}
              >
                <MerchantFilter
                  sort={sort}
                  setSort={setSort}
                  handleFilterData={handleFilterData}
                  shopId={shopId}
                />
              </div>
              <div className={`${isFilter ? "w-full md:w-3/4" : "w-full"}`}>
                {loading ? (
                  <div className="h-[700px] w-full">
                    {/* <Loading /> */}
                    <SkeletonLoading limit={limitSkeleton} />
                  </div>
                ) : (
                  <div className="w-full pb-3">
                    {isEmpty ? (
                      <div className="flex h-[840px] w-full items-center justify-center">
                        <EmptyPage message={emptyMessage} />
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
                        {productLists?.map(
                          (pro: CustomCardProps, index: number) => (
                            <div key={index}>
                              <CustomCard
                                key={pro.id}
                                id={pro.id}
                                typeCard={CardCustomType.product}
                                imgUrl={pro.avatar?.id}
                                avatar={pro.avatar}
                                name={pro.name}
                                point={pro.point}
                                price={pro.price}
                                price_after_tax={pro.price_after_tax}
                                original_price={pro.original_price}
                                original_price_after_tax={pro.original_price_after_tax}
                                sum_review={pro.sum_review}
                                quantity={pro.quantity}
                                voucherOfProducts={[pro.voucher as string]}
                                productId={pro.id}
                                shop_name={pro.shop?.name}
                                sub_label={pro.sub_label}
                                shop={pro.shop}
                                is_bookmark={pro.is_bookmark}
                                handleReload={handleReloadData}
                                bloom_filter={bloomData}
                                sold={pro?.sold}
                                is_flash_deal={pro.is_flash_deal}
                                flash_deal_status={pro?.flash_deal_status}
                                flash_deals={pro?.flash_deals}
                                flash_deals_time={pro?.flash_deals_time}
                                shop_id={pro?.shop_id}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={loadingRef} className="w-full"></div>
      {currentPage >= 2 ? (
        <div className="w-full">
          {!loadMoreYourMarket && (
            <div className="flex items-center justify-center">
              {productLists?.length < totalProducts ? (
                <div
                  onClick={handleLoadMore}
                  className="cursor-pointer py-1 text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("shoppingPage.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}

      {loadMoreYourMarket && (
        <div className="flex justify-center pb-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
