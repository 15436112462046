import React, { useEffect, useState } from "react"
import HomePageSponsor from "src/components/home-page-sponsor"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { getBloomFilter } from "src/services/bookmark-service"
import { HOME_MOST, SERVICE } from "src/constants/common"
import { useSearchParams } from "react-router-dom"
import MostPopularView from "src/components/most-popular-view"
import BestReviewedView from "src/components/best-reviewed-view"
import BestSellersView from "src/components/best-sellers-view"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { handlePromiseAll } from "src/utils/common"

export default function MostViewPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams] = useSearchParams()
  const authStore = useSelector((state: RootState) => state.auth)
  const [bloomData, setBloomData] = useState<string>("")
  const [selected, setSelected] = useState<string>("")
  const [sponsorData, setSponsorData] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRender, setIsRender] = useState<boolean>(false)

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }
  const handleGetSponsorData = async () => {
    const localRegion = localStorage.getItem("localRegion") || ""
    await new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchSponsorsGet("", 0, 5, undefined, {
        headers: {
          region: localRegion,
        },
      })
      .then((response) => {
        setSponsorData(response?.data?.data?.data)
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    const apis = []
    apis.push(handleGetSponsorData())
    apis.push(handleGetBloomData())
    handlePromiseAll(apis).then(() => {
      setSelected(searchParams.get("code") as string)
      setIsRender(true)
    })
  }, [])
  if(!isRender) return null
  return (
    <div className="mx-auto mt-3 flex w-full max-w-[1200px] flex-col gap-1">
      {/* sponsor */}
      {selected && sponsorData.length && !isLoading && (
        <div className="mb-4px flex w-full items-center">
          <HomePageSponsor
            bloom_filter={bloomData as string}
            handleReload={handleGetBloomData}
            classCard="w-[228.3px]"
            sponsorData={sponsorData}
            space={14}
          />
        </div>
      )}

      {/* products view */}

      <div className="w-full">
        {selected === HOME_MOST.MOST_POPULAR && (
          <MostPopularView
            bloom_filter={bloomData}
            handleReload={handleGetBloomData}
            sponsorDataCount={sponsorData.length}
            isLoading={isLoading}
            updateLoading={(value:boolean) => setIsLoading(value)}
          />
        )}
        {selected === HOME_MOST.BEST_REVIEW && (
          <BestReviewedView
            bloom_filter={bloomData}
            handleReload={handleGetBloomData}
            sponsorDataCount={sponsorData.length}
            isLoading={isLoading}
            updateLoading={(value:boolean) => setIsLoading(value)}
          />
        )}
        {selected === HOME_MOST.BEST_SELLER && (
          <BestSellersView
            bloom_filter={bloomData}
            handleReload={handleGetBloomData}
            sponsorDataCount={sponsorData.length}
            isLoading={isLoading}
            updateLoading={(value:boolean) => setIsLoading(value)}
          />
        )}
      </div>
    </div>
  )
}
