import { StatusControllerApi } from "@soctrip/axios-order-service"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import DialogCustom from "src/components/dialog-custom/dialog-custom"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import i18n from "src/locales"
import { CancelOrderModalProps, ReasonsType } from "./props"
import EmptyDataFound from "src/components/approve-info-view/empty-data"
import Loading from "src/components/loading"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"


export default function CancelOrderModal(props: CancelOrderModalProps) {
  const { isShowConfirmModal, setIsShowConfirmModal, selectReasons, setSelectReason, handleConfirmCancel } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reasons, setReason] = useState<ReasonsType[]>([])
  const { t } = useTranslation()

  const getReasonData = () => {
    setIsLoading(true)
    new StatusControllerApi(configHeader(SERVICE.ORDER))
      .statusReasonsGet("CANCEL", i18n.language)
      .then((res) => {
        if (res?.data?.data?.length) setReason(res?.data?.data as ReasonsType[])
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsLoading(false))

  }

  useEffect(() => {
    if (isShowConfirmModal) getReasonData()
  }, [isShowConfirmModal, i18n.language])

  return (
    <div>
      <DialogCustom
        header={capitalizedFirst(t("purchase-history.cancel-order"))}
        labelButton={capitalizedFirst(t("global.submit"))}
        position="center"
        visibleShowDialog={isShowConfirmModal}
        setVisibleShowDialog={() => setIsShowConfirmModal(false)}
        showIconClose={true}
        classLabel="w-[35vw]"
      >
        <div className="flex h-full w-full flex-col gap-4 px-4 pb-3">
          {
            !isLoading ? null : (<div className="p-4 min-h-[200px] flex justify-center items-center">
              <Loading />
            </div>
            )
          }
          {
            isLoading ? null : (
              <>
                {
                  !reasons.length ? <EmptyDataFound message={capitalizedFirst(t("global.data-not-found"))} /> : (
                    <>
                      <div className="flex w-full flex-col gap-3">
                        <span className="text-sm font-medium text-gray-700">
                          {capitalizedFirst(t("purchase-history.are-you-sure-you-want-to-cancel-this-order"))}
                        </span>
                        <div className="flex flex-col gap-1">
                          <span className="text-sm font-medium text-gray-900">
                            {capitalizedFirst(t("purchase-history.reason"))}
                            <span className="text-red-500">*</span>
                          </span>
                          <Dropdown
                            value={selectReasons}
                            onChange={(e: DropdownChangeEvent) => setSelectReason(e.value)}
                            options={reasons}
                            optionLabel="name"
                            placeholder={capitalizedFirst(t("purchase-history.select-a-reason"))}
                            className="md:w-14rem flex h-[44px] w-full items-center"
                          />
                        </div>
                      </div>

                      <div className="flex w-full items-center justify-end gap-3">						  <button
                        onClick={() => setIsShowConfirmModal(false)}
                        className="w-[100px] rounded-3 border border-gray-300 bg-gray-50 px-4 py-2 text-16 font-semibold text-gray-700"
                      >
                        {capitalizedFirst(t("purchase-history.cancel"))}
                      </button>

                      <button
                        disabled={!selectReasons}
                        onClick={handleConfirmCancel}
                        className={`w-[100px] rounded-3 bg-blue-500 px-4 py-2 text-16 font-semibold text-white ${selectReasons ? "" : "opacity-50"}`}
                      >
                        {capitalizedFirst(t("purchase-history.submit"))}
                      </button>
                      </div>
                    </>
                  )
                }
              </>

            )
          }

        </div>
      </DialogCustom>
    </div>
  )
}