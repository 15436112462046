/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react"
import { Dialog } from "primereact/dialog"
// import { capitalizedFirst } from "src/utils/capitalized-first-character"
// import { useTranslation } from "react-i18next"
import data from "@emoji-mart/data"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import "./index.scss"
import { MenuItem } from "primereact/menuitem"
import { ShareToFeedProps } from "./type"
import ImgCustom from "../img-custom"
import Picker from "@emoji-mart/react"
import { Menu } from "primereact/menu"
import { shareToFeed } from "src/services/share-to-feed"
import { useToast } from "src/hooks/use-toast"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

interface Country {
  name: string
  code: string
  icon: string
}

const privacies: Country[] = [
  { name: "share-to-feed.private", code: "PRIVATE", icon: "pi-lock" },
  { name: "share-to-feed.public", code: "PUBLIC", icon: "pi-globe" },
  { name: "share-to-feed.friends", code: "FRIENDS", icon: "pi-users" },
]

export default function ShareToFeed(props: ShareToFeedProps) {
  const menuRight = useRef<Menu>(null)
  const { t } = useTranslation()
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const fullName = authStore?.value?.user?.full_name
  // const avatar = authStore?.value?.user?.avatar_thumbnail_url
  const {
    isShow,
    handleClose,
    id,
    proDetail,
    restaurantName,
    descriptionRestaurant,
    linkType,
    objectName,
    avatar,
    background,
  } = props
  // const { t } = useTranslation()
  const [input, setInput] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [limitDescription, setLimitDescription] = useState<number>(500)
  const [selectedPrivacy, setSelectedPrivacy] = useState<Country | null>(privacies[1])

  const handleSubmit = async () => {
    const dto = {
      content: input,
      post_type: "USER",
      post_privacy: selectedPrivacy?.code,
      friend_tags: [],
      share_link: location?.href,
      share_link_type: linkType,
      dag_object_name: objectName,
      dag_object_id: id,
    }

    await shareToFeed(dto)
      .then(() => {
        showToast({
          detail: capitalizedFirst(handleTranslate("share-to-feed.message-share-success")),
          severity: "success",
        })
      })
      .catch(() =>
        showToast({
          detail: capitalizedFirst(handleTranslate("share-to-feed.message-share-failed")),
          severity: "error",
        })
      )
    handleCloseDialog()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEmoji = (e: any) => {
    const sym = e.unified.split("-")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _codesArray: any[] = []
    sym.forEach((el: string) => _codesArray.push("0x" + el))
    const emoji = String.fromCodePoint(..._codesArray)
    setInput(input + emoji)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedPrivacyTemplate = (option: Country, props: any) => {
    if (option) {
      return (
        <div className="flex items-center gap-1 text-14 font-medium text-gray-600">
          <span className={`pi ${option.icon}`}></span>
          <div>{capitalizedFirst(handleTranslate(option.name))}</div>
          <span className="pi pi-angle-down flex items-center"></span>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const privacyOptionTemplate = (option: Country) => {
    return (
      <div className="flex items-center gap-1 text-14 font-medium">
        <span className={`pi ${option.icon} hover:text-blue-500`}></span>
        <div className="font-medium leading-5 text-gray-600 hover:text-blue-500">
          {capitalizedFirst(handleTranslate(option.name))}
        </div>
      </div>
    )
  }

  const items: MenuItem[] = [
    {
      template: () => {
        return (
          <div className="absolute right-0 top-[95%] z-[9999] h-[100px]">
            <Picker
              id="emoji-picket"
              data={data}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onEmojiSelect={(event: any) => handleEmoji(event)}
              theme={"light"}
            />
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    const newDescription = proDetail?.description ? proDetail?.description : descriptionRestaurant
    if (newDescription) {
      if (newDescription?.length >= limitDescription) {
        setDescription(`${newDescription?.slice(0, limitDescription)}...`)
      } else {
        setDescription(newDescription)
      }
    }
  }, [limitDescription])

  const handleCloseDialog = () => {
    handleClose()
    setLimitDescription(500)
    setInput("")
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  return (
    <Dialog
      visible={isShow}
      showHeader={false}
      onHide={() => handleCloseDialog()}
      draggable={false}
      resizable={false}
      id="share-to-fit-dialog"
    >
      <div className="flex min-h-fit w-full min-w-[unset] flex-col rounded-3 md:min-w-[650px] md:max-w-[708px]">
        {/* header */}
        <div className="flex w-full flex-col items-start bg-gradient-to-b from-blue-light-200 to-white p-3">
          <div className="flex h-[60px] w-full items-start justify-between">
            <p className="text-18 font-semibold leading-28 text-gray-900">
              {capitalizedFirst(handleTranslate("share-to-feed.create-post"))}
            </p>
            <span
              onClick={() => handleCloseDialog()}
              className="pi pi-times cursor-pointer text-16 text-gray-500 hover:text-gray-700"
            ></span>
          </div>
          {/* input description */}
          <div className="relative flex w-full flex-col">
            <div className="absolute left-1 top-1 h-[35px] w-[35px]">
              <ImgCustom
                url={authStore?.value?.user?.avatar_thumbnail_url as string}
                alt="header"
                className="opacity-1 h-full w-full rounded-full object-cover"
              />
            </div>
            <InputTextarea
              autoResize
              value={input}
              onChange={(e) => setInput(e.target.value)}
              rows={2}
              cols={30}
              placeholder={`Hi ${fullName}, share your feeling!`}
              className="w-full border border-gray-200 pl-[50px]"
            />

            <div
              onClick={(event) => menuRight?.current?.toggle(event)}
              aria-haspopup
              className={
                "absolute bottom-0 right-0 flex w-fit justify-end stroke-blue-600 p-2 hover:stroke-blue-300"
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.66666 11.6666C6.66666 11.6666 7.91666 13.3333 10 13.3333C12.0833 13.3333 13.3333 11.6666 13.3333 11.6666M12.5 7.49996H12.5083M7.5 7.49996H7.50833M18.3333 9.99996C18.3333 14.6023 14.6024 18.3333 10 18.3333C5.39762 18.3333 1.66666 14.6023 1.66666 9.99996C1.66666 5.39759 5.39762 1.66663 10 1.66663C14.6024 1.66663 18.3333 5.39759 18.3333 9.99996ZM12.9167 7.49996C12.9167 7.73008 12.7301 7.91663 12.5 7.91663C12.2699 7.91663 12.0833 7.73008 12.0833 7.49996C12.0833 7.26984 12.2699 7.08329 12.5 7.08329C12.7301 7.08329 12.9167 7.26984 12.9167 7.49996ZM7.91666 7.49996C7.91666 7.73008 7.73012 7.91663 7.5 7.91663C7.26988 7.91663 7.08333 7.73008 7.08333 7.49996C7.08333 7.26984 7.26988 7.08329 7.5 7.08329C7.73012 7.08329 7.91666 7.26984 7.91666 7.49996Z" />
              </svg>
              <Menu model={items} popup ref={menuRight} id="popup_menu_right" />
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="flex min-h-[150px] w-full p-3">
          <div className="flex h-full w-full flex-col justify-center gap-1 rounded-3 border border-gray-200 pt-3">
            <div className="flex w-full items-center gap-1 px-3 text-16 font-semibold">
              <div className="h-[44px] w-[44px]">
                <ImgCustom
                  url={
                    proDetail?.attachments?.[0]?.id
                      ? (proDetail?.attachments?.[0]?.id as string)
                      : (avatar as string)
                  }
                  alt="header"
                  className="opacity-1 h-full w-[44px] rounded-full object-cover"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-16 font-semibold leading-6 text-gray-800">
                  {proDetail?.name || restaurantName}
                </p>
                <p className="text-14 font-normal text-gray-400">
                  {restaurantName ? "Shop" : "Product"}
                </p>
              </div>
            </div>
            {/* description */}
            {description ? (
              <div className="flex h-fit max-h-[500px] w-full flex-col gap-1 px-3 pt-1">
                <div
                  className="h-full w-full text-14 font-normal leading-5 text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></div>
                {description?.length > limitDescription && limitDescription < 1500 ? (
                  <div
                    onClick={() => {
                      setLimitDescription(1500)
                    }}
                    className="cursor-pointer text-14 font-semibold leading-5 text-gray-600 hover:text-gray-700"
                  >
                    {capitalizedFirst(handleTranslate("global.view-more"))}
                  </div>
                ) : (
                  <div>
                    <div
                      onClick={() => {
                        setLimitDescription(500)
                      }}
                      className="cursor-pointer text-14 font-semibold leading-5 text-gray-600 hover:text-gray-700"
                    >
                      {capitalizedFirst(handleTranslate("global.view-less"))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div className="relative flex h-[150px] w-full items-center justify-between">
              <ImgCustom
                url={
                  proDetail?.attachments?.[0]?.id
                    ? (proDetail?.attachments?.[0]?.id as string)
                    : (background as string)
                }
                alt="header"
                className="max-h-[150px] w-full rounded-3 object-cover object-center"
              />
              <div className="absolute top-0 h-full w-full bg-gradient-to-t from-orange-dark-25 to-transparent"></div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="flex w-full items-center justify-end gap-1 p-3">
          <div className="flex items-center">
            <Dropdown
              value={selectedPrivacy}
              onChange={(e: DropdownChangeEvent) => setSelectedPrivacy(e.value)}
              options={privacies}
              optionLabel="name"
              valueTemplate={selectedPrivacyTemplate}
              itemTemplate={privacyOptionTemplate}
              className="flex h-[40px] items-center rounded-3 border-none font-medium leading-5 text-gray-600"
            ></Dropdown>
          </div>
          <button
            onClick={handleSubmit}
            className="h-[40px] rounded-3 bg-blue-600 px-3 py-10px text-14 font-semibold leading-5 text-white"
          >
            {capitalizedFirst(handleTranslate("share-to-feed.post"))}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
