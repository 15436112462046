export interface ProductType {
  productPrice: number
  productImg: string
  productName: string
  quantity: number
  variation: string
  index?: number
  id: string
  sku: string
}

export interface BillingDetailType {
  subtotal: number
  total: number
  delivery: number
  discount: number
  deliveryDiscount: number
  tax: number
}

export const STATE_VIEW = {
  INIT: "INIT",
  COMPLETE: "COMPLETE",
  FAIL: "FAIL",
  NOT_FOUND: "NOT_FOUND",
}

export interface Payment {
  payment_method: { code: string; name: string }
  vouchers: { id: string; name: string }[]
}

export interface OrderPayment {
    /**
     *
     * @type {Date}
     * @memberof OrderPayment
     */
    created_at?: Date;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    order_total?: number;
    /**
     *
     * @type {PaymentMethod}
     * @memberof OrderPayment
     */
    payment_method?: PaymentMethod;
    /**
     *
     * @type {Array<Voucher>}
     * @memberof OrderPayment
     */
    vouchers?: Array<Voucher>;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    discounts?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    payment_total?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    shipment_fee?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    shipment_discount?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    shipment_actual_fee?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    soctrip_promotion?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    tax?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    shop_promotion?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    shop_discounts?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    soctrip_discounts?: number;
    /**
     *
     * @type {string}
     * @memberof OrderPayment
     */
    invoice_url?: string;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    commission_fee?: number;
    /**
     *
     * @type {string}
     * @memberof OrderPayment
     */
    origin_currency?: string;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    origin_rate?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    shop_flash_deal?: number;
    /**
     *
     * @type {number}
     * @memberof OrderPayment
     */
    soctrip_flash_deal?: number;
    code?: string;
    /**
     *
     * @type {boolean}
     * @memberof OrderPayment
     */
    is_paid?: boolean;
}

export interface PaymentMethod {
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  code?: string;
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  bank_code?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  gateway?: PaymentMethodGatewayEnum;
}
/**
  * @export
  * @enum {string}
  */
export declare enum PaymentMethodGatewayEnum {
  COD = "COD",
  STRIPE = "STRIPE",
  APPOTA = "APP_OTA",
  DIRECTPURCHASE = "DIRECT_PURCHASE"
}

export interface Voucher {
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  voucher_code?: string;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  discount_type?: VoucherDiscountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof Voucher
   */
  icon?: string;
}
/**
  * @export
  * @enum {string}
  */
export declare enum VoucherDiscountTypeEnum {
  PRICE = "PRICE",
  PERCENTAGE = "PERCENTAGE",
  COIN = "COIN"
}
