export const SearchAttribute = [
  {
    attributeName: "Region",
    id: "1",
    data: [
      { id: "reg1", name: "Hồ Chí Minh" },
      { id: "reg2", name: "Hà Nội" },
      { id: "reg3", name: "Đà Nẵng" },
      { id: "reg4", name: "Hải Phòng" },
      { id: "reg5", name: "Bình Dương" },
      { id: "reg6", name: "Long An" },
      { id: "reg7", name: "Cần Thơ" },
      { id: "reg8", name: "Bến Tre" },
      { id: "reg9", name: "An Giang" },
      { id: "reg10", name: "Cà Mau" },
    ],
  },
  {
    attributeName: "Brand",
    id: "2",
    data: [
      { id: "br1", name: "brand1" },
      { id: "br2", name: "brand2" },
      { id: "br3", name: "brand3" },
      { id: "br4", name: "brand4" },
      { id: "br5", name: "brand5" },
      { id: "br6", name: "brand6" },
      { id: "br7", name: "brand7" },
      { id: "br8", name: "brand8" },
      { id: "br9", name: "brand9" },
      { id: "br10", name: "brand10" },
    ],
  },
]

export const filterRating = [
  { id: "star5", starLabel: "5 Stars", startValue: 5 },
  { id: "star4", starLabel: "4 Stars & Up", startValue: 4 },
  { id: "star3", starLabel: "3 Stars & Up", startValue: 3 },
  { id: "star2", starLabel: "2 Stars & Up", startValue: 2 },
  { id: "star1", starLabel: "1 Star & Up", startValue: 1 },
]

export const dataShoppingFilterRegion = [
  { id: "reg1", regionName: "Hồ Chí Minh" },
  { id: "reg2", regionName: "Hà Nội" },
  { id: "reg3", regionName: "Đà Nẵng" },
  { id: "reg4", regionName: "Hải Phòng" },
  { id: "reg5", regionName: "Bình Dương" },
  { id: "reg6", regionName: "Long An" },
  { id: "reg7", regionName: "Cần Thơ" },
  { id: "reg8", regionName: "Bến Tre" },
  { id: "reg9", regionName: "An Giang" },
  { id: "reg10", regionName: "Cà Mau" },
]
