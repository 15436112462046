import React from "react"
import { useTranslation } from "react-i18next"
import cartEmptyLogo from "src/assets/images/cart-empty-logo.svg"
import { useNavigate } from "react-router-dom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
export default function EmptyPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goToHome = () => {
    navigate("/shopping")
  }
  return (
    <div className="flex flex-col items-center justify-between rounded-3 border border-gray-200 bg-white p-3 py-[80px]">
      <img
        className="h-[172px] w-[172px]"
        src={cartEmptyLogo}
        alt="cartEmptyLogo"
      />
      <span className="mt-3 text-[18px] font-semibold leading-[28px] text-gray-900">
        {capitalizedFirst(t("shoppingPage.cart_empty_title"))}
      </span>
      <span className="mt-1 text-[14px] leading-[20px] text-gray-700">
        {capitalizedFirst(t("shoppingPage.cart_empty_info"))}
      </span>
      <button
        onClick={goToHome}
        className="mt-3 rounded-[8px] bg-blue-600 px-20px py-2 text-[14px] leading-[20px] text-white hover:bg-blue-500"
      >
        {capitalizedFirst(t("shoppingPage.cart_continue_btn"))}
      </button>
    </div>
  )
}
