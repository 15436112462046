import React from "react"
import imageNotFound from "../../assets/images/page_not_found.png"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div className="flex min-h-[800px] w-full items-center justify-center">
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="flex h-[554px] w-[792px] flex-col gap-5">
          {/* images */}
          <div className="flex w-full justify-center">
            <div className="h-[318px] w-[356px]">
              <img src={imageNotFound} alt="image" className="h-full w-full" />
            </div>
          </div>
          {/* Info */}
          <div className="flex w-full flex-col items-center gap-3">
            <h2 className="text-[30px] font-semibold leading-[38px] text-gray-900">
              {capitalizedFirst(t("global.page-not-found"))}
            </h2>
            <h6 className="text-14 font-normal leading-5 text-gray-900">
              {capitalizedFirst(t("global.page-not-found-message"))}
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
