export interface Address {
  address: string
  address_type: string
  city: string
  cityCode: null | string
  city_id: null | string
  country: string
  countryCode: null | string
  country_id: null | string
  district: string
  districtCode: null | string
  district_id: null | string
  id: string
  is_default: boolean
  updated_by: string
  user_full_name: string
  user_id: string
  user_phone_number: string
  ward: string
  wardCode: null | string
  ward_id: null | string
}

export enum TypeAddress{
  HOME = "HOME",
  OFFICE="OFFICE"
}

export interface AddressListProps {
  onDataChange: () => void
}