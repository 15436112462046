import React, { useEffect, useState } from "react"
import CustomCard from "../card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { MostPopularProps } from "../home-page-most-popular/type"
import { TrendingCatalogProps } from "./type"
import { TrendingControllerApi } from "@soctrip/axios-trending-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import SkeletonLoading from "../skeleton-loading"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { AdvertisingControllerApi } from "@soctrip/axios-advertising-service"

export default function HomePageBestSellingCategory({
  bloomFilter,
  handleReload,
}: MostPopularProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [trendingCatalogs, setTrendingCatalog] = useState<
    TrendingCatalogProps[]
  >([])

  useEffect(() => {
    getTrendingCatalog()
  }, [])

  const getTrendingCatalog = async () => {
    const localRegion = localStorage.getItem("localRegion") || ""
    await new TrendingControllerApi(configHeader(SERVICE.TRENDING))
      .getCatalogTrending(3, 3, {
        headers: {
          region: localRegion,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setTrendingCatalog(response?.data?.data)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const handleClickSponsor = async (id: string, isSponsor: boolean) => {
    if (isSponsor) {
      const dto = [{ id: id, total_of_click: 1, total_of_view: 1 }]
      await new AdvertisingControllerApi(configHeader(SERVICE.ADVERTISING))
        .updateCLick(dto)
        .then(() => {
          console.log("Click successfully")
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="w-full">
          <SkeletonLoading limit={5} />
        </div>
      ) : (
        <>
          {trendingCatalogs?.length ? (
            <div className="mt-1 flex flex-col">
              <div className="flex w-full flex-col">
                <div className="text-18 font-semibold leading-8 md:text-24">
                  {capitalizedFirst(t("shoppingPage.bestselling-category"))}
                </div>
                <div className="truncate text-14 font-medium leading-7 text-gray-500 md:text-16">
                  {capitalizedFirst(
                    t("shoppingPage.bestselling-category-message")
                  )}
                </div>
                <div className="mt-2 flex h-full w-full flex-col gap-3 md:flex-row">
                  {trendingCatalogs?.map((catalog, index) => (
                    <div
                      key={index}
                      className="h-full w-full rounded-3 bg-gradient-to-b from-orange-dark-100 via-[_#FFFFFF_23%] to-white p-2 shadow-2xl shadow-blue-light-50 md:p-3"
                    >
                      <div className="flex w-full items-center justify-start pb-2 text-16 font-semibold text-gray-700 md:text-18">
                        {catalog?.name}
                      </div>
                      <div className="flex w-full flex-col gap-3">
                        {catalog?.products?.slice(0, 3)?.map((pro, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleClickSponsor(
                                pro?.id as string,
                                pro?.is_sponsored as boolean
                              )
                            }
                          >
                            <CustomCard
                              typeCard={CardCustomType.productReComment}
                              id={pro?.id}
                              imgUrl={pro.avatar?.id}
                              avatar={pro.avatar}
                              name={pro.name}
                              point={pro.point}
                              price={pro.price}
                              price_after_tax={pro.price_after_tax}
                              original_price={pro.original_price}
                              original_price_after_tax={pro.original_price_after_tax}
                              sum_review={pro.sum_review}
                              quantity={pro.quantity}
                              productAttr={pro.product_attributes}
                              shop_name={pro.shop?.name}
                              sub_label={pro.sub_label}
                              shop={pro.shop}
                              is_bookmark={pro.is_bookmark}
                              handleReload={handleReload}
                              bloom_filter={bloomFilter}
                              voucher={pro?.voucher}
                              sold={pro?.sold}
                              is_flash_deal={pro?.is_flash_deal}
                              flash_deal_status={pro?.flash_deal_status}
                              flash_deals={pro?.flash_deals}
                              flash_deals_time={pro?.flash_deals_time}
                              shop_id={pro?.shop_id}
                              vouchers={pro?.vouchers}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  )
}
