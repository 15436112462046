import React, { useEffect, useMemo, useState } from "react"
import { RegisterFormType } from "./props"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { InputText } from "primereact/inputtext"
import { useFormik } from "formik"
import * as Yup from "yup"
import "./index.scss"
import { createAccount } from "src/services/user-service"
import { getLogoApp } from "src/utils/app-logo"

const RegisterForm = (props: RegisterFormType) => {
  const { updateStateView } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<string>("")
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])
  const dataForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required!").email("Please enter the email"),
    }),
    onSubmit: () => {
      dataForm.resetForm()
    },
    validateOnChange: true,
    validateOnMount: true,
  })
  const isInValidField = () => {
    return !!(dataForm?.touched.email && dataForm?.errors.email)
  }
  const getLoginUrl = useMemo(() => {
    return `${process.env.REACT_APP_HOME_DOMAIN_URL}/login?r=${window.location.href}`
  }, [])
  const handleSignUp = () => {
    setIsLoading(true)
    createAccount(dataForm.values.email)
      .then((res) => {
        if (res?.data?.data?.user) {
          updateStateView()
        } else {
          setMessageError(res?.data?.error?.message || "")
          setIsLoading(false)
        }
      })
      .catch(() => setIsLoading(false))
  }
  useEffect(() => {
    if (messageError) setMessageError("")
  }, [dataForm.values])
  return (
    <div className="flex w-full flex-col p-4">
      <div className="flex items-center justify-center gap-1">
        <img className="h-[30px] w-[30px]" alt="appLogo" src={getLogoApp()} />
        <span className="items-center text-24 font-bold text-blue-600 md:flex">
          {capitalizedFirst(t("global.app-name", { getAppTitle: getAppTitle }))}
        </span>
      </div>
      <div className="mt-3 flex flex-col">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {capitalizedFirst(t("global.email"))}{" "}
          <span className="text-red-500">*</span>
        </span>
        <InputText
          className={`mt-6px rounded-3 border border-gray-300 py-10px px-14px text-16 font-normal leading-24 text-gray-900  ${
            isInValidField() ? "p-invalid" : ""
          } `}
          value={dataForm?.values.email}
          placeholder={capitalizedFirst(t("global.email"))}
          onChange={(e) => {
            if (!dataForm?.touched?.email)
              dataForm.setTouched({
                ...dataForm.touched,
                ...{ email: true },
              })
            dataForm.setFieldValue("email", e?.target?.value)
          }}
        />
        {(dataForm.errors.email && dataForm.touched.email) || messageError ? (
          <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
            {messageError ? messageError : dataForm.errors.email}
          </span>
        ) : null}
      </div>
      <div className="mt-3 flex w-full items-center justify-center">
        <button
          disabled={!dataForm.values.email || isLoading || isInValidField()}
          onClick={handleSignUp}
          className={`
          ${isInValidField() ? "opacity-50" : ""} 
          ${isLoading ? "animate-pulse duration-75" : ""} 
          w-full rounded-3 bg-blue-600 px-3 py-1 text-[16px] font-semibold leading-[24px] text-white hover:bg-blue-500`}
        >
          {isLoading
            ? capitalizedFirst(t("global.loading"))
            : capitalizedFirst(t("modal.sign-up-modal"))}
        </button>
      </div>
      <div className="mt-3 flex items-center justify-center gap-1 text-[16px] leading-[20px]">
        <span className="text-gray-600">
          {capitalizedFirst(t("modal.have-account"))}
        </span>
        <a href={getLoginUrl} className="text-blue-700 hover:underline">
          {capitalizedFirst(t("modal.sign-in-modal"))}
        </a>
      </div>
    </div>
  )
}
export default RegisterForm
