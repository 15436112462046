import React, { useEffect, useState } from "react"
import { translateCapitalize } from "src/utils/translate-with-capitalize"
import SkeletonLoading from "../skeleton-loading"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { getBookmarkList } from "src/services/bookmark-service"
import EmptyPage from "src/pages/empty-page"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import CustomCard from "../card-custom/card-custom/custom-card"
import { CustomCardProps } from "../card-custom/card-custom/custom-card-props"

export default function BookmarkList() {
  const [products, setProducts] = useState<CustomCardProps[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalProduct, setTotalProduct] = useState<number>(0)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [emptyMessage, setEmptyMessage] = useState<string>(
    "Network Error. Please refresh page try again."
  )
  const authStore = useSelector((state: RootState) => state.auth)

  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  useEffect(() => {
    if (authStore.value) {
      getBookmark()
    }
  }, [authStore])

  const getBookmark = async () => {
    const filterString = encodeURIComponent(
      `user_id==${authStore?.value?.user?.id}, object_type==PRODUCT`
    )
    setLoading(true)
    await getBookmarkList(filterString, 0, 12)
      .then((response) => {
        setProducts(response?.data?.data?.data)
        setLoading(false)
        setTotalProduct(response?.data?.data?.totalElement)
        if (products?.length === 0) {
          setEmptyMessage("Data not found.")
        }
      })
      .catch(() => {
        setLoading(false)
        setEmptyMessage("Network Error. Please refresh page try again.")
      })
  }

  const handleMoreProduct = async () => {
    const filterString = encodeURIComponent(
      `user_id==${authStore?.value?.user?.id}, object_type==PRODUCT`
    )
    const currentPage = page + 1
    setPage(currentPage)
    setLoadMore(true)
    try {
      const response = await getBookmarkList(filterString, currentPage, 12)
      setProducts([...products, ...response.data.data.data])
      setLoadMore(false)
    } catch (error) {
      setLoadMore(false)
    }
  }

  return (
    <div className="h-full w-full rounded-3 bg-white px-2">
      <div className="flex h-full w-full flex-col">
        {/* header */}
        <div className="flex h-[68px] w-full items-center border-b border-gray-200 px-3">
          <p className="text-18 font-semibold leading-7 text-gray-800">
            {translateCapitalize("purchase-history.bookmark-list")}
          </p>
        </div>
        {/* content */}
        <div className="mt-3 h-full w-full px-3">
          {loading ? (
            <div className="w-full">
              <SkeletonLoading limit={5} />
            </div>
          ) : (
            <div className="mt-1 w-full pb-4">
              {products?.length === 0 ? (
                <div className="flex h-[600px] w-full items-center justify-center">
                  <EmptyPage message={emptyMessage} />
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                    {products?.map((pro: CustomCardProps, index: number) => (
                      <div key={index}>
                        <CustomCard
                          key={pro.id}
                          id={pro.id}
                          typeCard={CardCustomType.product}
                          imgUrl={pro.avatar?.id}
                          avatar={pro.avatar}
                          name={pro.name}
                          point={pro.point}
                          price={pro.price}
                          price_after_tax={pro.price_after_tax}
                          original_price={pro.original_price}
                          original_price_after_tax={pro.original_price_after_tax}
                          sum_review={pro.sum_review}
                          quantity={pro.quantity}
                          voucherOfProducts={[pro.voucher as string]}
                          productId={pro.id}
                          shop_name={pro.shop?.name}
                          sub_label={pro.sub_label}
                          shop={pro.shop}
                          is_bookmark={pro.is_bookmark}
                          sold={pro?.sold}
                          is_flash_deal={pro?.is_flash_deal}
                          flash_deal_status={pro?.flash_deal_status}
                          flash_deals={pro?.flash_deals}
                          flash_deals_time={pro?.flash_deals_time}
                          shop_id={pro?.shop_id}
                        />
                      </div>
                    ))}
                  </div>
                  {(products?.length as number) < totalProduct && (
                    <p
                      onClick={handleMoreProduct}
                      className="w-fit cursor-pointer py-4 text-16 font-semibold text-blue-700"
                    >
                      {loadMore ? (
                        <i className="pi pi-spin pi-spinner text-20"></i>
                      ) : (
                        capitalizedFirst(
                          handleTranslate("shoppingPage.view-more")
                        )
                      )}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
