import React, { useEffect, useState } from "react"
import ApproveShopInformation from "../approve-shop-information"
import ApproveShopUserInfo from "../approve-shop-user-info"
// import ActivitiesLog from "../approve-activities-log"
import { useParams } from "react-router-dom"
import { ShopControllerApi } from "@soctrip/axios-shop-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { ShopDataProps } from "./type"
import EmptyDataFound from "./empty-data"
import ApproveRequestShopLoading from "./loading"
import ApproveVerifyInformation from "../approve-verify-information"

export default function ApproveInfoView() {
  const { id } = useParams()
  const [data, setData] = useState<ShopDataProps>()
  const [loading, setLoading] = useState<boolean>(true)
 
  useEffect(() => {
    if (id) {
      handleGetShopInfo(id)
    }
  }, [id])

  const handleGetShopInfo = async (id: string) => {
    setLoading(true)
    await new ShopControllerApi(configHeader(SERVICE.SHOP))
      .shopsIdInfoGet(id)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        setData(data?.data?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }


  return (
    <div className="h-full w-full">
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <ApproveRequestShopLoading />
        </div>
      ) : (
        <div className="h-full w-full">
          {!data ? (
            <div className="flex h-full w-full items-center justify-center bg-white">
              <EmptyDataFound title="No data found" />
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <div className="w-full text-18 font-semibold text-gray-900">
                {data.country?.name} ({data.country?.code.toUpperCase()}) - Shop
              </div>
              <div className="flex h-full w-full flex-col gap-3 rounded-3">
                <div className="flex w-full flex-col rounded-3 border border-gray-100">
                  <div className="flex w-full cursor-pointer items-center justify-between rounded-t-3 bg-gray-100 px-3 py-2">
                    <div className="flex flex-col gap-6px">
                      <div className="flex w-full items-center gap-1">
                        <div className="text-18 font-semibold leading-7 text-gray-900">
                          Information
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex h-fit w-full flex-col gap-1 rounded-b-3 bg-white">
                    <div className="flex w-full flex-col gap-3">
                      <ApproveShopInformation {...data} />
                      <ApproveShopUserInfo {...data} />
                      <ApproveVerifyInformation {...data} />
                    </div>
                  </div>
                </div>
                {/* Activities log */}
                {/* <div className="w-full">
                  <ActivitiesLog />
                </div> */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
