import React from "react"
import { Dialog } from "primereact/dialog"
import { ConfirmModalProps } from "./props"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function LogoutModal(props: ConfirmModalProps) {
  const { title, message, isShow, handleClose, handleConfirm } = props
  const { t } = useTranslation()
  const footerContent = (
    <div
      className="flex items-center gap-3 text-[16px] font-semibold"
      id="dialogFooter"
    >
      <button
        onClick={() => handleClose()}
        className="flex-1 rounded-3 border border-blue-700 bg-gray-100 px-[18px] py-[10px] text-blue-700 hover:bg-gray-200"
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        onClick={() => handleConfirm()}
        className="flex-1 rounded-3 border border-blue-600 bg-blue-600 px-[18px] py-[10px] text-white"
        autoFocus
      >
        {capitalizedFirst(t("header-setting.sign-out"))}
      </button>
    </div>
  )
  return (
    <Dialog
      header={title}
      visible={isShow}
      style={{ width: "400px" }}
      onHide={() => handleClose()}
      footer={footerContent}
      draggable={false}
    >
      <div className="flex items-center justify-center">
        <span>{message}</span>
      </div>
    </Dialog>
  )
}
