/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paginator } from "primereact/paginator"
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { RatingControllerApi } from "@soctrip/axios-rating-service"
import RantingsAndReviewsCustom from "src/components/ratings-and-reviews-custom/ratings-and-reviews-custom"
import {
  CommentListType,
  MediaType,
} from "../../../pages/shopping/product-detail/props"
import { configHeader } from "src/config/interceptors"
import { CommentViewType } from "./types"
import { SERVICE } from "src/constants/common"
import CommentLoading from "./loading"
import EmptyComment from "./empty"

const CommentView = (props: CommentViewType) => {
  const { starFilter, noteFilter } = props
  const { id } = useParams()
  const [pageSize, setPageSize] = useState<number>(5)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [first, setFirst] = useState<number>(0)
  const [rows, setRows] = useState<number>(5)
  const [commentData, setCommentData] = useState<CommentListType[]>([])
  const [totalComment, setTotalComment] = useState()
  const [loading, setLoading] = useState<boolean>(true)
  const commentRef = useRef<HTMLDivElement>(null)

  const handlePageChange = (e: any) => {
    setPageSize(e?.rows as number)
    setPageIndex(e?.page as number)
    setFirst(e.first)
    setRows(e.rows)
    props?.scrollInToView()
  }

  const getListMedia = (medias: any) => {
    const result: MediaType[] = []
    if (medias && medias?.length) {
      medias.forEach((i: any) => {
        result.push({
          id: i?.id as string,
          isImage: i?.type.includes("image"),
          type: i?.type,
        })
      })
    }
    return result
  }

  const fetchRatingOfProduct = async () => {
    setLoading(true)
    try {
      let filter = ""
      if (starFilter && noteFilter) {
        filter = `rating==${starFilter},notes.id==${noteFilter}`
      } else if (starFilter && !noteFilter) {
        filter = `rating==${starFilter}`
      } else if (!starFilter && noteFilter) {
        filter = `notes.id==${noteFilter}`
      }

      const res = await new RatingControllerApi(
        configHeader(SERVICE.RATING)
      ).ratingsObjectIdGet(id as string, pageIndex, pageSize, filter)
      const data = res?.data?.data
      setLoading(false)
      if (data) {
        setTotalComment(data?.totalElement)
        const result: CommentListType[] = []
        data?.data?.forEach((rating: any) => {
          const variants = rating.factors
            ?.filter((factor: any) => factor.type !== "ORDER")
            ?.map((factor: any) => ({
              key: factor?.type,
              value: factor?.value,
            }))
          result.push({
            fullName: rating?.created_by?.full_name,
            imgUrl: rating?.created_by?.avatar_thumbnail_url,
            ownerId: rating?.created_by?.id,
            variants,
            comment: rating?.content,
            commentDate: rating?.updated_at,
            listImage: getListMedia(rating?.medias),
            valueRating: rating?.rating as number,
            object_type: rating?.object_type,
            object_id: rating?.object_id,
            id: rating?.id,
            username: rating?.created_by?.username,
            city_code: rating?.created_by?.city_code,
          })
        })
        setCommentData(result)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) fetchRatingOfProduct()
  }, [id, pageIndex, pageSize, starFilter, noteFilter])

  useEffect(() => {
    setPageIndex(0)
    setFirst(0)
  }, [starFilter, noteFilter])

  return (
    <>
      {loading ? (
        <div>
          <CommentLoading />
        </div>
      ) : (
        <>
          {!commentData?.length ? (
            <div>
              <EmptyComment />
            </div>
          ) : (
            <div>
              <div
                ref={commentRef}
                className="border-b border-solid border-gray-100 px-1"
              >
                {commentData.map((review, index) => (
                  <RantingsAndReviewsCustom
                    key={index}
                    imgUrl={review.imgUrl}
                    fullName={review.fullName}
                    labelRating={review.labelRating}
                    variants={review.variants}
                    comment={review.comment}
                    commentDate={review.commentDate}
                    listImage={review?.listImage || []}
                    valueRating={review.valueRating}
                    object_id={review?.object_id}
                    object_type={review?.object_type}
                    id={review.id}
                    ownerId={review?.ownerId}
                    username={review?.username}
                    city_code={review?.city_code}
                  ></RantingsAndReviewsCustom>
                ))}
              </div>
              {(totalComment as unknown as number) > pageSize ? (
                <div className="float-right">
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalComment}
                    onPageChange={handlePageChange}
                  />
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
    </>
  )
}
export default CommentView
