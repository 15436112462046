import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { ProgressBar } from "primereact/progressbar"
import { useTranslation } from "react-i18next"
import { RootState } from "src/app/store"
import flashSaleBestIcon from "src/assets/images/flash-sale-best-icon.svg"
import ImgCustom from "src/components/img-custom"
import { BOOKMARK_CATEGORY, TYPELINK } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { bookmarkProduct, deleteBookmark } from "src/services/bookmark-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { convertViToEn, handleCheckBloomFilter } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"
import "../index.scss"
import { FlashSaleProductProps } from "../type"
export default function FlashSaleCardView({
  avatar,
  name,
  id,
  price_after_tax,
  original_price_after_tax,
  sub_label,
  sold,
  handleReload,
  bloom_filter,
  quantity,
  flash_deals,
  isShowName,
  max_sold,
}: FlashSaleProductProps) {
  const { t, i18n } = useTranslation()
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)
  const [value, setValue] = useState<number>(0)
  const [stringProcess, setStringProcess] = useState<string>("")
  const [play, setPlay] = useState<number>(0)
  const [autoPlay, setAutoPlay] = useState<boolean>(false)
  const [isClickBookMark, setIsClickBookMark] = useState<boolean>(false)

  const handleBookmark = (id?: string) => {
    if(isClickBookMark) return
    setIsClickBookMark(true)
    if (authStore?.value && id) {
      if (bloom_filter) {
        if (!handleCheckBloomFilter(bloom_filter as string, id as string)) {
          bookmarkProduct(id, BOOKMARK_CATEGORY.PRODUCT)
            .then(() => {
              if (handleReload) {
                handleReload()
              }
            })
            .catch((error) => {
              error
            }).finally(() => setIsClickBookMark(false))
        } else {
          deleteBookmark(id)
            .then(() => {
              if (handleReload) {
                handleReload()
              }
            })
            .catch((error) => {
              error
            }).finally(() => setIsClickBookMark(false))
        }
      } else {
        bookmarkProduct(id, BOOKMARK_CATEGORY.PRODUCT)
          .then(() => {
            if (handleReload) {
              handleReload()
            }
          })
          .catch((error) => {
            error
          }).finally(() => setIsClickBookMark(false))
      }
    } else {
      showToast({ detail: t("product-details.login-before-bookmark"), severity: "warn" })
      setIsClickBookMark(false)
    }
  }

  const isShowDisCount = (originalPrice: number, price: number) => {
    return Math.floor(100 - (price * 100) / originalPrice) > 1
  }

  const handleGetNewNameToNavigate = (id: string | undefined, name?: string) => {
    const productCode = id?.split("-")?.length ? id?.split("-")[0] : ""
    const link = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/${convertViToEn(name as string)}/${id}/${productCode}`
    return link
  }

  const handleGetDiscount = (originalPrice: number, price: number) => {
    return `- ${Math.floor(100 - (price * 100) / originalPrice + 0.5)}%`
  }

  const handleGetPercentageSold = () => {
    const percentage = Math.floor(
      ((sold as number) * 100) / (max_sold as number)
    ) as number
    setValue(percentage)
    if (percentage <= 10) {
      setStringProcess(`${capitalizedFirst(t("global.just-launched"))}`)
    } else if (percentage >= 90) {
      setStringProcess(`${capitalizedFirst(t("global.best-seller"))}`)
    } else {
      setStringProcess(
        `${capitalizedFirst(t("global.sold-items", { sold: sold }))}`
      )
    }
  }

  useEffect(() => {
    handleGetPercentageSold()
  }, [i18n?.language])

  const typeDataImage = () => {
    if (avatar?.type === TYPELINK.LINK) {
      if (avatar?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)) {
        return (
          <div className="h-[209px] w-full rounded-t-3">
            <iframe
              src={`${avatar.id}?autoplay=${play}&mute=1`}
              frameBorder="8px"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
              className="h-full w-full rounded-t-3"
            />
          </div>
        )
      } else
        return (
          <video
            src={avatar?.id}
            muted
            className="h-full w-full object-cover"
            autoPlay={autoPlay}
          ></video>
        )
    } else
      return (
        <ImgCustom
          className="max-h-[384px] w-full transform rounded-2 object-cover transition duration-[1500ms]
          hover:scale-110"
          url={avatar?.id as string}
          alt="images"
          type="WEB"
        />
      )
  }

  const handleMouseEnter = () => {
    setPlay(1)
    setAutoPlay(true)
  }
  const handleMouseLeave = () => {
    setPlay(0)
    setAutoPlay(false)
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="h-full w-full cursor-pointer rounded-3 border border-gray-200 bg-white shadow-sm"
    >
      <div className="relative">
        {avatar && (
          <div
            className="h-[175px] w-full rounded-t-3 md:h-[209px]"
          >
            <a href={handleGetNewNameToNavigate(id, name)}>{typeDataImage()}</a>
          </div>
        )}
        <div
          onClick={() => handleBookmark(id)}
          className={`absolute right-6px top-6px flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full bg-gray-100 fill-none stroke-gray-500 ${
            id &&
            bloom_filter &&
            handleCheckBloomFilter(bloom_filter as string, id as string)
              ? "fill-yellow-500 stroke-yellow-500"
              : "fill-none stroke-gray-500"
          }`}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
          </svg>
        </div>
        {original_price_after_tax &&
        original_price_after_tax !== 0 &&
        price_after_tax !== original_price_after_tax &&
        isShowDisCount(original_price_after_tax as number, price_after_tax as number) ? (
            <div className="custom-button-shadow absolute left-6px top-6px cursor-pointer rounded-1 bg-gray-200 px-6px py-[1px] text-12 font-medium leading-18 text-red-500">
              <p className="text-white">
                {handleGetDiscount(original_price_after_tax as number, price_after_tax as number)}
              </p>
            </div>
          ) : null}
        {sub_label && (
          <div className="absolute bottom-0 left-0 rounded-tr-2 bg-gradient-to-l from-orange-dark-400 to-orange-dark-500 px-6px py-2px text-10 font-medium leading-18 text-white">
            {sub_label}
          </div>
        )}
        {/* sold out */}
        {sold === max_sold && (
          <div className="absolute top-1/2 left-1/2 flex h-[96px] w-[96px] -translate-x-1/2 -translate-y-1/2 items-center justify-center">
            <div
              style={{ background: "rgba(16, 24, 40, 0.6)" }}
              className="flex h-full w-full items-center justify-center break-words rounded-full text-16 font-medium leading-6 text-white"
            >
              {capitalizedFirst(t("flash-deals.sold-out"))}
            </div>
          </div>
        )}
        {/* attributes */}
        <div className="absolute bottom-4px right-4px flex h-[132px] w-40px flex-col-reverse gap-4px">
          {flash_deals?.map((flash_deal) => (
            <div
              key={flash_deal?.id}
              className="flex h-30px w-full flex-col items-center justify-center bg-indigo-600"
            >
              <ImgCustom
                url={flash_deal?.filter_thumbnail}
                alt={flash_deal?.title}
                className="h-full w-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <a
        className="flex flex-col gap-2 px-2"
        href={handleGetNewNameToNavigate(id, name)}
      >
        {isShowName ? (
          <a
            id="product_title"
            className="flex h-[40px] max-w-[250px] cursor-pointer pt-4px text-12 font-medium leading-18 hover:text-blue-light-500  hover:underline md:text-14"
            href={handleGetNewNameToNavigate(id, name)}
          >
            {name}
          </a>
        ) : null}

        <div className="flex flex-col w-full justify-center gap-[2px] pt-2">
          <div className="flex items-center truncate">
            <p className="truncate text-16 font-semibold leading-8 text-orange-dark-500 sm:text-18 md:text-20">
              {convertCurrency(price_after_tax)}
            </p>
          </div>
          <div className="flex items-center h-[32px]">
            <p className="truncate text-12 font-normal leading-8 text-gray-400 line-through sm:text-14 md:text-16">
              {original_price_after_tax && original_price_after_tax !== price_after_tax ? convertCurrency(original_price_after_tax) : ""}
            </p> 
          </div>
        </div>
        <div className="flex w-full items-center pb-2">
          <div
            id="process-bar-flash"
            className="relative inline-flex w-full items-center"
          >
            {value >= 80 && (
              <div className="absolute bottom-[-23%] left-[-4%] z-10 flex items-center justify-center">
                <img src={flashSaleBestIcon} alt="" />
              </div>
            )}

            {quantity !== 0 && (
              <div className="relative w-full">
                <ProgressBar value={value} showValue={false}></ProgressBar>
                <div className="absolute top-1/2 left-1/2 flex h-[20px] w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center truncate">
                  <p className="flex h-full items-center justify-center pb-2px text-12 font-medium text-white md:text-14">
                    {stringProcess}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </a>
    </div>
  )
}
