import { GenericAbortSignal } from "axios"
import http from "src/config/interceptors"

export const handleGetMerchantData = (id: string | undefined) => {
  return http.get(`/shop/shops/${id}`)
}

export const handleGetAvatar = (id: string) => {
  return http.get(`/storage/files/web/${id}`)
}

export const handleGetBackground = (id: string) => {
  return http.get(`/storage/files/web/${id}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleCreateFollower = (dto: any) => {
  return http.post("/shop/shop-followers", dto)
}

export const getAllAttribute = (signal?: GenericAbortSignal) => {
  return http.get("/stock/product-attributes", { signal: signal })
}

export const GetCategoryFilterOption = (shopId: string) => {
  return http.get(
    `/catalog/shop-catalogs?pageNum=0&pageSize=1000&filters=${shopId}`
  )
}

export const GetCountryFilter = () => {
  return http.get("/shipment/address/countries")
}

export const GetProvinceFilter = (countryId: string) => {
  return http.get(`/shipment/address/provinces?countryId=${countryId}`)
}

export const GetProductList = (
  shopId?: string,
  pageNum?: number,
  limit?: number,
  textFilter?: string,
  sort?: string
) => {
  return http.get(
    `/stock/shops/${shopId}/search?pageNum=${pageNum}&pageSize=${limit}&filters=${textFilter}&sort=${sort}`
  )
}

export const getListProductByIdLists = (idString: string) => {
  return http.get(`/stock/products/list?${idString}`)
}
