import http from "src/config/interceptors"
import {AddressType } from "./props"

export const getListAddress = () => {
  const url = "/order/address"
  return http.get(url)
}

export const getAddressDefault = () => {
  const url = "/order/address/default"
  return http.get(url)
}

export const deleteAddress = (addressId: string) => {
  const url = `/order/address/${addressId}`
  return http.delete(url)
}

export const getAddressById = (addressId: string) => {
  const url = `/order/address/${addressId}`
  return http.get(url)
}


export const updateAddress = (addressData: AddressType) => {
  const url = `/order/address/${addressData.id}`
  const dataRes = {
    user_full_name: addressData.userFullName,
    user_phone_number: addressData.userPhoneNumber,
    address: addressData.address,
    country: addressData.country,
    city: addressData.city,
    district: addressData.district,
    ward: addressData.ward,
    address_type: addressData.addressType,
    is_default: addressData.isDefault,
  }
  return http.put(url, dataRes)
}

export const createNewAddress = (addressData: AddressType) => {
  const url = "/order/address"
  const dataRes = {
    user_full_name: addressData.userFullName,
    user_phone_number: addressData.userPhoneNumber,
    address: addressData.address,
    country: addressData.country,
    city: addressData.city,
    district: addressData.district,
    ward: addressData.ward,
    address_type: addressData.addressType,
    is_default: addressData.isDefault,
  }
  return http.post(url, dataRes)
}

export const getCountriesData = () => {
  const url = "/shipment/address/countries"
  return http.get(url)
}

export const getProvincesData = (countryId: string) => {
  const url = "shipment/address/provinces"
  return http.get(url, {params: {
    countryId: countryId,
  }})
}


export const getDistrictsData = (provinceId: string) => {
  const url = "/shipment/address/districts"
  return http.get(url, {params: {
    provinceId: provinceId,
  }})
}

export const getWardsData = (districtId: string) => {
  const url = "shipment/address/wards"
  return http.get(url, {params: {
    districtId: districtId,
  }})
}

