/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import ButtonCustom from "src/components/button-custom/button-custom/button-custom"
import { ButtonSeverity } from "src/constants/constants/button-severity"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import star from "../../../assets/images/star-icon.svg"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { convertViToEn, handleCheckBloomFilter } from "src/utils/common"
import { bookmarkProduct, deleteBookmark } from "src/services/bookmark-service"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { BOOKMARK_CATEGORY } from "src/constants/common"
import { useToast } from "src/hooks/use-toast"
import { CustomCardShopProps } from "./props"
import ShopIcon from "src/assets/images/shop-icon.png"
import reviewIcon from "../../../assets/images/review-icon.svg"
import EmptyPage from "src/pages/empty-page"
import { handleCountViewBoth } from "src/services/count-view-service"
import ImgCustom from "src/components/img-custom"
import { SocChatInstance, SocChatOpenProductType } from "src/utils/socchat-instance"

export default function ShopOnProductDetail(props: CustomCardShopProps) {
  const {
    typeCard,
    shopName,
    rating,
    shopId,
    location,
    followers,
    isShopOwner,
    bloomData,
    handleGetBloomData,
    badges,
    shopDetailDTO,
    id,
    name,
    price_after_tax,
    attachments,
  } = props

  const [followerNew, setFollowerNew] = useState<number>(0)
  const showToast = useToast()

  const handleSelectShop = (id: string | undefined) => {
    if (authStore?.value) {
      handleCountViewBoth(id as string)
    }
  }

  const handleGetLinkOfShop = (id: string) => {
    const shopCode = id?.split("-")?.length ? id?.split("-")[0] : ""
    return `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/merchant/${convertViToEn(
      shopName as string
    )}/${id}/${shopCode}`
  }
  const authStore = useSelector((state: RootState) => state.auth)

  const handleFollowShop = async () => {
    if (authStore?.value && shopId) {
      if (bloomData) {
        if (!handleCheckBloomFilter(bloomData as string, shopId as string)) {
          bookmarkProduct(shopId, BOOKMARK_CATEGORY.SHOP)
            .then(() => {
              setFollowerNew(followerNew + 1)
              handleGetBloomData()
              showToast({
                detail: handleTranslate("product-details.follow-success"),
                severity: "success",
              })
            })
            .catch((error) => {
              throw error
            })
        } else {
          deleteBookmark(shopId)
            .then(() => {
              handleGetBloomData()
              setFollowerNew(followerNew - 1)
              showToast({
                detail: handleTranslate("product-details.unfollow-success"),
                severity: "success",
              })
            })
            .catch((error) => {
              error
            })
        }
      } else {
        bookmarkProduct(shopId, BOOKMARK_CATEGORY.SHOP)
          .then(() => {
            handleGetBloomData()
            setFollowerNew(followerNew + 1)
            showToast({
              detail: handleTranslate("product-details.follow-success"),
              severity: "success",
            })
          })
          .catch((error) => {
            throw error
          })
      }
    } else {
      showToast({
        detail: handleTranslate("product-details.login-before-follow"),
        severity: "warn",
      })
    }
  }
  useEffect(() => {
    setFollowerNew(followers as number)
  }, [followers])
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  const handleOpenSocChatForProduct = () => {
    const productCode = id?.split("-")?.length ? id?.split("-")[0]: ""
    const productData = {
      agencyId: shopId,
      metaData: {
        object_id: id,
        name: name,
        avt: attachments?.length ? attachments[0].id : "",
        type: "PRODUCT",
        user_link: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/${convertViToEn(name as string)}/${id}/${productCode}`,
        agency_link: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/${convertViToEn(name as string)}/${id}/${productCode}`,
        admin_link: `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/${convertViToEn(name as string)}/${id}/${productCode}`,
        price: price_after_tax,
      },
    } as SocChatOpenProductType
    SocChatInstance.startNewConversation(productData)
  }

  const showReviewLabel = () => {
    if (shopDetailDTO?.total_reviews && shopDetailDTO?.total_reviews > 1) {
      return handleTranslate("global.reviews")
    }
    else return handleTranslate("global.review")
  }

  return (
    <>
      {shopId && shopName ? (
        <div className="flex flex-row">
          {typeCard?.toLocaleUpperCase() === CardCustomType.profile && (
            <div className="flex w-full flex-wrap justify-between gap-2 rounded-4 border border-gray-200 bg-white p-3 md:flex-row md:items-center ">
              <div className="flex w-full flex-row justify-between gap-3">
                <div className="flex w-full flex-1 flex-row gap-1 md:gap-[16px]">
                  <div className="flex flex-1 items-center gap-1 md:gap-[12px]">
                    <div className="flex h-[48px] w-[48px] items-center justify-center rounded-[50%] bg-orange-dark-50">
                      <img
                        src={ShopIcon}
                        alt="ShopIcon"
                        className="h-[32px] w-[32px] object-contain"
                      />
                    </div>
                    <div className="flex w-full flex-col gap-1">
                      <div className="flex flex-1 flex-row justify-between">
                        <div className="w-full">
                          <div className="flex w-full flex-col items-center sm:flex-row sm:gap-1">
                            <div
                              className="flex w-full cursor-pointer justify-between text-16 font-semibold leading-[24px] text-gray-600"
                            >
                              <a
                                onClick={() => handleSelectShop(shopId)}
                                className="max-w-[170px] truncate md:max-w-[400px] lg:max-w-[170px] hover:text-blue-light-500 hover:underline"
                                href={handleGetLinkOfShop(shopId)}
                              >
                                {shopName}
                              </a>
                              {/* SOCCHAT PRODUCT */}
                              <div
                                onClick={() => handleOpenSocChatForProduct()}
                                className="flex h-max w-max cursor-pointer items-center rounded-full bg-white"
                              >
                                <i className="sctr-icon-message-chat-square text-[20px]"/>
                              </div>
                            </div>
                            {badges?.name ? (
                              <div className="flex items-center gap-4px rounded-3 bg-blue-50 px-[4px] py-[2px]">
                                <div className="ml-1 h-[16px] w-[16px]">
                                  <ImgCustom url={badges?.avatar?.id as string} alt="image" />
                                </div>
                                <div className="truncate text-[12px] leading-[16px] text-blue-600">
                                  {badges.name}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="text-14 font-medium leading-5 text-gray-500">
                            {location}
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-3 md:w-fit md:flex-row md:items-center">
                        <div className="flex">
                          <div className="justify-starts flex items-center gap-4px text-14 font-medium text-gray-600">
                            <span className="text-14 font-normal leading-5 text-gray-500">
                              {capitalizedFirst(handleTranslate("global.rating"))}:
                            </span>
                            <div className="flex flex-row items-center gap-4px">
                              <img className="h-3 w-3" src={star} alt="star" />
                              <p className="text-14 font-medium leading-5 text-amber-400">{`${rating?.toFixed(
                                1
                              )}`}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-1 items-center">
                          <div
                            className={`flex flex-row items-center gap-4px ${
                              isShopOwner ? "pr-1" : ""
                            }`}
                          >
                            <span className="text-14 font-normal leading-5 text-gray-500">
                              {capitalizedFirst(handleTranslate("global.review"))}:
                            </span>
                            <div className="flex flex-row items-center gap-2px">
                              <img src={reviewIcon} alt="MessageView" className="h-3 w-3" />
                              <span className="flex flex-row items-center text-14 font-medium leading-5 text-gray-500">
                                {shopDetailDTO?.total_reviews}{" "}
                                <span className="ml-2px truncate">
                                  {showReviewLabel()}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start">
                    {!isShopOwner && (
                      <div className="flex items-center justify-between">
                        {bloomData &&
                        shopId &&
                        handleCheckBloomFilter(bloomData as string, shopId as string) ? (
                          <ButtonCustom
                            classCustom="py-6px px-2 rounded-2 border border-blue-700 text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                            severity={ButtonSeverity.custom}
                          >
                            <div
                              onClick={handleFollowShop}
                              className={`flex items-center justify-between gap-2  ${
                                bloomData &&
                                shopId &&
                                handleCheckBloomFilter(bloomData as string, shopId as string)
                                  ? "text-blue-700"
                                  : "text-gray-700"
                              }`}
                            >
                              <p className="truncate text-14 font-semibold">
                                {capitalizedFirst(handleTranslate("global.followed"))}
                              </p>
                            </div>
                          </ButtonCustom>
                        ) : (
                          <ButtonCustom
                            classCustom="rounded-2 border border-blue-700  text-gray-500 hover:text-blue-500 hover:border-blue-500 active:text-blue-700 active:border-blue-700"
                            severity={ButtonSeverity.custom}
                          >
                            <div
                              onClick={handleFollowShop}
                              className={`flex items-center justify-between gap-2 px-1 py-[2px] md:py-6px md:px-2 ${
                                bloomData &&
                                shopId &&
                                handleCheckBloomFilter(bloomData as string, shopId as string)
                                  ? "text-blue-700"
                                  : "text-gray-700"
                              }`}
                            >
                              <p className="truncate text-12 md:text-14 font-semibold text-blue-700">
                                {capitalizedFirst(handleTranslate("global.follow"))}
                              </p>
                            </div>
                          </ButtonCustom>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="h-auto w-full">
          <EmptyPage message="Not found data. Please try again later." />
        </div>
      )}
    </>
  )
}
