import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { TimeListType, SystemSliceType, SystemVoucherType } from "./props"
import { VoucherType } from "src/components/modal/select-voucher-modal/types"

export const initialState: SystemSliceType = {
  value: [],
  systemVoucher: undefined,
  shipVoucherMin: undefined,
}

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    updateFlashTimes: (state, action: PayloadAction<TimeListType[]>) => {
      state.value = action.payload
    },
    updateSystemVoucher: (
      state,
      action: PayloadAction<SystemVoucherType | undefined>
    ) => {
      state.systemVoucher = action.payload
    },
    updateShipVoucherMin: (
      state,
      action: PayloadAction<VoucherType | undefined>
    ) => {
      state.shipVoucherMin = action.payload
    },
  },
})

export const { updateFlashTimes, updateSystemVoucher, updateShipVoucherMin } =
  systemSlice.actions

export default systemSlice.reducer
