import React from "react"
import ImgCustom from "src/components/img-custom"
import { useNavigate } from "react-router-dom"
import liveViewIcon from "src/assets/images/live-view-icon.svg"
import liveReactionIcon from "src/assets/images/live-reaction-icon.svg"
import liveSmileIcon from "src/assets/images/live-smile-reaction-icon.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { LiveStreamViewType } from "../types"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"
import { useToast } from "src/hooks/use-toast"

const LiveStreamView = (props: LiveStreamViewType) => {
  const { hostImg, hostName, bgImg, liveName, views, actionCount, isLive, liveId } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const showToast = useToast()
  const goToLive = () => {
    if (authStore?.value) {
      navigate(`/shopping/livestream/room/${liveId}`)
    } else {
      showToast({ detail: "Please login before continue", severity: "warn" })
    }
  }

  return (
    <div onClick={goToLive} className="flex h-fit cursor-pointer flex-col">
      <div className="relative h-[175px]">
        <ImgCustom
          alt="liveBackground"
          url={bgImg}
          className="h-full w-full rounded-[6px]"
        />
        <div className={`absolute left-[8px] bottom-[8px] rounded-3 px-[6px] py-[2px] text-[12px] font-medium leading-[16px] text-white ${isLive ? "bg-red-500" : "bg-amber-500"}`}>
          {capitalizedFirst(t("live-stream.live"))}
        </div>
      </div>
      <span className="mt-[4px] text-[16px] font-semibold leading-[24px] text-gray-700 line-clamp-2">
        {liveName}
      </span>
      <div className="flex items-center">
        <div className="h-[20px] w-[20px] rounded-full">
          <ImgCustom
            alt="hostIcon"
            url={hostImg}
            className="h-full w-full rounded-full"
          />
        </div>
        <span className="ml-4px text-[14px] leading-[20px] text-gray-700">
          {hostName}
        </span>
      </div>
      <div className="mt-4px flex items-center">
        <div className="flex items-center">
          <img
            src={liveViewIcon}
            className="h-[20px] w-[20px]"
            alt="viewIcon"
          />
          <span className="ml-[2px] text-[14px] font-medium leading-[20px] text-gray-500">
            {views}
          </span>
        </div>
        <div className="ml-[12px] flex items-center">
          <img src={liveReactionIcon} className="z-[2] h-[20px] w-[20px]" />
          <img
            src={liveSmileIcon}
            className="z-[1] ml-[-6px] h-[20px] w-[20px]"
          />
          <span className="ml-[2px] text-[14px] font-medium leading-[20px] text-gray-500">
            {actionCount}
          </span>
        </div>
      </div>
    </div>
  )
}

export default LiveStreamView
