import React, { useEffect, useState } from "react"
import { CountDownCartType } from "./type"

export default function CountDownCartView(props: CountDownCartType) {
  const { target, callbackTrigger } = props
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)
  const [isTimeout, setIsTimeout] = useState<boolean>(false)
  const targetTime = new Date(target)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const different = targetTime.getTime() - now.getTime()
      const h = Math.floor(
        (different % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      setHours(h)
      const m = Math.floor((different % (1000 * 60 * 60)) / (1000 * 60))
      setMinutes(m)
      const s = Math.floor((different % (1000 * 60)) / 1000)
      setSeconds(s)
      if (h <= 0 && m <= 0 && s <= m) {
        setIsTimeout(true)
        if (callbackTrigger) callbackTrigger()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [target])

  if (isTimeout) return null
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <div
          className={
            "flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-12 sm:text-16 font-semibold leading-6 text-orange-dark-500"
          }
        >
          {hours <= 9 ? <div>{`0${hours}`}</div> : <div>{hours}</div>}
        </div>
        <div className={"text-12 sm:text-14 font-medium text-gray-600"}>:</div>
        <div
          className={
            "flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-12 sm:text-16 font-semibold leading-6 text-orange-dark-500"
          }
        >
          {minutes <= 9 ? <div>{`0${minutes}`}</div> : <div>{minutes}</div>}
        </div>
        <div className={"text-12 sm:text-14 font-medium text-gray-600"}>:</div>
        <div
          className={
            "bg-orange-dark-50 flex items-center justify-center rounded-1 p-4px text-12 sm:text-16 font-semibold leading-6 text-orange-dark-500"
          }
        >
          {seconds <= 9 ? <div>{`0${seconds}`}</div> : <div>{seconds}</div>}
        </div>
      </div>
    </div>
  )
}
