import http from "src/config/interceptors"

export const handleGetMost = (
  pageNum: number,
  pageSize: number,
  sortFilter: string,
  filterString?: string
) => {
  if (filterString) {
    return http.get(
      `/stock/products/search?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filterString}&sort=${sortFilter}`
    )
  } else {
    return http.get(
      `/stock/products/search?pageNum=${pageNum}&pageSize=${pageSize}&sort=${sortFilter}`
    )
  }
}

export const handleGetShopBannerList = (
  pageNumber: number,
  pageSize: number
) => {
  return http.get(
    `/shop/shop-banners?pageNum=${pageNumber}&pageSize=${pageSize}`
  )
}
