import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { HeaderStateProperties, PurchaseHistoryProperties } from "src/pages/purchase-history/props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import Orders from "../orders"
import { getOrderList, getTabs } from "src/services/purchase-history-service"
import EmptyPage from "src/pages/empty-page"
import { useDebounce } from "use-debounce"
import useWindowDimensions from "src/utils/screen-size"

export default function MyOrders() {
  const { width } = useWindowDimensions()
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string | undefined>("")
  const [headerSelected, setHeaderSelected] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataOrders, setDataOrder] = useState<PurchaseHistoryProperties[]>()
  const [headerData, setHeaderData] = useState<HeaderStateProperties[]>([])
  const [viewMoreLoading, setViewMoreLoading] = useState<boolean>(false)
  const [searchDebounce] = useDebounce(searchValue, 800)
  const [totalOrders, setTotalOrders] = useState<number>(0)
  const [orderPage, setOrderPage] = useState<number>(0)
  const [emptyMessage, setEmptyMessage] = useState<string>(
    "Not found any orders. Please try again later."
  )
  const [isRenderView, setIsRenderView] = useState<boolean>(false)
  const { t, i18n } = useTranslation()
  const language = localStorage.getItem("i18nextLng")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
  }
  useEffect(() => {
    const historyTab = localStorage.getItem("ORDER_TAB")
    const currentTab = historyTab ? historyTab : "ALL"
    setHeaderSelected(currentTab)
    setIsRenderView(true)
  }, [])

  const handleChangeTab = (code: string) => {
    localStorage.setItem("ORDER_TAB", code)
    setHeaderSelected(code)
    setOrderPage(0)
  }
  const handleGetData = (code: string, text?: string) => {
    const controller = new AbortController()
    const signal = controller.signal
    setDataOrder([])
    setIsLoading(true)
    const dataGet = setTimeout(async () => {
      await getOrderList(code, text, orderPage, 10, signal, language || "en")
        .then((response) => {
          setTotalOrders(response?.data?.data?.totalElement)
          setIsLoading(false)
          setDataOrder(response?.data?.data?.data)
          if (!dataOrders) {
            setEmptyMessage(capitalizedFirst(t("purchase-history.order-not-found-message")))
          }
        })
        .catch(() => {
          setIsLoading(false)
          setEmptyMessage(capitalizedFirst(t("purchase-history.network-error-message")))
        })
    }, 100)
    return () => {
      clearTimeout(dataGet)
      controller.abort()
    }
  }

  useEffect(() => {
    if (!isRenderView) return
    handleGetData(headerSelected, searchValue)
  }, [headerSelected, searchDebounce, isRenderView, i18n?.language])

  const handleGetTabs = async () => {
    await getTabs()
      .then((response) => {
        setHeaderData(response?.data)
      })
      .catch((error) => error)
  }

  useEffect(() => {
    handleGetTabs()
  }, [])

  const handleLoadMore = async () => {
    const controller = new AbortController()
    const signal = controller.signal
    const currentPage = orderPage + 1
    setOrderPage(currentPage)
    setViewMoreLoading(true)
    await getOrderList(headerSelected, searchValue, currentPage, 10, signal, language || "en")
      .then((response) => {
        if (dataOrders?.length && response?.data?.data?.data?.length) {
          setViewMoreLoading(false)
          // eslint-disable-next-line no-unsafe-optional-chaining
          setDataOrder([...dataOrders, ...response?.data?.data?.data])
        }
      })
      .catch(() => {
        setViewMoreLoading(false)
      })
  }

  const handleRefreshPage = () => {
    const timeout = setTimeout(() => {
      handleGetData(headerSelected, searchValue)
    }, 300)
    return () => {
      clearTimeout(timeout)
    }
  }

  return (
    <div className="h-full w-full">
      <div className="flex flex-col rounded-3 bg-white">
        <div className="flex h-fit w-full flex-col items-center justify-between gap-4px border-b py-1 md:flex-row md:py-2">
          <div className="flex w-full items-start text-18 font-semibold text-gray-800 md:items-center md:px-3">
            {capitalizedFirst(handleTranslate("purchase-history.my-order"))}
          </div>
          <div className="flex w-full items-center justify-end md:w-[unset] md:px-3">
            <span className="p-input-icon-left w-full  md:w-[unset]">
              <i className="pi pi-search" />
              <InputText
                className="flex h-[36px] w-full items-center text-16 md:w-[unset] md:min-w-[50px] md:max-w-[320px]"
                placeholder={capitalizedFirst(handleTranslate("global.search"))}
                value={searchValue}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </span>
          </div>
        </div>

        {width < 768 ? (
          <div
            onClick={() => setIsShowMenu(!isShowMenu)}
            className="flex w-full flex-1 items-center justify-between rounded-3 bg-white py-1 px-1"
          >
            <div className="font-semibold text-gray-800">
              {capitalizedFirst(t("purchase-history.order-status-menu"))}
            </div>
            {!isShowMenu ? (
              <i className="pi pi-angle-up text-gray-500" style={{ fontSize: "1rem" }}></i>
            ) : (
              <i className="pi pi-angle-down text-gray-500" style={{ fontSize: "1rem" }}></i>
            )}
          </div>
        ) : null}
        {!isShowMenu && width < 768 ? (
          <div className="w-full py-2px"></div>
        ) : (
          <div className="item-center item-center flex h-fit flex-col flex-wrap justify-center gap-6px px-2  py-2 md:flex-row md:justify-start ">
            {headerData?.map((header: HeaderStateProperties, index: number) => (
              <div
                key={index}
                onClick={() => handleChangeTab(header.code)}
                className={`flex cursor-pointer items-center justify-start gap-2px truncate rounded-2 py-1 px-1 text-14 font-semibold md:text-12 ${
                  header.code === headerSelected
                    ? "bg-blue-light-100 text-blue-700"
                    : "text-gray-500"
                }`}
              >
                <p>{capitalizedFirst(t(`purchase-history.order-status-tabs.${header?.name}`))}</p>
                <div
                  className={`flex items-start rounded-3 border text-start ${
                    header.code === headerSelected
                      ? "border-blue-400 bg-blue-600 text-white"
                      : "border-gray-100"
                  }`}
                >
                  <span className="flex h-14px min-w-[20px] items-center justify-center rounded-circle p-2px text-10 font-normal ">
                    {header?.total_order}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* content */}
      <div className="mt-3 h-full w-full">
        {/* data order */}
        {isLoading ? (
          <div className="flex h-[255px] w-full items-center justify-center gap-2 rounded-3 bg-white">
            <p className="animate-pulse duration-75">{capitalizedFirst(t("global.loading"))}</p>
            <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
          </div>
        ) : (
          <div className="h-full w-full overflow-y-visible">
            {!dataOrders?.length ? (
              <div className="flex h-[600px] w-full items-center justify-center">
                <EmptyPage message={emptyMessage} />
              </div>
            ) : (
              <>
                <div className="w-full overflow-auto">
                  {dataOrders?.map((dataOrder) => (
                    <div className="mb-3 w-full" key={dataOrder?.id}>
                      <Orders data={dataOrder} refreshPage={handleRefreshPage} />
                    </div>
                  ))}
                </div>
                {dataOrders?.length < totalOrders ? (
                  <>
                    {viewMoreLoading ? (
                      <div className="animate-pulse text-14 font-semibold text-blue-700">
                        {capitalizedFirst(t("global.loading"))}
                      </div>
                    ) : (
                      <div className="w-full">
                        <p
                          onClick={handleLoadMore}
                          className="cursor-pointer text-14 font-semibold text-blue-700"
                        >
                          {capitalizedFirst(t("global.view-more"))}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
