/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from "react"
import { Button } from "primereact/button"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import {
  ORDER_STEP_VIEW,
  FLASH_DEAL_TYPE,
  ORDER_TAB_TYPE,
  ORDER_TYPE_MODE,
  ORDER_FAIL_CODE,
} from "../../../constants/common"
import { useSelector, useDispatch } from "react-redux"
import type { RootState } from "src/app/store"
import {
  BillingDetailTypes,
  ShopOrderType,
  ShopProductOrderType,
  VoucherSystemApplyType,
} from "./types"
import { toggleLoginModal } from "../../../features/auth"
import { useToast } from "src/hooks/use-toast"
import { getFlashDealTime, mathRoundNumber } from "src/utils/common"
import { CartType, ObjectDtoType } from "../type"
import { useNavigate } from "react-router-dom"
import { placeOrderOffline, placeOrderOnline } from "src/services/order-service"
import { convertCurrency } from "src/utils/convert-currency"
import shopIcon from "src/assets/images/shop-voucher.svg"
import shipmentIcon from "src/assets/images/shipment-voucher-icon.svg"
import { handleCod } from "src/services/payment-service"
import { addressService } from "src/services"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"
import { ConfirmModal } from "src/components/modal"
import { updateCart } from "src/features/shopping-cart"

const BillingDetail = (props: BillingDetailTypes) => {
  const { t, i18n } = useTranslation()
  const {
    stepView,
    updateStepView,
    address,
    billingAddressData,
    invoiceData,
    tab,
    paymentMethodType,
    isCallClick,
    updateIsCallClick,
  } = props
  const showToast = useToast()
  const dispatch = useDispatch()

  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const authStore = useSelector((state: RootState) => state.auth)
  const systemStore = useSelector((state: RootState) => state.system)
  const [isClickOrder, setIsClickOrder] = useState<boolean>(false)
  const [addressDefaultId,setAddressDefaultId] = useState<string>()
  const [isWarning, setIsWarning] = useState<boolean>(false)
  const [isInValidVoucher, setIsInValidVoucher] = useState<boolean>(false)

  const navigator = useNavigate()
  const getVoucherShop = (shopId: string) => {
    const result: string[] = []
    const shopDetect = shoppingCartStore.cartData.find(
      (shop) => shop.cartId === shopId
    )
    if (shopDetect?.shopVoucher?.id)
      result.push(shopDetect?.shopVoucher?.id as string)
    if (shopDetect?.shipVoucher?.id)
      result.push(shopDetect?.shipVoucher?.id as string)
    return result
  }
  const getOrderData = () => {
    const stockProducts: ShopOrderType[] = []
    shoppingCartStore.cartData?.forEach((cart) => {
      let isOrder = false
      let shopAmount = 0
      let taxValue = 0
      const shopData: ShopOrderType = {
        vendor_id: cart.cartId,
        data: [],
        voucher_ids: getVoucherShop(cart.cartId),
        note: cart.shopNote,
        shipping_code: cart.feeShip?.value || "",
        is_invoice_exact: cart?.isShopInvoice as boolean,
        amount: 0,
      }
      cart?.objectDto?.forEach((obj) => {
        if (obj?.isSelected) {
          if (!isOrder) isOrder = true
          shopAmount = shopAmount + getPriceProduct(obj) * obj?.quantity
          if (obj.tax > 0)
            taxValue =
              taxValue + (getPriceProduct(obj) * obj?.quantity * obj.tax) / 100
          shopData.data.push({
            stock_id: obj?.stockDetail?.id,
            quantity: obj.quantity,
            token: obj?.liveToken || undefined,
          })
        }
      })
      // discount
      if (cart.shopVoucher?.id) {
        shopAmount = shopAmount - cart.shopVoucher.value
      }
      // delivery
      if (cart?.feeShip?.name) {
        shopAmount = shopAmount + cart.feeShip.discount
      }

      if (
        cart?.shipVoucher?.id &&
        cart?.feeShip?.name &&
        cart?.shipVoucher?.value
      ) {
        let shipDiscountAmount = 0
        const feeShipCompare = cart.feeShip.discount
        const shipVoucherCompare =
          (cart.shipVoucher.value * feeShipCompare) / 100

        if (cart.shipVoucher?.isLimit) {
          const resultMaxDiscount = cart.shipVoucher.maxDiscount || 0
          shipDiscountAmount =
            shipVoucherCompare > resultMaxDiscount
              ? resultMaxDiscount
              : shipVoucherCompare
        } else {
          shipDiscountAmount = shipVoucherCompare
        }
        shopAmount = shopAmount - shipDiscountAmount
      }
      shopData.amount = shopAmount + taxValue
      if (isOrder) stockProducts.push(shopData)
    })
    return stockProducts
  }
  const getOrderDataOffline = (callbackUrl: string) => {
    const resultData = {
      vendor_id: "",
      data: [] as ShopProductOrderType[],
      voucher_ids: [] as string[],
      vouchers: [] as VoucherSystemApplyType[],
      note: "",
      amount: 0,
      success_callback: "",
      fail_callback: "",
      is_invoice_exact: false,
      address_id: "",
    }
    let isOrder = false
    shoppingCartStore.cartData?.forEach((cart) => {
      if (isOrder) return
      let shopAmount = 0
      let taxValue = 0
      resultData.vendor_id = cart.cartId
      resultData.voucher_ids = getVoucherShop(cart.cartId)
      resultData.note = cart.shopNote
      resultData.is_invoice_exact = cart?.isShopInvoice as boolean
      resultData.fail_callback = callbackUrl
      resultData.success_callback = callbackUrl

      cart?.objectDto?.forEach((obj) => {
        if (obj?.isSelected) {
          if (!isOrder) isOrder = true
          shopAmount = shopAmount + getPriceProduct(obj) * obj?.quantity
          if (obj.tax > 0)
            taxValue =
              taxValue + (getPriceProduct(obj) * obj?.quantity * obj.tax) / 100
          resultData.data.push({
            stock_id: obj?.stockDetail?.id,
            quantity: obj.quantity,
            token: obj?.liveToken || undefined,
          })
        }
      })
      // discount
      if (cart.shopVoucher?.id && shopAmount) {
        shopAmount = shopAmount - cart.shopVoucher.value
      }
      resultData.amount = shopAmount + taxValue
    })
    if(addressDefaultId){
      resultData.address_id = addressDefaultId
    }
    return resultData
  }
  const labelActionBtn = useMemo(() => {
    switch (stepView) {
      case ORDER_STEP_VIEW.CHECKOUT:
        return tab === ORDER_TAB_TYPE.ALL
          ? capitalizedFirst(t("global.payment"))
          : capitalizedFirst(t("global.confirm"))
      case ORDER_STEP_VIEW.PAYMENT:
        return capitalizedFirst(t("checkoutPage.placeOrder"))
      default:
        return capitalizedFirst(t("shoppingCartPage.checkout"))
    }
  }, [stepView, i18n.language])

  const handleOrderOnline = () => {
    const resData = {
      address_id: address?.id || "",
      cod: paymentMethodType === "COD",
      vouchers: [] as VoucherSystemApplyType[],
      orders: getOrderData() || [],
      success_callback: window.location.href,
      fail_callback: window.location.href,
    }
    setIsClickOrder(true)
    placeOrderOnline(resData)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          // const paymentUrl = `${res.data.data?.payment_url}?paymentToken=${res?.data?.data?.token}
          if(paymentMethodType === "ONLINE") {
            const paymentUrl = `${process.env.REACT_APP_PAYMENT_DOMAIN_URL}?paymentToken=${res?.data?.data?.token}`
            window.open(paymentUrl, "_self")
          } else {
            handleCod(res?.data?.data?.token as string ||"").catch(() => console.log("COD FAIL")).finally(() => {
              navigator(`/shopping/order-cart?token=${res?.data?.data?.token}`)
              navigator(0)
            })
          }

        } else {
          setIsClickOrder(false)
          let message = "Create order failed. Please try again!"
          if (
            res?.data?.error?.code &&
            ORDER_FAIL_CODE.includes(res.data.error.code)
          ) {
            const messageError = `order-fail-message.${res.data.error.code}`
            message = capitalizedFirst(t(messageError))
          }
          showToast({
            detail: message,
            severity: "error",
          })
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((data: any) => {
        setIsClickOrder(false)
        let message = "Create order failed. Please try again!"
        if (
          data?.response?.data?.error?.code &&
          ORDER_FAIL_CODE.includes(+data.response.data.data)
        ) {
          const messageError = `order-fail-message.${data.response.data.data}`
          message = capitalizedFirst(t(messageError))
        }
        // back to old step view
        if(isCallClick) {
          updateIsCallClick(false)
          updateStepView(ORDER_STEP_VIEW.CHECKOUT)
        }
        showToast({
          detail: message,
          severity: "error",
        })
      })
  }
  const handleOrderOffline = () => {
    setIsClickOrder(true)
    const resData = getOrderDataOffline(window.location.href)
    placeOrderOffline(resData)
      .then((res) => {
        if (res?.data?.data?.token) {
          navigator(
            `/shopping/order-cart?token=${res.data.data.token}&&isOffline=true`
          )
          navigator(0)
        } else {
          setIsClickOrder(false)
          let message = capitalizedFirst(t("order-fail-message.order-fail"))
          if (
            res?.data?.error?.code &&
            ORDER_FAIL_CODE.includes(res.data.error.code)
          ) {
            const messageError = `order-fail-message.${res.data.error.code}`
            message = capitalizedFirst(t(messageError))
          }
          showToast({
            detail: message,
            severity: "error",
          })
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((data: any) => {
        setIsClickOrder(false)
        let message = "Create order failed. Please try again!"
        if (
          data?.response?.data?.error?.code &&
          ORDER_FAIL_CODE.includes(data.response.data.error.code)
        ) {
          const messageError = `order-fail-message.${data.response.data.error.code}`
          message = capitalizedFirst(t(messageError))
        }
        showToast({
          detail: message,
          severity: "error",
        })
      })
  }
  const isTransitionCheckOut = () => {
    if (authStore.value && isHaveSelectedData()) return true
    return false
  }
  const isLimitTicketValid = () => {
    let result = true
    shoppingCartStore.cartData.forEach(cart => {
      let isCartHaveTicket = false
      let quantityTicketByCart = 0
      cart.objectDto.forEach(pro => {
        if(pro.isSelected && pro.catalogs?.length && pro.catalogs[0].type === "E_TICKET") {
          isCartHaveTicket = true
          quantityTicketByCart = quantityTicketByCart + pro.quantity
        }
      })
      // current hard code value is 6.
      if(isCartHaveTicket && quantityTicketByCart > 0 && (cart.limit_per_item < 0 || (cart.limit_per_item > 0 && cart.limit_per_item < quantityTicketByCart))) result = false
    })
    return result
  }
  const isInValidLiveVoucher = () => {
    let result = false
    shoppingCartStore.cartData.forEach(s => {
      if(s?.shopVoucher?.liveVoucherTime) {
        const nowTime = new Date().getTime()
        const endTime = new Date(s.shopVoucher.liveVoucherTime).getTime()
        if(endTime < nowTime) result = true
      }
    })
    return result
  }
  const handleClickAction = () => {
    if(isClickOrder || isInValidVoucher) return
    if(isInValidLiveVoucher()) {
      setIsWarning(true)
      return
    }
    switch (stepView) {
      case ORDER_STEP_VIEW.CART:
        if (isTransitionCheckOut()) {
          let couponData = ""
          if(cartBilling.discountName.length) {
            cartBilling.discountName.forEach(i => {
              couponData = couponData + i.name + ", "
            })
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const itemData = [] as any[]
          shoppingCartStore.cartData?.forEach((cart) => {
            cart?.objectDto?.forEach((obj) => {
              if (obj?.isSelected) {
                itemData.push({
                  item_id: obj.objectId,
                  item_name: obj.title,
                  price: mathRoundNumber(obj.stockDetail.price_after_tax),
                  quantity: obj.quantity,
                })
              }
            })
          })
          // GA POST ACTION
          gaPostAction(TAG_TYPE.BEGIN_CHECKOUT, {
              currency: "USD",
              value: cartBilling.totalPrice,
              coupon: couponData,
              item: itemData,
          })
          if(isLimitTicketValid()) updateStepView(ORDER_STEP_VIEW.CHECKOUT)
          else {
            showToast({ detail: capitalizedFirst(t("shoppingCartPage.e-ticket-warn")), severity: "warn" })
          }
        }
        else {
          if (!authStore.value) dispatch(toggleLoginModal(true))
          else if (!isHaveSelectedData())
            showToast({ detail: "Please select the item", severity: "error" })
        }
        break
      case ORDER_STEP_VIEW.CHECKOUT:        
        if (tab === ORDER_TYPE_MODE.ONLINE) {
          updateStepView(ORDER_STEP_VIEW.PAYMENT)
        } else handleOrderOffline()
        break
      case ORDER_STEP_VIEW.PAYMENT:
        handleOrderOnline()
        break
      default:
        break
    }
  }
  const isHaveSelectedData = () => {
    let result = false
    shoppingCartStore.cartData?.forEach((cart) => {
      cart.objectDto?.forEach((obj) => {
        if (obj?.isSelected) {
          result = true
          return
        }
      })
      if (result) return
    })
    return result
  }
  
  const getPriceProduct = (data: ObjectDtoType) => {
    const timeFlashDeal = getFlashDealTime(systemStore.value)
    let result = 0
    // livestream
    if (
      data?.liveToken &&
      data?.isLiveStream &&
      data?.liveStreamDeal &&
      data?.quantity <=
        data?.liveStreamDeal.quantity - data?.liveStreamDeal.sold
    ) {
      result =
        ((100 - data?.liveStreamDeal.discount_percent) *
          data?.stockDetail.original_price) /
        100
    } else if (
      // flash deal
      data?.isFlashDeal &&
      data?.flashDeal &&
      timeFlashDeal &&
      data?.quantity <= data?.flashDeal.max_sold - data?.flashDeal.sold
    ) {
      if (data.flashDeal.type === FLASH_DEAL_TYPE.FIXED_PRICE) {
        result = data.flashDeal.marketing_price
      } else {
        result =
          ((100 - data.flashDeal.marketing_price) *
            data.stockDetail.original_price) /
          100
      }
    } else {
      result = data?.stockDetail?.price
    }
    return result
  }
  const cartBilling = useMemo(() => {
    let deliveryPrice = 0
    let subtotal = 0
    let discountPriceTotal = 0
    let deliveryDiscount = 0
    let taxValue = 0
    const discountName: { name: string; type: string }[] = []
    const discountId: string[] = []
    const shopIds: string[] = []

    if (shoppingCartStore.cartData.length) {
      shoppingCartStore.cartData.forEach((cart) => {
        let isHaveOrder = false
        let totalShopValue = 0
        cart.objectDto?.forEach((obj) => {
          if (obj?.isSelected) {
            if (!shopIds.includes(obj?.entityId)) {
              shopIds.push(obj?.entityId)
            }
            if (!isHaveOrder) isHaveOrder = true
            subtotal = subtotal + getPriceProduct(obj) * obj?.quantity
            totalShopValue =
              totalShopValue + getPriceProduct(obj) * obj?.quantity
            if (obj.tax > 0)
              taxValue =
                taxValue +
                (getPriceProduct(obj) * obj?.quantity * obj.tax) / 100
          }
        })
        if (isHaveOrder) {
          // fee ship
          if (cart?.feeShip?.discount) {
            deliveryPrice = deliveryPrice + cart.feeShip.discount
          }
          // discount voucher
          const shopDiscountValue = cart.shopVoucher?.value || 0
          const budgeShopDiscount = cart.shopVoucher?.budgeValue || 0
          if (cart?.shopVoucher?.id) {
            if (cart.shopVoucher.isBudgetLimit) {
              discountPriceTotal =
                discountPriceTotal +
                (budgeShopDiscount > shopDiscountValue
                  ? shopDiscountValue
                  : budgeShopDiscount)
            } else {
              discountPriceTotal = discountPriceTotal + shopDiscountValue
            }
            if (!discountId.includes(cart.shopVoucher.id)) {
              discountName.push({
                name: cart.shopVoucher.name as string,
                type: "SHOP",
              })
              discountId.push(cart.shopVoucher.id)
            }
          }
          // discount ship voucher
          const shipDiscountValue = cart.shipVoucher?.value || 0
          const budgeShipDiscount = cart.shipVoucher?.budgeValue || 0
          const billingShopCompare = cart.feeShip?.discount || 0
          const isLimitBudgetShipVoucher = cart.shipVoucher?.isBudgetLimit
          let resultShipDiscountByShop = 0
          if (cart?.shipVoucher?.id) {
            // case budget limit
            if (isLimitBudgetShipVoucher) {
              let valueShipCompare =
                budgeShipDiscount > shipDiscountValue
                  ? shipDiscountValue
                  : budgeShipDiscount

              // Percent case
              if (cart?.shipVoucher?.type !== "PRICE") {
                valueShipCompare =
                  budgeShipDiscount >
                  (shipDiscountValue * billingShopCompare) / 100
                    ? (shipDiscountValue * billingShopCompare) / 100
                    : budgeShipDiscount
              }

              const shipVoucherCompare = valueShipCompare

              // limit discount
              if (cart.shipVoucher?.isLimit) {
                if (cart?.shipVoucher?.type === "PRICE") {
                  resultShipDiscountByShop =
                    shipDiscountValue > billingShopCompare
                      ? billingShopCompare
                      : shipDiscountValue
                } else {
                  const resultMaxDiscount = cart.shipVoucher.maxDiscount || 0
                  resultShipDiscountByShop =
                    shipVoucherCompare > resultMaxDiscount
                      ? resultMaxDiscount
                      : shipVoucherCompare
                }
              } else {
                resultShipDiscountByShop = shipVoucherCompare > billingShopCompare ? billingShopCompare : shipVoucherCompare
              }
            } else {
              const shipVoucherCompare =
                cart?.shipVoucher?.type === "PRICE"
                  ? shipDiscountValue
                  : (shipDiscountValue * billingShopCompare) / 100

              resultShipDiscountByShop = shipVoucherCompare > billingShopCompare ? billingShopCompare : shipVoucherCompare
            }
            //  get discount ship voucher by shop
            deliveryDiscount = deliveryDiscount + resultShipDiscountByShop

            if (!discountId.includes(cart.shipVoucher.id)) {
              discountId.push(cart.shipVoucher.id)
              discountName.push({
                name: cart.shipVoucher.name as string,
                type: "FREE_SHIP",
              })
            }
          }
        }
      })
    }
    const billing = {
      subtotalPrice: subtotal,
      deliveryPrice: deliveryPrice,
      discountPrice: discountPriceTotal,
      totalPrice:
        subtotal + deliveryPrice - discountPriceTotal - deliveryDiscount,
      totalPrice1:
        mathRoundNumber(subtotal) +
        mathRoundNumber(deliveryPrice) -
        mathRoundNumber(discountPriceTotal) -
        mathRoundNumber(deliveryDiscount),
      discountName: discountName,
      deliveryDiscount: deliveryDiscount,
      tax: taxValue,
    }
    return billing
  }, [shoppingCartStore])
  const itemSelected = useMemo(() => {
    let result = 0
    shoppingCartStore.cartData?.forEach((cart) => {
      cart?.objectDto?.forEach((obj) => {
        if (obj?.isSelected) result += 1
      })
    })
    return result
  }, [shoppingCartStore])

  const isDisabledAction = useMemo(() => {
    if (
      stepView === ORDER_STEP_VIEW.CHECKOUT &&
      tab === ORDER_TYPE_MODE.ONLINE
    ) {
      let countShop = 0
      let countShopFee = 0
      shoppingCartStore.cartData.forEach((cart) => {
        cart.objectDto.forEach((product) => {
          if (product.isSelected) {
            countShop += 1
            if (cart.feeShip?.name) countShopFee += 1
            return
          }
        })
      })
      return countShop != countShopFee
    } else if (
      stepView === ORDER_STEP_VIEW.PAYMENT &&
      tab === ORDER_TYPE_MODE.ONLINE
    )
      return !paymentMethodType
    return false
  }, [stepView, billingAddressData, invoiceData, shoppingCartStore.cartData, paymentMethodType])

  const getTotalResult = (tax: number, total: number) => {
    return tax + total
  }

  async function getDefaultAddress() {
    await addressService.getAddressDefault().then((res) => {
      if (res.data.data) {
        setAddressDefaultId(res.data.data.id)
      }
    })
  }
  // Auto Call click when have only one payment method
  useEffect(() => {
    if(stepView === ORDER_STEP_VIEW.PAYMENT && isCallClick && paymentMethodType) handleClickAction()
  }, [isCallClick, stepView])

  // Get address default for order
  useEffect(() => {
    getDefaultAddress()
  }, [])

 
  useEffect(() => {
    if(stepView === ORDER_STEP_VIEW.PAYMENT && paymentMethodType) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const itemData = [] as any []
      shoppingCartStore.cartData?.forEach((cart) => {
        cart?.objectDto?.forEach((obj) => {
          if (obj?.isSelected) {
            itemData.push({
              item_id: obj.objectId,
              item_name: obj.title,
              price: mathRoundNumber(obj.stockDetail.price_after_tax),
              quantity: obj.quantity,
            })
          }
        })
      })
      const gaData = {
        currency: "USD",
        value: mathRoundNumber(getTotalResult(cartBilling.tax, cartBilling.totalPrice)),
        payment_type: paymentMethodType,
        items: itemData,
      }      
      gaPostAction(TAG_TYPE.ADD_PAYMENT_INFO, gaData)
    }
  }, [stepView, paymentMethodType])

  // handle Confirm change remove live voucher
  const handleConfirmChangeLiveVoucher = () => {
    const newCarts = [] as CartType[]    
    shoppingCartStore.cartData.forEach(s => {
      // remove shop voucher
      if(s.shopVoucher?.liveVoucherTime) {
        const nowTime = new Date().getTime()
        const entTime = new Date(s.shopVoucher.liveVoucherTime).getTime()
        if(entTime < nowTime) {
          newCarts.push({
            ...s,
            shopVoucher: undefined,
          })
        }
      } else {
        newCarts.push({ ...s })
      }
    })
    dispatch(updateCart(newCarts))
    setIsInValidVoucher(false)
    handleClickAction()
    setIsWarning(false)
  }

  return (
    <div className="flex flex-col rounded-3 border border-gray-200 bg-white p-3">
      <span className="flex flex-row items-center gap-4px text-18 font-semibold leading-30 text-gray-800">
        {capitalizedFirst(t("shoppingCartPage.orderSummer"))}
        <span className="font-normal">
          {itemSelected > 1
            ? capitalizedFirst(
                t("shoppingCartPage.itemCounts", { count: itemSelected })
              )
            : capitalizedFirst(
                t("shoppingCartPage.itemCount", { count: itemSelected })
              )}
        </span>
      </span>
      <div className="mt-3 flex items-center justify-between text-14 font-semibold leading-20 text-gray-500">
        <span>{capitalizedFirst(t("checkoutPage.vouchers"))}</span>
        {cartBilling.discountName.length ? (
          <div className="flex flex-wrap gap-[4px]">
            {cartBilling?.discountName.map((i, index) => (
              <div key={index} className="ml-3 flex flex-1 items-center">
                <div
                  className={`flex items-center gap-4px truncate rounded-5 px-10px py-4px text-14 font-medium ${
                    i?.type == "FREE_SHIP"
                      ? "bg-emerald-50 text-emerald-600"
                      : "bg-orange-dark-50 text-orange-700"
                  }`}
                >
                  <span>
                    {i?.type == "FREE_SHIP" ? (
                      <img src={shipmentIcon} alt="icon" className="h-2 w-2" />
                    ) : (
                      <img src={shopIcon} alt="icon" />
                    )}
                  </span>
                  <span className="truncate max-w-[170px]">{i?.name}</span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {/* Subtotal */}
      <div className="mt-3 flex justify-between text-14 font-semibold leading-20 text-gray-500">
        <span>
          {capitalizedFirst(capitalizedFirst(t("shoppingCartPage.subtotal")))}
        </span>
        <span className="text-gray-700">
          {convertCurrency(cartBilling.subtotalPrice)}
        </span>
      </div>

      {/* Tax */}
      <div className="mt-3 flex justify-between text-14 font-semibold leading-20 text-gray-500">
        <span>{capitalizedFirst(t("shoppingCartPage.tax"))}</span>
        <span className="text-gray-700">
          {convertCurrency(cartBilling.tax)}
        </span>
      </div>

      {/* Discounts */}
      <div className="mt-3 flex justify-between text-14 font-semibold leading-20 text-gray-500">
        <span>{capitalizedFirst(t("shoppingCartPage.discounts"))}</span>
        <span className="text-gray-700">
          {cartBilling.discountPrice
            ? `-${convertCurrency(cartBilling.discountPrice)}`
            : `${convertCurrency(cartBilling.discountPrice)}`}
        </span>
      </div>

      {/* Delivery */}
      {tab === ORDER_TYPE_MODE.ONLINE ? (
        <div className="mt-3 flex justify-between text-14 font-semibold leading-20 text-gray-500">
          <span>{capitalizedFirst(t("shoppingCartPage.delivery"))}</span>
          <span className="text-gray-700">
            {convertCurrency(cartBilling.deliveryPrice)}
          </span>
        </div>
      ) : null}

      {/* Delivery discount */}
      {tab === ORDER_TYPE_MODE.ONLINE ? (
        <div className="mt-3 flex justify-between  border-b-[1px] border-b-gray-200 pb-1 text-14 font-semibold leading-20 text-gray-500">
          <span>
            {capitalizedFirst(t("shoppingCartPage.delivery-discount"))}
          </span>
          <span className="text-gray-700">
            {cartBilling.deliveryDiscount
              ? `-${convertCurrency(cartBilling.deliveryDiscount)}`
              : `${convertCurrency(cartBilling.deliveryDiscount)}`}
          </span>
        </div>
      ) : null}

      {/* Total */}
      <div className="mt-4 flex justify-between text-16 font-bold leading-24">
        <span className="text-gray-700">
          {capitalizedFirst(t("shoppingCartPage.total"))}
        </span>
        <span className="text-orange-dark-500">
          {convertCurrency(
            getTotalResult(cartBilling.tax, cartBilling.totalPrice)
          )}
        </span>
      </div>
      <div className="mt-3 flex items-center justify-between">
        <Button
          disabled={isDisabledAction || !isHaveSelectedData()}
          onClick={handleClickAction}
          className={`w-full rounded-3 bg-blue-600 text-16 font-semibold leading-24 text-white hover:!bg-blue-700 ${
            isDisabledAction && "opacity-50"
          } ${isClickOrder ? "animate-pulse duration-75 opacity-50" : ""}`}
          label={ isClickOrder ? capitalizedFirst(t("global.loading")) : labelActionBtn}
        />
      </div>
      {isWarning && (
        <ConfirmModal
          isShow={isWarning}
          title={capitalizedFirst(t("global.confirm"))}
          message={capitalizedFirst(t("warning-live-voucher"))}
          confirmLabel={"global.change"}
          handleClose={() => setIsWarning(false)}
          handleConfirm={handleConfirmChangeLiveVoucher}
        />
      )}
    </div>
  )
}

export default BillingDetail
