import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  ConfirmModal,
  CreateNewAddressModal,
  EditAddressModal,
} from "src/components/modal"
import { useToast } from "src/hooks/use-toast"
import { addressService } from "src/services"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import editAddressIcon from "../../../assets/images/edit-address-icon.svg"
import { Address, AddressListProps, TypeAddress } from "./props"

export default function AddressList({ onDataChange }: AddressListProps) {
  const { t } = useTranslation()
  const showToast = useToast()
  const [addressList, setAddressList] = useState<Address[]>([])
  const [isCreateModal, setIsCreateModal] = useState(false)
  const [editAddressModal, setEditAddressModal] = useState<{
    isShow: boolean
    addressId: string
  }>({ isShow: false, addressId: "" })
  const [deleteAddressModal, setDeleteAddressModal] = useState<{
    isShow: boolean
    addressId: string
  }>({ isShow: false, addressId: "" })

  const handleConfirmUpdate = () => {
    fetchAddress()
    setEditAddressModal({ isShow: false, addressId: "" })
  }

  const handleConfirmCreate = () => {
    fetchAddress()
    onDataChange()
    setIsCreateModal(false)
  }

  const handleDeleteAddress = async () => {
    try {
      setDeleteAddressModal({ isShow: false, addressId: "" })
      await addressService.deleteAddress(deleteAddressModal.addressId)
      await fetchAddress()
      onDataChange()
      showToast({ detail: capitalizedFirst(t("address-modal.delete-success")), severity: "success" })
    } catch (error) {
      showToast({ detail: capitalizedFirst(t("address-modal.delete-fail")), severity: "warn" })
    }
  }

  const fetchAddress = async () => {
    try {
      const response = await addressService.getListAddress()
      setAddressList(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAddress()
  }, [])

  return (
    <div>
      <div className="mb-2 flex items-center justify-between rounded-3 bg-white px-3 py-2">
        <div className="text-18 font-semibold">
          {capitalizedFirst(t("purchase-history.address-list"))}
        </div>
        <button
          className="flex items-center justify-center rounded-3 bg-blue-600 py-1 px-3 font-medium text-white transition hover:bg-blue-700"
          onClick={() => setIsCreateModal(true)}
        >
          <i className="pi pi-plus" />
          <span className="ml-1">
            {capitalizedFirst(t("checkoutPage.addAddress"))}
          </span>
        </button>
      </div>
      <div className="mb-3 rounded-3 bg-white">
        {addressList.map((address, index) => (
          <React.Fragment key={address.id}>
            <div className={`flex  justify-between p-3 ${index && "border-t"}`}>
              <div className="flex flex-1 flex-col">
                <div className="flex">
                  <span className="text-16 font-semibold leading-24 text-gray-700">
                    {address.user_full_name}
                  </span>
                  {address?.address_type ? (
                    <div className={`ml-1 flex items-center justify-center cursor-pointer rounded-5 py-2px px-1 text-12 font-semibold leading-18 ${address.address_type == TypeAddress.HOME ? "bg-orange-50 text-orange-700" : "text-emerald-600 bg-green-50"}`}>
                      {capitalizedFirst(t(`global.${address?.address_type}`))}
                    </div>
                  ) : null}
                  {address.is_default && (
                    <div className="ml-2 cursor-pointer rounded-5 bg-blue-50 py-2px px-1 text-12 font-semibold leading-18 text-blue-700">
                      {capitalizedFirst(t("global.default"))}
                    </div>
                  )}
                </div>
                <span className="mt-1 text-14 font-normal leading-20 text-gray-500">
                  {address.user_phone_number}
                </span>
                <span className="mt-1 text-14 font-normal leading-20 text-gray-500">{`${[
                  address.address,
                  address.ward,
                  address.district,
                  address.city,
                ]
                  .filter((i) => i)
                  .join(", ")}`}</span>
              </div>
              <div className="ml-1 flex w-[28px] flex-col">
                <div
                  onClick={() =>
                    setEditAddressModal({ addressId: address.id, isShow: true })
                  }
                  className="flex h-[28px] cursor-pointer items-center justify-center"
                >
                  <img src={editAddressIcon} alt="editAddressIcon" />
                </div>
                <div
                  onClick={() =>
                    setDeleteAddressModal({
                      isShow: true,
                      addressId: address.id,
                    })
                  }
                  className="mt-1 flex h-[28px] cursor-pointer items-center justify-center"
                >
                  <i className="pi pi-trash text-16 text-red-600" />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      {editAddressModal.isShow && (
        <EditAddressModal
          isSelected={false}
          isShow={editAddressModal.isShow}
          addressId={editAddressModal.addressId}
          handleClose={() =>
            setEditAddressModal({ isShow: false, addressId: "" })
          }
          handleConfirm={handleConfirmUpdate}
        />
      )}
      <ConfirmModal
        title={capitalizedFirst(t("address-modal.delete-title"))}
        message={capitalizedFirst(t("address-modal.delete-content"))}
        isShow={deleteAddressModal.isShow}
        handleClose={() =>
          setDeleteAddressModal({ isShow: false, addressId: "" })
        }
        handleConfirm={handleDeleteAddress}
        confirmLabel="global.delete"
      />
      {isCreateModal && (
        <CreateNewAddressModal
          isShow={isCreateModal}
          handleClose={() => setIsCreateModal(false)}
          handleConfirm={handleConfirmCreate}
        />
      )}
    </div>
  )
}
