export const PRODUCT_TYPE = {
  ORIGIN: "ORIGIN",
  FLASH_DEAL: "FLASH_DEAL",
  LIVE_STREAM: "LIVE_STREAM",
}
export interface InputNumberCartType {
  value: number
  max: number
  updateValue: (newValue: number) => void
  confirmDelete: () => void
  confirmChangeType: (newType: string) => void
  typeChange: string
  typeMax: number
  type: string
  stockId: string
}
