import React from "react"
import { t } from "i18next"
import { Rating } from "primereact/rating"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import ImgCustom from "src/components/img-custom"
import "./index.scss"
import { ProductEvaluateRatingViewProps } from "../rating-product-modal/props"

export default function ProductEvaluateRatingView(
  props: ProductEvaluateRatingViewProps
) {
  const { ratingProductData } = props
  return (
    <div className="flex flex-col gap-3 rounded-4 border border-gray-200 bg-white p-4 text-center shadow-sm">
      <div className="flex flex-col gap-3">
        <span className=" text-center text-18 font-semibold leading-7 text-gray-900">
          {capitalizedFirst(t("purchase-history.thanks-for-your-ratings"))}
        </span>
        <span className="text-center text-14 font-normal leading-5 text-gray-600">
          {capitalizedFirst(
            t("purchase-history.please-evaluate-the-store-services")
          )}
        </span>
      </div>
      {ratingProductData?.map((item, index) => (
        <div key={index} className="flex flex-row gap-2 py-1 ">
          <div className="h-[64px] w-[64px] text-center">
            <ImgCustom
              url={item?.productDetail?.productImg as string}
              alt="productImg"
            />
          </div>
          <div className="flex flex-col justify-between gap-2px">
            <span
              id="title_product_name"
              className="text-left text-14 font-semibold leading-5 text-gray-700"
            >
              {item?.productDetail?.productName}
            </span>
            <div className="item-center flex justify-between gap-2">
              <Rating
                readOnly
                className="relative mr-1"
                value={item?.rating}
                cancel={false}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
