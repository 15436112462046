import http from "src/config/interceptors"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const repayOrder = (orderId: string, data: any) => {
  const url = `/order/orders/${orderId}/re-pay`
  return http.put(url, data)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const placeOrderOnline = (data: any) => {
  const url = "/order/orders"
  return http.post(url, data)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const placeOrderOffline = (data: any) => {
  const url = "/order/orders/direct"
  return http.post(url, data)
}
