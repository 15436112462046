import React from "react"
import { ORDER_STEP_VIEW } from "../../../constants/common"
import CheckoutView from "./checkout-view"
import CheckoutHeader from "./checkout-header"
import PaymentCompleteView from "./payment-complete-view"
import { CheckoutProps } from "./types"

export default function Checkout(props: CheckoutProps) {
  const { stepView, updateStepView, typeView, shipAddress, tab } = props

  return (
    <div className="mx-auto flex w-full flex-col">
      {stepView !== ORDER_STEP_VIEW.COMPLETE && stepView !== ORDER_STEP_VIEW.PAYMENT && (
        <CheckoutHeader modeView={stepView} updateStepView={updateStepView} />
      )}
      <div className="mt-1 flex">
        <div className="flex w-full flex-col">
          {stepView === ORDER_STEP_VIEW.CHECKOUT && (
            <CheckoutView
              typeView={typeView}
              shipAddress={shipAddress}
              tab={tab}
            />
          )}
          {stepView === ORDER_STEP_VIEW.COMPLETE && <PaymentCompleteView />}
        </div>
      </div>
    </div>
  )
}
