import { Skeleton } from "primereact/skeleton"
import React from "react"

export default function SkeletonList() {
  return (
    <div className="card w-full">
      <div className="border-round border-1 surface-border p-4">
        <ul className="m-0 list-none p-0">
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="flex">
              <Skeleton size="4rem" className="mr-2"></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
