import React, { useRef } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"
import ImgCustom from "src/components/img-custom"
import { RefundDetailViewType } from "./types"

export default function RefundDetailView(props: RefundDetailViewType) {
  const { refundDetail } = props
  const elRef = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  const formatDate = (date: string | undefined) => {
    if (date) {
      const newTime = new Date(date)?.toLocaleString("en-GB")
      return newTime
    }
  }

  return (
    <>
      <div className="flex flex-col rounded-3 border border-gray-200 bg-white shadow-sm">
        <span className="px-4 py-20px text-18 font-semibold leading-7 text-gray-900">
          {capitalizedFirst(t("refund.request-status-detail"))}
        </span>
        {refundDetail?.status_details?.length ? (
          <div className="h-full w-full px-4 pb-3">
            {refundDetail?.status_details?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item: any, index: number) => (
                <div key={item?.id}>
                  {item?.is_public ? (
                    <div
                      ref={elRef}
                      className={`flex h-full w-full gap-2 ${
                        index !== 0 ? "mt-3" : "mt-2"
                      }`}
                    >
                      <div
                        className="flex flex-col gap-1 pt-10px"
                        style={{
                          height: `${elRef?.current?.clientHeight}`,
                        }}
                      >
                        <span
                          className={`pi pi-circle-fill text-10 text-gray-500 ${
                            index + 1 === refundDetail?.status_details?.length
                              ? "text-orange-dark-400"
                              : ""
                          }`}
                        ></span>
                        {index + 1 !== refundDetail?.status_details?.length ? (
                          <div className="ml-4px mt-4px h-full w-full border-l-2 border-gray-200"></div>
                        ) : null}
                      </div>
                      <div className="flex h-fit w-full flex-col gap-6px">
                        <div className="flex items-center gap-1">
                          <div
                            className={
                              "rounded-5 bg-gray-200 px-2 py-4px text-14 font-medium text-gray-700 "
                            }
                            style={{
                              backgroundColor: item?.status_property?.color,
                              color: item?.status_property?.font_color,
                            }}
                          >
                            {
                              item?.name
                            }
                          </div>
                        </div>
                        <div className="text-14 font-medium text-gray-900">
                          {item?.note}
                        </div>
                        <div className="flex gap-1">
                          {item?.attachments?.map(
                            (attachment: { id: string }, index: number) => {
                              return (
                                <div key={index}>
                                  <ImgCustom
                                    url={attachment?.id}
                                    alt="image"
                                    className="h-[70px] w-[72px] rounded-2 object-cover object-center"
                                  />
                                </div>
                              )
                            }
                          )}
                        </div>
                        <div className="text-14 font-normal leading-5 text-gray-700">
                          {item?.message}
                        </div>
                        <div className="text-12 font-medium text-gray-500">
                          {formatDate(item?.created_at)}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            )}
          </div>
        ) : (
          <div className="p-3 text-16 font-semibold text-gray-500">
            {capitalizedFirst(handleTranslate("global.empty-data"))}
          </div>
        )}
      </div>
    </>
  )
}
