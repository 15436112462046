import http from "src/config/interceptors"

export const getBanners = (
  pageNum: number,
  pageSize: number,
  filterString: string,
  sortString?: string
) => {
  return http.get(
    `/banner/s-banners?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filterString}&sorts=${sortString}`
  )
}

export const getClientBanners = (
  typeBanner: "SHOP_HERO_BANNER" | "SHOP_PROMOTION_SLIDE_BANNER"
) => {
  const localCountry = localStorage.getItem("localRegion")
    ? localStorage.getItem("localRegion")
    : "VN"
  return http.get(
    `/banner/client-s-banners/${typeBanner}?country=${localCountry}`
  )
}

const getBannerTimeZone = (code: string) => {
  switch (code) {
  case "US":
    return "America/New_York"
  case "FR":
    return "Europe/Paris"
  case "JP":
    return "Asia/Tokyo"
  case "DE":
    return "Europe/Berlin"
  default:
    return "Asia/Ho_Chi_Minh"
  }
}

export const getStickyBanner = () => {
  const regionCode = localStorage.getItem("localRegion") || "VN"
  const getTimeZone = getBannerTimeZone(regionCode)
  return http.get(
    `/banner/client-s-banners/SHOP_STICKY_BANNERS?country=${regionCode}&timezone=${getTimeZone}`
  )
}

export const getPopupBanner = () => {
  const regionCode = localStorage.getItem("localRegion") || "VN"
  const getTimeZone = "Asia/Ho_Chi_Minh"
  return http.get(
    `/banner/client-s-banners/SHOP_POPUP_BANNERS?country=${regionCode}&timezone=${getTimeZone}`
  )
}
