import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ImgCustom from "../img-custom"
import RantingsAndReviewsCustom from "../ratings-and-reviews-custom/ratings-and-reviews-custom"
import { RatingControllerApi } from "@soctrip/axios-rating-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE, TYPELINK } from "src/constants/common"
import {
  CommentListType,
  MediaType,
} from "src/pages/shopping/product-detail/props"
import { getProductDetail } from "src/services/shopping-homepage-service/product-detail-service"
import LoadingApproval from "./loading-approval"
import EmptyPage from "src/pages/empty-page"
import botIcon from "src/assets/images/bot-icon.png"
import { convertViToEn } from "src/utils/common"

// const ratingId = "bb1fe640-ab99-11ee-aa4e-9b6c72636825"

export default function IframeApprovalRequest() {
  const { id } = useParams()
  const [ratingData, setRatingData] = useState<CommentListType>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [productData, setProductData] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getListMedia = (medias: any) => {
    const result: MediaType[] = []
    if (medias && medias?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      medias.forEach((i: any) => {
        result.push({
          id: i?.id as string,
          isImage: i?.type.includes("image"),
          type: i?.type,
        })
      })
    }
    return result
  }

  const handleGetRatingById = async () => {
    await new RatingControllerApi(configHeader(SERVICE.RATING))
      .ratingsIdObjectGet(id || "")
      .then((response) => {
        const rating = response?.data?.data
        const autoLabeling = rating?.arr_auto_labeling?.substring(1, (rating?.arr_auto_labeling?.length || 0) - 1)
        if (rating) {
          setRatingData({
            fullName: rating?.created_by?.full_name,
            imgUrl: rating?.created_by?.avatar_thumbnail_url,
            variants: [],
            comment: rating?.content,
            commentDate: rating?.updated_at,
            listImage: getListMedia(rating?.medias),
            valueRating: rating?.rating as number,
            object_type: rating?.object_type,
            object_id: rating?.object_id,
            id: rating?.id,
            username: rating?.created_by?.username,
            arrAutoLabeling: autoLabeling?.split(", ") || [],
          })
        }
        const timeout = setTimeout(() => {
          setIsLoading(false)
        }, 500)
        return () => clearTimeout(timeout)
      })
      .catch(() => {
        const timeout = setTimeout(() => {
          setIsLoading(false)
        }, 500)
        return () => clearTimeout(timeout)
      })
  }

  useEffect(() => {
    handleGetRatingById()
  }, [])

  const handleGetDataProductDetails = async (objectId: string) => {
    await getProductDetail(objectId)
      .then((response) => {
        setProductData(response?.data?.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (ratingData) {
      handleGetDataProductDetails(ratingData?.object_id)
    }
  }, [ratingData])

  const getUrlProduct = () => {
    const productCode = productData?.id?.split("-")?.length ? productData?.id?.split("-")[0]: ""
    return `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/${convertViToEn(productData?.name as string)}/${productData?.id}/${productCode}`
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typeDataImage = (data: any) => {
    if (data?.type === TYPELINK.LINK) {
      if (data?.id?.includes(TYPELINK.DOMAIN_URL_YOUTUBE)) {
        return (
          <iframe
            src={`${data?.id}?autoplay=${1}`}
            allow="autoplay"
            allowFullScreen
            className="h-[400px] w-full object-cover"
          ></iframe>
        )
      }
    } else
      return (
        <div className="h-[400px] w-full">
          <ImgCustom
            className="seo-image h-[400px] w-full object-cover"
            url={data?.id}
            alt="images"
            type="WEB"
          />
        </div>
      )
  }

  return (
    <div className="mx-auto min-h-full w-full max-w-[1200px] bg-gray-50">
      {isLoading ? (
        <div className="w-full p-6">
          <LoadingApproval />
        </div>
      ) : (
        <div className="h-full w-full">
          {productData ? (
            <div className="flex h-full w-full flex-col gap-6 p-4">
              {/* Information user report */}
              {/* <div className="flex w-full items-center gap-4px">
          <div className="h-5 w-5">
            <img src={userAvatar} alt="" />
          </div>
          <div className="text-16 font-semibold">Nhi Nguyen</div>
        </div> */}
              {/* Content*/}
              <div className="flex w-full flex-col gap-4">
                {/* Product information */}
                <div className="flex w-full flex-col gap-4">
                  <a
                    href={getUrlProduct()}
                    target="_blank"
                    className="flex w-full cursor-pointer items-center text-20 font-semibold leading-8 text-gray-900 hover:text-blue-600 md:text-24"
                    rel="noreferrer"
                  >
                    {productData?.name}
                  </a>
                  {/* Descriptions */}
                  <div
                    className="mt-1 w-full text-16 leading-24 text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: productData?.description,
                    }}
                  ></div>
                  {/* Product images */}
                  <div className="flex w-full">
                    {productData?.attachments?.length ? (
                      <div className="grid w-full grid-cols-1 gap-2px sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {productData?.attachments
                          ?.slice(0, 4)
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ?.map((image: any, index: number) => (
                            <div key={index} className="h-[400px] w-full">
                              {typeDataImage(image)}
                            </div>
                          ))}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* Comment */}
                <div className="flex w-full rounded-3 border border-orange-dark-400">
                  <RantingsAndReviewsCustom
                    disableAction={true}
                    imgUrl={ratingData?.imgUrl}
                    fullName={ratingData?.fullName}
                    labelRating={ratingData?.labelRating}
                    variants={ratingData?.variants || []}
                    comment={ratingData?.comment}
                    commentDate={ratingData?.commentDate}
                    listImage={ratingData?.listImage || []}
                    valueRating={ratingData?.valueRating}
                    id={ratingData?.id || ""}
                    object_id={""}
                    object_type={""}
                    username={ratingData?.username}
                  />
                </div>
                { ratingData?.arrAutoLabeling?.length ? <div className="bg-gray-50 p-2 flex flex-row gap-2 flex-wrap">
                  <div className="rounded-full bg-blue-100 border-blue-800 border h-40px w-40px flex items-center justify-center">
                    <img className="h-20px w-20px" src={botIcon} alt="Bot Icon" />
                  </div>
                  {ratingData?.arrAutoLabeling?.map((label, index) =>
                    <div
                      className="bg-gray-200 rounded-2 font-medium p-1 flex items-center gap-2 text-14 text-gray-700"
                      key={index}
                    >
                      {label}
                    </div>
                  )}
                </div> : null}
              </div>
            </div>
          ) : (
            <div className="h-[600px] w-full">
              <EmptyPage message="Not data found" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
