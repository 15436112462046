import { Skeleton } from "primereact/skeleton"
import React from "react"

export default function HeaderLoading() {
  return (
    <div className="flex h-full w-full flex-col gap-2 border border-gray-50 bg-gray-25">
      <Skeleton width="100%" height="10rem"></Skeleton>
      <div className="flex w-full gap-6">
        <Skeleton width="60%" height="7rem"></Skeleton>
        <div className="flex w-[30%] items-end justify-end">
          <Skeleton height="3rem" width="100%"></Skeleton>
        </div>
      </div>
    </div>
  )
}
