import React, { useMemo } from "react"
import { Dropdown } from "primereact/dropdown"
import { InputTextarea } from "primereact/inputtextarea"
import UploadImageCustom from "./upload-image-custom"
import "./index.scss"
import { useTranslation } from "react-i18next"
import { RequestInfoViewType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { isInValidFile } from "src/utils/common"

export default function RequestInfoView(props: RequestInfoViewType) {
  const {
    setSelectedReason,
    selectedReason,
    reasons,
    description,
    updateDescription,
    files,
    updateFiles,
  } = props
  const { t } = useTranslation()

  const isFileError = useMemo(() => {
    let result = false
    files.forEach(i => {
      if(isInValidFile(i.file.type)) result = true
    })
    return result
  }, [files])

  return (
    <div className="flex h-fit w-full flex-col rounded-3 border border-gray-200 bg-white shadow-sm">
      <span className="px-4 py-20px text-18 font-semibold leading-7 text-gray-900">
        {capitalizedFirst(t("refund.request-information"))}
      </span>
      <div className="flex flex-col gap-3 px-4 pb-20px">
        <div className="flex flex-col gap-4px">
          <div className="flex flex-row gap-4px">
            <span className="text-14 font-medium leading-5 text-gray-700">
              {capitalizedFirst(t("refund.reason"))}
            </span>
            <span className="text-red-500">*</span>
          </div>
          <Dropdown
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.value)}
            options={reasons}
            optionLabel="name"
            dropdownIcon
            placeholder={capitalizedFirst(t("refund.select-reason"))}
            className="flex h-[44px] w-full items-center !text-14 font-normal"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-4px">
            <div className="flex flex-row gap-4px">
              <span className="text-14 font-medium leading-5 text-gray-700">
                {capitalizedFirst(t("refund.description"))}
              </span>
              <span className="text-14 font-normal leading-5 text-gray-700">
                ({description.length}/2000)
              </span>
            </div>
            {description.length > 2000 ? (
              <small className="text-12 font-medium leading-5 text-red-500">
                {capitalizedFirst(t("refund.exceed-2000-characters"))}
              </small>
            ) : null}
            <InputTextarea
              placeholder={capitalizedFirst(
                t("refund.description-place-holder")
              )}
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
              rows={5}
              cols={30}
              autoResize
              className="text-14 font-normal"
            />
          </div>
          <UploadImageCustom files={files} updateFiles={updateFiles} />
          {
            isFileError ? <span className="text-14 text-red-500 mt-1">{capitalizedFirst(t("in-valid-file"))}</span> : null
          }
        </div>
      </div>
    </div>
  )
}
