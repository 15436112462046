import React, { useEffect, useMemo, useState } from "react"
import flashSale from "src/assets/images/flash-sale-icon.svg"
import SkeletonLoading from "src/components/skeleton-loading"
import EmptyPage from "src/pages/empty-page"
import { useNavigate } from "react-router-dom"
import { ROUTERS } from "src/constants/router"
import FlashSaleCardView from "./product-flash-sale-card-view"
import {
  FlashSaleProductProps,
  FlashSaleProps,
  FlashSaleRangeType,
} from "./type"

import FlashSaleCountDown from "./flash-sale-countdown"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { HotDealSocketInstance } from "src/utils/hot-deal-socket-instance"
import { FlashDealSettingControllerApi } from "@soctrip/axios-advertising-service"
import useWindowDimensions from "src/utils/screen-size"

export default function ShoppingFlashSale({
  bloom_filter,
  handleReload,
}: FlashSaleProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const [productList, setProductList] = useState<FlashSaleProductProps[]>([])
  const [loadingYourMarket, setLoadingYourMarket] = useState<boolean>(true)
  const [showViewAll, setShowViewAll] = useState<boolean>(false)
  const [isRenderView, setIsRenderView] = useState<boolean>(false)
  const [flashSaleRange, setFlashSaleRange] = useState<FlashSaleRangeType[]>([])
  const [limit, setLimit] = useState<number>(5)
  const [flashSaleRangeCurrent, setFlashSaleRangeCurrent] = useState<
    FlashSaleRangeType | undefined
  >(undefined)
  const ref = React.useRef<HTMLInputElement>(null)
  const [isTimeOut, setIsTimeOut] = useState<boolean>(false)
  const fetchProductList = async () => {
    if (!flashSaleRangeCurrent) return
    const currentTime = new Date()
    const data = {
      start: currentTime.getTime() + flashSaleRangeCurrent?.timeStampStart,
      end: currentTime.getTime() + flashSaleRangeCurrent?.timeStampEnd,
      pageNum: 1,
      pageSize: 6,
      sort: "",
    }
    const localRegion = localStorage.getItem("localRegion") || ""
    await new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchFlashDealsGet(data.start, undefined, "", 0, 6, undefined, {
        headers: {
          region: localRegion,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          setProductList(res?.data?.data?.data)
          checkShouldShowViewAll(res?.data?.data?.data)
          setLoadingYourMarket(false)
        }
      })
      .catch(() => setLoadingYourMarket(false))
  }
  const handleGoToFlashSalePage = () => {
    navigate("/shopping/" + ROUTERS.FLASH_SALE)
  }
  useEffect(() => {
    new FlashDealSettingControllerApi(configHeader(SERVICE.ADVERTISING))
      .getFlashDealsSettingPage(undefined, undefined, undefined, undefined, {
        headers: {
          region: localStorage.getItem("localRegion") || "",
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          const result: FlashSaleRangeType[] = []
          if (res.data?.data?.data[0]?.time_slot_units?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data[0].time_slot_units?.forEach((i: any) => {
              const dateData = new Date(i?.start_at)
              const timeStampStart =
                dateData.getHours() * 1000 * 60 * 60 +
                dateData.getMinutes() * 1000 * 60 +
                dateData.getSeconds() * 1000
              const timeStampEnd = timeStampStart + i?.duration * 60 * 1000
              result.push({
                timeStampStart: timeStampStart,
                timeStampEnd: timeStampEnd,
                notify_before: i?.notify_before,
                notify_end: i?.notify_end,
                duration: i?.duration,
              })
            })
          }
          setFlashSaleRange(result)
        }
      })
  }, [])

  useEffect(() => {
    if (width >= 1024) {
      setLimit(5)
    } else {
      setLimit(4)
    }
  }, [width])

  useEffect(() => {
    if (flashSaleRange.length) {
      const clientTimeData = new Date()
      const currenTimestamp =
        clientTimeData.getHours() * 1000 * 60 * 60 +
        clientTimeData.getMinutes() * 1000 * 60 +
        clientTimeData.getSeconds() * 1000

      flashSaleRange.forEach((i) => {
        if (
          i?.timeStampEnd >= 24 * 60 * 60 * 1000 &&
          (currenTimestamp >= i?.timeStampStart ||
            currenTimestamp < i?.timeStampEnd - 24 * 60 * 60 * 1000)
        ) {
          setFlashSaleRangeCurrent(i)
          setIsRenderView(true)
        } else {
          if (
            currenTimestamp >= i?.timeStampStart &&
            currenTimestamp < i?.timeStampEnd
          ) {
            setFlashSaleRangeCurrent(i)
            setIsRenderView(true)
          }
        }
      })
    }
  }, [flashSaleRange])

  useEffect(() => {
    function initView() {
      if (!HotDealSocketInstance.stompClient) {
        HotDealSocketInstance?._connect()
      }
      fetchProductList()
    }
    if (isRenderView) initView()
  }, [isRenderView, flashSaleRangeCurrent])

  const tartGetDate = useMemo(() => {
    if (isRenderView && flashSaleRangeCurrent && productList.length) {
      const currentTime = new Date()
      currentTime.setHours(0)
      currentTime.setMinutes(0)
      currentTime.setSeconds(0)

      return currentTime.getTime() + flashSaleRangeCurrent.timeStampEnd
    }
    return 0
  }, [isRenderView, flashSaleRangeCurrent, productList])

  useEffect(() => {
    function handleResize() {
      checkShouldShowViewAll(productList)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [productList.length])

  const checkShouldShowViewAll = (productList: FlashSaleProductProps[]) => {
    const width = window.innerWidth
    const thresholds = { sm: 2, md: 3, xl: 5 }
    let minCount = thresholds.xl
    if (width < 1024) {
      minCount = thresholds.md
    }
    if (width < 768) {
      minCount = thresholds.sm
    }
    if (productList.length > minCount) {
      setShowViewAll(true)
    } else {
      setShowViewAll(false)
    }
  }

  if (!isRenderView || !productList.length || isTimeOut) return null

  return (
    <div className="w-full">
      <div className="w-full rounded-4">
        <div className="flex w-full flex-col gap-3 rounded-3 bg-white p-2">
          {/* header */}
          <div className="flex w-full flex-col items-center justify-between sm:flex-row">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1">
                <div>
                  <img src={flashSale} alt="flash-icon" />
                </div>
                <div className="flex items-center break-words text-16 font-bold leading-30 text-orange-dark-500 md:text-20">
                  {capitalizedFirst(t("flash-deals.flash-deals"))}
                </div>
              </div>

              <FlashSaleCountDown
                tartGetDate={tartGetDate}
                updateTimeOut={() => setIsTimeOut(true)}
              />
            </div>
            {showViewAll && (
              <div
                onClick={handleGoToFlashSalePage}
                className="flex cursor-pointer truncate text-12 font-medium text-blue-600 md:text-14"
              >
                {capitalizedFirst(t("global.view-all"))}
              </div>
            )}
          </div>

          {/* content */}
          <div className="flex h-full w-full flex-col">
            {loadingYourMarket ? (
              <div className="h-[600px] w-full">
                <SkeletonLoading limit={5} />
              </div>
            ) : (
              <>
                {productList?.length ? (
                  <div
                    ref={ref}
                    className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5"
                  >
                    {productList?.slice(0, limit)?.map((pro) => (
                      <FlashSaleCardView
                        key={pro.id}
                        id={pro.id}
                        avatar={pro.avatar}
                        name={pro.name as string}
                        point={pro.point}
                        sum_review={pro.sum_review}
                        price={pro.price as number}
                        price_after_tax={pro.price_after_tax as number}
                        original_price={pro.original_price}
                        original_price_after_tax={pro.original_price_after_tax}
                        quantity={pro.quantity}
                        sub_label={pro.sub_label}
                        handleReload={handleReload}
                        voucherOfProducts={[pro?.voucher as string]}
                        bloom_filter={bloom_filter as string}
                        sold={pro?.sold}
                        flash_deals={pro?.flash_deals}
                        max_sold={pro?.max_sold}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="h-[677px] w-full">
                    <EmptyPage message="Not found data. Please try again later." />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
