/* eslint-disable indent */
import React, { useMemo, useRef } from "react"
import { ItemRatingViewProps, NoteReviewType } from "./props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import ImgCustom from "src/components/img-custom"
import uploadFileIcon from "src/assets/images/upload-file-icon.svg"
import uploadMoreIcon from "src/assets/images/upload-more-file-icon.svg"
import deleteMediaIcon from "src/assets/images/delete-media-icon.svg"
import { fileServices } from "src/services"
import TextLineView from "./text-line-view"
import StarView from "./star-view"
import VideoCustom from "./video-custom"

const StarRating = [1, 2, 3, 4, 5]

const ItemRatingView = (props: ItemRatingViewProps) => {
  const { t } = useTranslation()
  const {
    rating,
    productDetail,
    index,
    notesReview,
    updateRatingItem,
    isRated,
    content,
    medias,
    notes,
  } = props
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])
  const getRateLabel = () => {
    switch (rating) {
      case 1:
        return "purchase-history.rating.terrible"
      case 2:
        return "purchase-history.rating.poor"
      case 3:
        return "purchase-history.rating.fair"
      case 4:
        return "purchase-history.rating.good"
      default:
        return "purchase-history.rating.amazing"
    }
  }
  const labelRateDisplay = useMemo(() => {
    return getRateLabel()
  }, [rating])

  const updateLikeText = (newLikeText: NoteReviewType) => {
    if (!isRated) {
      let result: NoteReviewType[] =
        notes?.map((tValue) => {
          return tValue
        }) || []
      if (!notes?.find((i) => i.id === newLikeText.id)) {
        result.push(newLikeText)
      } else {
        result = notes.filter((i) => i.id !== newLikeText.id)
      }

      if (updateRatingItem) {
        updateRatingItem({
          id: productDetail?.id as string,
          rating: rating,
          object_id: productDetail?.productId as string,
          object_type: "STOCK",
          notes: result,
          content: content,
          medias: medias,
          productDetail: productDetail,
        })
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeFile = (file: any) => {
    if (file?.files && file?.files?.length) {
      const filesToUpload = Array.from(file.files).slice(0,5)
      Promise.all(
        filesToUpload.map((fileToUpload) => {
          return fileServices
            .uploadFile(fileToUpload)
            .then((res) => {
              if (res?.data?.data) {
                return res.data.data
              }
              return null
            })
            .catch((err) => {
              console.log(err)
              return null
            })
        })
      ).then((uploadedMedia) => {
        // Filter out null values (failed uploads) and add the successfully uploaded media to newMediaResult
        const successfulUploads = uploadedMedia.filter(
          (media) => media !== null
        )
        const newMediaResult = medias
          ? [...medias, ...successfulUploads]
          : successfulUploads
        if (updateRatingItem) {
          updateRatingItem({
            id: productDetail?.id as string,
            rating: rating,
            object_id: productDetail?.productId as string,
            object_type: "STOCK",
            notes: notes,
            content: content,
            medias: newMediaResult,
            productDetail: productDetail,
          })
        }
      })
    }
  }
  const fileRef = useRef<HTMLInputElement>(null)

  const handleSelectFile = () => {
    if (isRated) return
    if (fileRef) {
      fileRef?.current?.click()
    }
  }
  const deleteMediaFile = (mediaId: string) => {
    fileServices.deleteFile(mediaId).then(() => {
      const newResult = medias?.filter((i) => i.id !== mediaId) || []
      if (updateRatingItem) {
        updateRatingItem({
          id: productDetail?.id as string,
          rating: rating,
          object_id: productDetail?.productId as string,
          object_type: "STOCK",
          notes: notes,
          content: content,
          medias: newResult,
          productDetail: productDetail,
        })
      }
    })
  }
  const updateRate = (newRate: number) => {
    if (!isRated) {
      if (updateRatingItem) {
        updateRatingItem({
          id: productDetail?.id as string,
          rating: newRate,
          object_id: productDetail?.productId as string,
          object_type: "STOCK",
          notes: notes,
          content: content,
          medias: medias,
          productDetail: productDetail,
        })
      }
    }
  }
  const updateContentChange = (newContent: string) => {
    if (updateRatingItem) {
      updateRatingItem({
        id: productDetail?.id as string,
        rating: rating,
        object_id: productDetail?.productId as string,
        object_type: "STOCK",
        notes: notes,
        content: newContent,
        medias: medias,
        productDetail: productDetail,
      })
    }
  }
  const getVariationDisplay = useMemo(() => {
    let result = ""
    if (productDetail?.variation.length) {
      productDetail.variation.forEach((i, index) => {
        result = index ? result + `/${i.value}` : result + i.value
      })
    }
    return result
  }, [productDetail])
  return (
    <div className={`flex flex-col ${index && "mt-3"}`}>
      <div className="flex border-b-[1px] border-gray-25 pb-3">
        {productDetail && (
          <div className="h-[56px] w-[56px]">
            <ImgCustom
              url={productDetail.productImg}
              alt="productImg"
              className="rounded-2 object-cover"
            />
          </div>
        )}
        <div className="ml-2 flex flex-1 flex-col">
          <span className="text-14 font-semibold leading-20 text-gray-700">
            {productDetail?.productName}
          </span>
          {getVariationDisplay && (
            <span className="mt-2px text-12 font-medium leading-18 text-gray-700">
              <span className="text-gray-500">{`${capitalizedFirst(
                t("shoppingCartPage.variation")
              )} `}</span>
              {getVariationDisplay}
            </span>
          )}
        </div>
      </div>
      <div className="mt-3">
        <span>{capitalizedFirst(t("purchase-history.ratingExperience"))}</span>

        <div className="mt-3 flex flex-col items-center rounded-3 bg-blue-25 py-3">
          <div className="flex items-center justify-center">
            {StarRating.map((i, index) => (
              <StarView
                updateRate={updateRate}
                key={index}
                index={index}
                isCheck={i <= rating}
              />
            ))}
          </div>
          {rating > 0 && (
            <span className="mt-1 text-14 font-medium leading-20 text-gray-500">
              {capitalizedFirst(t(labelRateDisplay))}
            </span>
          )}
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <span>
          {capitalizedFirst(
            t("purchase-history.ratingLike", { getAppTitle: getAppTitle })
          )}
        </span>
        {notesReview && (
          <div className="mt-3 grid grid-cols-2 gap-2 md:grid-cols-4 ">
            {notesReview?.map((n, index) => (
              <TextLineView
                label={n.name}
                id={n.id}
                key={index}
                updateLikeText={updateLikeText}
                isChecked={!!notes?.find((i) => i.id === n.id)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="mt-3 flex flex-col">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {capitalizedFirst(t("purchase-history.rateFeedback"))}
          {isRated ? null : <span className="ml-[2px] text-red-500">*</span>}
        </span>
        <textarea
          onChange={(e) => updateContentChange(e.target.value)}
          readOnly={isRated}
          value={content}
          className="mt-6px resize-none rounded-3 border border-gray-300 py-2 px-14px text-16 leading-24 text-gray-500 outline-none"
        />
      </div>
      {!isRated ? (
        <div className="mt-3 flex justify-start">
          {medias && !medias.length ? (
            <>
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                name="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeFile(e.target)
                }
                multiple
              />
              <button
                onClick={handleSelectFile}
                className="flex items-center justify-center rounded-3 bg-blue-50 px-2 py-1 hover:bg-blue-25"
              >
                <img
                  className="h-[20px] w-[20px]"
                  src={uploadFileIcon}
                  alt="uploadFileIcon"
                />
                <span className="ml-1 text-14 font-semibold leading-20 text-blue-700">
                  {"Photo/video"}
                </span>
              </button>
            </>
          ) : (
            <div className="grid grid-cols-3 gap-2 md:grid-cols-4">
              {medias?.map((i) => (
                <div
                  key={i.id}
                  className="relative h-[116px] w-[116px] md:h-[139px] md:w-[139px]"
                >
                  {i.type.includes("image") ? (
                    <ImgCustom
                      className={"h-full w-full rounded-3 object-cover"}
                      alt="fileItem"
                      url={i.id}
                    />
                  ) : (
                    <VideoCustom type={i.type} idSource={i.id} />
                  )}
                  <div
                    onClick={() => deleteMediaFile(i.id)}
                    className={`absolute top-1 right-1 flex h-[18px] w-[18px] cursor-pointer items-center justify-center rounded-[50%] border ${
                      i.type.includes("image")
                        ? "border border-orange-dark-400 bg-gray-50 text-orange-dark-700"
                        : "border border-orange-dark-400 bg-gray-50 text-orange-dark-700"
                    }`}
                  >
                    <i className="sctr-icon-x-close text-12 font-semibold"></i>
                  </div>
                </div>
              ))}
              {medias && medias?.length < 5 && (
                <div className="h-[116px] w-[116px] md:h-[139px] md:w-[139px]">
                  <input
                    ref={fileRef}
                    className="hidden"
                    type="file"
                    name="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeFile(e.target)
                    }
                  />
                  <button
                    onClick={handleSelectFile}
                    className="flex h-full w-full flex-col items-center justify-center rounded-3 border border-dashed border-gray-300"
                  >
                    <img
                      className="h-[24px] w-[24px]"
                      src={uploadMoreIcon}
                      alt="uploadFileIcon"
                    />
                    <span className="mt-3 text-10 leading-16 text-gray-500">{`${medias.length}/5`}</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
      {isRated ? (
        <div className="mt-3 flex">
          {medias && !medias.length ? (
            <>
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                name="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeFile(e.target)
                }
              />
              <button
                onClick={handleSelectFile}
                className="flex items-center justify-center rounded-3 bg-blue-50 px-2 py-1 hover:bg-blue-25"
              >
                <img
                  className="h-[20px] w-[20px]"
                  src={uploadFileIcon}
                  alt="uploadFileIcon"
                />
                <span className="ml-1 text-14 font-semibold leading-20 text-blue-700">
                  {"Photo/video"}
                </span>
              </button>
            </>
          ) : (
            <div className="grid grid-cols-4 gap-2">
              {medias?.map((i) => (
                <div
                  key={i.id}
                  className="relative h-[128px] w-[128px] rounded-3"
                >
                  {i.type.includes("image") ? (
                    <ImgCustom
                      className={"h-full w-full rounded-3 object-cover"}
                      alt="fileItem"
                      url={i.id}
                    />
                  ) : (
                    <VideoCustom type={i.type} idSource={i.id} />
                  )}
                  {!isRated ? (
                    <div
                      onClick={() => deleteMediaFile(i.id)}
                      className={`absolute right-1 top-1 flex h-[16px] w-[16px] cursor-pointer items-center justify-center rounded-[50%] border ${
                        i.type.includes("image")
                          ? "border-white"
                          : "border-transparent bg-gray-50"
                      }`}
                    >
                      <img
                        src={deleteMediaIcon}
                        className="z-[2]"
                        alt="deleteIcon"
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
      <div className="h-[1px] w-full bg-gray-25" />
    </div>
  )
}

export default ItemRatingView
