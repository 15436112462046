import React, { useState } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import StarView from "../rating-product-modal/star-view"
import { ShipRatingModalType } from "./props"
import { useTranslation } from "react-i18next"
const StarRating = [1, 2, 3, 4, 5]

export default function RatingShipperView(props: ShipRatingModalType) {
  const { feedbackData, ratingData } = props
  const [rating, setRating] = useState<number>(5)
  const [contentFeedback, setContentFeedBack] = useState<string>("")
  const { t } = useTranslation()

  const updateRate = (newRate: number) => {
    setRating(newRate)
    ratingData(newRate)
  }
  const updateContentFeedback = (value: string) => {
    setContentFeedBack(value)
    feedbackData(value)
  }
  return (
    <div className="w-full">
      <div className="flex flex-col gap-3 p-3 text-center">
        <span className="text-18 font-semibold leading-7 text-gray-900">
          {capitalizedFirst(
            t("purchase-history.reviewing-your-delivery-experience")
          )}
        </span>
      </div>
      <div className="flex flex-row justify-center rounded-3 bg-blue-25 py-3 text-center">
        {StarRating.map((i, index) => (
          <StarView
            updateRate={updateRate}
            key={index}
            index={index}
            isCheck={i <= rating}
          />
        ))}
      </div>
      <div className="mt-3 flex flex-col">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {capitalizedFirst(t("purchase-history.rateFeedback"))}
        </span>
        <textarea
          onChange={(e) => updateContentFeedback(e.target.value)}
          value={contentFeedback}
          placeholder={capitalizedFirst(t("global.enter-feedback-here"))}
          maxLength={100}
          className="mt-6px resize-none rounded-3 border border-gray-300 py-2 px-14px text-16 leading-24 text-gray-500 outline-none"
        />
        <span className="w-full pt-4px text-right text-12 font-normal leading-18 text-gray-600">
          {capitalizedFirst(
            t("global.characters-limit", { value: contentFeedback?.length })
          ) }
        </span>
      </div>
    </div>
  )
}
