import http from "src/config/interceptors"

export interface BuyNowVoucherType {
  liveId: string
  productId: string
  shopId: string
}
export const getBuyNowVoucher = (data: BuyNowVoucherType) => {
  return http.get(
    `/promotion/claim-vouchers/my-claim-vouchers/products?live_id=${data.liveId}&product_id=${data.productId}&shop_id=${data.shopId}`
  )
}
