import { RadioButtonChangeEvent } from "primereact/radiobutton"
import React, { useEffect, useState } from "react"
import { filterRating } from "src/constants/fake-data/merchant-page-search-data"
import { Category, FilterFunction } from "src/pages/merchant-search-page/props"
import "./index.scss"
import { GetCategoryFilterOption } from "src/services/merchant-service"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { CategoryOptionProperties } from "./props"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { Rating } from "primereact/rating"
import { InputNumber } from "primereact/inputnumber"
import { convertCurrency } from "src/utils/convert-currency"

export default function MerchantFilter({
  handleFilterData,
  shopId,
}: FilterFunction) {
  const rows = 5
  const [checkedRating, setCheckedRating] = useState<string>("0")
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [showOptionCategory, setShowOptionCategory] = useState<boolean>(false)
  const [showOptionRating, setShowOptionRating] = useState<boolean>(false)
  const [showOptionPriceRange, setShowOptionPriceRange] =
    useState<boolean>(false)
  const [categories, setCategories] = useState<CategoryOptionProperties[]>()
  const [limit, setLimit] = useState<number>(rows)
  const [limitRating, setLimitRating] = useState<number>(3)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [priceValue, setPriceValue] = useState<any>([0, 50000000])
  const [textPriceFilter, setTextPriceFilter] = useState<string>("")
  const [textRatingFilter, setTextRatingFilter] = useState<string>("")
  const [textCategoryFilter, setTextCategoryFilter] = useState<string>("")

  // push to parent component
  useEffect(() => {
    const getData = async () => {
      await Promise.all([
        textCategoryFilter,
        textPriceFilter,
        textRatingFilter,
      ]).then((response) => {
        handleFilterData({
          price: response[1],
          category: response[0],
          rating: response[2],
        })
      })
    }
    getData()
  }, [textPriceFilter, textRatingFilter, textCategoryFilter])

  const getCategoryFilterOption = async (shopId: string) => {
    await GetCategoryFilterOption(
      encodeURIComponent(`shop_id==${shopId},is_used==true`)
    )
      .then((res) => {
        if (res) {
          setCategories(res?.data?.data?.data)
        }
      })
      .catch(() => {
        setCategories([])
      })
  }

  const handleResetFilter = () => {
    setTextCategoryFilter("")
    setCheckedRating("0")
    setSelectedCategories([])
    setPriceValue([0, 50000000])
  }

  // Category selected handle
  const onCategoryChange = (e: CheckboxChangeEvent) => {
    let _selectedCategories = [...selectedCategories]

    if (e.checked) _selectedCategories.push(e.value)
    else
      _selectedCategories = _selectedCategories.filter(
        (category: { id: string }) => category.id !== e.value.id
      )

    setSelectedCategories(_selectedCategories)
  }

  const onRatingChange = (e: CheckboxChangeEvent) => {
    if (e.checked) setCheckedRating(e.value)
    else {
      setCheckedRating("0")
    }
  }

  const handleMoreCategory = () => {
    setLimit(limit + 100)
  }
  const handleLessCategory = () => {
    setLimit(rows)
  }

  useEffect(() => {
    if (shopId) {
      getCategoryFilterOption(shopId)
    }
  }, [shopId])

  // convert to text filter price
  useEffect(() => {
    const timeout = setTimeout(() => {
      const textMin = `price>=${priceValue[0]}`
      const textMax = `price<=${priceValue[1]}`
      const textPriceFilters = [textMin, textMax].join()
      setTextPriceFilter(textPriceFilters)
    }, 300)
    return () => clearTimeout(timeout)
  }, [priceValue])

  // convert to text filter rating
  useEffect(() => {
    const timeout = setTimeout(() => {
      const textRatingFilters = `point>=${checkedRating}`
      setTextRatingFilter(textRatingFilters)
    }, 200)
    return () => clearTimeout(timeout)
  }, [checkedRating])

  // convert to text filter category
  useEffect(() => {
    const timeout = setTimeout(() => {
      let textCategoryFilters = ""
      const categoryIds: string[] = []
      selectedCategories?.map((category: Category) => {
        categoryIds.push(category?.id)
      })
      if (categoryIds.length) {
        textCategoryFilters = "global_leaf_id.keyword==" + categoryIds?.join("|")
        setTextCategoryFilter(textCategoryFilters)
      } else {
        setTextCategoryFilter("")
      }
    }, 200)
    return () => clearTimeout(timeout)
  }, [selectedCategories])

  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }
  
  return (
    <div className="relative max-w-[1200px] rounded-3">
      <div className="flex w-full flex-col">
        {/* header */}
        <div className="flex w-full items-center justify-between border-b p-3">
          <div className="text-20 font-semibold text-gray-800">
            {capitalizedFirst(handleTranslate("global.filter"))}
          </div>
          <div
            onClick={handleResetFilter}
            className="cursor-pointer text-14 font-semibold text-blue-700 hover:underline active:text-blue-500"
          >
            {capitalizedFirst(handleTranslate("global.reset-filter"))}
          </div>
        </div>
        <div className="relative flex max-h-[1200px] w-full flex-col gap-3 overflow-y-auto p-3">
          {/* Price range */}
          <div className="flex w-full flex-col gap-1">
            <div className="w-full">
              <div
                onClick={() => setShowOptionPriceRange(!showOptionPriceRange)}
                className="flex w-full cursor-pointer items-center justify-between"
              >
                <div className="truncate text-16 font-semibold text-gray-700">
                  {capitalizedFirst(
                    handleTranslate("merchant-page.price-range")
                  )}
                </div>
                <div className="flex items-center justify-center">
                  {showOptionPriceRange ? (
                    <div className="flex items-center">
                      <span className="pi pi-angle-down"></span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <span className="pi pi-angle-up"></span>
                    </div>
                  )}
                </div>
              </div>
              {showOptionPriceRange ? (
                <div className="mb-4 flex flex-col gap-1 text-14">
                  <div className="flex items-center gap-1">
                    <div>Min:</div>
                    <div className="text-12 font-medium text-gray-600">
                      {convertCurrency(
                        Number(priceValue?.toString().split(",")[0])
                      )}
                    </div>
                  </div>
                  <InputNumber
                    inputClassName="w-[72px] text-14"
                    className="h-[28px]"
                    value={priceValue[0]}
                    onChange={(e) => {
                      setPriceValue([e.value, priceValue[1]])
                    }}
                    min={0}
                    max={200000000}
                  />
                  <div className="flex items-center gap-1">
                    <p>Max:</p>
                    <p className="text-12 font-medium text-gray-600">
                      {convertCurrency(
                        Number(priceValue?.toString().split(",")[1])
                      )}
                    </p>
                  </div>
                  <InputNumber
                    inputClassName="w-[72px] text-14"
                    className="h-[28px]"
                    value={priceValue[1]}
                    onChange={(e) => setPriceValue([priceValue[0], e.value])}
                    min={0}
                    max={200000000}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {/* Rating */}
          <div className="w-full">
            <div
              onClick={() => setShowOptionRating(!showOptionRating)}
              className="flex w-full cursor-pointer items-center justify-between"
            >
              <div className="truncate text-16 font-semibold text-gray-700">
                {capitalizedFirst(
                  handleTranslate("merchant-page.rating-filter")
                )}
              </div>
              <div className="flex items-center justify-center">
                {showOptionRating ? (
                  <div className="flex items-center">
                    <span className="pi pi-angle-down"></span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span className="pi pi-angle-up"></span>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full">
              {showOptionRating &&
                filterRating?.slice(0, limitRating)?.map((rating, index) => (
                  <div
                    key={index}
                    className="align-items-center flex py-1 text-gray-500"
                  >
                    <Checkbox
                      inputId={rating.startValue.toString()}
                      name="pizza"
                      value={rating.startValue}
                      onChange={(e: RadioButtonChangeEvent) =>
                        onRatingChange(e)
                      }
                      checked={checkedRating == rating.startValue.toString()}
                    />
                    <div className="ml-1">
                      <Rating
                        className="filter-star text-20"
                        cancel={false}
                        readOnly
                        value={rating.startValue}
                      />
                    </div>
                  </div>
                ))}
              {showOptionRating && limitRating < 5 ? (
                <p
                  onClick={() => setLimitRating(5)}
                  className="cursor-pointer py-1 text-12 font-medium text-blue-700"
                >
                  {capitalizedFirst(handleTranslate("shoppingPage.view-more"))}
                </p>
              ) : null}
              {showOptionRating && limitRating >= 5 ? (
                <p
                  onClick={() => setLimitRating(3)}
                  className="cursor-pointer py-1 text-12 font-medium text-blue-700"
                >
                  {capitalizedFirst(handleTranslate("shoppingPage.view-less"))}
                </p>
              ) : null}
            </div>
          </div>

          {/* product category */}
          {categories?.length && (
            <div className="flex w-full flex-col gap-2">
              <div
                onClick={() => setShowOptionCategory(!showOptionCategory)}
                className="flex w-full cursor-pointer items-center justify-between"
              >
                <div className="truncate text-16 font-semibold text-gray-700">
                  {capitalizedFirst(
                    handleTranslate("merchant-page.product-category")
                  )}
                </div>
                <div className="flex items-center justify-center">
                  {showOptionCategory ? (
                    <div className="flex items-center">
                      <span className="pi pi-angle-down"></span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <span className="pi pi-angle-up"></span>
                    </div>
                  )}
                </div>
              </div>
              {showOptionCategory &&
                categories?.slice(0, limit)?.map((category) => {
                  return (
                    <div
                      key={category.id}
                      className="align-items-center flex text-gray-500"
                    >
                      <Checkbox
                        inputId={category.id}
                        name="category"
                        value={category}
                        onChange={onCategoryChange}
                        checked={selectedCategories.some(
                          (item) => item.id === category.id
                        )}
                      />
                      <label
                        htmlFor={category.id}
                        className="ml-2 flex w-full items-center truncate text-14 font-medium"
                      >
                        {category.name}
                      </label>
                    </div>
                  )
                })}
              {limit < categories?.length && showOptionCategory && (
                <div
                  onClick={handleMoreCategory}
                  className="flex cursor-pointer items-center gap-4px py-1 text-12 font-medium text-blue-700"
                >
                  <p>{capitalizedFirst(handleTranslate("global.view-more"))}</p>
                </div>
              )}
              {limit >= categories?.length && categories?.length > 5 && (
                <div
                  onClick={handleLessCategory}
                  className="cursor-pointer py-2 text-12 font-medium text-blue-700"
                >
                  {capitalizedFirst(handleTranslate("global.view-less"))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
