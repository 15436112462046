import React from "react"

import { Dialog } from "primereact/dialog"

interface PromotionDetailModalType {
  handleClose: () => void;
  title: string
  content: string
  isShow: boolean
}

export default function PromotionDetailModal(props: PromotionDetailModalType) {
  const { handleClose, title, content, isShow } = props
  return (
    <Dialog
      id="promotionModal"
      header={title}
      visible={isShow}
      style={{ width: "480px" }}
      onHide={() => handleClose()}
      draggable={false}
    >
      <div className="flex flex-col gap-[12px] px-[24px] pb-[24px]">
        <div
          className="h-full w-full text-16 font-normal leading-[20px] text-gray-600"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
    </Dialog>
  )
}