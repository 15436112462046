import React, { useMemo } from "react"
import ProductOrders from "../product-orders"
import { ProductProperties, ShopOrderProperties } from "src/pages/purchase-history/props"
import { useNavigate } from "react-router-dom"
import ImgCustom from "src/components/img-custom"
import { convertViToEn } from "src/utils/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function ShopOrder(item: ShopOrderProperties) {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const handleGoToShop = (id: string) => {
    const shopCode = id.split("-")?.length ? id.split("-")[0] : ""
    navigate(`/shopping/merchant/${convertViToEn(item.shopName as string)}/${id}/${shopCode}`)
  }

  const isHaveLiveStream = useMemo(() => {
    let result = false
    item?.products.forEach(i => {
      if(i?.livestream_campaign_id || i?.livestream_id) result = true
    })
    return result
  }, [item])

  return (
    <div className="mb-2 flex w-full flex-col border-b border-gray-200">
      {/* header */}
      <div className="mb-1 flex w-full items-center gap-2 border-b py-2 px-2 md:px-3">
        {/* avatar */}
        <div className="h-[30px] w-[30px]">
          <ImgCustom
            isShop={true}
            url={item.avatar}
            alt="product image"
            className="h-[30px] w-[30px] rounded-full object-cover"
          />
        </div>

        <div
          onClick={() => handleGoToShop(item?.shopId)}
          className="flex flex-col md:flex-row cursor-pointer items-center text-16 font-semibold text-gray-700 gap-1"
        >          
          <span className="max-w-[150px] md:max-w-[180px] hover:underline hover:text-blue-700 line-clamp-1">{item.shopName}</span>
          {
            item?.isShowLiveLabel && isHaveLiveStream ? (
              <span className="text-orange-dark-600 font-semibold text-[12px] leading-[16px]">{capitalizedFirst(t("order-history.live-stream-orders"))}</span>
            ) : null
          }
        </div>
      </div>
      {/* content product */}
      {item?.products
        ?.slice(0, item?.limitProduct)
        ?.map((product: ProductProperties, index: number) => (
          <div key={index} className="flex h-[100px] w-full items-center px-2 md:px-3">
            <ProductOrders
              image={product.avatar?.id || ""}
              title={product.name}
              variation1={product.variation1}
              variation2={product.variation2}
              quantity={product.quantity}
              currentPrice={product.current_price || 0}
              oldPrice={product.original_price}
              product_id={product.product_id}
              tax={product.tax}
            />
          </div>
        ))}
    </div>
  )
}
