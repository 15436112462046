import React, { useEffect, useState } from "react"
import { Skeleton } from "primereact/skeleton"
import { SkeletonLoadingProps } from "./props"

export default function SkeletonLoading(props: SkeletonLoadingProps) {
  const { limit, rows, isGrid } = props
  const [displayRows, setDisplayRow] = useState<number>(5)
  useEffect(() => {
    if (rows) {
      setDisplayRow(rows)
    }
  }, [])

  return (
    <div className="flex w-full flex-col">
      <div className={`${isGrid ? "grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5" : "flex h-full w-full" }`}>
        <div className="card w-full">
          <div className="border-round border-1 surface-border surface-card p-2">
            <Skeleton width="100%" height="150px"></Skeleton>
            <div className="justify-content-between mt-3 flex">
              <Skeleton height="2rem" className="w-full"></Skeleton>
            </div>
            <div className="mb-3 mt-2 flex w-full">
              <div className="w-full">
                <Skeleton className="mb-2 w-full"></Skeleton>
                <Skeleton className="mb-2 w-full"></Skeleton>
              </div>
            </div>
          </div>
        </div>
        {(limit as number) >= 2 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}

        {(limit as number) >= 3 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex ">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}

        {(limit as number) >= 4 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex ">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}
        {(limit as number) >= 5 && (
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex ">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {displayRows > 2 && !isGrid ? (
        <div className="flex h-full w-full">
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-full">
            <div className="border-round border-1 surface-border surface-card p-2">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="justify-content-between mt-3 flex">
                <Skeleton height="2rem" className="w-full"></Skeleton>
              </div>
              <div className="mb-3 mt-2 flex w-full">
                <div className="w-full">
                  <Skeleton className="mb-2 w-full"></Skeleton>
                  <Skeleton className="mb-2 w-full"></Skeleton>
                </div>
              </div>
            </div>
          </div>
          {(limit as number) >= 3 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(limit as number) >= 4 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex ">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(limit as number) >= 5 && (
            <div className="card w-full">
              <div className="border-round border-1 surface-border surface-card p-2">
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="justify-content-between mt-3 flex ">
                  <Skeleton height="2rem" className="w-full"></Skeleton>
                </div>
                <div className="mb-3 mt-2 flex w-full">
                  <div className="w-full">
                    <Skeleton className="mb-2 w-full"></Skeleton>
                    <Skeleton className="mb-2 w-full"></Skeleton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}
