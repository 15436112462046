import React, { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { ShopControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { AllProductsTabProps } from "./type"
import EmptyPage from "src/pages/empty-page"
import SkeletonLoading from "../skeleton-loading"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"
import { getBloomFilter } from "src/services/bookmark-service"
import ProductViews from "./products-view"
import useOnScreen from "src/hooks/use-on-screen"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function AllProducts() {
  const { id } = useParams()
  const loadingRef = useRef(null)
  const { t } = useTranslation()
  const isOnScreen = useOnScreen(loadingRef)
  const authStore = useSelector((state: RootState) => state.auth)
  const [loading, setLoading] = useState<boolean>(true)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [totalElement, setTotalElement] = useState<number>(0)
  const [handleShowMoreLoading, setHandleShowMoreLoading] =
    useState<boolean>(false)
  const [catalogData, setCatalogData] = useState<AllProductsTabProps[]>([])
  const [bloomData, setBloomData] = useState<string>("")

  const handleGetAllProducts = async () => {
    await new ShopControllerApi(configHeader(SERVICE.STOCK))
      .shopsCatalogsGet(id, pageIndex, 100)
      .then((response) => {        
        setTotalElement(response?.data?.data?.totalElement)
        setCatalogData(sortCategories(response?.data?.data?.data))
        // if (response.data.data?.totalElement > catalogData?.length)
        //   // eslint-disable-next-line no-unsafe-optional-chaining
        //   setCatalogData([...catalogData, ...response?.data?.data?.data])
        setLoading(false)
        setHandleShowMoreLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (id) {
      handleGetAllProducts()
      setIsFirstLoad(false)
    }
  }, [id])

  const handleReloadData = async () => {
    handleGetBloomData()
  }

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }

  const handleLoadMore = () => {
    setHandleShowMoreLoading(true)
    setPageIndex(pageIndex + 1)
  }

  const sortCategories = useCallback((items: AllProductsTabProps[]): AllProductsTabProps[] => {
    const itemMap: { [key: string]: AllProductsTabProps & { children: AllProductsTabProps[] } } = {}
  
    items.forEach(item => {
      itemMap[item.id] = { ...item, children: [] }
    })
  
    const rootItems: (AllProductsTabProps & { children: AllProductsTabProps[] })[] = []
    items.forEach(item => {
      if (item.parent_id === null) {
        rootItems.push(itemMap[item.id])
      } else {
        itemMap[item.parent_id].children.push(itemMap[item.id])
      }
    })
  
    function sortRecursive(items: (AllProductsTabProps & { children: AllProductsTabProps[] })[]): AllProductsTabProps[] {
      items.sort((a, b) => a.order - b.order)
  
      const sortedItems: AllProductsTabProps[] = []
      items.forEach(item => {
        sortedItems.push(item)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortedItems.push(...sortRecursive(item.children as any))
      })
  
      return sortedItems
    }
  
    return sortRecursive(rootItems)
  }, [])

  useEffect(() => {
    if (isOnScreen && !isFirstLoad && catalogData?.length < totalElement) {
      handleLoadMore()
    }
  }, [isOnScreen])

  useEffect(() => {
    if (pageIndex && !isFirstLoad) handleGetAllProducts()
  }, [pageIndex])

  return (
    <div className="w-full">
      {loading ? (
        <div>
          <SkeletonLoading limit={5} />
        </div>
      ) : (
        <>
          {catalogData?.length ? (
            <div className="flex w-full flex-col">
              {catalogData?.map((data) => (
                <div key={data?.id} className="flex w-full flex-col gap-1">
                  {
                    <>
                      {data?.products?.length ? (
                        <div className="mt-4 flex flex-col gap-1">
                          <div className="text-18 font-semibold text-gray-700">
                            {data?.name}
                          </div>
                          <ProductViews
                            data={data}
                            bloom_filter={bloomData}
                            handleReloadData={handleReloadData}
                          />
                        </div>
                      ) : null}
                    </>
                  }
                </div>
              ))}
            </div>
          ) : (
            <div>
              <EmptyPage />
            </div>
          )}
        </>
      )}
      {pageIndex < 2 ? (
        <div>
          <div ref={loadingRef}></div>
        </div>
      ) : (
        <>
          {!handleShowMoreLoading && (
            <div className="flex items-center justify-center">
              {catalogData?.length < totalElement ? (
                <div
                  onClick={handleLoadMore}
                  className="cursor-pointer text-14 font-medium text-blue-700"
                >
                  {capitalizedFirst(t("global.view-more"))}
                </div>
              ) : null}
            </div>
          )}
        </>
      )}
      {handleShowMoreLoading && (
        <div className="flex justify-center py-4">
          <i
            className="pi pi-spin pi-spinner text-blue-500"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      )}
    </div>
  )
}
