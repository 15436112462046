import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./index.scss"
import { ProductDetailInformationProps } from "./props"
export default function ProductDetailInformation(
  props: ProductDetailInformationProps
) {
  const { t } = useTranslation()
  const { productDetailInformation, productDetailDescription } = props
  const getDetail = useMemo(() => {
    const result: string[] = []
    if (productDetailDescription?.length) {
      productDetailDescription.forEach((s) => {
        const p = s.value
        const c: string[] = []
        s.values.forEach((chill) => {
          c.push(chill.value)
        })
        let resultText = `${p}: `
        c.forEach((i) => {
          resultText = i ? resultText + i : `, ${i}`
        })
        result.push(resultText)
      })
    }
    return result
  }, [productDetailDescription])
  return (
    <div className="rounded-3 bg-white p-3">
      <span className="text-20 font-semibold leading-30 text-gray-800">
        {capitalizedFirst(t("global.about-this-item"))}
      </span>

      <div className="mt-3 flex flex-col px-1">
        <span className="text-18 font-semibold leading-28 text-gray-700">
          {capitalizedFirst(t("global.description"))}
        </span>
        <span
          id="custom-editor"
          className="mt-1 overflow-x-hidden text-16 leading-24 text-gray-600 break-words"
          dangerouslySetInnerHTML={{ __html: productDetailInformation }}
        ></span>
      </div>
      {getDetail.length ? (
        <div className="mt-1 flex flex-col px-1">
          <span className="text-18 font-semibold leading-28 text-gray-700">
            {capitalizedFirst(t("global.product-detail"))}
          </span>
          <ul className="mt-1 px-4 text-16 leading-24 text-gray-600">
            {getDetail.map((i, index) => (
              <li className="list-disc" key={index}>
                {i}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
