import http from "src/config/interceptors"

//category
export const getFilterCategory = (
  pageNum: number,
  pageSize: number,
  filterString?: string,
  language?: string
) => {
  return http.get(
    "/catalog/global-catalogs/search",
    {
      params: {
        pageNum,
        pageSize,
        filters: filterString,
        language: language || "en",
      },
    }
  )
}

//region
export const getFilterRegion = (region: string) => {
  return http.get(`/shipment/address/provinces?countryId=${region}`)
}

//attribute
export const getFilterAttributes = () => {
  return http.get("/stock/product-attributes")
}

//filter
export const getProductSearchFilter = (
  pageNum?: number,
  pageSize?: number,
  filterText?: string,
  sort?: string
) => {
  return http.get(
    `/stock/products/search?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filterText}&sort=${sort}`
  )
}

export const getAdvertisingSearchFilter = (
  campaignId: string,
  filterText?: string,
  pageNum?: number,
  pageSize?: number,
  sort?: string
) => {
  return http.get(
    `/stock/products/search/quick-search?campaign-id=${campaignId}&filters=${filterText}&pageNum=${pageNum}&pageSize=${pageSize}&sort=${sort}`
  )
}

export const getDynamicRegion = (
  pageNum: number,
  pageSize: number,
  filterString?: string
) => {
  if (filterString) {
    return http.get(
      `/shipment/locations/search?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filterString}`
    )
  } else {
    return http.get(
      `/shipment/locations/search?pageNum=${pageNum}&pageSize=${pageSize}`
    )
  }
}
