import * as Stomp from "stompjs"
import SockJS from "sockjs-client"

export const HotDealSocketInstance = {
  webSocketEndPoint: `${process.env.REACT_APP_API_URL}/websocket/ws`,
  topic: "/topic/flash-deals",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stompClient: undefined as any,
  _connect: function () {
    this.stompClient = Stomp.over(new SockJS(this.webSocketEndPoint))

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.stompClient.connect(
      {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (frame: any) {
        console.log(frame)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        self.stompClient.subscribe(self.topic, function (sdkEvent: any) {
          self.onMessageReceived(sdkEvent)
        })
        // _this.stompClient.reconnect_delay = 2000
      },
      self.errorCallBack
    )
  },
  _disconnect: function () {
    if (this.stompClient !== null) {
      this.stompClient.disconnect()
    }
  },

  // on error, schedule a reconnection attempt
  errorCallBack: function (error: string) {
    console.log("errorCallBack -> " + error)
    setTimeout(() => {
      this?._connect()
    }, 5000)
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _send: function (message: any) {
    this.stompClient.send("/app/message", {}, JSON.stringify(message))
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessageReceived: function (message: any) {
    const messageData = message.body

    console.log(messageData, "hotDealsMessage")
  },
}
