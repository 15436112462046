/* eslint-disable @typescript-eslint/no-explicit-any */
import { BloomFilter } from "soctrip-algorithm/dist/api.js"
import { TimeListType } from "src/features/system/props"

export const handlePromiseAll = (promises: any[]) => {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then(
      (val) => ({ status: "fulfilled", data: val }),
      (err) => ({ status: "rejected", reason: err })
    )
  )
  return Promise.all(wrappedPromises)
}

export const handlePromiseAllNoRejected = (promises: any[]) => {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then((val) => ({ status: "fulfilled", data: val }))
  )
  return Promise.all(wrappedPromises)
}

export const handleCheckBloomFilter = (
  bloomData: string,
  itemCheck: string
) => {
  if (bloomData && itemCheck) {
    const bloomFilter = BloomFilter.load(bloomData)
    const exist = bloomFilter.has(itemCheck)
    if (exist) {
      return true
    } else {
      return false
    }
  } else return false
}

export const getThumbnailVideo = (idVideo: string) => {
  return `${process.env.REACT_APP_API_URL}/storage-streaming/files/thumbnail/${idVideo}.webp`
}
export const delay = (delayTime: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayTime))
}

export const handleCheckTime = (startTime: string, endTime: string) => {
  const currentTime = new Date()
  const currentTimeStamp = Math.floor(currentTime.getTime())
  const start = new Date(startTime)
  const startTimeStamp = Math.floor(start.getTime())
  const end = new Date(endTime)
  const endTimeStamp = Math.floor(end.getTime())
  if (currentTimeStamp >= startTimeStamp && currentTimeStamp <= endTimeStamp) {
    return true
  } else {
    return false
  }
}

export const getFlashDealTime = (flashSaleRange: TimeListType[]) => {
  let result = undefined
  if (flashSaleRange.length) {
    const clientTimeData = new Date()
    // clientTimeData.setHours(7)
    const currentTimestamp = clientTimeData.getTime()
    flashSaleRange.forEach((i) => {
      if (
        i?.timeStampEnd >= 24 * 60 * 60 * 1000 &&
        (currentTimestamp >= i?.timeStampStart ||
          currentTimestamp < i?.timeStampEnd - 24 * 60 * 60 * 1000)
      ) {
        result = i as TimeListType
      } else {
        if (
          currentTimestamp >= i?.timeStampStart &&
          currentTimestamp < i?.timeStampEnd
        ) {
          result = i as TimeListType
        }
      }
    })
  }
  return result as undefined | TimeListType
}

export const mathRoundNumber = (value: number) => {
  return Math.round(value * 100) / 100
}

export const convertViToEn = (value: string) => {
  let result = value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
  result = result.replace(/\W+/g, " ")
  result = result.replace(/\s/g, "-")

  return result
}

export const isInValidFile = (typeFile: string) => {
  let result = false
  const blackListFile = ["avif"]
  blackListFile.forEach((i) => {
    if (typeFile.includes(i)) result = true
  })
  return result
}
