import React, { useEffect, useState } from "react"
import { InputText } from "primereact/inputtext"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useDebounce } from "use-debounce"
import { CartType, ObjectDtoType } from "../type"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { updateCart } from "src/features/shopping-cart"
import { CartHeaderProps } from "./types"
import { ORDER_TYPE_MODE } from "src/constants/common"

export default function CartHeader(props: CartHeaderProps) {
  const { updateIsSearch, changeTab, tab } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>("")
  const [isRender, setIsRender] = useState<boolean>(false)
  const handleSearch = (newValue: string) => {
    setSearch(newValue)
  }
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const dispatch = useDispatch()
  const [searchDebounce] = useDebounce(search, 800)

  useEffect(() => {
    if (isRender) handleSearchValue()
  }, [searchDebounce])
  useEffect(() => {
    setIsRender(true)
  }, [])

  const handleSearchValue = () => {
    const newCarts = [] as CartType[]
    shoppingCartStore.cartData.forEach((cart) => {
      const newObjectDto = [] as ObjectDtoType[]
      cart.objectDto.forEach((prod) => {
        let result = false
        if (prod.isSelected) {
          result = true
        } else {
          if (searchDebounce)
            result = prod.title
              .toLocaleLowerCase()
              .includes(searchDebounce.toLocaleLowerCase())
          else result = true
        }
        newObjectDto.push({ ...prod, isDisplay: result })
      })
      newCarts.push({ ...cart, objectDto: newObjectDto })
    })
    updateIsSearch(!!searchDebounce)
    dispatch(updateCart(newCarts))
  }

  const handleChangeTab = (tab: string) => {
    if (search) setSearch("")
    changeTab(tab)
  }
  return (
    <div className="flex gap-3 flex-col-reverse md:flex-row items-start md:items-center justify-between rounded-3 border border-gray-200 bg-white p-3 w-full">
      <div className="flex items-center justify-center gap-[8px] rounded-3 bg-gray-100 p-4px text-[14px] font-semibold leading-[20px] text-gray-500 w-4/5  md:w-fit w-full">
        <div
          onClick={() => handleChangeTab(ORDER_TYPE_MODE.ONLINE)}
          className={`hover-opacity-75 rounded-1 flex h-[36px] text-center px-2 cursor-pointer items-center justify-center ${
            tab === ORDER_TYPE_MODE.ONLINE ? "bg-white text-blue-600" : ""
          }`}
        >
          {capitalizedFirst(t("order-page.online-mode"))}
        </div>
        <div
          onClick={() => handleChangeTab(ORDER_TYPE_MODE.OFFLINE)}
          className={` hover-opacity-50 rounded-1 flex h-[36px] px-2 text-center cursor-pointer items-center justify-center ${
            tab === ORDER_TYPE_MODE.OFFLINE ? "bg-white text-blue-600 rounded-1" : ""
          }`}
        >
          {capitalizedFirst(t("order-page.offline-mode"))}
        </div>
        <div></div>
      </div>
      <div >
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="w-full md:w-[367px] h-[44px]"
            placeholder={capitalizedFirst(t("global.search"))}
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </span>
      </div>
    </div>
  )
}
