import React, { useState, useEffect, useMemo } from "react"
import {
  CartHeader,
  CartView,
  CheckoutView,
} from "src/components/order-cart-view"
import OrderBilling from "src/components/order-cart-view/order-billing"
import {
  ORDER_STEP_VIEW,
  ORDER_TYPE_MODE,
  ORDER_TYPE_VIEW,
} from "src/constants/common"
import {
  OrderBillingType,
  OrderDataType,
} from "src/components/order-cart-view/checkout-view/types"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { delay } from "src/utils/common"
import {
  AddressType,
  BillingAddressDataType,
  ShopInvoiceType,
} from "src/components/order-cart-view/type"
import { useSearchParams } from "react-router-dom"
import PaymentMethodView from "src/components/order-cart-view/payment-method-view"
export default function OrderCart() {
  const [searchParams] = useSearchParams()
  const [orderStepView, setOrderStepView] = useState<string>(
    ORDER_STEP_VIEW.INIT
  )
  const [paymentSelected, setPaymentSelected] = useState<string>("")
  const [isPaymentCod, setIsPaymentCod] = useState<boolean>(false)
  const [paymentName, setPaymentName] = useState<string>("")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orderData, setOrderData] = useState<OrderDataType[]>([])
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [tab, setTab] = useState<string>(ORDER_TYPE_MODE.ONLINE)
  const [orderBilling, setOrderBilling] = useState<
    OrderBillingType | undefined
  >(undefined)
  const [isShowCardForm, setIsShowCardForm] = useState<boolean>(false)
  const [billingAddressData, setBillingAddressData] = useState<
    BillingAddressDataType | undefined
  >(undefined)
  const [invoiceData, setInvoiceData] = useState<ShopInvoiceType | undefined>(
    undefined
  )
  const [shipAddress, setShipAddress] = useState<AddressType | undefined>(
    undefined
  )
  const [paymentMethod, setPaymentMethod] = useState<string>("")
  const [isCallClick, setIsCallClick] = useState<boolean>(false)

  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const updateDataPaymentCard = async () => {
    setIsShowCardForm(false)
    await delay(200)
    setOrderStepView(ORDER_STEP_VIEW.COMPLETE)
  }
  useEffect(() => {
    if (
      orderData.length &&
      isShowCardForm &&
      orderStepView === ORDER_STEP_VIEW.PAYMENT
    )
      updateDataPaymentCard()
  }, [orderData, isShowCardForm])

  const isBillingInvoice = useMemo(() => {
    let result = false
    shoppingCartStore.cartData.forEach((cart) => {
      if (cart.isShopInvoice) {
        result = true
        return
      }
    })
    return result
  }, [shoppingCartStore.cartData])

  useEffect(() => {
    const paymentToken = searchParams.get("token")
    if (paymentToken) {
      setOrderStepView(ORDER_STEP_VIEW.COMPLETE)
    } else {
      setOrderStepView(ORDER_STEP_VIEW.CART)
    }
  }, [])

  if (orderStepView === ORDER_STEP_VIEW.INIT) return null
  return (
    <div className="mx-auto flex max-w-[1200px] flex-col py-3 px-2 xl:px-0">
      {orderStepView === ORDER_STEP_VIEW.CART && (
        <CartHeader
          tab={tab}
          changeTab={(value: string) => setTab(value)}
          updateIsSearch={(value: boolean) => setIsSearch(value)}
        />
      )}
      <div className="mt-1 flex flex-col gap-3 lg:flex-row">
        <div className="flex flex-1 flex-col">
          {/* CART STEP */}
          {orderStepView === ORDER_STEP_VIEW.CART && (
            <CartView stepView={orderStepView} isSearch={isSearch} tab={tab} />
          )}
          {/* CHECKOUT STEP */}
          {orderStepView !== ORDER_STEP_VIEW.CART && (
            <CheckoutView
              typeView={ORDER_TYPE_VIEW.SHOP}
              orderData={orderData}
              billingDetail={orderBilling}
              updatePaymentSelected={(newPayment: string) =>
                setPaymentSelected(newPayment)
              }
              stepView={orderStepView}
              updateStepView={(newStep: string) => setOrderStepView(newStep)}
              setIsPaymentCod={(newValue: boolean) => setIsPaymentCod(newValue)}
              updatePaymentName={(newValue: string) => setPaymentName(newValue)}
              setBillingAddressData={(
                value: BillingAddressDataType | undefined
              ) => setBillingAddressData(value)}
              shipAddress={shipAddress}
              updateShopInvoice={(value: ShopInvoiceType | undefined) =>
                setInvoiceData(value)
              }
              tab={tab}
            />
          )}
          {/* PAYMENT STEP */}
          {orderStepView === ORDER_STEP_VIEW.PAYMENT && (
            <PaymentMethodView 
              getPaymentType={(value:string) => setPaymentMethod(value)}
              stepView={orderStepView}
              updateStepView={(newStep: string) => setOrderStepView(newStep)}
              updateIsCallClick={() => setIsCallClick(true)}
            />
          )}
        </div>
        {orderStepView !== ORDER_STEP_VIEW.COMPLETE &&
        shoppingCartStore.cartData.length ? (
            <OrderBilling
              updateOrderData={setOrderData}
              setOrderBilling={setOrderBilling}
              stepView={orderStepView}
              updateStepView={(step: string) => setOrderStepView(step)}
              paymentSelected={paymentSelected}
              typeView={ORDER_TYPE_VIEW.SHOP}
              isPaymentCod={isPaymentCod}
              showCardForm={() => setIsShowCardForm(true)}
              paymentName={paymentName}
              closeCardForm={() => setIsShowCardForm(false)}
              billingAddressData={billingAddressData}
              isNotInvoice={isBillingInvoice}
              invoiceData={invoiceData}
              updateAddressShip={(value: AddressType) => setShipAddress(value)}
              tab={tab}
              paymentMethodType={paymentMethod}
              isCallClick={isCallClick}
              updateIsCallClick={(value:boolean) => setIsCallClick(value)}
            />
          ) : null}
      </div>
    </div>
  )
}
