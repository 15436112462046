import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Typewriter from "typewriter-effect"
import { useDebounce } from "use-debounce"

import { useTranslation } from "react-i18next"
import HomePageCategories from "src/components/home-page-categories"
import ImgCustom from "src/components/img-custom"
import { configHeader } from "src/config/interceptors"
import { IMAGE_TYPE, LOGO, SERVICE } from "src/constants/common"
import { useClickOutside } from "src/hooks/use-click-outside"
import { getClientBanners } from "src/services/banners-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { handleCheckTime } from "src/utils/common"
import { getKeywords } from "src/utils/get-keyword-suggestion"
import "./index.scss"
import { BannerType } from "./type"
import RegionView from "src/components/header-view/region-view"
import { isProdMode } from "src/utils/app-logo"

export default function ShoppingHomePageHeader() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const clickRef = useRef<HTMLDivElement>(null)
  const [isSuggestionModal, setIsSuggestionModal] = useState(false)
  const [searchValue, setSearchValue] = useState<string>(
    localStorage.getItem("search_history") || ""
  )
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [banner, setBanner] = useState<string>("")
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
  }

  const handleToSearchPage = (keyword?: string) => {
    localStorage.setItem("search_history", keyword || "")
    const uri = searchValue
      ? `/shopping/search?keyword=${encodeURIComponent(keyword || searchValue)}`
      : "/shopping/search"
    navigate(uri, { state: { searchValue } })
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      const index =
        selectedSuggestionIndex == suggestions.length - 1
          ? 0
          : selectedSuggestionIndex + 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "ArrowUp") {
      const index =
        selectedSuggestionIndex == 0
          ? suggestions.length - 1
          : selectedSuggestionIndex - 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "Enter") {
      if (selectedSuggestionIndex !== -1) {
        handleToSearchPage(suggestions[selectedSuggestionIndex])
      } else {
        handleToSearchPage()
      }
    }
  }
  const [searchDebounce] = useDebounce(searchValue, 300)

  const fetchSuggestions = async (keyword: string) => {
    setIsSearching(true)
    try {
      const res = await new StockProductControllerApi(
        configHeader(SERVICE.STOCK)
      ).productsPreflightSearchGet(0, 12, keyword)
      const data = res?.data?.data?.data

      if (data) {
        const keywords = getKeywords(data, keyword)
        setSuggestions(keywords)
      }
      setIsSearching(false)
    } catch (error) {
      setIsSearching(false)
    }
  }

  useEffect(() => {
    const keyword = searchDebounce.trim()
    if (keyword) {
      setSelectedSuggestionIndex(-1)
      fetchSuggestions(keyword)
    }
  }, [searchDebounce])

  const onClickOutside = () => {
    setIsSuggestionModal(false)
  }

  const highlightMatchedText = (text: string, keyword: string) => {
    const startIndex = text
      .toLocaleLowerCase()
      .indexOf(keyword.toLocaleLowerCase())
    if (startIndex === -1) {
      return text
    }

    const endIndex = startIndex + keyword.length
    const prefix = text.slice(0, startIndex)
    const match = text.slice(startIndex, endIndex)
    const suffix = text.slice(endIndex)

    return (
      <>
        {prefix}
        <strong className="font-semibold text-black">{match}</strong>
        {suffix}
      </>
    )
  }

  useClickOutside(clickRef, onClickOutside)

  const handleGetBanners = async () => {
    try {
      const response = await getClientBanners("SHOP_HERO_BANNER")
      const data: BannerType[] = response?.data?.data || []

      // Filter for valid banners
      const validBanners = data.filter(
        (item) =>
          handleCheckTime(item?.start_time, item?.end_time) &&
          item?.medias?.length &&
          item?.published
      )

      // Set the banner to the first valid item's media ID if any exist
      if (validBanners.length > 0) {
        setBanner(validBanners[0]?.medias[0]?.id)
      }
    } catch (error) {
      console.error(error) // Changed to console.error for better error visibility
    }
  }

  useEffect(() => {
    handleGetBanners()
  }, [])

  return (
    <div className="relative mb-[70px] h-full w-full">
      <div className="relative h-[250px] w-full text-gray-200">
        <ImgCustom
          url={banner}
          isBanner={true}
          type={IMAGE_TYPE.WEB}
          alt="banner"
          className="h-full w-full object-cover"
        />
        <div className="absolute top-[30%] left-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-3">
          <div
            className="flex items-center justify-center gap-2 truncate rounded-3 px-3 py-3 font-[Fahkwang700] text-24 font-semibold leading-6 tracking-wider text-white md:text-[34px]"
            style={{ background: "rgba(0, 0, 0, 0.20)" }}
          >
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                deleteSpeed: 10000,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString(`Welcome to ${LOGO.NAME}`)
                  .pauseFor(2000)
                  .deleteAll()
                  .typeString("Get everything you need")
                  .pauseFor(2000)
                  .deleteAll()
                  .start()
              }}
            />
          </div>
        </div>
        {/* REGION VIEW */}
        {
          !isProdMode() ? (
            <>
              <div className="absolute top-[4px] md:top-[12px] right-[8px] md:right-[calc((100vw-1200px)/2+16px)]">
                <RegionView/>
              </div>
            </>
          ) : null
        }

      </div>
      {/* Search Products */}
      <div
        ref={clickRef}
        className="absolute top-[54%] left-1/2 z-[2] flex w-[350px] -translate-x-1/2 -translate-y-1/2 transform sm:w-[450px] md:w-[550px] lg:w-[680px]"
      >
        <span className="p-input-icon-left relative w-full">
          {isSearching ? (
            <i className="pi pi-spin pi-spinner"></i>
          ) : (
            <i className="pi pi-search" />
          )}
          <InputText
            className="flex h-[44px] w-full items-center rounded-3 pl-[60px] text-14 md:text-16"
            placeholder={capitalizedFirst(t("global.search-for-products"))}
            value={searchValue}
            onChange={(event) => {
              handleSearch(event.target.value)
              setIsSuggestionModal(true)
            }}
            onKeyDown={handleKeydown}
            onClick={() => setIsSuggestionModal(true)}
          />
        </span>
        <button
          type="button"
          onClick={() => handleToSearchPage()}
          className="absolute right-4px top-1/2 -translate-y-1/2 transform rounded-3 bg-blue-500 py-6px px-2 text-14 font-medium text-white"
        >
          {capitalizedFirst(t("global.search"))}
        </button>
        {searchValue.trim() && (
          <div className="absolute top-[52px] left-0 right-0 rounded-3 bg-white shadow">
            {suggestions.length > 0 && isSuggestionModal && (
              <ul className="py-3">
                {suggestions.map((suggestion, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleToSearchPage(suggestion)}
                      className={`max-w-full cursor-pointer truncate px-3 py-[4px] text-14 text-gray-700 hover:bg-gray-100 ${
                        selectedSuggestionIndex === index ? "bg-gray-100" : ""
                      }`}
                    >
                      {highlightMatchedText(suggestion, searchValue)}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        )}
      </div>
      <div className="absolute left-1/2 -bottom-[135px] z-[1] mx-auto flex w-full max-w-[1200px] -translate-x-1/2 transform bg-transparent px-3 md:-bottom-[120px]">
        <div className="h-full w-full">
          <HomePageCategories />
        </div>
      </div>
    </div>
  )
}
