import React from "react"
import ApproveInfoView from "src/components/approve-info-view"
import ApproveShopChangeLog from "src/components/approve-shop-change-log"

export default function IframeApproveShop() {
  return (
    <div className="flex flex-col gap-4">
      <div className="h-full w-full p-3 rounded-3 bg-white border">
        <ApproveInfoView />
      </div>

      <div className="h-full w-full p-3 rounded-3 bg-white border">
        <ApproveShopChangeLog />
      </div>
    </div>
  )
}
