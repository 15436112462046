import React, { useEffect, useState } from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import "./index.scss"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { t } from "i18next"
import { CardAddOnDealsProps, ProductDetailAddOnDealProps } from "./props"
import * as StockControllerApi from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import ImgCustom from "src/components/img-custom"
import SkeletonLoading from "src/components/skeleton-loading"
import { useNavigate } from "react-router-dom"
import { useToast } from "src/hooks/use-toast"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { handleCountViewBoth } from "src/services/count-view-service"
import { convertViToEn } from "src/utils/common"
import { convertCurrency } from "src/utils/convert-currency"

export function CardAddOnDeal(props: CardAddOnDealsProps) {
  const { name, price, original_price, avatar, stocks, shopId, quantity, isShopOwner } = props
  const navigate = useNavigate()
  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)

  const getDataAddToCart = () => {
    const result = {
      data: {
        object_type: "PRODUCT",
        entity_id: shopId,
        object_id: props?.id,
        stock_id: "",
        quantity: 1,
      },
    }
    if (stocks.length) {
      result.data.stock_id = stocks[0]?.id
    }
    return result
  }

  const handleClickAddToCart = () => {
    const reqData = getDataAddToCart()
    if (authStore.value) callApiAddToCart(reqData)
    else {
      // Please login before add to cart!
      showToast({
        detail: "Please login before add to cart!",
        severity: "warn",
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callApiAddToCart = (reqData: any) => {
    new CartControllerApi(configHeader(SERVICE.CART))
      .cartsPost(reqData?.data)
      .then(() => {
        showToast({
          detail: "Add product to cart successfully",
          severity: "success",
        })
      })
      .catch(() => {
        showToast({
          detail: "Add product to cart fail",
          severity: "error",
        })
      })
  }

  const handleSelectItem = (id: string, name?: string) => {
    if (authStore?.value) {
      handleCountViewBoth(shopId, id)
    }
    const productCode = id?.split("-")?.length ? id?.split("-")[0]: ""
    navigate(`/shopping/${convertViToEn(name as string)}/${id}/${productCode}`)
  }
  return (
    <div className="flex max-w-[134px] flex-col items-center shadow-sm">
      <div className="h-[134px] w-[134px]">
        <ImgCustom
          url={avatar?.id as string}
          alt={name as string}
          className="h-full w-full transform rounded-4 border object-cover transition duration-[1500ms]
          hover:scale-110"
        />
      </div>

      <div className="mt-2px flex w-full flex-col">
        <span
          id="title-add-on-deals"
          className="h-[32px] w-full max-w-[150px] cursor-pointer text-14 font-semibold leading-4 text-gray-700 hover:text-blue-600"
          onClick={() => handleSelectItem(props?.id, props?.name)}
        >
          {name}
        </span>
        <div className="flex w-full items-center justify-between">
          <div className="flex w-full max-w-[150px] flex-col items-start justify-start rounded-2">
            <span className="flex w-full flex-row gap-2px truncate text-16 font-semibold leading-6 text-orange-dark-600">
              {convertCurrency(price)}
            </span>
            <span className="flex w-full flex-row gap-2px truncate text-12 font-medium leading-5 text-gray-400 line-through">
              {convertCurrency(original_price)}
            </span>
          </div>
          {
            quantity == 0  ? null :
              (<div className="flex h-[46px] items-end">
                <div className={`flex h-[30px] w-[30px] items-center justify-center rounded-2 border px-1  !py-2px  ${isShopOwner?"bg-gray-200":"hover:bg-blue-300 hover:!text-white"} `}>
                  <button
                    disabled={isShopOwner}
                    onClick={() => handleClickAddToCart()}
                    className="mx-2px flex items-center justify-center"
                  >
                    <i className={`pi pi-shopping-cart flex items-center justify-center text-16 ${isShopOwner?"text-gray-300":"text-gray-500"} `} />
                  </button>
                </div>
              </div>)
          }  
        </div>
      </div>
    </div>
  )
}

export default function ProductDetailAddOnDeal(
  props: ProductDetailAddOnDealProps
) {
  const { products_combo , isShopOwner} = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [product, setProduct] = useState<[]>([])
  const products = new StockControllerApi.StockProductControllerApi(
    configHeader(SERVICE.STOCK)
  )
  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true)
      if (products_combo) {
        const localRegion = localStorage.getItem("localRegion") || ""
        products
          .productsListGet(products_combo as string[], {
            headers: {
              region: localRegion,
            },
          })
          .then((res) => {
            if (res?.data?.data?.length) {
              setProduct(res.data.data)
            }
          })
          .catch((e) => console.log(e))
          .finally(() => setIsLoading(false))
      }
    }
    fetchData()
  }, [products_combo])

  if (!products_combo || !products_combo.length) return <></>
  return (
    <div className="rounded-4  border border-gray-200 bg-white px-3 pb-3 shadow-sm">
      <div className="pb-2 pt-1">
        <span className="text-18 font-semibold leading-7 text-gray-700">
          {capitalizedFirst(t("product-details.add-on-deals"))}
        </span>
      </div>
      {isLoading ? (
        <div className="h-fit w-full">
          <SkeletonLoading limit={3} />
        </div>
      ) : (
        <div id="swiper-add-on-deal">
          <Swiper
            direction="horizontal"
            modules={[Navigation]}
            navigation={true}
            pagination={true}
            slidesPerView={"auto"}
            spaceBetween={20}
            className="ml-1 bg-white"
          >
            {product.map((pro: CardAddOnDealsProps, index: number) => (
              <SwiperSlide key={index}>
                <CardAddOnDeal {...pro} shopId={props?.shopId} isShopOwner={isShopOwner} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  )
}
