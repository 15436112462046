import React from "react"
import HeaderView from "src/components/header-view"

const NotLoginView = () => {
  return (
    <div className="flex h-screen w-screen flex-col bg-white">
      <HeaderView />
      <div className="flex flex-1 items-center justify-center text-[16px] font-semibold leading-[24px] text-gray-700">
        <span>{"Please login before watching the livestream"}</span>
      </div>
    </div>
  )
}

export default NotLoginView
