import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { RefundHeaderView } from "./types"
export default function HeaderView(props: RefundHeaderView) {
  const { title } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onBack = () => {
    navigate("/shopping/orders")
  }
  const getTitle = useMemo(() => {
    let result = "global.back"
    if (title) {
      result = `global.${title}`
    }
    return result
  }, [title])
  return (
    <div className="flex items-center gap-3 py-4">
      <button
        className="flex h-[36px] w-[36px] items-center justify-center rounded-3 border border-gray-200 bg-white p-1"
        onClick={onBack}
      >
        <i className="sctr-icon-arrow-narrow-left text-20" />
      </button>
      <span className="text-20 font-semibold leading-[30px] text-gray-900">
        {capitalizedFirst(t(getTitle))}
      </span>
    </div>
  )
}
