import React, { useEffect, useState } from "react"
import { CountDownProps } from "../type"

export default function FlashSaleCountDown({
  target,
  customClass,
  spaceClass,
  tartGetDate,
  updateTimeOut,
  triggerRender,
}: CountDownProps) {
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)
  const [isTimeout, setIsTimeout] = useState<boolean>(false)
  const targetTime = tartGetDate
    ? new Date(tartGetDate)
    : new Date(target as string)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const different = targetTime.getTime() - now.getTime()
      const h = Math.floor(
        (different % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      setHours(h)
      const m = Math.floor((different % (1000 * 60 * 60)) / (1000 * 60))
      setMinutes(m)
      const s = Math.floor((different % (1000 * 60)) / 1000)
      setSeconds(s)
      if (h <= 0 && m <= 0 && s <= m) {
        setIsTimeout(true)
        if (updateTimeOut) updateTimeOut()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [tartGetDate])

  useEffect(() => {
    if((hours || minutes || seconds) && triggerRender) triggerRender()
  }, [hours, minutes, seconds])

  if (isTimeout) return null
  return (
    <div className="flex items-center gap-4px">
      {isTimeout ? (
        <div className="flex items-center gap-1">
          <div
            className={`flex items-center justify-center rounded-1 p-4px px-1 ${
              customClass
                ? `${customClass}`
                : "bg-orange-dark-50 text-14 font-semibold leading-6 text-orange-dark-500 md:text-16"
            }`}
          >
            00
          </div>
          <div
            className={`text-14 font-medium ${
              spaceClass ? `${spaceClass}` : "text-gray-600"
            }`}
          >
            :
          </div>
          <div
            className={`flex items-center justify-center rounded-1 p-4px px-1 ${
              customClass
                ? `${customClass}`
                : "bg-orange-dark-50 text-14 font-semibold leading-6 text-orange-dark-500 md:text-16"
            }`}
          >
            00
          </div>
          <div
            className={`text-14 font-medium ${
              spaceClass ? `${spaceClass}` : "text-gray-600"
            }`}
          >
            :
          </div>
          <div
            className={`flex items-center justify-center rounded-1 p-4px px-1 ${
              customClass
                ? `${customClass}`
                : "bg-orange-dark-50 text-14 font-semibold leading-6 text-orange-dark-500 md:text-16"
            }`}
          >
            00
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-1">
          <div
            className={`flex items-center justify-center rounded-1 p-4px px-1 ${
              customClass
                ? `${customClass}`
                : "bg-orange-dark-50 text-16 font-semibold leading-6 text-orange-dark-500"
            }`}
          >
            {hours <= 9 ? <div>{`0${hours}`}</div> : <div>{hours}</div>}
          </div>
          <div
            className={`text-14 font-medium ${
              spaceClass ? `${spaceClass}` : "text-gray-600"
            }`}
          >
            :
          </div>
          <div
            className={`flex items-center justify-center rounded-1 p-4px px-1 ${
              customClass
                ? `${customClass}`
                : "bg-orange-dark-50 text-16 font-semibold leading-6 text-orange-dark-500"
            }`}
          >
            {minutes <= 9 ? <div>{`0${minutes}`}</div> : <div>{minutes}</div>}
          </div>
          <div
            className={`text-14 font-medium ${
              spaceClass ? `${spaceClass}` : "text-gray-600"
            }`}
          >
            :
          </div>
          <div
            className={`flex items-center justify-center rounded-1 p-4px px-1 ${
              customClass
                ? `${customClass}`
                : "bg-orange-dark-50 text-16 font-semibold leading-6 text-orange-dark-500"
            }`}
          >
            {seconds <= 9 ? <div>{`0${seconds}`}</div> : <div>{seconds}</div>}
          </div>
        </div>
      )}
    </div>
  )
}
