import React from "react"
import { Dialog } from "primereact/dialog"
import { ConfirmModalProps } from "./props"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function ConfirmModal(props: ConfirmModalProps) {
  const { title, message, isShow, handleClose, handleConfirm, confirmLabel } = props
  const { t } = useTranslation()
  const footerContent = (
    <div
      className="mt-3 flex items-center justify-end gap-2 font-semibold"
      id="dialogFooter"
    >
      <button
        onClick={() => handleClose()}
        className="rounded-3 border border-gray-300 px-3 py-1 text-gray-500 hover:text-gray-900"
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        onClick={() => handleConfirm()}
        className="rounded-3 bg-red-500 hover:bg-red-600 px-3 py-1 text-white"
        autoFocus
      >
        {capitalizedFirst(t(confirmLabel))}
      </button>
    </div>
  )
  const headerCustom = (
    <div className="flex text-[20px] font-semibold text-gray-800">
      <span>{title}</span>
    </div>
  )
  return (
    <Dialog
      header={headerCustom}
      visible={isShow}
      style={{ width: "400px" }}
      onHide={() => handleClose()}
      footer={footerContent}
      draggable={false}
    >
      <div className="flex items-center justify-center px-3 text-center">
        <span>{message}</span>
      </div>
    </Dialog>
  )
}
