import http from "src/config/interceptors"

export const getUserInfo = (userId: string) => {
  return http.get(`/user/users/${userId}`)
}

export const createAccount = (email: string) => {
  const requestData = {
    email: email,
  }
  return http.post("/user/quick-signup", requestData)
}

export const getMyRegion = () => {
  return http.get("/regional/me")
}
export const getRegions = () => {
  return http.get(
    "/regional/regional/country?validCountry=true&isAllCountries=false"
  )
}

export const updateUserLanguage = (lng: string, currency: string) => {
  return http.put("/user/language-currency", {
    language: lng,
    currency: currency,
  })
}
