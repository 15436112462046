import React, { useEffect, useMemo, useState } from "react"
import CustomCard from "src/components/card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { CustomCardProps } from "src/components/card-custom/card-custom/custom-card-props"
import EmptyPage from "src/pages/empty-page"
import Loading from "src/components/loading"
import { getBloomFilter } from "src/services/bookmark-service"
import GalleriaHomePage from "src/components/galleria-home-page"
import HomePageMostPopular from "src/components/home-page-most-popular"
import HomePageBestSellingCategory from "src/components/home-page-best-selling-category"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import ShoppingFlashSale from "../shopping-flash-sale"
import HomePageAttribute from "src/components/home-page-attributes"
import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { useInView } from "react-intersection-observer"

export default function ShoppingHomePageContent() {
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const [pageYourMarket, setPageYourMarket] = useState<number>(0)
  const [loadingYourMarket, setLoadingYourMarket] = useState<boolean>(false)
  const [loadMoreYourMarket, setLoadMoreYourMarket] = useState<boolean>(false)
  const [bloomData, setBloomData] = useState<string>("")
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [productList, setProductList] = useState<CustomCardProps[]>([])
  const { ref, inView } = useInView({
    threshold: 0,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  const handleMoreProduct = async (pageSize?: number) => {
    if (productList?.length < totalProducts) {
      const curPage = pageYourMarket + 1
      setPageYourMarket(curPage)
      setLoadMoreYourMarket(true)
      const localRegion = localStorage.getItem("localRegion") || ""
      await new StockProductControllerApi(configHeader(SERVICE.STOCK))
        .productsSearchGet(curPage, pageSize, undefined, undefined, {
          headers: {
            region: localRegion,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          setProductList([...productList, ...res?.data?.data?.data])
          setLoadMoreYourMarket(false)
        })
        .catch(() => {
          setLoadMoreYourMarket(false)
        })
    }
  }

  useEffect(() => {
    if (inView && !isFirstLoad && pageYourMarket < 2) {
      handleMoreProduct(40)
    }
  }, [inView])

  //call api get product list
  useEffect(() => {
    handleGetBloomData()
    setPageYourMarket(0)
    setLoadingYourMarket(true)
    const localRegion = localStorage.getItem("localRegion") || ""
    new StockProductControllerApi(configHeader(SERVICE.STOCK))
      .productsSearchGet(pageYourMarket, 40, undefined, undefined, {
        headers: {
          region: localRegion,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setTotalProducts(res?.data?.data?.totalElement)
        setProductList(res?.data?.data?.data)
        setLoadingYourMarket(false)
        setIsFirstLoad(false)
      })
      .catch(() => {
        setLoadingYourMarket(false)
      })
  }, [])

  // get bloom data
  const handleGetBloomData = async () => {
    if (authStore?.value) {
      await getBloomFilter()
        .then((response) => {
          setBloomData(response?.data?.data?.bloom_filter)
        })
        .catch((error) => error)
    }
  }
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])
  const detectStructView = (index: number) => {
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = productList.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach(i => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === "ONGOGING" || i?.flash_deal_status === "UPCOMING" ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }
  return (
    <div className="mt-6 flex flex-col gap-2">
      <div className="w-full">
        <div className="w-full rounded-4">
          <GalleriaHomePage />
        </div>
      </div>
      <ShoppingFlashSale bloom_filter={bloomData} handleReload={handleGetBloomData} />
      <div className="w-full">
        <div className="w-full rounded-4">
          <HomePageAttribute />
        </div>
      </div>
      <div className="w-full">
        <HomePageMostPopular bloomFilter={bloomData} handleReload={handleGetBloomData} />
      </div>
      <div className="w-full">
        <HomePageBestSellingCategory bloomFilter={bloomData} handleReload={handleGetBloomData} />
      </div>
      <div className="w-full">
        <div className="py-1">
          <p className="text-18 font-semibold leading-8 md:text-24">
            {capitalizedFirst(handleTranslate("shoppingPage.your-market"))}
          </p>
          <div>
            <p className="text-14 font-medium leading-7 text-gray-500 md:text-16">
              {capitalizedFirst(t("shoppingPage.shopping-soctrip", { getAppTitle: getAppTitle }))}
            </p>
          </div>
        </div>
        {loadingYourMarket ? (
          <div className="h-[700px] w-full">
            <Loading />
          </div>
        ) : (
          <>
            {!productList?.length && bloomData ? (
              <div className="flex h-[300px] w-full items-center justify-center">
                <EmptyPage message="No data found." />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                  {productList?.map((pro, index) => (
                    <div key={pro.id}>
                      <div ref={pageYourMarket * 40 + 20 === index ? ref : null}></div>
                      <CustomCard
                        id={pro.id}
                        typeCard={CardCustomType.product}
                        imgUrl={pro.avatar?.id}
                        avatar={pro.avatar}
                        name={pro.name}
                        point={pro.point}
                        sum_review={pro.sum_review}
                        price={pro.price}
                        price_after_tax={pro.price_after_tax}
                        original_price={pro.original_price}
                        original_price_after_tax={pro.original_price_after_tax}
                        quantity={pro.quantity}
                        productAttr={pro.product_attributes}
                        productId={pro.id}
                        shop_id={pro?.shop_id}
                        shop_name={pro.shop?.name}
                        sub_label={pro.sub_label}
                        shop={pro.shop}
                        is_bookmark={pro.is_bookmark}
                        handleReload={handleGetBloomData}
                        voucherOfProducts={[pro?.voucher as string]}
                        bloom_filter={bloomData as string}
                        sold={pro?.sold}
                        is_flash_deal={pro?.is_flash_deal}
                        flash_deal_status={pro?.flash_deal_status}
                        flash_deals={pro?.flash_deals}
                        flash_deals_time={pro?.flash_deals_time}
                        vouchers={pro?.vouchers}
                        structView={detectStructView(index)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {!loadMoreYourMarket && (
        <div className="flex items-center justify-center">
          {productList?.length < totalProducts ? (
            <div
              onClick={() => handleMoreProduct(40)}
              className="cursor-pointer py-2px text-12 font-medium text-blue-700 md:py-1 md:text-14"
            >
              {capitalizedFirst(t("shoppingPage.view-more"))}
            </div>
          ) : null}
        </div>
      )}

      {loadMoreYourMarket && (
        <div className="flex justify-center py-4">
          <i className="pi pi-spin pi-spinner text-blue-500" style={{ fontSize: "1.5rem" }}></i>
        </div>
      )}
    </div>
  )
}
