import React from "react"

import { HeaderImageCustomProps } from "./props"
import { ImgCustomType } from "src/constants/constants/image-custom-type"
import ImgCustom from "src/components/img-custom"
import { IMAGE_TYPE } from "src/constants/common"

export default function MerchantHeaderCustom(props: HeaderImageCustomProps) {
  const { typeImg, imgUrl } = props

  return (
    <div>
      {typeImg?.toLocaleUpperCase() === ImgCustomType.image && (
        <div className="flex min-h-[360px] w-full items-center rounded-t-3 bg-blue-light-100 object-cover object-center">
          {imgUrl && (
            <ImgCustom
              url={imgUrl}
              alt="header"
              className="max-h-[400px] min-h-[360px] w-full rounded-t-3 object-cover object-center"
              type={IMAGE_TYPE.WEB}
            />
          )}
        </div>
      )}
    </div>
  )
}
