import React, { useEffect } from "react"
import { InputText } from "primereact/inputtext"
import { UserFormType } from "../types"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import * as userService from "src/services/user-service"
import "./index.scss"
import { useTranslation } from "react-i18next"
const UserInformationForm = (props: UserFormType) => {
  const { t } = useTranslation()
  const { dataForm } = props
  const authStore = useSelector((state: RootState) => state.auth)

  const getUserInfo = () => {
    if (authStore?.value?.user?.id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userService.getUserInfo(authStore.value.user.id).then((res: any) => {
        if (res?.data?.data) {
          dataForm?.setFieldValue("user_name", res.data.data?.full_name)
          dataForm?.setFieldValue("user_email", res.data.data?.email)
          dataForm?.setFieldValue("user_phone", res.data.data?.phone)
        }
      })
    }
  }
  const isInValidField = (name: string) => {
    return !!(dataForm?.touched[`${name}`] && dataForm?.errors[`${name}`])
  }
  useEffect(() => {
    if (authStore.value?.user?.id) {
      getUserInfo()
    }
  }, [authStore.value?.user?.id])

  if (!authStore.value?.user?.id) return null

  return (
    <div className="rounded mt-3 w-full rounded-3 border-gray-200 bg-white p-3">
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {t("request-open-page.user-information")}
            </span>
          </div>
        </div>
        <div className="ml-4 flex w-full lg:w-[760px] flex-col">
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.full-name")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <InputText
              className={`mt-[6px] w-full ${
                isInValidField("user_name") ? "p-invalid" : ""
              } `}
              placeholder={t("request-open-page.enter-full-name") || ""}
              name="user_name"
              value={dataForm?.values?.user_name}
              onChange={(e) => {
                if (!dataForm?.touched?.user_name)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ user_name: true },
                  })
                dataForm.setFieldValue("user_name", e.target.value)
              }}
            />
            {dataForm.errors.user_name && dataForm.touched.user_name && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.user_name}
              </span>
            )}
          </div>
          <div className="mt-3 flex md:flex-row flex-col gap-3">
            <div className="flex flex-1 flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {"Email "}
                <span className="text-red-600">{" *"}</span>
              </span>
              <InputText
                className={`mt-[6px] w-full ${
                  isInValidField("user_email") ? "p-invalid" : ""
                } `}
                placeholder={t("request-open-page.enter-email") || ""}
                name="user_email"
                value={dataForm?.values?.user_email}
                onChange={(e) => {
                  if (!dataForm?.touched?.user_email)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ user_email: true },
                    })
                  dataForm.setFieldValue("user_email", e.target.value)
                }}
              />
              {dataForm.errors.user_email && dataForm.touched.user_email && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.user_email}
                </span>
              )}
            </div>
            <div className="flex flex-1 flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {t("request-open-page.phone")}
                <span className="text-red-600">{"*"}</span>
              </span>
              <InputText
                className={`mt-[6px] w-full ${
                  isInValidField("user_phone") ? "p-invalid" : ""
                } `}
                placeholder={t("request-open-page.enter-phone-number") || ""}
                name="user_phone"
                value={dataForm?.values?.user_phone}
                onChange={(e) => {
                  if (!dataForm?.touched?.user_phone)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ user_phone: true },
                    })
                  dataForm.setFieldValue("user_phone", e.target.value)
                }}
              />
              {dataForm.errors.user_phone && dataForm.touched.user_phone && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.user_phone}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInformationForm
