import http from "src/config/interceptors"

export const getPaymentType = () => {
  const urlFile = "payment/payment-type"
  return http.get(urlFile)
}

interface OrderDataType {
  paymentType: string
  voucherIds: string[]
  products: ProductType[]
  addressId: string
}
interface ProductType {
  stock_id: string
  quantity: number
}

export const handleOrder = (data: OrderDataType) => {
  const urlFile = "/order/orders"
  const resBody = {
    products: data.products,
    payment_method_id: data.paymentType,
    address_id: data.addressId,
    voucher_ids: data.voucherIds,
  }
  return http.post(urlFile, resBody)
}

export const getPaymentGateway = () => {
  return http.get("/payment/payment-gateway")
}

export const handleCod = (paymentToken: string) => {
  return http.post(`/payment/payments/COD/charge?payment_token=${paymentToken}`)
}
