import axios from "axios"
import http from "src/config/interceptors"
import { IMAGE_TYPE } from "src/constants/common"

// export const getImageFile = (imgId: string) => {
//   const urlFile = `/storage/files/web/${imgId}`
//   return http.get(urlFile, {
//     responseType: "arraybuffer",
//   })
// }

export const getImageUrl = (imgId: string, type?: string) => {
  if (IMAGE_TYPE.WEB === type) {
    return `${process.env.REACT_APP_API_URL}/storage/files/web/${imgId}.webp`
  } else {
    return `${process.env.REACT_APP_API_URL}/storage/files/thumbnail/${imgId}.webp`
  }
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = (file: any) => {
  const formData = new FormData()
  formData.append("file", file)
  const urlFile = `${process.env.REACT_APP_API_URL}/storage/files/upload-file`
  return axios.post(urlFile, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      securitySignature: "Security Signature",
      Authorization: localStorage.getItem("accessToken"),
    },
    timeout: 1000 * 60,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFiles = (files: any) => {
  const formData = new FormData()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files.forEach((f: any) => {
    formData.append("files", f)
  })
  const urlFile = `${process.env.REACT_APP_API_URL}/storage/files/upload-files`
  return axios.post(urlFile, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      securitySignature: "Security Signature",
      Authorization: localStorage.getItem("accessToken"),
    },
    timeout: 1000 * 60,
  })
}

export const deleteFile = (fileId: string) => {
  const urlFile = `/storage/files/${fileId}`
  return http.delete(urlFile)
}
