export const dataShoppingFilterRating = [
  { id: "5", starLabel: "5 Stars", startValue: 5 },
  { id: "4", starLabel: "4 Stars & Up", startValue: 4 },
  { id: "3", starLabel: "3 Stars & Up", startValue: 3 },
  { id: "2", starLabel: "2 Stars & Up", startValue: 2 },
  { id: "1", starLabel: "1 Star & Up", startValue: 1 },
]

export const dataShoppingSort = [
  { id: "az", starLabel: "A -> Z", code: "name" },
  { id: "za", starLabel: "Z -> A", code: "-name" },
  { id: "htl", starLabel: "High to Low", code: "-price|double" },
  { id: "lth", starLabel: "Low to High", code: "price|double" },
]
