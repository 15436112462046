import { StockProductControllerApi } from "@soctrip/axios-stock-service"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { configHeader } from "src/config/interceptors"
import { HOME_MOST, IMAGE_TYPE, SERVICE } from "src/constants/common"
import { useClickOutside } from "src/hooks/use-click-outside"
import { getClientBanners } from "src/services/banners-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { handleCheckTime } from "src/utils/common"
import { getKeywords } from "src/utils/get-keyword-suggestion"
import { useDebounce } from "use-debounce"
import ImgCustom from "../img-custom"
import { BannerType } from "../shopping-homepage-view/shopping-homepage-header/type"

export default function MostHeaderView() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const clickRef = useRef<HTMLDivElement>(null)
  const [isSuggestionModal, setIsSuggestionModal] = useState(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
  const [searchDebounce] = useDebounce(searchValue, 300)
  const [selected, setSelected] = useState<string>("")
  const [banner, setBanner] = useState<string>("")

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
  }

  const handleToSearchPage = (keyword?: string) => {
    const uri = searchValue
      ? `/shopping/search?keyword=${encodeURIComponent(keyword || searchValue)}`
      : "/shopping/search"
    navigate(uri, { state: { searchValue } })
  }

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      const index =
        selectedSuggestionIndex == suggestions.length - 1 ? 0 : selectedSuggestionIndex + 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "ArrowUp") {
      const index =
        selectedSuggestionIndex == 0 ? suggestions.length - 1 : selectedSuggestionIndex - 1
      setSelectedSuggestionIndex(index)
    } else if (event.key === "Enter") {
      if (selectedSuggestionIndex !== -1) {
        handleToSearchPage(suggestions[selectedSuggestionIndex])
      } else {
        handleToSearchPage()
      }
    }
  }

  const onClickOutside = () => {
    setIsSuggestionModal(false)
  }

  const highlightMatchedText = (text: string, keyword: string) => {
    const startIndex = text.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase())
    if (startIndex === -1) {
      return text
    }

    const endIndex = startIndex + keyword.length
    const prefix = text.slice(0, startIndex)
    const match = text.slice(startIndex, endIndex)
    const suffix = text.slice(endIndex)

    return (
      <>
        {prefix}
        <strong className="font-semibold text-black">{match}</strong>
        {suffix}
      </>
    )
  }

  useClickOutside(clickRef, onClickOutside)

  const fetchSuggestions = async (keyword: string) => {
    setIsSearching(true)
    try {
      const res = await new StockProductControllerApi(
        configHeader(SERVICE.STOCK)
      ).productsPreflightSearchGet(0, 12, keyword)
      const data = res?.data?.data?.data

      if (data) {
        const keywords = getKeywords(data, keyword)
        setSuggestions(keywords)
      }
      setIsSearching(false)
    } catch (error) {
      setIsSearching(false)
    }
  }

  const handleGetBanner = async () => {
    try {
      // const response = await getClientBanners("SHOP_PROMOTION_SLIDE_BANNER")
      const response = await getClientBanners("SHOP_HERO_BANNER")
      const data: BannerType[] = response?.data?.data || []
      const validBanners: BannerType[] = []
  
      data.forEach((item) => {
        if (
          handleCheckTime(item?.start_time, item?.end_time) &&
          item?.medias?.length &&
          item?.published
        ) {
          validBanners.push(item)
        }
      })
      if (validBanners.length > 0) {
        setBanner(validBanners[0]?.medias?.[0]?.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const keyword = searchDebounce.trim()
    if (keyword) {
      setSelectedSuggestionIndex(-1)
      fetchSuggestions(keyword)
    }
  }, [searchDebounce])

  useEffect(() => {
    setSelected(searchParams.get("code") as string)
    handleGetBanner()
  }, [])

  return (
    <div className="relative z-10 h-full w-full">
      <div className="h-[224px] w-full">
        <ImgCustom
          url={banner}
          isBanner={true}
          type={IMAGE_TYPE.WEB}
          alt="banner"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col gap-3">
        <div className="item-center flex w-full justify-center gap-1">
          {selected === HOME_MOST.MOST_POPULAR && (
            <div className="font-[AlfaSlabOne-Regular-400] text-[36px] text-white">
              {capitalizedFirst(t("global.most-popular")).toUpperCase()}
            </div>
          )}
          {selected === HOME_MOST.BEST_REVIEW && (
            <div className="font-[AlfaSlabOne-Regular-400] text-[36px] text-white">
              {capitalizedFirst(t("global.best-reviewed")).toUpperCase()}
            </div>
          )}
          {selected === HOME_MOST.BEST_SELLER && (
            <div className="font-[AlfaSlabOne-Regular-400] text-[36px] text-white">
              {capitalizedFirst(t("global.best-seller")).toUpperCase()}
            </div>
          )}
        </div>
        {/* Search Products */}
        <div className="flex w-full items-center justify-center">
          <div ref={clickRef} className="relative w-[70%] max-w-[680px]">
            <span className="p-input-icon-left w-full">
              {isSearching ? (
                <i className="pi pi-spin pi-spinner"></i>
              ) : (
                <i className="pi pi-search" />
              )}
              <InputText
                className="flex h-[44px] w-full items-center rounded-3"
                placeholder={capitalizedFirst(t("global.search-for-products"))}
                value={searchValue}
                onChange={(event) => {
                  handleSearch(event.target.value)
                  setIsSuggestionModal(true)
                }}
                onKeyDown={handleKeydown}
                onClick={() => setIsSuggestionModal(true)}
              />
            </span>
            <button
              type="button"
              onClick={() => handleToSearchPage()}
              className="absolute right-4px top-1/2 -translate-y-1/2 transform rounded-3 bg-blue-500 py-6px px-2 text-14 font-medium text-white"
            >
              {capitalizedFirst(t("global.search"))}
            </button>
            {searchValue.trim() && (
              <div className="absolute top-[52px] left-0 right-0 rounded-3 bg-white shadow">
                {suggestions.length > 0 && isSuggestionModal && (
                  <ul className="py-3">
                    {suggestions.map((suggestion, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleToSearchPage(suggestion)}
                          className={`max-w-full cursor-pointer truncate px-3 py-[4px] text-14 text-gray-700 hover:bg-gray-100 ${
                            selectedSuggestionIndex === index ? "bg-gray-100" : ""
                          }`}
                        >
                          {highlightMatchedText(suggestion, searchValue)}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
