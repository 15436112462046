import React, { useMemo } from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import discountShippingIcon from "src/assets/images/discount-shipping-icon.svg"
import discountVoucherIcon from "src/assets/images/discount-voucher-icon.svg"
import discountSoctripIcon from "src/assets/images/soctrip-i.svg"
import {
  VOUCHER_TYPE,
  VOUCHER_EXPIRED_TIME,
  VOUCHER_EXPIRED_SOON_7_DAY,
  VOUCHER_EXPIRED_SOON_1_DAY,
} from "../../../constants/common"
import "./index.scss"
import { convertTimeCommon } from "src/utils/time-utils"
import { ProgressBar } from "primereact/progressbar"
import ImgCustom from "src/components/img-custom"
import { convertCurrency } from "src/utils/convert-currency"
import { VoucherType } from "../select-voucher-modal/types"

const ItemView = (props: VoucherType) => {
  const { t } = useTranslation()
  const {
    name,
    itemType,
    index,
    minimum_order_price,
    end_date,
    is_limit_usage,
    used_count,
    voucherType,
    maximum_usage_per_user_count,
    shopBilling,
  } = props

  const getExpiredDay = useMemo(() => {
    if(!end_date) return capitalizedFirst(t("shoppingCartPage.voucher-no-expired"))
    const voucherTime = new Date(end_date).getTime()
    if (voucherTime < VOUCHER_EXPIRED_TIME)
      return convertTimeCommon(end_date as string)
    return capitalizedFirst(t("shoppingCartPage.voucher-no-expired"))
  }, [end_date])
  
  const usePercent = useMemo(() => {
    let result = 0
    if (is_limit_usage && used_count && maximum_usage_per_user_count) {
      result = (used_count * 100) / maximum_usage_per_user_count
    }
    return result
  }, [])


  const isExpiringSoon = useMemo(() => {
    if(!end_date) return false
    const voucherTime = new Date(end_date).getTime()
    const currentTime = new Date().getTime()
    if (
      voucherTime < VOUCHER_EXPIRED_TIME &&
      voucherTime - currentTime <= VOUCHER_EXPIRED_SOON_7_DAY
    )
      return true
    return false
  }, [])
  const getIsExpiringData = useMemo(() => {
    let result = 0
    if (!end_date) return false
    const voucherTime = new Date(end_date).getTime()
    const currentTime = new Date().getTime()
    if (
      voucherTime < VOUCHER_EXPIRED_TIME &&
      voucherTime - currentTime <= VOUCHER_EXPIRED_SOON_7_DAY
    ) {
      const expiredDate = (voucherTime - currentTime) / VOUCHER_EXPIRED_SOON_1_DAY
      if(expiredDate >= 1)  {
        const roundedDate = Math.round(expiredDate)
        result = expiredDate > roundedDate ? roundedDate + 1 : roundedDate
      }
      else result = 1
    }
    return result
  }, [])

  const getStyleView = useMemo(() => {
    const commonStyle = "border flex min-w-[374px] cursor-pointer rounded-5"
    const borderView =
      itemType === VOUCHER_TYPE.SHIPPING
        ? "border-blue-500"
        : itemType === VOUCHER_TYPE.DISCOUNT
          ? "border-blue-500"
          : " border-orange-dark-300"
    const bgActiveView = itemType === VOUCHER_TYPE.SHIPPING
      ? "bg-emerald-25"
      : itemType === VOUCHER_TYPE.DISCOUNT
        ? "bg-blue-25"
        : "bg-orange-dark-25"
    const backgroundView = (shopBilling || 0) - minimum_order_price  > 0 ? bgActiveView : "bg-gray-50 opacity-50"
    return {
      wrapperView:
        itemType === VOUCHER_TYPE.SHIPPING
          ? "bg-blue-25"
          : itemType === VOUCHER_TYPE.DISCOUNT
            ? "bg-blue-25"
            : "bg-orange-dark-25",
      contentView: `${commonStyle} ${borderView} ${backgroundView}`,
      lineView: borderView,
    }
  }, [itemType])

  return (
    <div
      className={`rounded-5 ${
        index ? "mt-3" : ""
      }`}
    >
      <div className={getStyleView?.contentView}>
        <div className="relative flex w-[88px] items-center justify-center">
          {voucherType?.icon ? (
            <>
              <div className="h-[48px] w-[48px]">
                <ImgCustom
                  className="h-full w-full"
                  alt="voucherIcon"
                  url={voucherType.icon as string}
                />
              </div>
            </>
          ) : (
            <img
              className="h-[48px] w-[48px]"
              src={
                itemType === VOUCHER_TYPE.SHIPPING
                  ? discountShippingIcon
                  : itemType === VOUCHER_TYPE.DISCOUNT
                    ? discountSoctripIcon
                    : discountVoucherIcon
              }
              alt="discountIcon"
            />
          )}
          <div
            className={`absolute top-[-7px] right-[-6px] h-[12px] w-[12px] rotate-[135deg] rounded-[50%] border ${getStyleView?.lineView} border-b-[transparent] border-l-[transparent] bg-white`}
          />
          <div
            className={`absolute bottom-[-7px] right-[-6px] h-[12px] w-[12px] rotate-[-45deg] rounded-[50%] border ${getStyleView?.lineView} border-b-[transparent] border-l-[transparent] bg-white`}
          />
        </div>
        <div
          className={`my-auto h-[65px] w-[1px] border-l-[1px] border-dashed ${getStyleView?.lineView}`}
        ></div>
        <div className="flex flex-1 p-3">
          <div className="flex flex-1 flex-col">
            <span className="text-16 font-semibold leading-24 text-gray-700">
              {name}
            </span>
            <div className="mt-[2px] flex items-center justify-between text-12 font-medium leading-18 text-gray-700">
              <span>
                {capitalizedFirst(
                  t("checkoutPage.minSpend", {
                    minSpend:
                      convertCurrency(minimum_order_price),
                  })
                )}
              </span>
            </div>
            {is_limit_usage ? (
              <>
                <div className="mt-[2px]" id="voucherProgress">
                  <ProgressBar
                    color={"#DC6803"}
                    value={usePercent}
                    className="max-h-[8px]"
                  />
                </div>
              </>
            ) : null}
            <div className="flex items-center justify-between text-[10px] leading-[16px] text-gray-500">
              {is_limit_usage ? (
                <span>
                  {capitalizedFirst(
                    t("shoppingCartPage.voucher-use", {
                      count: usePercent,
                    })
                  )}
                </span>
              ) : null}

              <span className={isExpiringSoon ? "text-red-600" : ""}>
                {isExpiringSoon
                  ? (
                    getIsExpiringData === 1
                      ? capitalizedFirst(t("checkoutPage.expires-in-one-day", {value: getIsExpiringData}))
                      : capitalizedFirst(t("checkoutPage.expiredSoon", {value: getIsExpiringData}))
                  )
                  : capitalizedFirst(
                    t("checkoutPage.expiredDate", {
                      expiredDate: getExpiredDay,
                    })
                  )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemView
