import React from "react"
import { StockViewType } from "./type"
// import { StockItemView } from "./stock-item-view"
import { StockItemImageView } from "./stock-item-image-view"

export const StockView = (props: StockViewType) => {
  const { data, selectedId, updateSelected } = props

  if (!data) return null
  return (
    <>
      <StockItemImageView
        selectedId={selectedId}
        data={data}
        updateSelected={updateSelected}
      />
    </>
  )
}
