/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react"
import { Dialog } from "primereact/dialog"
import { LoginModalProps } from "./props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { InputText } from "primereact/inputtext"
import logo from "src/assets/images/app-logo-icon.png"
import "./index.scss"
import { Button } from "primereact/button"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === "IFRAME"

export interface IframeShareTokenType {
  url: string
  id: string
  name: string
  index: number
  status: string
  shareState?: string
  updateStatusIframe?: (id: string) => void
}
const STATUS_IFRAME = {
  INIT: "INIT",
  SHARE: "SHARE",
}
const shareTokenDomains = [
  {
    index: 0,
    // url: "http://localhost:4300/share-token",
    url: `${process.env.REACT_APP_FOOD_DOMAIN_URL}/share-token`,
    name: "foodIframe",
    id: "foodIframe",
    status: "INIT",
  },
  {
    index: 1,
    // url: "http://localhost:4200/share-token",
    url: `${process.env.REACT_APP_FOOD_MANAGEMENT_DOMAIN_URL}/share-token`,
    name: "foodMgtIframe",
    id: "foodMgtIframe",
    status: "INIT",
  },
  {
    index: 2,
    // url: "http://localhost:50969/share-token",
    url: `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/share-token`,
    name: "eCommerceMgtIframe",
    id: "eCommerceMgtIframe",
    status: "INIT",
  },
]
export const STATE_SHARE_TOKEN = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
}

export const SHARE_MESSAGE_TYPE = {
  INIT: "INIT",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  RECEIVE_SUCCESS: "RECEIVE_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
}

const RenderIframe = (props: IframeShareTokenType) => {
  const { url, name, id, updateStatusIframe, shareState, status } = props
  const viewRef = useRef(null)
  const authStore = useSelector((state: RootState) => state.auth)
  const handleOnLoad = () => {
    handleSendMessage(SHARE_MESSAGE_TYPE.INIT, "init")
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSendMessage = (messageType: string, data: any) => {
    const frame = document.getElementById(id)
    if (isIFrame(frame) && frame.contentWindow) {
      const message = {
        channel: "AUTH",
        message: {
          type: messageType,
          data: data,
        },
        date: Date.now(),
      }
      frame.contentWindow.postMessage(message, "*")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e: any) => {
    if (e?.data?.message?.type && updateStatusIframe) {
      switch (e.data.message.type) {
        case SHARE_MESSAGE_TYPE.LOGIN_SUCCESS:
          {
            updateStatusIframe(id as string)
          }
          break
        case SHARE_MESSAGE_TYPE.LOGOUT_SUCCESS:
          {
            updateStatusIframe(id as string)
          }
          break
        default:
          break
      }
    }
  }

  const sendMessage = (messageType: string) => {
    switch (messageType) {
      case STATE_SHARE_TOKEN.LOGIN:
        handleSendMessage(SHARE_MESSAGE_TYPE.LOGIN, authStore.value)
        break
      case STATE_SHARE_TOKEN.LOGOUT:
        handleSendMessage(SHARE_MESSAGE_TYPE.LOGOUT, "logout")
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleReceiveMessage)
    return () => window.removeEventListener("message", handleReceiveMessage)
  }, [])

  useEffect(() => {
    if (shareState) {
      if (shareState === STATE_SHARE_TOKEN.LOGIN) {
        if (authStore.value && status === STATUS_IFRAME.INIT) {
          // share token:
          sendMessage(STATE_SHARE_TOKEN.LOGIN)
        }
      } else {
        if (!authStore.value && status === STATUS_IFRAME.INIT) {
          // remove token
          sendMessage(STATE_SHARE_TOKEN.LOGOUT)
        }
      }
    }
  }, [authStore.value])

  return (
    <iframe
      id={id}
      ref={viewRef}
      style={{
        width: 0,
        height: 0,
        position: "absolute",
        left: 0,
        top: 0,
        display: "none",
        visibility: "hidden",
      }}
      width={0}
      height={0}
      title={name}
      src={url}
      onLoad={handleOnLoad}
    />
  )
}

export default function LoginModal(props: LoginModalProps) {
  const { t } = useTranslation()
  const { isShow, handleClose, handleConfirm } = props
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const authStore = useSelector((state: RootState) => state.auth)
  const [iframeViews, setIframeViews] = useState<IframeShareTokenType[]>([])
  const [shareTokenState, setShareTokenState] = useState<string>("")

  const handleChangeEmail = (newEmail: string) => {
    setEmail(newEmail)
  }

  const handleChangePassword = (newPassword: string) => {
    setPassword(newPassword)
  }
  const handleLogin = () => {
    if (email && password) {
      const resData = {
        phone_or_email: email,
        password: password,
        area_code: "",
        device: "",
      }
      handleConfirm(resData)
    }
  }
  const handleSignUp = () => {
    handleClose()
  }
  if (!isShow) return null

  // init Iframe share token
  useEffect(() => {
    const shareState = authStore.value
      ? STATE_SHARE_TOKEN.LOGOUT
      : STATE_SHARE_TOKEN.LOGIN
    setIframeViews(shareTokenDomains)
    setShareTokenState(shareState)
  }, [])

  const updateStatusIframe = (id: string) => {
    const result = iframeViews.find((i) => i.id === id)
    if (result) {
      const newData: IframeShareTokenType[] = []
      iframeViews?.forEach((i) => {
        if (i.id === result.id) {
          result.status = STATUS_IFRAME.SHARE
          newData.push({ ...result })
        } else {
          newData.push({ ...i })
        }
        if (newData.length) setIframeViews(newData)
      })
    }
  }
  useEffect(() => {
    //close modal when share token successfully
    if (iframeViews.length) {
      let itemComplete = 0
      iframeViews?.forEach((i: IframeShareTokenType) => {
        if (i.status === STATUS_IFRAME.SHARE) itemComplete += 1
      })
      if (iframeViews.length && itemComplete === iframeViews.length) {
        setTimeout(() => {
          handleClose()
        }, 200)
      }
    }
  }, [iframeViews])

  return (
    <Dialog
      id="loginModal"
      visible={isShow}
      draggable={false}
      onHide={() => handleClose()}
    >
      <div className="flex w-[500px] flex-col p-4 pt-0">
        <div className="flex flex-col items-center justify-center">
          <img className="h-60px w-[60px]" src={logo} alt="logo" />
          <span className="mt-1 text-24 font-bold text-blue-700">
            {process.env.REACT_APP_TITLE}
          </span>
        </div>
        <span className="mt-3 text-gray-500">
          {capitalizedFirst(t("modal.login-description"))}
        </span>
        <div className="p-input-icon-left mt-3 w-full">
          <i className="pi pi-envelope" />
          <InputText
            value={email}
            onChange={(e) => handleChangeEmail(e.target.value)}
            className="w-full rounded-3"
            placeholder={capitalizedFirst(t("global.email"))}
          />
        </div>
        <div className="p-input-icon-left mt-3 w-full">
          <i className="pi pi-lock" />
          <InputText
            type="password"
            value={password}
            onChange={(e) => handleChangePassword(e.target.value)}
            className="w-full rounded-3"
            placeholder={capitalizedFirst(t("global.password"))}
          />
        </div>
        <div className="mt-3 flex items-center justify-end">
          <span
            onClick={handleSignUp}
            className="cursor-pointer text-gray-500 hover:text-blue-600"
          >
            {capitalizedFirst(t("modal.sign-up"))}
          </span>
        </div>
        <Button
          // disabled={isLoading}
          // loading={isLoading}
          onClick={handleLogin}
          label={capitalizedFirst(t("modal.login"))}
          className="mt-3 flex items-center justify-center rounded-3 bg-blue-600 px-3 py-1 font-medium text-white transition hover:bg-blue-700"
        />
        {iframeViews.map((i) => (
          <RenderIframe
            updateStatusIframe={(iframeId: string) =>
              updateStatusIframe(iframeId)
            }
            shareState={shareTokenState}
            key={i.id}
            {...i}
          />
        ))}
      </div>
    </Dialog>
  )
}
