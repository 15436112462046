import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { SystemVoucherType, initialState } from "./type"
import { CartType } from "src/components/order-cart-view/type"
import { PingProductType } from "src/components/live-streams/types"
export const shoppingCartSlice = createSlice({
  name: "myCart",
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<CartType[]>) => {
      state.cartData = action.payload
    },
    updateTotalCart: (state, action: PayloadAction<number>) => {
      state.totalCart = action.payload
    },
    resetListDataCartLocal: (state) => {
      state.cartData = []
      state.totalCart = 0
    },
    setBuyNowId: (state, action: PayloadAction<string>) => {
      const value = action.payload
        ? `${action.payload}||${new Date().getTime()}`
        : ""
      state.buyNowId = value
    },
    setBuyLiveStream: (
      state,
      action: PayloadAction<PingProductType | undefined>
    ) => {
      const nowTime = new Date().getTime()
      const result = { ...action.payload, time: nowTime } as PingProductType
      state.buyLiveIdData = result
    },
    resetBuyLive: (state) => {
      state.buyLiveIdData = undefined
    },
    updateSystemVoucher: (
      state,
      action: PayloadAction<SystemVoucherType | undefined>
    ) => {
      state.systemVoucher = action.payload
    },
  },
})

export const {
  updateCart,
  updateTotalCart,
  resetListDataCartLocal,
  setBuyNowId,
  updateSystemVoucher,
  setBuyLiveStream,
  resetBuyLive,
} = shoppingCartSlice.actions

export default shoppingCartSlice.reducer
