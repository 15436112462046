import React from "react"
import { useTranslation } from "react-i18next"
import checkoutHeaderIcon from "../../../../assets/images/checkout-header-icon.svg"
import checkoutHeaderActiveIcon from "../../../../assets/images/checkout-header-active-icon.svg"
import shoppingCartHeaderIcon from "../../../../assets/images/shopping-cart-header.svg"
import paymentHeaderActiveIcon from "../../../../assets/images/payment-header-active-icon.svg"
import paymentHeaderDisableIcon from "../../../../assets/images/payment-header-disable-icon.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { ORDER_STEP_VIEW } from "../../../../constants/common"
import { CheckoutHeaderProps } from "./type"

export default function CheckoutHeader(props: CheckoutHeaderProps) {
  const { modeView, updateStepView } = props
  const { t } = useTranslation()

  const changeStepView = (toStep: string) => {
    switch (toStep) {
    case ORDER_STEP_VIEW.CART:
      updateStepView(ORDER_STEP_VIEW.CART)
      break
    case ORDER_STEP_VIEW.CHECKOUT:
      updateStepView(ORDER_STEP_VIEW.CHECKOUT)
      break 
    default:
      break
    }
  }
  return (
    <div className="flex h-fit  md:h-[113px] w-full flex-col items-center justify-center rounded-3 border border-gray-200 bg-white p-3">
      <div className="flex w-full items-center md:w-[559px]">
        <img
          onClick={() => changeStepView(ORDER_STEP_VIEW.CART)}
          className="h-[34px] w-[34px] cursor-pointer"
          src={shoppingCartHeaderIcon}
        />
        <div className="h-[1px] flex-1 bg-blue-600"></div>
        <img
          alt="image"
          onClick={() => changeStepView(ORDER_STEP_VIEW.CHECKOUT)}
          className="h-[34px] w-[34px] cursor-pointer"
          src={modeView === ORDER_STEP_VIEW.PAYMENT ? checkoutHeaderActiveIcon : checkoutHeaderIcon}
        />
        <div
          className={`h-[1px] flex-1 ${
            modeView === ORDER_STEP_VIEW.CHECKOUT ? "bg-gray-300" : "bg-blue-600"
          }`}
        ></div>
        <img
          alt="image"
          className="h-[34px] w-[34px] cursor-pointer"
          src={
            modeView === ORDER_STEP_VIEW.CHECKOUT
              ? paymentHeaderDisableIcon
              : paymentHeaderActiveIcon
          }
        />
      </div>
      <div className="mt-1 hidden md:flex w-full items-center justify-between text-14 font-semibold leading-20 text-blue-600 md:w-[640px]">
        <div className="flex w-fit items-center truncate md:w-[300px]">
          <span className="flex w-[150px] items-center justify-start md:pl-[28px]">
            {capitalizedFirst(t("contentHeader.shoppingCart"))}
          </span>
        </div>
        <div className="flex w-fit items-center justify-center truncate md:w-[300px]">
          {capitalizedFirst(t("contentHeader.delivery"))}
        </div>
        <div
          className={`flex w-fit items-center justify-end truncate md:w-[300px] ${
            modeView === ORDER_STEP_VIEW.CHECKOUT && "text-gray-300"
          }`}
        >
          {capitalizedFirst(t("contentHeader.paymentMethod"))}
        </div>
      </div>
    </div>
  )
}
