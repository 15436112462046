export interface CreateAccountModalType {
  isShow: boolean
  handleClose: () => void
}
export const STEP_VIEW = {
  REGISTER: "REGISTER",
  CONFIRM: "CONFIRM",
}

export interface RegisterFormType {
  updateStateView: () => void
}
