import React, { useState } from "react"
import { Dialog } from "primereact/dialog"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import "./dialog-custom.scss"
import { DialogProperties } from "./dialog-custom-props"

export default function DialogCustom(props: DialogProperties) {
  const {
    children,
    header,
    classLabel,
    position,
    showIconClose,
    visibleShowDialog,
    setVisibleShowDialog,
  } = props

  const [visible, setVisible] = useState<boolean>(false)

  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  return (
    <div>
      <Dialog
        draggable={false}
        header={capitalizedFirst(handleTranslate(header))}
        visible={visibleShowDialog == undefined ? visible : visibleShowDialog}
        onHide={() => {
          setVisibleShowDialog == undefined
            ? setVisible(false)
            : setVisibleShowDialog(false)
        }}
        closable={showIconClose}
        className={classLabel}
        position={position}
      >
        {children}
      </Dialog>
    </div>
  )
}
