declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag?: any
  }
}

export const TAG_TYPE = {
  PAGE_VIEW: "page_view",
  PURCHASE: "purchase",
  VIEW_ITEM: "view_item",
  VIEW_ITEM_LIST: "view_item_list",
  BEGIN_CHECKOUT: "begin_checkout",
  ADD_TO_CART: "add_to_cart",
  SIGN_UP: "sign_up",
  LOGIN: "login",
  SELECT_ITEM: "select_item",
  SEARCH: "search",
  ADD_SHIPPING_INFO: "add_shipping_info",
  ADD_PAYMENT_INFO: "add_payment_info",
  SELECT_PROMOTION: "select_promotion",
  REMOVE_FROM_CART: "remove_from_cart",
  REFUND: "refund",
  ADD_TO_WISHLIST: "add_to_wishlist",
  VIEW_PROMOTION: "view_promotion",
  VIEW_CART: "view_cart",
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDataGaType = (type: string, data: any) => {
  let result = undefined
  // PAGE_VIEW
  if (type === TAG_TYPE.PAGE_VIEW) {
    result = {
      page_location: window.location.href,
      page_title: document.title,
      page_path: window.location.pathname,
      content_id: data?.contentId || "",
      content_type: data?.contentType || "",
    }
  } else {
    result = data
  }
  return result
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gaPostAction = (type: string, data: any) => {
  if (window?.gtag) {
    const gaData = getDataGaType(type, data)
    // console.log(data, type)
    window.gtag("event", type, gaData)
  }
}
