import React from "react"
import { ProductType } from "./type"
import ImgCustom from "src/components/img-custom"
import { TYPELINK } from "src/constants/common"
import { convertCurrency } from "src/utils/convert-currency"

const ProductItemView = (props: ProductType) => {
  const { productPrice, productImg, productName, quantity, variation, index } =
    props
  return (
    <div className={`flex items-center py-3 text-gray-700 ${index && "mt-1"}`}>
      <div className="flex h-[64px] w-[64px]">
        {productImg?.includes(TYPELINK.DOMAIN_URL_YOUTUBE) ? (
          <img
            src={`https://img.youtube.com/vi/${
              productImg?.split("/").slice(-1)[0]
            }/maxresdefault.jpg`}
            alt="productImg"
            className="h-[64px] w-[64px] object-cover"
          />
        ) : (
          <ImgCustom
            className="h-[64px] w-[64px] object-cover"
            alt="productImg"
            url={productImg as string}
          />
        )}
      </div>
      <div className="ml-2 flex flex-1 flex-col">
        <span className="text-12 font-semibold leading-18">{productName}</span>
        <div className="mt-2px flex  items-center text-10 leading-16">
          <span className="font-normal">{"Variation: "}</span>
          <span className="ml-4px font-semibold">{variation}</span>
        </div>
        <span className="mt-2px text-10 font-normal leading-16">{`X${quantity}`}</span>
      </div>
      <div className="ml-4 flex w-[71px] justify-end text-14 font-semibold leading-20">
        {convertCurrency(productPrice)}
      </div>
    </div>
  )
}
export default ProductItemView
