import ImgCustom from "../img-custom"
import React, { useMemo, useRef, useState } from "react"
import moment from "moment"
import { Menu } from "primereact/menu"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export interface NotificationItemViewType {
  id: string
  sender_avatar: string
  sender_full_name: string
  icon_color: string
  icon_image: string
  body: string
  send_time: string
  _read: boolean
  _cta_complete: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cta_action: any
  web_link: string
  module_type: string
  handleDeleteItem: (id: string) => void
  toggleNotification: (id: string) => void
  processAction: (id: string, path: string) => void
}
export const NOTIFICATION_TYPE = {
  REQUEST_NEW_FRIEND: "request_new_friend",
  TAG_IN_POST: "tag_you_in_post",
  TAG_IN_COMMENT: "tag_you_in_comment",
  UPLOAD_EVENT: "upload_event",
  NEW_POST: "new_post",
  REACT_ON_POST: "react_on_post",
  EVENT: "event",
  LIVE_STREAM: "livestream",
  MEMORY: "memory",
  GIFT: "gift",
}
export const MODULE_TYPE = {
  SOCIAL: "SOCIAL",
  SHOP: "SHOP",
  RESTAURANT: "RESTAURANT",
  HOTEL: "HOTEL",
  TRAVEL_TOUR: "TRAVEL_TOUR",
  CAR: "CAR",
}
const NotificationItemView = (props: NotificationItemViewType) => {
  const {
    id,
    sender_avatar,
    icon_color,
    icon_image,
    body,
    sender_full_name,
    send_time,
    _read,
    _cta_complete,
    cta_action,
    web_link,
    module_type,
    handleDeleteItem,
    toggleNotification,
    processAction,
  } = props
  const { t } = useTranslation()
  const [isShowSetting, setIsShowSetting] = useState<boolean>(false)
  const notificationMenuRef = useRef<Menu>(null)
  const getStyleWrapperView = () => {
    switch (icon_color) {
    case NOTIFICATION_TYPE.REQUEST_NEW_FRIEND:
      return {
        background: "bg-yellow-100",
        color: "text-yellow-500",
      }
    case NOTIFICATION_TYPE.TAG_IN_POST:
      return {
        background: "bg-amber-100",
        color: "text-amber-500",
      }
    case NOTIFICATION_TYPE.TAG_IN_COMMENT:
      return {
        background: "bg-blue-100",
        color: "text-blue-500",
      }
    case NOTIFICATION_TYPE.UPLOAD_EVENT:
      return {
        background: "bg-rose-100",
        color: "text-rose-500",
      }
    case NOTIFICATION_TYPE.NEW_POST:
      return {
        background: "bg-violet-100",
        color: "text-violet-500",
      }
    case NOTIFICATION_TYPE.REACT_ON_POST:
      return {
        background: "bg-fuchsia-100",
        color: "text-fuchsia-500",
      }
    case NOTIFICATION_TYPE.EVENT:
      return {
        background: "bg-rose-100",
        color: "text-rose-500",
      }
    case NOTIFICATION_TYPE.LIVE_STREAM:
      return {
        background: "bg-red-100",
        color: "text-red-500",
      }
    case NOTIFICATION_TYPE.MEMORY:
      return {
        background: "bg-teal-100",
        color: "text-teal-500",
      }
    case NOTIFICATION_TYPE.GIFT:
      return {
        background: "bg-orange-100",
        color: "text-orange-500",
      }
    default:
      return {
        background: "bg-blue-100",
        color: "text-blue-500",
      }
    }
  }
  const settingItems = [
    {
      template: () => {
        return (
          <div className="flex flex-col">
            <div
              onClick={(e) => {
                notificationMenuRef?.current?.toggle(e)       
                e.stopPropagation()
                handleMarkRead()
              }}
              className="flex cursor-pointer items-center gap-1 rounded-3 p-1 font-medium text-gray-700 hover:bg-gray-200"
            >
              <i className="sctr-icon-book-open-01 text-[16px] " />
              <span>{capitalizedFirst(t(_read ? "notify.mark-as-unread" : "notify.mark-item-read"))}</span>
            </div>
            <div
              onClick={(e) => {
                notificationMenuRef?.current?.toggle(e)
                e.stopPropagation()
                handleRemoveNotify()
              }}
              className="flex cursor-pointer items-center gap-1 rounded-3 p-1 font-medium text-red-600 hover:bg-gray-200"
            >
              <i className="sctr-icon-trash-03 text-[16px] " />
              <span>{capitalizedFirst(t("notify.remove"))}</span>
            </div>
          </div>
        )
      },
    },
  ]

  const styleView = useMemo(() => {
    const commonWrapperView =
      "w-[24px] h-[24px] bottom-[0px] right-[-10px] rounded-full absolute flex items-center justify-center"
    const styleData = getStyleWrapperView()
    return {
      wrapperView: `${commonWrapperView} ${styleData.background}`,
      iconView: `${icon_image} ${styleData.color}`,
    }
  }, [])
  const calculateFromNow = (date: string) => {
    return moment(date).fromNow()
  }
  const handleMouseEnter = () => {
    setIsShowSetting(true)
  }
  const handleMouseLeave = () => {
    setIsShowSetting(false)
  }
  const handleRemoveNotify = () => {
    handleDeleteItem(id)
  }

  const getUrlDetail = () => {
    switch (module_type) {
    case MODULE_TYPE.SHOP:
    {
      let result = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}${web_link}`
      // shop mgt
      if(web_link.includes("approval-request")) {
        result = `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}${web_link}`
      }
      return result
    }
    case MODULE_TYPE.RESTAURANT:
      return `${process.env.REACT_APP_FOOD_DOMAIN_URL}${web_link}`
    case MODULE_TYPE.HOTEL:
      return `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}${web_link}`
    case MODULE_TYPE.TRAVEL_TOUR:
      return `${process.env.REACT_APP_FLIGHT_BETA_DOMAIN_URL}${web_link}`    
    default:
      return `${process.env.REACT_APP_HOME_DOMAIN_URL}${web_link}`
    }
  }

  const isFullLink = (link: string) => {
    return link.includes("https://")
  }
  const handleClickItem = () => {
    if (web_link) {
      const urlDetail = isFullLink(web_link) ? web_link : getUrlDetail()
      if (!_read) toggleNotification(id)
      window.open(urlDetail, "_self")
    }
  }
  const handleMarkRead = () => {
    toggleNotification(id)
  }
  const clickAction = (post_link: string) => {
    processAction(id, post_link)
  }
  return (
    <div
      onClick={handleClickItem}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`flex cursor-pointer items-center gap-3 p-1 px-[8px] ${
        !_read ? "bg-blue-50" : ""
      }`}
    >
      <div>
        <div className="relative h-[44px] w-[44px] rounded-full">
          <ImgCustom
            isAvatar={true}
            alt="userAvatar"
            url={sender_avatar || ""}
            className="h-full w-full rounded-full object-cover object-center"
          />
          <div className={styleView.wrapperView}>
            <i className={styleView.iconView} />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1">
          <div className="flex flex-1 flex-col gap-4px">
            <div className="text-[14px] leading-[20px] text-gray-700">
              <div className="inline-flex max-w-[160px]">
                <span className="truncate font-semibold">
                  {sender_full_name}
                </span>
              </div>
              <div className="ml-4px inline">{body}</div>
            </div>
            <span className="text-[12px] leading-[16px] text-gray-500">
              {" "}
              {calculateFromNow(send_time)}
            </span>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation()
              notificationMenuRef?.current?.toggle(e)
            }}
            className={`z-[2] ml-[30px] flex w-[28px] items-center justify-center ${
              isShowSetting ? "" : "invisible"
            }`}
          >
            <i className="sctr-icon-dots-horizontal text- text-gray-600" />
          </div>
        </div>
        {!_cta_complete && cta_action?.length ? (
          <div className="flex justify-end gap-1 py-3 pb-1 text-[14px] leading-[20px]">
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {cta_action?.map((i: any) => (
              <div key={i?.label}>
                {i?.label === "Decline" || i?.label === "Reject" ? (
                  <div
                    onClick={() => clickAction(i?.post_link)}
                    className="cursor-pointer rounded-3 border border-gray-300 bg-white px-[12px] py-[4px] text-gray-700"
                  >
                    {i?.icon ? (
                      <span
                        className={` ${i?.icon} cursor-pointer px-2 text-gray-700`}
                      ></span>
                    ) : null}

                    <span>{i?.label}</span>
                  </div>
                ) : null}
                {i?.label === "Accept" ? (
                  <div
                    onClick={() => clickAction(i?.post_link)}
                    className="cursor-pointer rounded-3 border border-blue-600 bg-blue-600 px-[12px] py-4px text-white"
                  >
                    {i?.icon ? (
                      <span
                        className={`${i?.icon}  cursor-pointer px-2 text-white`}
                      ></span>
                    ) : null}

                    <span>{i?.label}</span>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <Menu
        model={settingItems}
        popup
        ref={notificationMenuRef}
        className="mt-2 w-fit p-1"
      />
    </div>
  )
}
export default NotificationItemView
