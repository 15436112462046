import React from "react"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import StarView from "../rating-product-modal/star-view"
import { useTranslation } from "react-i18next"
import { ItemRatingViewProps } from "../rating-product-modal/props"
const StarRating = [1, 2, 3, 4, 5]

export default function RatingShipperOnlyView(props: ItemRatingViewProps) {
  const { rating, content } = props

  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div className="flex flex-col gap-3 p-3 text-center">
        <span className="text-18 font-semibold leading-7 text-gray-900">
          {capitalizedFirst(
            t("purchase-history.reviewing-your-delivery-experience")
          )}
        </span>
      </div>
      <div className="flex flex-row justify-center rounded-3 bg-blue-25 py-3 text-center">
        {StarRating.map((i, index) => (
          <StarView key={index} index={index} isCheck={i <= rating} />
        ))}
      </div>
      <div className="mt-3 flex flex-col">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {capitalizedFirst(t("purchase-history.rateFeedback"))}
        </span>
        <textarea
          readOnly
          value={content}
          placeholder={capitalizedFirst(t("global.enter-feedback-here"))}
          maxLength={100}
          className="mt-6px resize-none rounded-3 border border-gray-300 py-2 px-14px text-16 leading-24 text-gray-500 outline-none"
        />
      </div>
    </div>
  )
}
