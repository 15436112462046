import { AddressDirectoryControllerApi } from "@soctrip/shipment-service"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { AddressType } from "../types"

const WARD_DATA_STATE = {
  INIT: "INIT",
  VALID: "VALID",
  IN_VALID: "IN_VALID",
}

export interface ShopAddressViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForm: any
  index: number
  countryData: AddressType[]
  deleteAddress: (id: number) => void
}
const ShopAddressView = (props: ShopAddressViewProps) => {
  const { dataForm, index, countryData, deleteAddress} = props
  const [cityData, setCityData] = useState<AddressType[]>([])
  const [districtData, setDistrictData] = useState<AddressType[]>([])
  const [wardData, setWardData] = useState<AddressType[]>([])
  const [isWardValid, setIsWardValid] = useState<string>(WARD_DATA_STATE.INIT)
  const { t } = useTranslation()

  const isInValidField = (name: string) => {
    return !!(dataForm?.touched.address?.length && dataForm?.touched.address[index][`${name}`] && dataForm?.errors.address[index][`${name}`])
  }

  const getCityList = (countryId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressProvincesGet(countryId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = [] 
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.province_id,
              name: i?.province_name,
              postal_code: i?.postal_code || "",
              code_name: i?.code_name,
            }) 
          }) 
          setCityData(result) 
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err)) 
  }
  const getDistrictList = (cityId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressDistrictsGet(cityId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = [] 
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.district_id,
              name: i?.district_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            }) 
          }) 
          setDistrictData(result) 
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err)) 
  }

  const getWardList = (districtId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(configHeader(SERVICE.SHIPMENT))
      .addressWardsGet(districtId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = [] 
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.ward_id,
              name: i?.ward_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            }) 
          }) 
          if (!result.length) setIsWardValid(WARD_DATA_STATE.IN_VALID) 
          setWardData(result) 
        } else {
          setIsWardValid(WARD_DATA_STATE.IN_VALID) 
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err)) 
  }

  const currentData = useMemo(() => {
    let result = undefined
    if(dataForm?.values?.address?.length && dataForm.values.address[index]) result = dataForm.values.address[index]
    return result
  }, [dataForm?.values?.address, index])

  const isForeign = (currentData?.country?.code_name ?? "") != "vn"

  //trigger country change
  useEffect(() => {
    if (currentData?.country) {
      setCityData([]) 
      setDistrictData([])      
      dataForm.setFieldValue(`address[${index}.city]`, null) 
      dataForm.setFieldValue(`address[${index}.district]`, null)
      dataForm.setFieldValue(`address[${index}.ward]`, null)
      getCityList(currentData?.country?.id) 
    }
  }, [currentData?.country])

  // trigger city change
  useEffect(() => {
    if (currentData?.city) {
      setDistrictData([]) 
      dataForm.setFieldValue(`address[${index}.district]`, null)
      dataForm.setFieldValue(`address[${index}.ward]`, null)

      getDistrictList(currentData?.city?.id) 
    }
  }, [currentData?.city])

  // trigger district change
  useEffect(() => {
    if (currentData?.district) {
      setWardData([]) 
      dataForm.setFieldValue(`address[${index}.ward]`, null)
      setIsWardValid(WARD_DATA_STATE.INIT) 
      getWardList(currentData?.district?.id) 
    }
  }, [currentData?.district])

  const deleteItem = () => {
    deleteAddress(index)
  }
  return (
    <>
      {/* ADDRESS */}
      <fieldset className="mt-1 rounded-2 border border-gray-200 p-3 pt-1">
        {
          !index ? (
            <legend className="px-2 text-14 font-medium text-gray-700">
              {t("request-open-page.address-line")}
            </legend>
          ) : null
        }
        {/* Shop label */}
        <div className="flex flex-1 flex-col gap-1">
          {
            currentData?.isDefault ? (
              <span className="text-14 font-medium leading-20 text-gray-700">
                {t("request-open-page.address-default")}
              </span>
            ) : null
          }
          <div className="flex flex-1 items-center justify-between gap-1">
            <InputText
              className={`w-full ${!currentData.isDefault ? "opacity-50" : ""} ${
                isInValidField("default_label") ? "p-invalid" : ""
              } `}
              placeholder={
                t("request-open-page.enter-address-line") || ""
              }
              value={currentData?.default_label}
              name="default_label"
              onChange={(e) => {
                dataForm.setFieldValue(`address[${index}.default_label]`, e.target.value)
              }}
            />
            {
              !currentData?.isDefault ? (
                <div onClick={deleteItem} className="px-2 py-[12px] border border-red-300 rounded-3 cursor-pointer" >{capitalizedFirst(t("global.delete"))}</div>
              ) : null
            }    
          </div>
          {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.default_label && dataForm?.touched?.address?.length && dataForm.touched.address[index].default_label && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm?.errors?.address[index]?.default_label}
            </span>
          )}
        </div>
        <div
          id="shopInformation"
          className="mt-3 grid grid-cols-1 gap-3 md:grid-cols-2"
        >
          {/* COUNTRY */}
          <div className="flex flex-1 flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.country")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              value={currentData?.country}
              name="country"
              onChange={(e) => {
                // if (dataForm?.touched?.address?.length && !dataForm.touched.address[index]?.country)
                //   dataForm.setTouched({
                //     ...dataForm.touched,
                //     ...{ shop_country: true },
                //   })
                dataForm.setFieldValue(`address[${index}.country]`, e.value)
                dataForm.setFieldValue(`address[${index}.city]`, null) 
                dataForm.setFieldValue(`address[${index}.district]`, null)
                if (
                  e.value?.code_name === "us" &&
                  currentData?.subAddress
                )
                  dataForm.setFieldValue(`address[${index}.subAddress]`, "") 
              }}
              options={countryData}
              optionLabel="name"
              placeholder={t("request-open-page.select-a-country") || ""}
              className={`mt-[6px] w-full ${
                isInValidField("country") ? "p-invalid" : ""
              } `}
            />
            {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.country && dataForm?.touched?.address?.length && dataForm.touched.address[index].country && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm?.errors?.address[index]?.country}
              </span>
            )}
          </div>
          {/* CITY */}
          <div className="flex flex-1 flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {isForeign
                ? t("request-open-page.state")
                : t("request-open-page.city/province")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              value={currentData?.city}
              name="city"
              onChange={(e) => {
                // if (!dataForm?.touched?.shop_city)
                //   dataForm.setTouched({
                //     ...dataForm.touched,
                //     ...{ shop_city: true },
                //   })

                dataForm.setFieldValue(`address[${index}.city]`, e.value)
              }}
              options={cityData}
              optionLabel="name"
              placeholder={
                (isForeign
                  ? t("request-open-page.select-a-state")
                  : t("request-open-page.select-a-city/province")) || ""
              }
              className={`mt-[6px] w-full ${
                isInValidField("city") ? "p-invalid" : ""
              } `}
            />
            {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.city && dataForm?.touched?.address?.length && dataForm.touched.address[index].city && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm?.errors?.address[index]?.city}
              </span>
            )}
          </div>

          {/* DISTRICT */}
          <div className="flex flex-1 flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {isForeign
                ? t("request-open-page.city")
                : t("request-open-page.district")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              name="district"
              value={currentData?.district}
              onChange={(e) => {
                // if (!dataForm?.touched?.shop_district)
                //   dataForm.setTouched({
                //     ...dataForm.touched,
                //     ...{ shop_district: true },
                //   })
                dataForm.setFieldValue(`address[${index}.district]`, e.value)
              }}
              options={districtData}
              optionLabel="name"
              placeholder={
                (isForeign
                  ? t("request-open-page.select-a-city")
                  : t("request-open-page.select-a-district")) || ""
              }
              className={`mt-[6px] w-full ${
                isInValidField("district") ? "p-invalid" : ""
              } `}
            />
            {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.district && dataForm?.touched?.address?.length && dataForm.touched.address[index].district && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm?.errors?.address[index]?.district}
              </span>
            )}
          </div>

          {/* WARD */}
          <div className="flex flex-1 flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.ward")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              value={currentData?.ward}
              name="shop_ward"
              onChange={(e) => {
                // if (!dataForm?.touched?.shop_ward)
                //   dataForm.setTouched({
                //     ...dataForm.touched,
                //     ...{ shop_ward: true },
                //   }) 
                dataForm.setFieldValue(`address[${index}.ward]`, e.value)
              }}
              options={wardData}
              optionLabel="name"
              placeholder={t("request-open-page.select-a-ward") || ""}
              className={`mt-[6px] w-full ${
                isInValidField("ward") ? "p-invalid" : ""
              } `}
            />
            {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.ward && dataForm?.touched?.address?.length && dataForm.touched.address[index].ward && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {isWardValid === WARD_DATA_STATE.IN_VALID ? t("request-open-page.invalid-address") : dataForm?.errors?.address[index]?.ward}
              </span>
            )}
          </div>
        </div>
        
        {/* ADDRESS LINE 1*/}
        <div className="mt-[16px] flex flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {t(
              currentData?.country?.code_name === "us"
                ? "request-open-page.address-line-1"
                : "request-open-page.address"
            )}
            <span className="text-red-600">{" *"}</span>
          </span>
          <InputText
            className={`mt-[6px] w-full ${
              isInValidField("address") ? "p-invalid" : ""
            } `}
            placeholder={
              t(
                currentData?.country?.code_name === "us"
                  ? "request-open-page.enter-address-line-1"
                  : "request-open-page.enter-address-line"
              ) || ""
            }
            value={currentData?.address}
            name="address"
            onChange={(e) => {
              // if (!dataForm?.touched?.shop_address)
              //   dataForm.setTouched({
              //     ...dataForm.touched,
              //     ...{ shop_address: true },
              //   })
              dataForm.setFieldValue(`address[${index}.address]`, e.target.value)
            }}
          />
          <span className="mt-[6px] text-[12px] font-medium leading-[16px] text-[#e24c4c]">
            {capitalizedFirst(t("request-open-page.note-address-1"))}
          </span>
          {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.address && dataForm?.touched?.address?.length && dataForm.touched.address[index].address && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm?.errors?.address[index]?.address}
            </span>
          )}
        </div>

        {/* ADDRESS LINE 2 only support for region is USA*/}
        {currentData?.country?.code_name === "us" ? (
          <>
            <div className="mt-[16px] flex flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {t("request-open-page.address-line-2")}
              </span>
              <InputText
                className={`mt-[6px] w-full ${
                  isInValidField("subAddress") ? "p-invalid" : ""
                } `}
                placeholder={t("request-open-page.enter-address-line-2") || ""}
                value={currentData?.subAddress}
                name="subAddress"
                onChange={(e) => {
                  // if (!dataForm?.touched?.sub_shop_address)
                  //   dataForm.setTouched({
                  //     ...dataForm.touched,
                  //     ...{ sub_shop_address: true },
                  //   })
                  dataForm.setFieldValue(`address[${index}.subAddress]`, e.target.value)
                }}
              />
              <span className="mt-[6px] text-[12px] font-normal leading-[16px]">
                {capitalizedFirst(t("request-open-page.note-address-2"))}
              </span>
              {dataForm?.errors?.address?.length && dataForm?.errors?.address[index]?.subAddress && dataForm?.touched?.address?.length && dataForm.touched.address[index].subAddress && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm?.errors?.address[index]?.subAddress}
                </span>
              )}

            </div>
          </>
        ) : null}
      </fieldset>
    </>
  )
}

export default ShopAddressView
