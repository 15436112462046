import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "primereact/tooltip"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { useTranslation } from "react-i18next"

import ImgCustom from "../img-custom"
import { getFilterCategory } from "src/services/shopping-homepage-service/shopping-search-service"

import "swiper/css"
import "swiper/css/navigation"
import "./style.scss"

export default function HomePageCategories() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [categoriesList, setCategoriesList] = useState([])
  const [numberSlice, setNumberSlice] = useState(8)
  const [keyRender, setKeyRender] = useState(0)
  const handleClickCate = (item) => {
    navigate("/shopping/search", { state: { category: item } })
  }

  useEffect(() => {
    const fetchDataCategory = async () => {
      try {
        const res = await getFilterCategory(0, 100, "", i18n.language)
        setCategoriesList(res?.data?.data)
      } catch (err) {
        console.log(err) 
      }
    }
    fetchDataCategory()
  }, [i18n.language])

  const getStringCount = (count) => {
    if (count > 500 && count < 1000) {
      return `${500}+ ${t("global.products")}`
    } else if (count >= 1000) {
      const newCount = Math.floor(count / 1000)
      if (newCount >= 10) {
        return `10k+ ${t("global.products")}`
      } else {
        return `${newCount}k+ ${t("global.products")}`
      }
    } else {
      return `${count} ${t("global." + (count <= 1 ? "product" : "products"))}`
    }
  }

  function handleResize() {
    const wrapperView = document.getElementById("home-page-categories")
    let result = 8
    if(wrapperView) {
      if(wrapperView.clientWidth < 600) result = 4
      else if(wrapperView.clientWidth > 600 && wrapperView.clientWidth < 810) result = 6
    }
    if(result !== numberSlice) {
      setNumberSlice(result)
      setTimeout(() => {
        setKeyRender(new Date().getTime())
      }, 200)
    }
  }
  useEffect(() => {
    if(categoriesList.length) {      
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [categoriesList])
  return (
    <div key={keyRender} className="flex h-full items-center">
      <div className="h-full w-full overflow-hidden">
        <div
          id="home-page-categories"
          className="relative flex h-full w-full justify-center gap-1"
        >
          <Swiper
            navigation={true}
            spaceBetween={20}
            modules={[Navigation]}
            slidesPerView={numberSlice}
          >
            {categoriesList?.map((cate, index) => (
              <SwiperSlide key={index}>
                <div
                  onClick={() => handleClickCate(cate)}
                  className="flex h-[190px] cursor-pointer flex-col items-center gap-4px rounded-2 pt-2 text-gray-700"
                >
                  <div className="group h-[80px] w-[80px] sm:h-[100px] sm:w-[100px] md:h-[110px] md:w-[110px] lg:h-[122px] lg:w-[122px]">
                    <ImgCustom
                      url={cate?.catalog?.image?.id}
                      alt={cate?.catalog?.image?.original_name}
                      className="h-[80px] w-[80px] rounded-full border-[4px] border-white border-opacity-70 object-cover object-center shadow-lg shadow-blue-light-200 transition group-hover:shadow-blue-light-300 sm:h-[90px] sm:w-[90px] md:h-[100px] md:w-[100px] lg:h-[112px] lg:w-[112px]"
                    />
                  </div>
                  <Tooltip target=".target-name" />
                  <div className="flex w-full flex-col items-center">
                    <div
                      className="target-name w-full max-w-[90px] truncate text-center text-14 font-semibold leading-6 text-gray-700 md:max-w-[110px] md:text-16"
                      data-pr-tooltip={cate?.catalog?.name}
                      data-pr-position="top"
                      data-pr-at="right-2 top"
                    >
                      {cate?.catalog?.name}
                    </div>
                    <p className="text-12 font-normal leading-18 text-gray-700 lowercase">
                      {/* {cate?.count} Products */}
                      {getStringCount(cate?.count)}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
