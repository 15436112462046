import {
  ClaimVoucherControllerApi,
  PromotionControllerApi,
  SoctripPromotionControllerApi,
} from "@soctrip/axios-promotion-service"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import { configHeader } from "src/config/interceptors"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { handlePromiseAll, mathRoundNumber } from "src/utils/common"
import {
  DISCOUNT_TYPE,
  MAX_ORDER_PRICE,
  SERVICE,
  TYPE_VOUCHER_VIEW,
  VOUCHER_TYPE,
} from "../../../constants/common"
import EmptyPage from "./empty-voucher"
import "./index.scss"
import ItemView from "./item-view"
import { SelectVoucherModalProps, VoucherApplyType, VoucherType } from "./types"

export default function SelectVoucherModal(props: SelectVoucherModalProps) {
  const {
    isShow,
    handleClose,
    handleConfirm,
    voucherData,
    shopBilling,
    voucherApply,
    shipVoucherApply,
    isHiddenShip,
    dataReadOnly,
    isReadOnly,
    isHiddenFooter,
    isHiddenVoucher,
    idsShop,
    liveBilling,
    isHiddenDiscount,
    liveProductData,
    campaignId,
  } = props
  const { t } = useTranslation()
  const [vouchers, setVouchers] = useState<VoucherType[]>([])
  const [voucherCodeShared, setVoucherCodeShared] = useState<string>("")
  const [voucherSelected, setVoucherSelected] = useState<
    VoucherType | undefined
  >(undefined)
  const [shipVouchers, setShipVouchers] = useState<VoucherType[]>([])
  const [shipVoucherSelected, setShipVoucherSelected] = useState<
    VoucherType | undefined
  >(undefined)
  const [voucherDiscountSoctrip, setVoucherDiscountSoctrip] = useState<
    VoucherType[]
  >([])
  const [voucherPageIndex, setVoucherPageIndex] = useState<number>(1)
  const [shipVoucherPageIndex, setShipVoucherPageIndex] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [liveVouchers, setLiveVouchers] = useState<VoucherType[]>([])
  const [liveVoucherSelected, setLiveVoucherSelected] = useState<
    VoucherType | undefined
  >(undefined)
  const [searchVoucher, setSearchVoucher] = useState<VoucherType | undefined>(undefined)
  const [systemVoucher, setSystemVoucher] = useState<VoucherType | undefined>(
    undefined
  )
  const [liveVoucherPageIndex, setLiveVoucherPageIndex] = useState<number>(1)
  const [isInitView, setIsInitView] = useState<boolean>(false)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [voucherShopSearch, setVoucherShopSearch] = useState<VoucherType[]>([])
  const [voucherSystemSearch, setVoucherSystemSearch] = useState<VoucherType[]>([])
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )

  const isVoucherApply = (voucherData: VoucherType) => {
    if (voucherData.isInvalid) return false
    if (shopBilling) {
      const usagePerUser = voucherData.maximum_usage_per_user_count
        ? voucherData.maximum_usage_per_user_count
        : 0

      if (
        voucherData.is_limit_usage &&
        voucherData.used_count &&
        (voucherData.used_count * 100) / usagePerUser >= 100
      )
        return false
      if (
        voucherData.itemType === VOUCHER_TYPE.VOUCHER ||
        voucherData.itemType === VOUCHER_TYPE.LIVE_STREAM
      ) {
        return (
          shopBilling >= voucherData.minimum_order_price &&
          voucherData.platform !== "MOBILE"
        )
      }
      return voucherDiscount
        ? shopBilling - mathRoundNumber(voucherDiscount) >=
            voucherData.minimum_order_price && voucherData.platform !== "MOBILE"
        : shopBilling >= voucherData.minimum_order_price &&
            voucherData.platform !== "MOBILE"
    }
    return false
  }

  const getVoucherData = () => {
    return new PromotionControllerApi(configHeader(SERVICE.PROMOTION))
      .findByObjectIdsOrElementIds(
        0,
        50,
        voucherData?.cartId,
        voucherData?.objId
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          const result: VoucherType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            if((i.budget_voucher - i.used_amount > 0) || !i?.is_limit_budget) {
              if (i?.voucher_discount_range?.length) {
                let isHaveData = false
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                i?.voucher_discount_range?.forEach((rangeVoucher: any) => {
                  const maxValue = rangeVoucher?.max_order_price
                    ? rangeVoucher?.max_order_price
                    : MAX_ORDER_PRICE
                  if (
                    shopBilling > rangeVoucher?.min_order_price &&
                    shopBilling <= maxValue                  
                  ) {
                    isHaveData = true
                    result.push({
                      ...i,
                      ...{
                        isLimitPercent:
                          rangeVoucher?.is_limit_discount_price,
                        max_discount_price: rangeVoucher?.max_discount_price || 0,
                        minimum_order_price: rangeVoucher?.min_order_price || 0,
                        discount_amount: rangeVoucher?.discount_amount || 0,
                        discount_percent: rangeVoucher?.discount_percent || 0,
                        discount_type: rangeVoucher?.discount_type || "",
                      },
                    })
                    return
                  }
                })
                if (!isHaveData) {
                  const lastMinOrder =
                    i.voucher_discount_range[i.voucher_discount_range.length - 1]
                  result.push({
                    ...i,
                    ...{
                      isInvalid: true,
                      minimum_order_price: lastMinOrder?.min_order_price || 0,
                      max_order_price:
                        lastMinOrder?.max_order_price || MAX_ORDER_PRICE,
                    },
                  })
                }
              } else {
                result.push({ ...i, ...{ isInvalid: false } })
              }
            }
          })
          // sort list data
          result.forEach((i) => (i.isApply = isVoucherApply(i)))
          result.sort((i1, i2) => {
            const a1 = i1.isApply ? 1 : 0
            const a2 = i2.isApply ? 1 : 0
            return a2 - a1
          })
          setVouchers(result)
          if (voucherApply?.id) {
            const voucherSelect = result.find(
              (i: VoucherType) => i?.id === voucherApply.id
            )
            if (voucherSelect) {
              setVoucherSelected(voucherSelect)
            }
          }
        }
      })
      .catch((err) => console.log(err))
  }
  const getLiveVoucher = () => {
    if (!idsShop || !idsShop?.length) return
    const filters = campaignId ? `object_id==${idsShop[0]},live_campaign_id==${campaignId}` : `object_id==${idsShop[0]}`
    return new ClaimVoucherControllerApi(configHeader(SERVICE.PROMOTION))
      .findByObjectId1("LIVESTREAM", "ONGOING", 0, 50, filters)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.data?.length) {
          const shopLiveVoucher: VoucherType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            if(((i?.end_at && (new Date(i?.end_at || "").getTime() > new Date().getTime())) || !i?.end_at) &&  ((i?.voucher?.budget_voucher - i?.voucher?.used_amount > 0) || !i?.is_limit_budget)) {
              if (i?.voucher?.voucher_discount_range?.length) {
                let isHaveData = false
                i?.voucher?.voucher_discount_range?.forEach(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (rangeVoucher: any) => {
                    const liveVoucherDetail = i?.voucher as VoucherType
                    const maxValue = rangeVoucher?.max_order_price
                      ? rangeVoucher?.max_order_price
                      : MAX_ORDER_PRICE
                    if (
                      shopBilling >= rangeVoucher?.min_order_price &&
                      shopBilling <= maxValue
                    ) {
                      isHaveData = true
                      shopLiveVoucher.push({
                        ...liveVoucherDetail,
                        ...{
                          isLimitPercent:
                            rangeVoucher?.is_limit_discount_price,
                          max_discount_price:
                            rangeVoucher?.max_discount_price || 0,
                          minimum_order_price: rangeVoucher?.min_order_price || 0,
                          discount_amount: rangeVoucher?.discount_amount || 0,
                          discount_percent: rangeVoucher?.discount_percent || 0,
                          discount_type: rangeVoucher?.discount_type || "",
                          liveEndTime: i?.end_at,
                        },
                      })
                      return
                    }
                  }
                )
                if (!isHaveData) {
                  const lastMinOrder =
                    i.voucher.voucher_discount_range[
                      i.voucher.voucher_discount_range.length - 1
                    ]
                  shopLiveVoucher.push({
                    ...i?.voucher,
                    ...{
                      isInvalid: true,
                      minimum_order_price: lastMinOrder?.min_order_price || 0,
                      max_order_price:
                        lastMinOrder?.max_order_price || MAX_ORDER_PRICE,
                    },
                  })
                }
              } else {
                shopLiveVoucher.push({
                  ...i?.voucher,
                  ...{ isInvalid: false },
                })
              }
            }
          })
          // sort list data
          shopLiveVoucher.forEach((i) => (i.isApply = isVoucherApply(i)))
          shopLiveVoucher.sort((i1, i2) => {
            const a1 = i1.isApply ? 1 : 0
            const a2 = i2.isApply ? 1 : 0
            return a2 - a1
          })
          if (shopLiveVoucher.length) {
            setLiveVouchers(shopLiveVoucher)
            if (voucherApply?.id && voucherApply?.isLiveStream) {
              const voucherSelect = shopLiveVoucher.find(
                (i: VoucherType) => i?.id === voucherApply.id
              )
              if (voucherSelect) {
                setLiveVoucherSelected(voucherSelect)
              }
            }
          }
        }
      })
      .catch((err) => console.log(err))
  }

  const getVoucherShipping = () => {
    return new SoctripPromotionControllerApi(configHeader(SERVICE.PROMOTION))
      .findSoctripVoucherByObjectIdsOrElementIds(
        0,
        50,
        idsShop,
        voucherData?.objId
      )
      .then((res) => {
        if (res?.data?.data?.data?.length) {
          const result: VoucherType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            if((i.budget_voucher - i.used_amount > 0) || !i?.is_limit_budget) {
              if (i?.voucher_discount_range?.length) {
                let isHaveData = false
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                i?.voucher_discount_range?.forEach((rangeVoucher: any) => {
                  const maxValue = rangeVoucher?.max_order_price
                    ? rangeVoucher?.max_order_price
                    : MAX_ORDER_PRICE
                  if (
                    shopBilling >= rangeVoucher?.min_order_price &&
                    shopBilling <= maxValue
                  ) {
                    isHaveData = true
                    result.push({
                      ...i,
                      ...{
                        isLimitPercent:
                          rangeVoucher?.is_limit_discount_price,
                        max_discount_price: rangeVoucher?.max_discount_price || 0,
                        minimum_order_price: rangeVoucher?.min_order_price || 0,
                        discount_percent:
                          rangeVoucher?.discount_type === "PRICE"
                            ? rangeVoucher?.discount_amount || 0
                            : rangeVoucher?.discount_percent || 0,
                        discount_type: rangeVoucher?.discount_type,
                        discount_amount: rangeVoucher?.discount_amount || 0,
                      },
                    })
                    return
                  }
                })
                if (!isHaveData) {
                  const lastMinOrder =
                    i.voucher_discount_range[i.voucher_discount_range.length - 1]
                  result.push({
                    ...i,
                    ...{
                      isInvalid: true,
                      minimum_order_price: lastMinOrder?.min_order_price || 0,
                      max_order_price:
                        lastMinOrder?.max_order_price || MAX_ORDER_PRICE,
                      discount_type: i.voucher_discount_range[i.voucher_discount_range.length - 1]?.discount_type,
                      discount_amount: i.voucher_discount_range[i.voucher_discount_range.length - 1]?.discount_amount || 0,
                      discount_percent:
                      i.voucher_discount_range[i.voucher_discount_range.length - 1]?.discount_type === "PRICE"
                        ? i.voucher_discount_range[i.voucher_discount_range.length - 1]?.discount_amount || 0
                        : i.voucher_discount_range[i.voucher_discount_range.length - 1]?.discount_percent || 0,
                    },
                  })
                }
              } else {
                result.push({ ...i, ...{ isInvalid: false } })
              }
            }

          })
          // sort list data
          result.forEach((i) => (i.isApply = isVoucherApply(i)))
          result.sort((i1, i2) => {
            const a1 = i1.isApply ? 1 : 0
            const a2 = i2.isApply ? 1 : 0
            return a2 - a1
          })
          setShipVouchers(result)
          if (shipVoucherApply?.id) {
            const voucherSelect = result.find(
              (i: VoucherType) => i?.id === shipVoucherApply.id
            )
            if (voucherSelect) {
              setShipVoucherSelected(voucherSelect)
            }
          }
        }
      })
      .catch((err) => console.log(err))
  }

  // get init selected voucher
  const getSelectVoucherInit = () => {
    if(idsShop?.length) {
      const currentShop = shoppingCartStore.cartData.find(
        (i) => i.cartId === idsShop[0] 
      )
      if(currentShop?.systemVoucher) {
        setSystemVoucher(currentShop.systemVoucher)
        if(currentShop.systemVoucher.soctrip_voucher_type?.code === "FREE_SHIP") setShipVoucherSelected(currentShop.systemVoucher)
        else setVoucherSelected(currentShop.systemVoucher)
        return 
      }      
    }
    // all voucherData
    const voucherData = [...vouchers]
    // ship discount voucher
    shipVouchers.forEach(i => {
      if(i.soctrip_voucher_type?.code === "DISCOUNT") voucherData.push(i)
    })
    const initVoucherSelected = voucherData.find((i) => i.id === voucherApply?.id)
    if (initVoucherSelected) {
      setVoucherSelected(initVoucherSelected)
    }
  }
  useEffect(() => {
    function initView() {
      if (dataReadOnly) {
        if (isHiddenShip && isHiddenDiscount) {
          setVouchers(dataReadOnly || [])
        }
        if (isHiddenVoucher && isHiddenDiscount) {
          setShipVouchers(dataReadOnly || [])
        }
        if (isHiddenVoucher && isHiddenShip) {
          setVoucherDiscountSoctrip(dataReadOnly || [])
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apis: any[] = []
        apis.push(getVoucherData())
        apis.push(getVoucherShipping())
        apis.push(getLiveVoucher())
        setIsLoading(true)
        handlePromiseAll(apis).then(() => {
          
          setIsInitView(true)
          setIsLoading(false)
        })
      }
    }
    initView()
  }, [dataReadOnly, isHiddenShip, isHiddenVoucher])

  useEffect(() => {
    if(isInitView) getSelectVoucherInit()
  }, [isInitView])

  const handleUpdateSelected = (newSelected: string | undefined) => {
    if (!newSelected) {
      setVoucherSelected(undefined)
      return
    }
    // all voucherData
    const voucherData = [...vouchers]
    // system voucher
    if(systemVoucher && systemVoucher?.soctrip_voucher_type?.code !== "FREE_SHIP") voucherData.push(systemVoucher)
    // search voucher
    if(searchVoucher && searchVoucher.soctrip_voucher_type?.code !== "FREE_SHIP") voucherData.push(searchVoucher)
    // ship discount voucher
    shipVouchers.forEach(i => {
      if(i.soctrip_voucher_type?.code === "DISCOUNT") voucherData.push(i)
    })
    const newVoucherSelected = voucherData.find((i) => i.id === newSelected)
    if (newVoucherSelected) {
      if (liveVoucherSelected) setLiveVoucherSelected(undefined)
      setVoucherSelected(newVoucherSelected)
    }
  }
  const handleUpdateLiveSelected = (newSelected: string | undefined) => {
    if (!newSelected) {
      setLiveVoucherSelected(undefined)
      return
    }
    const newLiveVoucherSelected = liveVouchers.find(
      (i) => i.id === newSelected
    )
    if (newLiveVoucherSelected) {
      if (voucherSelected) setVoucherSelected(undefined)
      setLiveVoucherSelected(newLiveVoucherSelected)
    }
  }

  const handleUpdateShipSelected = (newSelected: string | undefined) => {
    if (!newSelected) {
      setShipVoucherSelected(undefined)
      return
    }
    const dataFilter = [...shipVouchers]
    if(searchVoucher && searchVoucher.soctrip_voucher_type?.code === "FREE_SHIP") dataFilter.push(searchVoucher)
    const newVoucherSelected = dataFilter.find((i) => i.id === newSelected)
    if (newVoucherSelected) setShipVoucherSelected(newVoucherSelected)
  }

  const voucherDiscount = useMemo(() => {
    if (voucherSelected || liveVoucherSelected) {
      let voucherApplyData: VoucherType | undefined = undefined
      if (voucherSelected)
        voucherApplyData = voucherSelected.voucher_discount_range?.length
          ? voucherSelected.voucher_discount_range[0]
          : undefined
      if (liveVoucherSelected)
        voucherApplyData = liveVoucherSelected.voucher_discount_range?.length
          ? liveVoucherSelected.voucher_discount_range[0]
          : undefined
      // Have Data
      if (voucherApplyData) {
        let discountValue = 0
        if (voucherApplyData.discount_type === DISCOUNT_TYPE.PRICE) {
          discountValue =
            voucherApplyData.discount_amount > shopBilling
              ? shopBilling
              : voucherApplyData.discount_amount
        } else {
          const compareValue = voucherSelected?.discount_percent || 0
          discountValue = (compareValue * shopBilling) / 100
        }
        return discountValue
      }
    }
    return 0
  }, [voucherSelected, liveVoucherSelected])

  const getLiveBilling = () => {
    let result = 0
    if(liveVoucherSelected?.element_ids?.length) {
      liveProductData.forEach(i => {
        if(liveVoucherSelected.element_ids?.includes(i.id)) result += i.value
      })
    } else result = liveBilling
    return result
  }

  const applyVoucher = () => {
    let voucherApply: VoucherApplyType | undefined = undefined
    let shipVoucherApply: VoucherApplyType | undefined = undefined
    let systemVoucherApply: VoucherType | undefined
    let discountValue = 0
    let isLiveStreamType = false
    // shop voucher
    if ((voucherSelected || liveVoucherSelected) && !voucherSelected?.isSystemVoucher) {
      let voucherApplyData: VoucherType | undefined = undefined
      if (voucherSelected) voucherApplyData = voucherSelected
      if (liveVoucherSelected) {
        voucherApplyData = liveVoucherSelected
        isLiveStreamType = true
      }
      const billingCompare = isLiveStreamType ? getLiveBilling() : shopBilling
      // Have Data
      if (voucherApplyData) {
        if (voucherApplyData.discount_type === DISCOUNT_TYPE.PRICE) {
          discountValue =
            voucherApplyData.discount_amount > billingCompare
              ? billingCompare
              : voucherApplyData.discount_amount
        } else {
          const percentValue = voucherApplyData?.discount_percent || 0
          if (voucherApplyData?.isLimitPercent) {
            discountValue =
              (percentValue * billingCompare) / 100 >
              voucherApplyData.max_discount_price
                ? voucherApplyData.max_discount_price
                : (percentValue * billingCompare) / 100
          } else {
            discountValue = (percentValue * billingCompare) / 100
          }
        }
        let budgetVoucherValue = 0
        if(voucherApplyData.isLimitPercent) {
          if (voucherApplyData?.budget_voucher) {
            const discountUse = voucherApplyData?.used_amount || 0
            budgetVoucherValue = voucherApplyData.budget_voucher - discountUse
          }
        } else {
          const discountUse = voucherApplyData?.used_amount || 0
          budgetVoucherValue = MAX_ORDER_PRICE - discountUse
        }       
        voucherApply = {
          id: voucherApplyData.id,
          value: discountValue || 0,
          name: voucherApplyData.name,
          payment_id: voucherApplyData?.payment_type_id,
          isLiveStream: isLiveStreamType,
          budgeValue: budgetVoucherValue,
          isBudgetLimit: !!voucherApplyData.is_limit_budget,
          liveVoucherTime: liveVoucherSelected && liveVoucherSelected.liveEndTime? liveVoucherSelected.liveEndTime : "",
        }
        console.log(voucherApply, "voucherApply")
      }
    }
    // ship voucher
    const discountCountCompare = liveVoucherSelected ? liveBilling : shopBilling
    if (
      shipVoucherSelected &&
      discountCountCompare - (discountValue || 0) >
        shipVoucherSelected?.minimum_order_price
    ) {
      if (shipVoucherSelected) {
        let budgetShipValue = 0
        if (shipVoucherSelected?.budget_voucher) {
          const discountUse = shipVoucherSelected?.used_amount || 0
          budgetShipValue = shipVoucherSelected.budget_voucher - discountUse
        }
        shipVoucherApply = {
          id: shipVoucherSelected.id,
          value: shipVoucherSelected?.discount_percent || 0,
          isLimit:
            shipVoucherSelected.discount_type === "PRICE"
              ? true
              : shipVoucherSelected?.is_limit_discount_amount,
          maxDiscount: shipVoucherSelected.max_discount_price,
          name: shipVoucherSelected.name,
          payment_id: shipVoucherSelected?.payment_type_id,
          type: shipVoucherSelected.discount_type || "",
          budgeValue: budgetShipValue,
          voucherType: shipVoucherSelected?.soctrip_voucher_type?.code || "",
          isBudgetLimit: !!shipVoucherSelected.is_limit_budget,
        }
      }
    }
    // cache search voucher by shop
    if(searchVoucher) systemVoucherApply = searchVoucher
    handleConfirm(voucherApply, shipVoucherApply, systemVoucherApply)
  }

  const footerContent = !isHiddenFooter ? (
    <div className="text-16px leading-24px mt-1 flex justify-end font-semibold">
      <button
        onClick={() => handleClose()}
        className="rounded-3 border border-gray-300 py-[10px] px-[18px] text-gray-700"
      >
        {capitalizedFirst(t("global.cancel"))}
      </button>
      <button
        className="ml-2 rounded-3 bg-blue-600 py-[10px] px-[18px] text-white hover:bg-blue-500"
        onClick={applyVoucher}
        autoFocus
      >
        {capitalizedFirst(t("global.confirm"))}
      </button>
    </div>
  ) : null

  const isHaveLiveStreamData = useMemo(() => {
    let result = false
    if (idsShop?.length) {
      const currentShop = shoppingCartStore.cartData.find(
        (i) => i.cartId === idsShop[0]
      )
      if (currentShop) {
        currentShop.objectDto.forEach((prod) => {
          if (
            prod.isSelected &&
            prod.isLiveStream &&
            prod.liveStreamDeal &&
            prod.liveToken
          ) {
            result = true
            return
          }
        })
      }
    }
    return result
  }, [idsShop])

  const handleSearch = () => {
    const apis = []
    apis.push(handleSearchVoucherByCode())
    apis.push(handleSearchSystemVoucher())
    setIsSearch(true)
    setIsLoading(true)
    setSearchVoucher(undefined)
    setVoucherSystemSearch([])
    setVoucherShopSearch([])
    handlePromiseAll(apis).then(() => {
      setIsLoading(false)
    })
  }
  useEffect(() => {
    if(!isLoading && isSearch && (voucherShopSearch.length || voucherSystemSearch.length )){
      const result = voucherSystemSearch.length ? voucherSystemSearch : voucherShopSearch
      if(result.length && searchVoucher?.id !== result[0].id) setSearchVoucher(result[0])
    }
  }, [isLoading, isSearch, voucherShopSearch, voucherSystemSearch])
  const handleSearchSystemVoucher = () => {
    if (idsShop) {
      return new SoctripPromotionControllerApi(configHeader(SERVICE.PROMOTION))
        .findPrivateVoucher(voucherData?.objId, voucherData?.objId, voucherCodeShared)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            const result = [] as VoucherType[]
            if((res.data.data?.budget_voucher - res.data.data?.used_amount <= 0) && res.data.data?.is_limit_budget) return
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data?.voucher_discount_range?.forEach((rangeVoucher: any) => {
              const maxValue = rangeVoucher?.max_order_price
                ? rangeVoucher?.max_order_price
                : MAX_ORDER_PRICE
              if (
                shopBilling >= rangeVoucher?.min_order_price &&
                shopBilling <= maxValue                  
              ) {
                result.push({
                  ...res.data.data,
                  ...{
                    isLimitPercent:
                      rangeVoucher?.is_limit_discount_price,
                    max_discount_price: rangeVoucher?.max_discount_price || 0,
                    minimum_order_price: rangeVoucher?.min_order_price || 0,
                    discount_amount: rangeVoucher?.discount_amount || 0,
                    discount_percent: rangeVoucher?.discount_percent || 0,
                    discount_type: rangeVoucher?.discount_type || "",
                  },
                })
              }
            })
            if(result.length) setVoucherSystemSearch(result)
          }
        })
        .catch((err) => console.log(err))
    }
  }
  const handleSearchVoucherByCode = () => {
    if (idsShop) {
      return new PromotionControllerApi(configHeader(SERVICE.PROMOTION))
        .findByVoucherCode(idsShop[0], voucherCodeShared, voucherData?.objId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            const result = [] as VoucherType[]
            if((res.data.data?.budget_voucher - res.data.data?.used_amount <= 0) && res.data.data?.is_limit_budget) return
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data?.voucher_discount_range?.forEach((rangeVoucher: any) => {
              const maxValue = rangeVoucher?.max_order_price
                ? rangeVoucher?.max_order_price
                : MAX_ORDER_PRICE
              if (
                shopBilling >= rangeVoucher?.min_order_price &&
                shopBilling <= maxValue                  
              ) {
                result.push({
                  ...res.data.data,
                  ...{
                    isLimitPercent:
                      rangeVoucher?.is_limit_discount_price,
                    max_discount_price: rangeVoucher?.max_discount_price || 0,
                    minimum_order_price: rangeVoucher?.min_order_price || 0,
                    discount_amount: rangeVoucher?.discount_amount || 0,
                    discount_percent: rangeVoucher?.discount_percent || 0,
                    discount_type: rangeVoucher?.discount_type || "",
                  },
                })
              }
            })
            if(result.length) setVoucherShopSearch(result)
          }
        })
        .catch((err) => console.log(err))
    }
  }

  const handleClearVoucherCode = () => {
    setVoucherCodeShared("")
    setSearchVoucher(undefined)
    setVoucherSystemSearch([])
    setVoucherShopSearch([])
    setIsSearch(false)
  }
  
  const handleChangeVoucherCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherCodeShared(e.target.value)
    if (!e.target.value) {
      if(isSearch) {
        getVoucherData()
        setIsSearch(false)
      }
    }
  }

  // get voucher UI display
  const vouchersData = useMemo(() => {

    const result = [] as VoucherType []
    // case search
    if(searchVoucher && searchVoucher?.soctrip_voucher_type?.code !== "FREE_SHIP") {
      result.push(searchVoucher)
    } else {
      if(voucherSelected) result.push(voucherSelected)
      // system voucher
      if(systemVoucher && systemVoucher?.soctrip_voucher_type?.code !== "FREE_SHIP" && voucherSelected?.id !== systemVoucher?.id) result.push(systemVoucher)
      // search action
      vouchers.forEach(i => {
        if(i.id !== systemVoucher?.id && i.id !== voucherSelected?.id) result.push(i)
      })
      shipVouchers.forEach(i => {
        if(i.soctrip_voucher_type?.code === "DISCOUNT") result.push(i)
      })
    }
    return result

  }, [vouchers, systemVoucher, shipVouchers, searchVoucher])

  // get ship voucher UI display
  const shipVoucherData = useMemo(() => {
    const result = [] as VoucherType []
    if(searchVoucher && searchVoucher?.soctrip_voucher_type?.code === "FREE_SHIP") {
      result.push(searchVoucher)
    } else {
      // selected
      if(shipVoucherSelected) result.push(shipVoucherSelected)
      // system voucher
      if(systemVoucher && systemVoucher?.soctrip_voucher_type?.code === "FREE_SHIP" && systemVoucher?.id !== shipVoucherSelected?.id) result.push(systemVoucher)
      shipVouchers.forEach(i => {
        if(i.soctrip_voucher_type?.code === "FREE_SHIP" && i.id !== shipVoucherSelected?.id) result.push(i)
      })
    }

    return result

  }, [shipVouchers, searchVoucher, systemVoucher])

  const isShipVoucherLoadMore = useMemo(() => {
    if (!shipVoucherData.length) return false
    return shipVoucherData.length - shipVoucherPageIndex * 3 >= 1
  }, [shipVoucherPageIndex, shipVoucherData])

  const isVoucherLoadMore = useMemo(() => {
    if (!vouchersData.length) return false
    return vouchersData.length - voucherPageIndex * 3 >= 1
  }, [vouchersData, voucherPageIndex])

  // get live data filter
  const liveDataFilter = useMemo(() => {
    const result = [] as VoucherType[]
    if(idsShop?.length && shoppingCartStore.cartData.length) {
      const productIds = [] as string[]
      const shopSelect = shoppingCartStore.cartData.find(s => s.cartId === idsShop[0])
      shopSelect?.objectDto.forEach(p => {
        if(p.isSelected) productIds.push(p.objectId)
      })
      liveVouchers.forEach(i => {
        if(i.element_ids?.length) {
          let isSupport = false
          productIds.forEach(p => {
            if(i.element_ids?.includes(p)) isSupport = true
          })
          if(isSupport) result.push(i)
        } else result.push(i)
      })
    }

    return result
  }, [liveVouchers, shoppingCartStore, idsShop])

  const isLiveVoucherLoadMore = useMemo(() => {
    if (!liveDataFilter.length) return false
    return liveVouchers.length - liveVoucherPageIndex * 3 >= 1
  }, [liveVoucherPageIndex, liveDataFilter])

  return (
    <Dialog
      id="voucherModal"
      draggable={false}
      header={capitalizedFirst(
        t(
          isReadOnly
            ? "shoppingCartPage.shopVouchers"
            : "shoppingCartPage.selectVouchers"
        )
      )}
      visible={isShow}
      onHide={() => handleClose()}
      footer={footerContent}
    >
      <div className="no-scrollbar flex w-full min-w-[unset] flex-col px-4 pb-4 md:min-w-[500px]">
        <div className="mt-4px mb-2 flex items-center gap-1">
          <div className="relative flex-1">
            <InputText
              placeholder={t("shoppingPage.enter-voucher") as string}
              className="h-[44px] w-full"
              value={voucherCodeShared}
              onChange={(e) => handleChangeVoucherCode(e)}
            />
            {voucherCodeShared && (
              <i
                className="pi pi-times absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
                onClick={handleClearVoucherCode}
              ></i>
            )}
          </div>
          <Button
            disabled={!voucherCodeShared}
            className="h-[44px] text-14"
            label={t("global.search") as string}
            onClick={handleSearch}
          />
        </div>
        <div className="flex flex-col">
          {isLoading ? (
            <>
              <span className="text-16 font-semibold leading-24 text-gray-700">
                {capitalizedFirst(t("shoppingCartPage.shopVouchers"))}
              </span>
              <div className="my-3 flex h-[300px] w-full items-center justify-center gap-2 rounded-3 bg-white">
                <p className="animate-pulse duration-75">
                  {capitalizedFirst(t("global.loading"))}
                </p>
                <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
              </div>
            </>
          ) : (
            <div className="flex flex-col ">
              {/* LIVESTREAM VOUCHER SESSION */}
              {isHaveLiveStreamData && liveDataFilter?.length ? (
                <div className="mb-1 flex flex-col">
                  <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                    {capitalizedFirst(
                      t("shoppingCartPage.live-streams-voucher-title")
                    )}
                  </span>
                  <div className="mt-1">
                    {liveDataFilter
                      .slice(0, liveVoucherPageIndex * 3)
                      .map((item, index) => (
                        <ItemView
                          key={item?.id}
                          index={index}
                          itemType={VOUCHER_TYPE.LIVE_STREAM}
                          liveVoucherSelected={liveVoucherSelected}
                          shopBilling={shopBilling}
                          updateSelectedLive={(
                            newSelected: string | undefined
                          ) => handleUpdateLiveSelected(newSelected)}
                          {...item}
                          isReadOnly={isReadOnly}
                        />
                      ))}
                    {isLiveVoucherLoadMore ? (
                      <div
                        onClick={() =>
                          setLiveVoucherPageIndex(liveVoucherPageIndex + 1)
                        }
                        className="mt-1 flex cursor-pointer items-center justify-center py-4px text-[14px] font-semibold leading-[20px] text-blue-700"
                      >
                        {capitalizedFirst(t("shoppingPage.view-more"))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              
              {/* VOUCHER SESSION */}
              {!isHiddenVoucher ? (
                <div className="flex flex-col">
                  <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                    {capitalizedFirst(t("shoppingCartPage.shop-voucher"))}
                  </span>
                  <div className="mt-1">
                    {vouchersData.length ? (
                      <>
                        {vouchersData
                          .slice(0, voucherPageIndex * 3)
                          .map((item, index) => (
                            <ItemView
                              key={item?.id}
                              index={index}
                              itemType={VOUCHER_TYPE.VOUCHER}
                              voucherSelected={voucherSelected}
                              shopBilling={shopBilling}
                              updateSelected={(
                                newSelected: string | undefined
                              ) => handleUpdateSelected(newSelected)}
                              {...item}
                              isReadOnly={isReadOnly}
                            />
                          ))}
                      </>
                    ) : (
                      <EmptyPage
                        voucherType={VOUCHER_TYPE.VOUCHER}
                        typeView={TYPE_VOUCHER_VIEW.SHOP}
                      />
                    )}
                    {isVoucherLoadMore ? (
                      <div
                        onClick={() =>
                          setVoucherPageIndex(voucherPageIndex + 1)
                        }
                        className="mt-1 flex cursor-pointer items-center justify-center py-4px text-[14px] font-semibold leading-[20px] text-blue-700"
                      >
                        {capitalizedFirst(t("shoppingPage.view-more"))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {/* SHIP VOUCHER SESSION */}
              {!isHiddenShip && !isHiddenVoucher ? (
                <div className="my-3 ml-[-24px] h-[1px] w-[calc(100%+48px)] bg-gray-200" />
              ) : null}
              {!isHiddenShip ? (
                <div className="flex flex-col">
                  <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                    {capitalizedFirst(t("shoppingCartPage.ship-voucher"))}
                  </span>
                  <div className="mt-1">
                    {shipVoucherData.length ? (
                      <>
                        {shipVoucherData
                          .slice(0, shipVoucherPageIndex * 3)
                          .map((item, index) => (
                            <ItemView
                              key={item?.id}
                              index={index}
                              itemType={VOUCHER_TYPE.SHIPPING}
                              shipVoucherSelected={shipVoucherSelected}
                              shopBilling={shopBilling}
                              voucherDiscount={voucherDiscount || 0}
                              updateSelectedShip={(
                                newSelected: string | undefined
                              ) => handleUpdateShipSelected(newSelected)}
                              {...item}
                              isReadOnly={isReadOnly}
                            />
                          ))}
                      </>
                    ) : (
                      <EmptyPage
                        voucherType={VOUCHER_TYPE.SHIPPING}
                        typeView={TYPE_VOUCHER_VIEW.SHOP}
                      />
                    )}
                    {isShipVoucherLoadMore ? (
                      <div
                        onClick={() =>
                          setShipVoucherPageIndex(shipVoucherPageIndex + 1)
                        }
                        className="mt-1 flex cursor-pointer items-center justify-center py-4px text-[14px] font-semibold leading-[20px] text-blue-700"
                      >
                        {capitalizedFirst(t("shoppingPage.view-more"))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {/* VOUCHER DISCOUNT */}

              {/* {!isHiddenShip && !isHiddenVoucher ? (
                <div className="my-3 ml-[-24px] h-[1px] w-[calc(100%+48px)] bg-gray-200" />
              ) : null} */}
              {isHiddenShip && isHiddenVoucher ? (
                <div className="flex flex-col">
                  <span className="text-[16px] font-semibold leading-[24px] text-gray-700">
                    {capitalizedFirst(t("shoppingCartPage.ship-voucher"))}
                  </span>
                  <div className="mt-1">
                    {voucherDiscountSoctrip.length ? (
                      <>
                        {voucherDiscountSoctrip
                          .slice(0, shipVoucherPageIndex * 3)
                          .map((item, index) => (
                            <ItemView
                              key={item?.id}
                              index={index}
                              itemType={VOUCHER_TYPE.DISCOUNT}
                              shopBilling={shopBilling}
                              voucherDiscount={voucherDiscount || 0}
                              updateSelectedShip={(
                                newSelected: string | undefined
                              ) => handleUpdateShipSelected(newSelected)}
                              {...item}
                              isReadOnly={isReadOnly}
                            />
                          ))}
                      </>
                    ) : (
                      <EmptyPage
                        voucherType={VOUCHER_TYPE.DISCOUNT}
                        typeView={TYPE_VOUCHER_VIEW.SHOP}
                      />
                    )}
                    {isShipVoucherLoadMore ? (
                      <div
                        onClick={() =>
                          setShipVoucherPageIndex(shipVoucherPageIndex + 1)
                        }
                        className="mt-1 flex cursor-pointer items-center justify-center py-4px text-[14px] font-semibold leading-[20px] text-blue-700"
                      >
                        {capitalizedFirst(t("shoppingPage.view-more"))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}
