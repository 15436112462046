import React, { useEffect, useState } from "react"
import { InputNumber } from "primereact/inputnumber"
import "./index.scss"
import { InputNumberCustomProps } from "./types"
import { useDebounce } from "use-debounce"

export default function InputNumberCustom(props: InputNumberCustomProps) {
  const { value, updateValue, min, max, confirmDelete } = props
  const [inputValue, setInputValue] = useState<number>(0)
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [inputDebounce] = useDebounce(inputValue, 500)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    if (isLoad && value !== inputValue) {
      const result = inputValue > max ? max : inputValue
      updateValue(result)
    }
  }, [inputDebounce])

  useEffect(() => {
    setIsLoad(true)
  }, [])

  useEffect(() => {
    if (inputValue > max) setInputValue(max)
  }, [inputValue, max])

  const handleOriginValueChange = (newValue: number) => {
    if (inputValue === newValue && newValue === 1) {
      if (confirmDelete) confirmDelete()
    }
  }

  return (
    <div className="input-number-custom rounded-3 border border-gray-200">
      <InputNumber
        min={min}
        max={max}
        value={inputValue}
        onValueChange={(e) => handleOriginValueChange(e.value as number)}
        onChange={(e) => setInputValue(e.value as number)}
        showButtons
        buttonLayout="horizontal"
        inputClassName="text-gray-700"
        decrementButtonClassName="text-16 text-gray-700"
        incrementButtonClassName="text-16 text-gray-700"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
      />
    </div>
  )
}
