import React from "react"
import "./product-detail-custom.scss"
import { useNavigate } from "react-router-dom"
import {
  BreadCrumbType,
} from "./props"

const CustomBreadcrumb = (props: BreadCrumbType) => {
  const navigate = useNavigate()
  const { label, url, index, shopId, isLast } = props
  const handleClick = () => {
    navigate("/shopping/merchant-page/categories?", {
      state: { shopId: shopId, categoryId: url },
    })
  }
  if (index) {
    return (
      <div className="ml-2 flex items-center text-gray-700">
        <i className="pi pi-angle-right" />
        <span onClick={handleClick} className={`ml-2 cursor-pointer ${isLast ? "font-bold" : ""}`}>
          {label}
        </span>
      </div>
    )
  }
  return (
    <span onClick={handleClick} className="cursor-pointer text-gray-700">
      {label}
    </span>
  )
}
export default CustomBreadcrumb
