import React, { useState } from "react"
import { Dialog } from "primereact/dialog"
import { CreateAccountModalType, STEP_VIEW } from "./props"
import "./index.scss"
import RegisterForm from "./register-form"
import ConfirmForm from "./confirm-form"

export default function CreateAccountModal(props: CreateAccountModalType) {
  const { isShow, handleClose } = props
  const [stepView, setStepView] = useState<string>(STEP_VIEW.REGISTER)
  return (
    <Dialog
      id="createAccountModal"
      visible={isShow}
      style={{ width: "400px", maxWidth: "90%" }}
      onHide={() => handleClose()}
      draggable={false}
    >
      <div className="flex flex-col items-center justify-center">
        {stepView === STEP_VIEW.REGISTER ? (
          <RegisterForm
            updateStateView={() => setStepView(STEP_VIEW.CONFIRM)}
          />
        ) : null}
        {stepView === STEP_VIEW.CONFIRM ? <ConfirmForm /> : null}
      </div>
    </Dialog>
  )
}
