import { Skeleton } from "primereact/skeleton"
import React from "react"

export default function SkeletonDetails() {
  return (
    <div className="card mx-auto flex h-full w-full p-3">
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full flex-col gap-3 md:flex-row">
          <Skeleton width="30rem" height="20rem"></Skeleton>
          <div className="flex w-full flex-col gap-3">
            <Skeleton className="mb-2 w-full"></Skeleton>
            <Skeleton width="15rem" className="mb-2"></Skeleton>
            <Skeleton width="30rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="30rem" className="mb-2"></Skeleton>
            <Skeleton height="5.2rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div className="w-full">
          <div className="flex w-full flex-col gap-3">
            <Skeleton height="5.2rem" className="mb-2"></Skeleton>
            <Skeleton className="mb-2 w-full"></Skeleton>
            <Skeleton width="15rem" className="mb-2"></Skeleton>
            <Skeleton width="30rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="30rem" className="mb-2"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  )
}
