import React, { useEffect, useState } from "react"
import * as StockControllerApi from "@soctrip/axios-stock-service"
import CustomCard from "src/components/card-custom/card-custom/custom-card"
import { CardCustomType } from "src/constants/constants/card-custom-type"
import { Navigation } from "swiper/modules"
import "./index.scss"
import SkeletonLoading from "src/components/skeleton-loading"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/effect-coverflow"
import EmptyPage from "src/pages/empty-page"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { CustomCardProps } from "src/components/card-custom/card-custom/custom-card-props"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ProductDetailRelatedShop(props: any) {
  const { name_shop, products_related, bloomData, handleGetBloomData } = props
  const productList = new StockControllerApi.StockProductControllerApi(
    configHeader(SERVICE.STOCK)
  )
  const { t } = useTranslation()
  const [products, setProducts] = useState<[]>([])
  const [loadingProductRelated, setLoadingProductRelated] =
    useState<boolean>(false)
  useEffect(() => {
    const fetchData = () => {
      setLoadingProductRelated(true)
      const localRegion = localStorage.getItem("localRegion") || ""
      productList
        .productsListGet(products_related, {
          headers: {
            region: localRegion,
          },
        })
        .then((res) => {
          setProducts(res?.data?.data)
          setLoadingProductRelated(false)
        })
        .catch(() => setLoadingProductRelated(false))
    }
    fetchData()
  }, [products_related])

  const detectStructView = (index: number) => {
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = products.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach((i: CustomCardProps) => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === "ONGOGING" || i?.flash_deal_status === "UPCOMING" ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }


  if (!products_related || !products_related.length) return null
  return (
    <div className="rounded-3 border border-gray-200 bg-white p-3 shadow-sm">
      <div>
        <span className="flex flex-row items-center gap-4px truncate pb-4 text-20 font-semibold text-gray-800">
          {capitalizedFirst(t("product-details.more-relate-to"))}
          <span className="text-18 font-semibold leading-7 text-blue-light-600">
            {name_shop}
          </span>
        </span>
      </div>

      <div
        id="container-swiper-related"
        className="relative flex w-full py-2 lg:h-auto  lg:w-auto"
      >
        {loadingProductRelated ? (
          <div className="h-fit w-full">
            <SkeletonLoading limit={3} />
          </div>
        ) : (
          <>
            {products.length ? (
              <Swiper
                direction="horizontal"
                modules={[Navigation]}
                navigation={true}
                slidesPerView={"auto"}
                spaceBetween={20}
                className="h-full w-full"
              >
                {products.map((pro: CustomCardProps, index: number) => (
                  <SwiperSlide key={index}>
                    <div className="max-w-[200px]">
                      <CustomCard
                        key={pro.id}
                        id={pro.id}
                        typeCard={CardCustomType.product}
                        imgUrl={pro.avatar?.id}
                        avatar={pro.avatar}
                        name={pro.name}
                        point={pro.point}
                        sum_review={pro.sum_review}
                        price={pro.price}
                        price_after_tax={pro.price_after_tax}
                        original_price={pro.original_price}
                        original_price_after_tax={pro.original_price_after_tax}
                        quantity={pro.quantity}
                        productAttr={pro.product_attributes}
                        productId={pro.id}
                        shop_id={pro?.shop_id}
                        shop_name={pro.shop?.name}
                        sub_label={pro.sub_label}
                        shop={pro.shop}
                        handleReload={handleGetBloomData}
                        voucherOfProducts={[pro?.voucher as string]}
                        bloom_filter={bloomData as string}
                        sold={pro?.sold}
                        is_flash_deal={pro.is_flash_deal}
                        flash_deal_status={pro?.flash_deal_status}
                        flash_deals={pro?.flash_deals}
                        flash_deals_time={pro?.flash_deals_time}
                        vouchers={pro?.vouchers}
                        structView={detectStructView(index)}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="h-auto w-full">
                <EmptyPage message="Not found data. Please try again later." />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
