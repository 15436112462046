import React, { useEffect, useMemo, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import freeShip from "src/assets/images/freeship-icon.png"
import hotVoucherIcon from "src/assets/images/hot-voucher-icon.png"
import hotDealIcon from "src/assets/images/hot-deal-icon.png"
import liveIcon from "src/assets/images/livestream-icon.svg"
import { useTranslation } from "react-i18next"
import "swiper/css"
import "swiper/css/navigation"
import "./index.scss"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useNavigate } from "react-router-dom"
import { FILTER_TYPE } from "src/constants/common"
import { ROUTERS } from "src/constants/router"
import { FlashDealCampaignControllerApi } from "@soctrip/axios-advertising-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import ImgCustom from "./../img-custom/index"
import i18n from "src/locales"

export default function HomePageAttribute() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [attributeData, setAttributeData] = useState([])
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])
  const handleClickAttributes = (attribute) => {
    if (attribute?.code === "LIVE") {
      navigate("/shopping/"+ROUTERS.LIVESTREAM)
    } else if(attribute?.marketingName) {
      navigate(`/shopping/promotion/${attribute?.marketingName}`)
    } else {
      navigate(`/shopping/search?${attribute?.type}#${attribute?.code}`)
    }
  }

  const attributeDataLocal = [
    {
      name: `${capitalizedFirst(t("global.shopping-live"))}`,
      code: "LIVE",
      image: liveIcon,
      color: "#FFF1F3",
      type: FILTER_TYPE.FILTER,
    },
    {
      name: `${capitalizedFirst(
        t("global.freeship-in-soctrip", { getAppTitle: getAppTitle })
      )}`,
      code: "FREESHIP",
      image: freeShip,
      color: "#EDFCF2",
      type: FILTER_TYPE.FILTER,
    },
    {
      name: `${capitalizedFirst(t("global.hot-voucher"))}`,
      code: "HOT_VOUCHER",
      image: hotVoucherIcon,
      color: "#FEFBE8",
      type: FILTER_TYPE.FILTER,
    },
    {
      name: `${capitalizedFirst(t("global.stay-on-trend-with-hot-deals"))}`,
      code: "-week_count|long",
      type: FILTER_TYPE.SORT,
      image: hotDealIcon,
      color: "#FFF1F3",
    },
  ]

  const handleGetListFilter = async () => {
    new FlashDealCampaignControllerApi(configHeader(SERVICE.ADVERTISING))
      .filterQuickSearch(0, 100, {
        headers: {
          region: localStorage.getItem("localRegion") || "",
        },
      })
      .then((response) => {
        const filterArray = []
        const data = response?.data?.data?.data
        data?.map((item) => {
          const filter = {
            name: item?.title,
            type: FILTER_TYPE.ADVERTISING,
            code: item?.id,
            image: item?.filter_thumbnail,
            color: "#FFF1F3",
            marketingName: item?.marketing_name || "",
          }
          filterArray.push(filter)
        })
        setAttributeData([...filterArray, ...attributeDataLocal])
      })
      .catch((error) => {
        setAttributeData([...attributeDataLocal])
        console.log(error)
      })
  }

  useEffect(() => {
    handleGetListFilter()
  }, [i18n.language])

  return (
    <div className="flex h-full w-full items-center">
      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-3 bg-white px-1 py-2">
        <div id="home-page-attributes" className="relative flex h-full w-full">
          <Swiper
            navigation={true}
            spaceBetween={38}
            modules={[Navigation]}
            slidesPerView={"auto"}
          >
            {attributeData?.map((attribute, index) => (
              <SwiperSlide key={index}>
                <div
                  onClick={() => {
                    handleClickAttributes(attribute)
                  }}
                  className="flex w-[80px] cursor-pointer flex-col items-center gap-6px rounded-2 text-gray-700 md:w-[100px] lg:w-[160px]"
                >
                  <div
                    className="flex h-[65px] w-[65px] items-center justify-center rounded-full md:h-[80px] md:w-[80px]"
                    style={{ backgroundColor: attribute?.color }}
                  >
                    {attribute?.type === FILTER_TYPE.ADVERTISING ? (
                      <div className="flex h-[40px] w-[40px] items-center justify-center md:h-[54px] md:w-[54px]">
                        <ImgCustom
                          url={attribute?.image}
                          alt="image"
                          className="h-full w-full rounded-full object-cover object-center transition"
                        />
                      </div>
                    ) : (
                      <div className="flex h-[40px] w-[40px] items-center justify-center md:h-[48px] md:w-[48px]">
                        <img
                          src={attribute?.image}
                          alt=""
                          className="h-[48px] w-[48px]"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex w-full flex-col items-center">
                    <div
                      id="attribute-name"
                      className="target-name w-full min-w-[110px] max-w-[181px] break-words text-center text-12 font-medium leading-5 text-gray-500 md:text-14"
                    >
                      {attribute?.name}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
